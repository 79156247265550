import {useRef, useCallback, useEffect} from 'react'
import axios from 'axios'

const UseCancelTokenHasKey = () => {
  const axiosSources = useRef(new Map()) // Use a Map to store multiple cancel tokens

  const newCancelTokenHasKey = useCallback((key) => {
    // Cancel the existing token for the key if it exists
    if (axiosSources.current.has(key)) {
      axiosSources.current.get(key).cancel('Operation canceled due to new request.')
    }
    // Create a new cancel token and store it in the Map
    const source = axios.CancelToken.source()
    axiosSources.current.set(key, source)
    return source.token
  }, [])

  const isCancelHasKey = useCallback((error) => axios.isCancel(error), [])

  useEffect(() => {
    return () => {
      // Cancel all ongoing requests when the component unmounts
      axiosSources.current.forEach((source) =>
        source.cancel('Operation canceled due to component unmounting.')
      )
    }
  }, [])

  return {newCancelTokenHasKey, isCancelHasKey}
}

export default UseCancelTokenHasKey
