import {useFormik} from 'formik'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {OPTION_DIMENSION_UNIT} from '../../../../orders/core/_const'
import SettingsService from '../../../core/_requests'
import clsx from 'clsx'
import {omit} from 'lodash'

type Props = {
  show: boolean
  handleClose: () => void
  handleCreatePackagesSuccess: any
}

const CreatePackagesModal: React.FC<Props> = ({show, handleClose, handleCreatePackagesSuccess}) => {
  const intl = useIntl()
  const {stringYup, numberYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)

  const initValidateSchema = useMemo(() => {
    return {
      name: stringYup(255, 'PACKAGE_NAME'),
      length: numberYup.parcel('LENGTH'),
      width: numberYup.parcel('WIDTH'),
      height: numberYup.parcel('HEIGHT'),
      dimension_unit: stringYup(50, 'UNIT'),
    }
  }, [numberYup, stringYup])
  const [validateSchema] = useState<any>(initValidateSchema)
  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      name: null,
      length: null,
      width: null,
      height: null,
      dimension_unit: null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingForm(true)
      try {
        const res = await SettingsService.createPackages(
          {...values, unit: values.dimension_unit},
          {cancelToken: newCancelToken()}
        )
        if (res) {
          toast.success(intl.formatMessage({id: 'CREATED_PACKAGE_SUCCESSFULLY'}))
          handleCloseModal()
          handleCreatePackagesSuccess({...res, ...omit(formik.values, 'name')})
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_create_package'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-650px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'ADD_CUSTOM_PACKAGE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='h-250px scroll-y'>
            <Container>
              <div className='row mb-12'>
                <div className='col-md-12 mb-1'>
                  <div className='d-flex'>
                    <InputTextFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label'
                      required
                      label={intl.formatMessage({id: 'PACKAGE_NAME'})}
                      formik={formik}
                      name='name'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 d-flex'>
                {['length', 'width', 'height'].map((item, index) => {
                  return (
                    <div className={clsx('col-3')} key={index}>
                      <InputTextFormik
                        type='number'
                        min={0}
                        className={'mx-1'}
                        labelClassName={'text-muted'}
                        label={intl.formatMessage({id: item.toUpperCase()})}
                        formik={formik}
                        name={item}
                        checkFormik={false}
                        required
                      />
                    </div>
                  )
                })}
                <div className='col-3'>
                  <SelectFormik
                    className='mx-1'
                    label={intl.formatMessage({id: 'UNIT'})}
                    labelClassName={'text-muted'}
                    options={OPTION_DIMENSION_UNIT}
                    formik={formik}
                    name={'dimension_unit'}
                    checkFormik={false}
                    required
                    placeholder=''
                  />
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CreatePackagesModal}
