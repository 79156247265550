import {useFormik} from 'formik'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {Button, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import SettingsService from '../../../core/_requests'
import UserService from '../../../../users/core/_requests'
import {useAuth} from '../../../../auth'
import {InputPassword} from '../../../../../../_gori/partials/widgets/form/InputPassword'

type Props = {
  show: boolean
  handleClose: () => void
}

const ChangePasswordModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {authYup, stringYup} = UseYupValidate()
  const {setCurrentUser} = useAuth()
  const {newCancelToken} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const validateSchema = useMemo(() => {
    return {
      current_password: stringYup(50, 'CURRENT_PASSWORD'),
      password: authYup.password(),
      password_confirmation: authYup.passwordConfirmation(),
    }
  }, [authYup, stringYup])

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const config = {cancelToken: newCancelToken()}
        setSubmitting(true)
        await SettingsService.changePassword(values, config)
        setCurrentUser(await UserService.getUser(config))
        toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
      } catch (error: any) {
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_change_password'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'CHANGE_YOUR_PASSWORD'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='change-password-block'>
                <InputPassword
                  className={'mb-4'}
                  label={intl.formatMessage({id: 'CURRENT_PASSWORD'})}
                  size={'lg'}
                  formik={formik}
                  name={'current_password'}
                  openHighlight={false}
                  required
                />
                <InputPassword
                  className={'mb-4'}
                  label={intl.formatMessage({id: 'NEW_PASSWORD'})}
                  size={'lg'}
                  strengthMessage={intl.formatMessage({id: 'PASSWORD_STRENGTH_MESSAGE'})}
                  formik={formik}
                  name={'password'}
                  required
                />
                <InputPassword
                  className={'mb-4'}
                  label={intl.formatMessage({id: 'CONFIRM_PASSWORD'})}
                  size='lg'
                  openHighlight={false}
                  formik={formik}
                  name={'password_confirmation'}
                  required
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                disabled={formik.isSubmitting}
                event={formik.submitForm}
                loading={formik.isSubmitting}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {ChangePasswordModal}
