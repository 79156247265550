import _ from 'lodash'
import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  show: boolean
  handleClose: () => void
  errors: any
}

const FileErrorModal: React.FC<Props> = ({show, handleClose, errors}) => {
  const intl = useIntl()

  return (
    <Modal
      id='gori_batch_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-400px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'ERRORS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          {errors &&
            Object.values(errors).map((value: any, index) => (
              <div key={index}>
                <div className='col-12'>
                  <div className='fw-bolder'>
                    {intl.formatMessage({id: 'ROW'})}: {parseInt(value.row)}
                  </div>
                </div>
                <div className='col-auto'>
                  {value.error &&
                    value.error.map((error: any, index: number) => {
                      let arrayError = error.split('|')
                      if (arrayError.length === 1) {
                        return (
                          <div key={index} className='fw-bolder text-danger'>
                            -{intl.formatMessage({id: arrayError[0]})}
                          </div>
                        )
                      } else {
                        return (
                          <div key={index} className='fw-bolder text-danger'>
                            -
                            {intl.formatMessage(
                              {id: arrayError[1]},
                              {
                                input: arrayError[0] ? _.startCase(arrayError[0]) : '',
                                max: arrayError[2] ?? '',
                              }
                            )}
                          </div>
                        )
                      }
                    })}
                </div>
              </div>
            ))}
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {FileErrorModal}
