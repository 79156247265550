import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Button, CarrierBadge, Table} from '../../../../_gori/partials/widgets'
import Container from 'react-bootstrap/Container'
import OrderService from '../core/_requests'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import _, {uniqueId} from 'lodash'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import {ParcelBadge} from '../../../../_gori/partials/widgets/mixed/ParcelBadge'
import {useAuth} from '../../auth'
import {ShowAddress} from '../../../../_gori/components'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const CombineOrderModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const {currentCompany} = useAuth()
  const [orderTabActive, setOrderTabActive] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [orderSelected, setOrderSelected] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers

  const handleCloseForm = () => {
    handleClose()
    initCombineOrder()
  }
  const selectTab = (props) => {
    setOrderTabActive(props.index)
    setOrderSelected(props.order?.original)
  }

  const initCombineOrder = useCallback(() => {
    setOrderSelected(orders[0]?.original)
    setOrderTabActive(0)
  }, [orders])

  useEffect(() => {
    initCombineOrder()
  }, [initCombineOrder])

  const handleSubmitForm = async () => {
    let combine: any = []
    _.forEach(orders, function (order) {
      if (orderSelected?.id !== order.original?.id) {
        combine.push(order.original?.id)
      }
    })
    let payload = {
      order_ids: combine,
      parent_id: orderSelected?.id,
    }
    try {
      setIsSubmitting(true)
      const res = await OrderService.combine(payload, {cancelToken: newCancelToken()})
      if (res) {
        toast.success(intl.formatMessage({id: 'COMBINED_ORDER_SUCCESSFULLY'}))
        reloadTable()
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setIsSubmitting(false)
      handleCloseForm()
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-100px w-300px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return <ShowAddress data={row.original} name='to' />
        },
      },
      {
        Header: intl.formatMessage({id: 'PACKAGE'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='text-center'>
              {row.original?.parcels.length > 0 &&
                row.original.parcels.map((parcel: any) => (
                  <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                ))}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'SELECTED_SERVICE'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          let service = carriers?.[row.original.carrier + '_' + row.original.service] ?? []
          return <>{row.original.carrier && <CarrierBadge service={service} />}</>
        },
      },
    ],
    [carriers, intl]
  )

  const OrderTab = useCallback(
    (props) => {
      return (
        <>
          <div
            onClick={() => selectTab(props)}
            className={`border cursor-pointer rounded ${
              props.index === orderTabActive ? 'border-primary' : 'border-secondary'
            }`}
          >
            <div className='mx-5 my-6'>
              <h4 className='fw-bolder'>{`${currentCompany?.sin_code}-${props.order.original.code}`}</h4>
              <Table
                columns={columns}
                data={[props.order.original]}
                tableClass='table'
                tbodyClass='text-gray-600 fw-bold'
                useSort={false}
              />
            </div>
          </div>
          <div className='mb-4'></div>
        </>
      )
    },
    [columns, orderTabActive, currentCompany?.sin_code]
  )

  return (
    <>
      <Modal
        id='gori_combine_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => handleCloseForm()}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'COMBINE_SHIPMENTS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='row my-8'>
                {orders.map((order, index) => {
                  return <OrderTab index={index} key={index} order={order}></OrderTab>
                })}
                <span className='text-center fw-bolder fs-6 text-danger'>
                  {intl.formatMessage({id: 'COMBINE_TEXT'})}
                </span>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-3'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  event={() => handleCloseForm()}
                  disabled={isSubmitting}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  loading={isSubmitting}
                  event={() => {
                    handleSubmitForm()
                  }}
                />
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CombineOrderModal}
