import React from 'react'

type Props = {
  length?: number
}

const StatisticSkeleton: React.FC<Props> = ({length = 4}) => {
  const items = Array.from({length: length})

  return (
    <div className='bg-white bg-opacity-50 p-5'>
      <div className='col-9 d-flex align-items-center '>
        {items.map((item, index) => (
          <div className='col-2 placeholder-wave' key={index}>
            <span className='col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
        ))}
      </div>
    </div>
  )
}

export {StatisticSkeleton}
