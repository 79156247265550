const RateSkeleton: React.FC = () => {
  return (
    <div className='d-flex flex-fill flex-column mb-4'>
      <div className='div col-2'>
        <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
      </div>
      <div className='col-12 mt-5'>
        <span className='fs-3 col-2 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
        {Array.from({length: 1}).map((item, index) => (
          <div
            key={index}
            className='text-muted mt-4 fw-bold fs-7 mb-4 border border-secondary rounded border-dashed border-1 p-3 d-flex justify-content-between'
          >
            <div className='col-10'>
              <span className='btn  col-1 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
              <span className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
            </div>
            <span className='btn  col-1 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
          </div>
        ))}
      </div>
      <div className='col-12 mt-5'>
        <span className='fs-3 col-2 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
        {Array.from({length: 3}).map((item, index) => (
          <div
            key={index}
            className='text-muted mt-4 fw-bold fs-7 mb-4 border border-secondary rounded border-dashed border-1 p-3 d-flex justify-content-between'
          >
            <div className='col-10'>
              <span className='btn  col-1 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
              <span className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
            </div>
            <span className='btn  col-1 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
          </div>
        ))}
      </div>
    </div>
  )
}

export {RateSkeleton}
