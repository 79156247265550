import {isEmpty} from 'lodash'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {VALIDATE_FILE} from '../core/_const'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'

type Props = {
  formik: any
  nameField: string
}

const ViewUploadedOldFile: FC<Props> = ({formik, nameField}) => {
  const valueFields = formik.getFieldProps(nameField).value

  const isOtherImageFile = (path) => {
    const validExtensions = VALIDATE_FILE.EXTENSION_OTHER_IMAGE
    const fileExtension = path.split('.')
    return validExtensions.includes(fileExtension[fileExtension.length - 1])
  }

  const handleDeletePoof = (key) => {
    const newArr = valueFields.filter((photo) => photo.key !== key)
    formik.setFieldValue(formik.getFieldProps(nameField).name, newArr)
  }

  return !isEmpty(valueFields) ? (
    <div className='row mb-5'>
      <div className='col-md-12'>
        <div className='d-flex flex-lg-wrap align-items-center bg-light rounded-2 p-3'>
          {valueFields.map((img, index) => {
            return (
              <div
                key={index}
                className='border border-2 border-light border-hover-danger m-4 p-1 rounded-2'
              >
                {isOtherImageFile(img?.path) ? (
                  <div className='cursor-pointer border-danger'>
                    <img
                      onClick={() => blockPopUp(img.url)}
                      src={toAbsoluteUrl('/media/gori/claims/folder.svg')}
                      width={150}
                      height={150}
                      alt='folder'
                      className='delete-item-poof rounded-2'
                    />
                    <span
                      onClick={() => handleDeletePoof(img.key)}
                      className='position-absolute translate-middle badge border border-light rounded-circle bg-secondary text-dark bg-hover-danger text-hover-white px-2 fs-4'
                    >
                      x
                    </span>
                  </div>
                ) : (
                  <div className='border-danger'>
                    <img
                      src={img?.url}
                      width={150}
                      height={150}
                      alt='img'
                      className='position-relative rounded-2'
                    />
                    <span
                      onClick={() => handleDeletePoof(img.key)}
                      className='position-absolute translate-middle badge border border-light rounded-circle bg-secondary text-dark bg-hover-danger text-hover-white px-2 fs-4'
                    >
                      x
                    </span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export {ViewUploadedOldFile}
