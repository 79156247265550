import clsx from 'clsx'
import React from 'react'

type Props = {
  className?: string
  name: string
  data: any
}

const ShowAddress: React.FC<Props> = ({name, data, className = ''}) => {
  const first_name = data?.[`${name}_first_name`]
  const last_name = data?.[`${name}_last_name`]
  const fullName = `${first_name || ''} ${last_name || ''}`
  const company = data?.[`${name}_company`]
  const street1 = data?.[`${name}_street1`]
  const city = data?.[`${name}_city`]
  const state = data?.[`${name}_state`]
  const zip = data?.[`${name}_zip`]

  return (
    <div className={clsx(className)}>
      {company && <div className='fw-bolder'>{company}</div>}
      {(first_name || last_name) && <div className='fs-7'>{fullName}</div>}
      <div className='d-block mt-2'>
        {`${street1 || ''} ${city || ''} ${state || ''} ${zip || ''}`}
      </div>
    </div>
  )
}

export {ShowAddress}
