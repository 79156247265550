import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {blockPopUp} from '../../../../../_gori/helpers/BrowserHelpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {DropdownButton, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import SettingsService from '../../../settings/core/_requests'
import {ShipmentRefund} from '../../../shipments/core/_const'
import ShipmentService from '../../../shipments/core/_requests'
import {OrderStatus} from '../../core/_const'
import OrderService from '../../core/_requests'
import {NoticePrintPackingSlips} from '../../modals/NoticePrintPackingSlips'

type Props = {
  canSave: boolean
  data: any
  setData: any
  disabled: any
}

const ActionsDetailButton: React.FC<Props> = ({
  canSave = false,
  data,
  setData,
  disabled = false,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const params = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<{
    eventAction: boolean
  }>({
    eventAction: false,
  })
  const [dataModal, setDataModal] = useState<{noticePrintPackingSlip: any}>({
    noticePrintPackingSlip: {},
  })
  const [showModal, setShowModal] = useState<{
    noticePrintPackingSlip: boolean
  }>({
    noticePrintPackingSlip: false,
  })

  const getSettings = useCallback(async () => {
    if (!params?.id) return
    try {
      setLoading((prev) => ({...prev, eventAction: true}))
      const settings = await SettingsService.settings({cancelToken: newCancelToken()})
      return settings
    } catch (error) {
      if (isCancel(error)) return []
    } finally {
      setLoading((prev) => ({...prev, eventAction: false}))
    }
  }, [isCancel, newCancelToken, params?.id])

  const handlePrintPackingSlip = useCallback(
    async (id) => {
      if (canSave) {
        return toast.warning(
          intl.formatMessage(
            {id: 'PLEASE_SAVE_YOUR_ORDER_BEFORE_ACTION'},
            {action: intl.formatMessage({id: 'PRINTING'})}
          )
        )
      }
      const settings = await getSettings()
      if (!id || isEmpty(settings)) return
      const {
        packing_slip: {disable},
      } = await settings
      if (disable) {
        const configNoticePackingSlip = {
          title: 'PRINT_PACKING_SLIP',
          content: 'DISABLE_PACKING_SLIPS_MESSAGE',
        }
        setDataModal((prev) => ({...prev, noticePrintPackingSlip: configNoticePackingSlip}))
        setShowModal((prev) => ({...prev, noticePrintPackingSlip: true}))
        return
      }

      setLoading((prev) => ({...prev, eventAction: true}))
      try {
        let payload = {
          order_id: id,
        }
        const res = await ShipmentService.storePackingSlip(payload, {
          cancelToken: newCancelToken(),
        })
        if (res) {
          if (blockPopUp(res.packing_slip_url)) {
            toast.warning(intl.formatMessage({id: 'POP_UP_IS_BLOCKED'}))
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, eventAction: false}))
      }
    },
    [canSave, getSettings, intl, newCancelToken, isCancel]
  )

  const handleCopy = useCallback(
    async (id) => {
      if (!id) return
      if (canSave) {
        return toast.warning(
          intl.formatMessage(
            {id: 'PLEASE_SAVE_YOUR_ORDER_BEFORE_ACTION'},
            {action: intl.formatMessage({id: 'COPYING'})}
          )
        )
      }
      setLoading((prev) => ({...prev, eventAction: true}))
      try {
        const {order} = await OrderService.get(id, {cancelToken: newCancelToken()})
        if (order) {
          setData(null)
          navigate('/orders/create', {state: {dataCopy: {...order, id: null}}})
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, eventAction: false}))
      }
    },
    [canSave, intl, isCancel, navigate, newCancelToken, setData]
  )

  const handlePrintLabel = useCallback(
    async (dataOrder: any) => {
      if (canSave) {
        return toast.warning(
          intl.formatMessage(
            {id: 'PLEASE_SAVE_YOUR_ORDER_BEFORE_ACTION'},
            {action: intl.formatMessage({id: 'PRINTING'})}
          )
        )
      }
      debugger
      const shipment = dataOrder?.shipments?.[0]
      if (isEmpty(shipment)) return
      const isRefunded =
        shipment?.refund?.status === ShipmentRefund.SUBMITTED ||
        shipment?.refund?.status === ShipmentRefund.REFUNDED
      if (isRefunded) {
        toast.warning(
          intl.formatMessage({
            id: 'PRINT_LABEL_REFUNDED',
          })
        )
        return
      }
      setLoading((prev) => ({...prev, eventAction: true}))

      const config = {cancelToken: newCancelToken()}
      const data = {
        order_ids: [dataOrder.id],
      }
      await OrderService.print(data, config)
        .then((response) => {
          if (blockPopUp(response.label_url)) {
            toast.warning(
              intl.formatMessage({
                id: 'POP_UP_IS_BLOCKED',
              })
            )
          }
        })
        .catch((error: any) => {
          if (isCancel(error)) return
          setValidationErrors(error?.response)
        })
        .finally(() => {
          setLoading((prev) => ({...prev, eventAction: false}))
        })
    },
    [canSave, newCancelToken, intl, isCancel]
  )

  const optionsAction = useMemo(() => {
    let option = [
      {
        label: intl.formatMessage({id: 'PRINT_PACKING_SLIP'}),
        className: clsx({'cursor-no-drop': disabled || loading.eventAction}),
        action: () => {
          !disabled && !loading.eventAction && handlePrintPackingSlip(params?.id)
        },
      },
      {
        label: intl.formatMessage({id: 'COPY'}),
        className: clsx({'cursor-no-drop': disabled || loading.eventAction}),
        action: () => !disabled && !loading.eventAction && handleCopy(params?.id),
      },
    ]
    if (data?.status === OrderStatus.SHIPPED) {
      option = [
        {
          label: intl.formatMessage({id: 'PRINT_LABEL'}),
          className: clsx({'cursor-no-drop': disabled || loading.eventAction}),
          action: () => !disabled && !loading.eventAction && handlePrintLabel(data),
        },
        ...option,
      ]
    }
    return option
  }, [
    data,
    disabled,
    handleCopy,
    handlePrintLabel,
    handlePrintPackingSlip,
    intl,
    loading.eventAction,
    params?.id,
  ])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      {showModal.noticePrintPackingSlip && (
        <NoticePrintPackingSlips
          show={showModal.noticePrintPackingSlip}
          handleClose={() => setShowModal((prev) => ({...prev, noticePrintPackingSlip: false}))}
          configModal={dataModal.noticePrintPackingSlip}
        />
      )}

      <div className={clsx({'cursor-no-drop': disabled || loading.eventAction})}>
        <DropdownButton
          list={optionsAction}
          title={intl.formatMessage({id: 'ACTIONS'})}
          className={clsx('text-primary fw-bolder bg-hover-light-primary ps-3 pe-1 rounded-2')}
          loading={loading.eventAction}
        />
      </div>
    </>
  )
}

export {ActionsDetailButton}
