import React, {useCallback, useEffect, useState} from 'react'
import CountUp from 'react-countup'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../../auth'
import {ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import DashboardService from '../../core/_requests'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'

type Props = {
  loading?: boolean
  onLoading: any
}

const PostagePaid: React.FC<Props> = ({loading, onLoading}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch} = useAuth()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [postage, setPostage] = useState<number>(0)

  const getPostage = useCallback(async () => {
    const config = {
      cancelToken: newCancelToken(),
    }
    try {
      const response = await DashboardService.getPostagePaid(config)
      setPostage(response.postage_paid)
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      onLoading('postagePaid')
    }
  }, [isCancel, newCancelToken, onLoading])

  useEffect(() => {
    if (loadingSwitch) return
    getPostage()
  }, [getPostage, loadingSwitch])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loading ? (
          <div className='card shadow-sm h-210'>
            <div className='card-body d-flex flex-column'>
              <div className='col-10 placeholder-wave'>
                <span className='col-5 fs-1 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                <span className='col-6 fs-1 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='d-flex justify-content-between align-items-center mt-12'>
                <div className='col-7 placeholder-wave'>
                  <span className='btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='card shadow-sm h-210'>
            <div className='card-body d-flex flex-column'>
              <div className='d-flex'>
                <div className='text-dark fw-bolder fs-3 pe-2 opacity-75'>
                  {intl.formatMessage({id: 'POSTAGE_PAID'})}
                </div>
                <span className='text-muted fw-bold mt-1'>
                  {intl.formatMessage({id: 'THIS_MONTH'})}
                </span>
              </div>
              <div className='d-flex justify-content-between mt-12'>
                <div className='dashboard-text-color'>
                  <span className='fw-bolder fs-3 lh-0 align-middle opacity-75 me-1'>$</span>
                  <span className='align-middle opacity-75 fs-1 fw-bolder'>
                    <CountUp start={0} end={postage} separator=',' decimal={'.'} decimals={2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </CSSTransition>
    </>
  )
}

export {PostagePaid}
