import {useFormik} from 'formik'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import SettingsService from '../../../core/_requests'
import UserService from '../../../../users/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
}

const AccountEditModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()
  const {stringYup} = UseYupValidate()
  const {isCancel, newCancelToken} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const validateSchema = useMemo(() => {
    return {
      first_name: stringYup(50, 'FIRST_NAME'),
      last_name: stringYup(50, 'LAST_NAME'),
    }
  }, [stringYup])

  const formik = useFormik({
    initialValues: {
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      email: currentUser?.email,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validateSchema),
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const config = {cancelToken: newCancelToken()}
        values = {...values, ...{type: 'user'}}
        setSubmitting(true)
        await SettingsService.accountEdit(values, config)
        const userAuth = await UserService.getUser(config)
        setCurrentUser(userAuth)
        toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }
  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_account_setting'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'ACCOUNT_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <div className='row my-4'>
              <div className='col-6'>
                <InputTextFormik
                  label={intl.formatMessage({id: 'EMAIL'})}
                  required
                  formik={formik}
                  name='email'
                  disabled
                />
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-6'>
                <InputTextFormik
                  label={intl.formatMessage({id: 'FIRST_NAME'})}
                  required
                  formik={formik}
                  name='first_name'
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='col-6'>
                <InputTextFormik
                  label={intl.formatMessage({id: 'LAST_NAME'})}
                  required
                  formik={formik}
                  name='last_name'
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                disabled={formik.isSubmitting}
                event={formik.submitForm}
                loading={formik.isSubmitting}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {AccountEditModal}
