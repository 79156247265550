import {forEach, isEmpty} from 'lodash'
import React, {FC, useEffect, useState} from 'react'
import {InputTextFormik} from '../../../../_gori/partials/widgets'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import clsx from 'clsx'

type Props = {
  onSelected?: any
  required?: boolean
  label?: string | null
  formik: any
  name: string
  className?: string
  labelClassName?: string
}
const addOutsideClickListener = (ref, onOutsideClick) => {
  const handleWindowClick = (e) => {
    if (onOutsideClick) {
      let node = e.target
      while (node) {
        if (ref.current === node) {
          return
        }
        node = node.parentNode
      }
      onOutsideClick()
    }
  }
  window.addEventListener('click', handleWindowClick)
  return (): void => {
    window.removeEventListener('click', handleWindowClick)
  }
}

const useOutsideClickHandler = (onOutsideClick) => {
  const ref = React.useRef(null)
  React.useEffect(() => addOutsideClickListener(ref, onOutsideClick), [onOutsideClick])
  return ref
}

const AutocompleteAddress: FC<Props> = ({
  onSelected,
  required = true,
  label = null,
  formik,
  name,
  className = 'col-9',
  labelClassName = 'col-3 col-form-label',
}) => {
  const [maps, setMaps] = useState<any>()
  const [show, setShow] = useState<boolean>(false)
  const {placesService, placePredictions, getPlacePredictions} = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  const ref = useOutsideClickHandler(() => setShow(false))
  useEffect(() => {
    let list: any = []
    if (placePredictions.length > 0) {
      forEach(placePredictions, (item) => {
        list.push({
          value: item.place_id,
          mainText: item.structured_formatting?.main_text,
          secondaryText: item.structured_formatting?.secondary_text,
        })
      })
      setMaps(list)
      setShow(true)
    }
  }, [placePredictions])

  const selectedPlace = (selected) => {
    setShow(false)
    formik.setFieldValue(formik.getFieldProps(name).name, selected.mainText)
    placesService?.getDetails({placeId: selected.value}, (placeDetails) => {
      onSelected(placeDetails)
    })
  }

  const getPlace = (e) => {
    if (isEmpty(e?.target?.value)) {
      setMaps([])
      setShow(false)
    } else {
      getPlacePredictions({input: e?.target?.value})
    }
  }

  return (
    <div className='flex-fill' ref={ref}>
      <div className='d-flex'>
        <InputTextFormik
          className={className}
          labelClassName={labelClassName}
          required={required}
          label={label}
          formik={formik}
          name={name}
          onChange={(e) => getPlace(e)}
          onFocus={(e) => getPlace(e)}
        />
      </div>
      <div className='input-group col-md-9 offset-md-3'>
        <div data-bs-toggle='dropdown' aria-expanded={show}></div>
        <div className={`dropdown-menu fw-bold fs-7 py-2 col-md-9 ${show ? 'show' : ''}`}>
          {!isEmpty(maps) &&
            maps.map((item: any, index: number) => {
              return (
                <div
                  className={`map-item my-1 text-gray-600 text-hover-dark cursor-pointer fw-bold`}
                  key={index}
                  onClick={() => {
                    selectedPlace(item)
                  }}
                >
                  <span className='menu-link'>
                    {item.mainText}
                    {!isEmpty(item.secondaryText) ? ' , ' : ''}
                    <span className='secondary-text'>{item.secondaryText}</span>
                  </span>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export {AutocompleteAddress}
