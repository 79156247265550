import {getIn} from 'formik'
import {cloneDeep, compact, isEmpty, pick} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {ShowAddress} from '../../../../_gori/components'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import {SelectFormik} from '../../../../_gori/partials/widgets'
import {OrdersConfig} from '../core/_const'
import {FromOrToModal} from '../modals/FromOrToModal'

type Props = {
  formik: any
  fromToModal: any
  handleCloseFromToModal: any
  disabled?: boolean
  name?: string
  hasReturn?: boolean
  optionReturn?: string
}

const EditShipAddress: React.FC<Props> = ({
  formik,
  fromToModal,
  handleCloseFromToModal,
  disabled = false,
  name = '',
  hasReturn = false,
  optionReturn = '',
}) => {
  const intl = useIntl()
  const {statesGlobal, getAddress} = useStatesGlobal()
  const address = statesGlobal.address
  const [optionsAddress, setOptionsAddress] = useState<any>([])

  // BEGIN: Field Address
  useEffect(() => {
    let nameReturn = hasReturn ? (name === 'from' ? 'to' : 'from') : name
    if (optionReturn) {
      nameReturn = optionReturn
    }
    getAddress(nameReturn)

    return () => {}
  }, [getAddress, hasReturn, name, optionReturn])

  useEffect(() => {
    let nameReturn = hasReturn ? (name === 'from' ? 'to' : 'from') : name
    if (optionReturn) {
      nameReturn = optionReturn
    }
    if (isEmpty(address?.[nameReturn])) {
      setOptionsAddress([])
    } else {
      setOptionsAddress(
        address[nameReturn].map((item) => {
          const {first_name, last_name, company, street1, city, state, zip, is_verified} = item
          const fullName = compact([first_name, last_name]).join(', ')
          const labelOption = (
            <div>
              <div className='fw-bolder'>{company}</div>
              <div className='fs-7'>{fullName}</div>
              <div className='d-block fw-bold'>
                {compact([street1, city, state, zip]).join(', ')}
              </div>
              {is_verified && (
                <div className='text-success'>{intl.formatMessage({id: 'VERIFIED_ADDRESS'})}</div>
              )}
            </div>
          )

          let valueOption = {}
          const customValues = pick(item, [
            ...Object.values(cloneDeep(OrdersConfig.SHIPPING)).map((item) => {
              return item.value
            }),
          ])
          if (customValues) {
            valueOption = Object.entries(customValues).reduce((_values, [key, value]) => {
              switch (key) {
                case OrdersConfig.SHIPPING.VERIFIED.value:
                case OrdersConfig.SHIPPING.RESIDENTIAL.value:
                  _values[`${name}_${key}`] = value || false
                  break
                case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
                  _values[`${name}_${key}`] = !!value
                  break
                default:
                  _values[`${name}_${key}`] = value || ''
                  break
              }
              return _values
            }, {})
          }

          return {
            label: labelOption,
            value: valueOption,
          }
        })
      )
    }

    return () => {
      setOptionsAddress([])
    }
  }, [address, hasReturn, intl, name, optionReturn])

  const checkValidateFailed = useMemo(() => {
    if (!formik.values[name][`${name}_company`] && !formik.values[name][`${name}_first_name`]) {
      return true
    }
    const validate = [
      `${name}_city`,
      `${name}_state`,
      `${name}_country`,
      `${name}_zip`,
      `${name}_street1`,
    ]
    return validate.some((item) => !formik.values[name][item])
  }, [formik.values, name])
  // END: Field Address

  const handleSaveModal = (name, values) => {
    formik.setFieldValue(name, values)
    handleCloseFromToModal()
  }

  return (
    <>
      {fromToModal.show && (
        <FromOrToModal
          data={fromToModal}
          handleSave={handleSaveModal}
          handleClose={handleCloseFromToModal}
          labelModal={fromToModal?.label}
          disabled={disabled}
          hasReturn={hasReturn}
        />
      )}

      <div className=''>
        <div>
          <SelectFormik
            className='mt-5'
            options={optionsAddress}
            name={name}
            formik={formik}
            required
            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
            isSearchable={false}
            emptyDefault={false}
            showMessageError={false}
            disabled={disabled}
          />
        </div>
        {!isEmpty(formik.getFieldProps(name).value) && (
          <div className='px-4 mt-2'>
            <ShowAddress data={formik.values[name]} name={name} />
            {formik.getFieldProps(`${name}.${name}_is_verified`).value ? (
              <div className='text-success mt-2'>
                {intl.formatMessage({id: 'VERIFIED_ADDRESS'})}
              </div>
            ) : null}
          </div>
        )}
        {checkValidateFailed && getIn(formik.touched, name) && (
          <div className='px-4 mt-2'>
            <span className='text-danger d-block fw-bold'>
              {intl.formatMessage(
                {id: 'INPUT_IS_REQUIRED'},
                {
                  input: intl.formatMessage({
                    id: `SHIP_${name?.toUpperCase()}`,
                  }),
                }
              )}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export {EditShipAddress}
