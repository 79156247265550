import clsx from 'clsx'
import React, {useCallback, useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_PAGE} from '../../../constants'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {useIntl} from 'react-intl'

type Props = {
  children: any
  keyCheckActive: string
  dataTabs: Array<{label: string; value: string; count: number; active?: boolean}>
  childrenTab?: any
  classNameTabs?: string
  classNameChild?: string
  classNameChildTabs?: string
  disableTabAll?: boolean
  childrenEqualTab?: any
}

const TableTabs: React.FC<Props> = ({
  children,
  keyCheckActive,
  dataTabs,
  childrenTab,
  classNameTabs = '',
  classNameChild = '',
  classNameChildTabs = '',
  disableTabAll = false,
  childrenEqualTab,
}) => {
  const intl = useIntl()
  const {height, width} = useWindowDimensions()
  const [searchParams, setSearchParams] = useSearchParams()

  const tabsInit = useMemo(() => {
    const tabs = disableTabAll
      ? dataTabs
      : [
          {
            label: 'All',
            value: '',
            count: dataTabs.reduce((initTab, currentTab) => (initTab += currentTab.count), 0),
          },
          ...dataTabs,
        ]
    return tabs
  }, [dataTabs, disableTabAll])

  const newTabs = useMemo(() => {
    const valueCheckActive = searchParams.get(keyCheckActive)
    let tabsNew: Array<{}> = []

    if (!valueCheckActive) {
      tabsNew = tabsInit.map((tab: any, key: number) => {
        tab.active = key === 0
        return tab
      })
    } else {
      tabsNew = tabsInit.map((tab: any) => {
        tab.active = tab.value === valueCheckActive
        return tab
      })
    }

    return tabsNew
  }, [tabsInit, searchParams, keyCheckActive])

  const deleteParamEmpty = useCallback(
    (name) => {
      if (!searchParams.get(name)) {
        searchParams.delete(name)
      }
    },
    [searchParams]
  )

  const handleChangeTab = useCallback(
    (valueCheckActive) => {
      searchParams.set('page', DEFAULT_PAGE)
      searchParams.set(keyCheckActive, valueCheckActive)
      deleteParamEmpty('status')
      deleteParamEmpty('search_text')
      setSearchParams(searchParams)
    },
    [deleteParamEmpty, keyCheckActive, searchParams, setSearchParams]
  )

  return (
    <>
      <div
        className={clsx('d-flex justify-content-between align-items-center flex-3xl-row-reverse', {
          row: !(width >= 1600 || height >= 1600),
          'flex-row-reverse': width >= 1600 || height >= 1600,
        })}
      >
        {/* col-lg-3 order-lg-2 */}
        <div className={clsx({[classNameChildTabs]: !!classNameChildTabs})}>{childrenTab}</div>
        {/* col-lg-9 order-lg-1 */}
        <div className={clsx('table-tabs', {[classNameTabs]: !!classNameTabs})}>
          {newTabs.map((tab: any, key) => (
            <div
              className={clsx('table-tabs__tab', {active: tab.active})}
              key={key}
              onClick={() => handleChangeTab(tab.value)}
            >
              <div>{intl.formatMessage({id: tab.label.toUpperCase().replaceAll(' ', '_')})}</div>
              {tab.count > 0 && (
                <div className={clsx('table-tabs__tab__count', {active: tab.active})}>
                  {tab.count}
                </div>
              )}
            </div>
          ))}
          {childrenEqualTab}
        </div>
      </div>

      <div
        className={clsx('rounded-start-0 d-flex flex-column flex-column-fluid mt-3 mt-sm-0', {
          [classNameChild]: !!classNameChild,
        })}
      >
        {children}
      </div>
    </>
  )
}

export {TableTabs}
