import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useSearchParams} from 'react-router-dom'
import AuthService from '../core/_requests'
import {CSSTransition} from 'react-transition-group'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import clsx from 'clsx'
import {isUndefined} from 'lodash'

export function ActiveAccount() {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const slug = searchParams.get('slug')
  const [loading, setLoading] = useState(true)
  const [isValid, setIsValid] = useState<boolean>()
  const [errorMessage, setErrorMessage] = useState<boolean>(false)
  const [statusForm, setStatusForm] = useState<
    {status: 'success' | 'error'; message: string} | undefined
  >(undefined)

  useEffect(() => {
    const activeAccount = async () => {
      setStatusForm(undefined)
      try {
        if (!token) {
          setLoading(false)
          setIsValid(false)
          return
        }
        const config = {cancelToken: newCancelToken()}
        await AuthService.active({token: token, slug: slug}, config)
        setIsValid(true)
      } catch (error: any) {
        if (isCancel(error)) return
        setStatusForm({
          status: 'error',
          message: intl.formatMessage({id: error?.response?.data?.error?.message}),
        })
        if (error?.response?.data?.error?.fields?.expired) {
          setErrorMessage(true)
        }
        setIsValid(false)
      } finally {
        setLoading(false)
      }
    }
    activeAccount()
  }, [isCancel, newCancelToken, token, slug, intl])

  const resendVerificationEmail = async () => {
    setStatusForm(undefined)

    try {
      setLoading(true)
      const config = {cancelToken: newCancelToken()}

      let payload = {
        email: token,
        slug: slug,
      }
      const res = await AuthService.resendVerificationEmail(payload, config)
      if (res) {
        setStatusForm({
          status: 'success',
          message: intl.formatMessage({id: 'ENTER_VERIFICATION_ACCOUNT_SENT'}),
        })
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setStatusForm({
        status: 'error',
        message: intl.formatMessage({id: 'SEND_VERIFICATION_ACCOUNT_ERROR'}),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'ACTIVATE_ACCOUNT'})}</h1>
        </div>
        {loading ? (
          <div className='text-center'>
            <div className='spinner-border spinner-custom border-5' role='status' />
          </div>
        ) : (
          <>
            {token && isValid ? (
              <>
                <div className='mb-10 text-center'>
                  <i className='text-primary bi bi-check size-icon-check' />

                  <div className='text-gray-400 fw-bold fs-4'>
                    {intl.formatMessage({
                      id: 'ACCOUNT_ACTIVATED_SUCCESSFULLY',
                    })}{' '}
                    <Link to='/auth/login' className='link-primary fw-bolder'>
                      {intl.formatMessage({id: 'SIGN_IN_HERE'})}
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!isUndefined(statusForm) && (
                  <div
                    className={clsx('mb-lg-15 alert', {
                      'bg-light-primary': statusForm.status === 'success',
                      'alert-danger': statusForm.status === 'error',
                    })}
                  >
                    <div className='alert-text font-weight-bold'>{statusForm.message}</div>
                  </div>
                )}

                {errorMessage && (
                  <div
                    onClick={() => {
                      if (!loading) {
                        resendVerificationEmail()
                      }
                    }}
                    tabIndex={1000}
                    className='d-flex link-danger fs-6 fw-bolder text-decoration-underline cursor-pointer mt-n10 mb-2'
                  >
                    {intl.formatMessage({id: 'RESEND_VERIFICATION_EMAIL'})}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </div>
                )}

                {/* begin::Action */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <Link to='/auth/login' className='link-primary fw-bolder'>
                    <button
                      type='submit'
                      id='gori_sign_in_submit'
                      className='btn btn-lg btn-primary fw-bolder me-4'
                    >
                      {intl.formatMessage({id: 'LOGIN'})}
                    </button>
                  </Link>
                </div>
                {/* end::Action */}
              </>
            )}
          </>
        )}
      </div>
    </CSSTransition>
  )
}
