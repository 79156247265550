import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    if (!getCookie('cookiesAccepted')) {
      setShowBanner(true)
    }
  }, [])

  const setCookie = (name: string, value: string, days: number) => {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()}`
    document.cookie = `${name}=${value};${expires};path=/`
  }

  const getCookie = (name: string): string | null => {
    const nameEQ = `${name}=`
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim()
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length)
    }
    return null
  }

  const handleAccept = () => {
    setCookie('cookiesAccepted', 'true', 365)
    setShowBanner(false)
  }

  const bannerStyles: React.CSSProperties = {
    position: 'fixed',
    width: '90%',
    maxWidth: '430px',
    bottom: '0',
    zIndex: '999',
    left: '50%',
    transform: 'translateX(-50%)',
    marginBottom: '20px',
    backgroundColor: '#FFF',
    color: '#000',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1)',
  }

  const buttonStyles: React.CSSProperties = {
    marginLeft: '8px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#FFF',
  }

  const cookieImgStyles: React.CSSProperties = {
    display: 'inline-block',
    width: '30px',
    height: '30px',
    marginRight: '8px',
  }

  return (
    <>
      {showBanner && (
        <div style={bannerStyles}>
          <img
            src={toAbsoluteUrl('/media/gori/landing/cookie.webp')}
            style={cookieImgStyles}
            alt=''
          />
          <p style={{margin: '0'}}>
            By using this website, you agree to our{' '}
            <Link
              to='/privacy-policy'
              rel='noreferrer'
              target='_blank'
              style={{color: '#14B8A6', textDecoration: 'underline', whiteSpace: 'nowrap'}}
            >
              Privacy Policy
            </Link>
            .
          </p>
          <button
            type='button'
            onClick={handleAccept}
            style={buttonStyles}
            className='btn-close'
          ></button>
        </div>
      )}
    </>
  )
}

export default CookieBanner
