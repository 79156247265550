import {FC} from 'react'
import {Container} from 'react-bootstrap'
import {TableSkeleton} from '../../../../_gori/helpers/components'

type Props = {
  name: 'edit' | 'detail'
}

const SkeletonClaimsForm: FC<Props> = ({name}) => {
  return (
    <Container>
      <div className='col-12 mb-5 d-flex flex-fill flex-column'>
        {name === 'detail' && (
          <>
            <div className='mb-5'>
              <div className='fs-1 col-2 placeholder placeholder-lg rounded-2 bg-secondary mb-5' />
              <TableSkeleton countColumn={3} countRow={2} showPagination={false} />
            </div>
            <div className='mb-5'>
              <div className='fs-1 col-2 placeholder placeholder-lg rounded-2 bg-secondary mb-5' />
              <TableSkeleton countColumn={3} countRow={5} showPagination={false} />
            </div>
            <div className='mb-5 bg-light rounded p-10 pt-5'>
              <div className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary mb-5' />
              <div className='col-12 d-flex flex-column mt-5'>
                <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
                <div className='btn col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-12 d-flex flex-column mt-5'>
                <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
                <div className='col-12 h-75px placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-12 d-flex flex-column mt-5'>
                <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
                <div className='col-12 h-75px placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </>
        )}
        {name === 'edit' && (
          <>
            <div className='d-flex flex-column align-items-center'>
              <div className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
              <div className='fs-3 col-6 placeholder placeholder-lg rounded-2 bg-secondary  mb-13' />
            </div>
            <div className='d-flex justify-content-between'>
              <div className='col-6'>
                <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  me-4' />
                <div className='btn col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-6 d-flex flex-row-reverse'>
                <div className='btn col-7 placeholder placeholder-lg rounded-2 bg-secondary ms-4' />
                <div className='fs-3 col-4 d-flex align-items-center'>
                  <div className='col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
            </div>
          </>
        )}
        <div className='mt-15 mb-5'>
          <div className='fs-1 col-2 placeholder placeholder-lg rounded-2 bg-secondary mb-5' />
          <div className='bg-light px-10 pt-5 pb-2 mb-3 rounded'>
            <div className='d-flex align-items-center justify-content-between mb-5'>
              <div className='fs-3 col-1 placeholder placeholder-lg rounded-2 bg-secondary' />
              <div className='btn rounded-circle placeholder placeholder-lg rounded-2 bg-secondary' />
            </div>
            {Array.from({length: 2}).map((item, key) => (
              <div className='d-flex flex-fill justify-content-between mb-5' key={key}>
                <div className='col-6'>
                  <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  me-4' />
                  <div className='btn col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <div className='col-6 d-flex flex-row-reverse'>
                  <div className='btn col-7 placeholder placeholder-lg rounded-2 bg-secondary ms-4' />
                  <div className='fs-3 col-4 d-flex align-items-center'>
                    <div className='col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
              </div>
            ))}
            <div className='mt-12 mb-5'>
              <div className='fs-3 col-6 placeholder placeholder-lg rounded-2 bg-secondary  mb-5' />
              <div className='col-12 h-150px placeholder placeholder-lg rounded-2 bg-secondary' />
            </div>
            <div className='mb-5 d-flex flex-column'>
              <div className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary  mb-5' />
              <div className='col-6 h-100px placeholder placeholder-lg rounded-2 bg-secondary' />
            </div>
          </div>
          <div className='bg-light h-40px rounded-2 mb-8' />
        </div>
        <div className='mb-5 d-flex flex-column mb-8'>
          <div className='fs-1 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-5' />
          <div className='fs-3 col-2 placeholder placeholder-lg rounded-2 bg-secondary  mb-5' />
          <div className='col-6 h-100px placeholder placeholder-lg rounded-2 bg-secondary' />
        </div>
        <div className='my-5 d-flex flex-column mb-5'>
          <div className='fs-1 col-5 placeholder placeholder-lg rounded-2 bg-secondary  mb-5' />
          <div className='col-6 d-flex'>
            <div className='col-9 d-flex align-items-center'>
              <div className='fs-3 col-11 placeholder placeholder-lg rounded-2 bg-secondary  me-5' />
            </div>
            <label className='form-check form-switch form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' disabled defaultChecked={false} />
            </label>
          </div>
          <div className='col-6 d-flex mt-3'>
            <div className='col-11 d-flex align-items-center'>
              <div className='fs-3 col-11 placeholder placeholder-lg rounded-2 bg-secondary  me-5' />
            </div>
            <label className='form-check form-switch form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' disabled defaultChecked={false} />
            </label>
          </div>
        </div>
        <div className='my-5'>
          <div className='fs-1 col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
          <div className='d-flex justify-content-between'>
            <div className='col-6'>
              <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  me-4' />
              <div className='btn col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
            </div>
            <div className='col-6 d-flex flex-row-reverse'>
              <div className='btn col-7 placeholder placeholder-lg rounded-2 bg-secondary ms-4' />
              <div className='fs-3 col-4 d-flex align-items-center'>
                <div className='col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export {SkeletonClaimsForm}
