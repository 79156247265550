import clsx from 'clsx'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {KTSVG} from '../../../../_gori/helpers'
import {useSearchParams} from 'react-router-dom'
import {isEmpty, startCase, upperCase} from 'lodash'
import {useIntl} from 'react-intl'
import {OrderType} from '../core/_const'

type Props = {
  data: any
  handleSubmit: any
  labelButton?: string
}

const StoreMenu: React.FC<Props> = ({data, handleSubmit, labelButton}) => {
  const intl = useIntl()
  const storeRef = useRef<any>(null)
  const [searchParams] = useSearchParams()
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visibleSubMenu, setVisibleSubMenu] = useState(undefined)
  const [label, setLabel] = useState(labelButton)

  const hasStoreType = useMemo(() => {
    return searchParams.get('store_type') || searchParams.get('store_id')
  }, [searchParams])

  useEffect(() => {
    if (!hasStoreType) {
      setLabel(intl.formatMessage({id: 'ALL_STORES'}))
    } else {
      if (searchParams.get('store_type') === OrderType.SHIPBAE) {
        setLabel(intl.formatMessage({id: 'SHIPBAE'}))
      } else {
        Object.entries(data).forEach(([key, value]: [any, any]) => {
          if (value.length > 0) {
            value.forEach((subitem) => {
              if (searchParams.get('store_id') === subitem.id.toString()) {
                setLabel(subitem.store_name)
              }
            })
          }
        })
      }
    }
  }, [data, hasStoreType, intl, labelButton, searchParams])

  const handleDropdownClick = () => {
    setVisibleMenu(!visibleMenu)
  }

  const resetDropdown = () => {
    setVisibleMenu(false)
    setVisibleSubMenu(undefined)
  }

  const setLabelStoreType = (type) => {
    return intl.formatMessage({id: `ALL_${upperCase(type)}_STORES`})
  }
  const renderMenuItem = ([key, value], index) => {
    const isAllStoreType =
      searchParams.get('store_type') === key && isEmpty(searchParams.get('store_id'))
    if (value.length > 0) {
      return (
        <div className={clsx('dropdown-submenu', {show: visibleSubMenu === key})} key={index}>
          <div
            className={clsx('dropdown-submenu__item text-dark', {show: visibleSubMenu === key})}
            onClick={() => setVisibleSubMenu((prev) => (prev === key ? undefined : key))}
          >
            {startCase(key)}
          </div>
          <div className={`dropdown-submenu__inner ${visibleSubMenu === key ? 'show' : ''}`}>
            <div
              key={key}
              className={clsx('dropdown-submenu__inner__item', {
                'text-hover-primary': !isAllStoreType,
                'text-white bg-primary': isAllStoreType,
              })}
              onClick={() => {
                setVisibleMenu(false)
                setLabel(setLabelStoreType(key))
                handleSubmit({store_type: key})
              }}
            >
              {setLabelStoreType(key)}
            </div>
            {value.map((subitem) => (
              <div
                key={subitem.store_id}
                className={clsx('dropdown-submenu__inner__item', {
                  'text-hover-primary': !searchParams.get('store_id') === subitem.id.toString(),
                  'text-white bg-primary': searchParams.get('store_id') === subitem.id.toString(),
                })}
                onClick={() => {
                  setVisibleMenu(false)
                  setLabel(subitem.store_name)
                  handleSubmit(subitem)
                }}
              >
                {subitem.store_name}
              </div>
            ))}
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (storeRef.current && !storeRef.current.contains(event.target)) {
        resetDropdown()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div>
      <div className='dropdown' ref={storeRef}>
        <div
          className='dropdown__label border border-2 border-gray-300'
          onClick={handleDropdownClick}
        >
          <div className='dropdown__label__left fs-6 fw-bold text-gray-700 me-20'>
            <KTSVG path='/media/gori/orders/store.svg' className='me-2' />
            <span>{label}</span>
          </div>
          <KTSVG path='/media/gori/orders/dropdown-arrow.svg' />
        </div>
        <div className={clsx('dropdown-menu dropdown__menu start-0', {show: visibleMenu})}>
          <div className={clsx('dropdown-submenu')}>
            <div
              className={clsx('dropdown-submenu__item', {
                'text-primary': !hasStoreType,
              })}
              onClick={() => {
                resetDropdown()
                setLabel(intl.formatMessage({id: 'ALL_STORES'}))
                handleSubmit()
              }}
            >
              {intl.formatMessage({id: 'ALL_STORES'})}
            </div>
          </div>
          <div className={clsx('dropdown-submenu')}>
            <div
              className={clsx('dropdown-submenu__item', {
                'text-primary': searchParams.get('store_type') === OrderType.SHIPBAE,
              })}
              onClick={() => {
                resetDropdown()
                setLabel(intl.formatMessage({id: 'SHIPBAE'}))
                handleSubmit({store_type: OrderType.SHIPBAE})
              }}
            >
              {intl.formatMessage({id: 'SHIPBAE'})}
            </div>
          </div>
          {Object.entries(data).map((item, index) => renderMenuItem(item, index))}
        </div>
      </div>
    </div>
  )
}

export {StoreMenu}
