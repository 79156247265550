import clsx from 'clsx'
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  name: string
  placeholder?: string
  className?: string
  valueInput: any
  setValueInput?: any
}

const InputFilter: FC<Props> = ({
  name = '',
  placeholder = '',
  className,
  valueInput = '',
  setValueInput,
}) => {
  const intl = useIntl()
  const [visibleMenu, setVisibleMenu] = useState(false)
  const filterRef = useRef<any>(null)
  const [value, setValue] = useState(valueInput)

  useEffect(() => {
    setValue(valueInput)
  }, [valueInput])

  const handleSearchParams = useCallback(() => {
    setVisibleMenu(false)
    setValueInput(value)
  }, [setValueInput, value])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchParams()
    }
  }
  const handleKeyUp = () => {
    if (value.trim() === '') {
      handleSearchParams()
    }
  }

  const handleClickOutside = useCallback((event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setVisibleMenu(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    const updateValue = setInterval(() => {
      if (value.trim() && valueInput.trim() && value.trim() !== valueInput.trim() && !visibleMenu) {
        handleSearchParams()
      }
    }, 1000)

    return () => {
      clearInterval(updateValue)
    }
  }, [handleSearchParams, value, valueInput, visibleMenu])

  return (
    <div
      key={name}
      className={clsx('dropdown', className, {
        'border border-2 border-primary rounded-2': valueInput,
      })}
      id={name}
      ref={filterRef}
    >
      <div
        className={clsx('dropdown__label btn btn-sm btn-white btn-active-light-primary border')}
        onClick={() => {
          setVisibleMenu(true)
        }}
      >
        <div className='dropdown__label__left fw-bold'>
          <span className='fw-bolder fs-5 text-gray-600'>{name}</span>
        </div>
        {visibleMenu || valueInput ? (
          <>
            <div className='border border-1 border-secondary mx-2 h-75'></div>
            <input
              autoFocus
              className='dropdown__label__value--input'
              placeholder={placeholder}
              value={value}
              onChange={(e: any) => {
                setValue(e.target.value)
              }}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
            />
            <div className='ms-2 px-3 py-2 rounded-2 bg-hover-white' onClick={handleSearchParams}>
              {intl.formatMessage({id: 'APPLY'})}
            </div>
          </>
        ) : (
          <i className='bi bi-caret-right-fill ms-2'></i>
        )}
      </div>
    </div>
  )
}

export {InputFilter}
