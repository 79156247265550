const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const formatOmitLongText = (value) => {
  if (!value) return '-'

  if (value.length > 12) {
    value = value.substring(0, 11) + '...'
  }

  return value
}
const formatPhoneNumber = (value) => {
  if (!value) return value

  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

// Round half away from zero
function round(num, decimalPlaces = 2) {
  if (num < 0) {
    return -round(-num, decimalPlaces)
  }
  let p = Math.pow(10, decimalPlaces)
  let n = (num * p).toPrecision(15)
  return Math.round(Number(n)) / p
}

export {formatBytes, formatPhoneNumber, round, formatOmitLongText}
