import clsx from 'clsx'
import React from 'react'

type Props = {
  className?: string
  children?: any
}

const TableWrapper: React.FC<Props> = ({className, children}) => {
  return <div className={clsx('card flex-fill h-100', className)}>{children}</div>
}

export {TableWrapper}
