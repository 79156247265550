import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {Button} from '../form/Button'

type Props = {
  className?: any
  event: any
  label?: any
  disabled?: boolean
}

const ButtonBack: FC<Props> = ({className, event, label, disabled = false}) => {
  return (
    <Button
      label={label}
      event={event}
      className={`${className} btn btn-light btn-sm fw-bolder me-5 ${!label ? 'btn-icon' : ''}`}
      children={<KTSVG path='/media/gori/arrows/arrow_left.svg' />}
      disabled={disabled}
    />
  )
}

export {ButtonBack}
