import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {isEmpty} from 'lodash'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

type Props = {
  services: any
}

const ServicesUsed: React.FC<Props> = ({services}) => {
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [percentage, setPercentage] = useState<any>(undefined)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    if (services) {
      let data: any = []
      let colors: any = []
      let percentages: any = []
      Object.entries(services).forEach(([key, value]: [any, any]) => {
        data.push({
          value: value.count,
          label: intl.formatMessage({id: value.name}),
        })
        colors.push(value.color)
        percentages.push({
          value: value.percentage,
          label: value.name,
          color: value.color,
        })
      })
      setPercentage(percentages)

      let root = am5.Root.new('chart-dashboard-service-used')
      root.setThemes([am5themes_Animated.new(root)])
      root?._logo?.dispose()

      let chart = root.container.children.push(am5percent.PieChart.new(root, {}))
      chart.root.dom.style.height = '100px'

      let series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: 'Series',
          valueField: 'value',
          categoryField: 'label',
        })
      )
      series?.get('colors')?.set('colors', colors)
      series.data.setAll(data)
      // Disabling labels and ticks
      series.labels.template.set('visible', false)
      series.ticks.template.set('visible', false)
      series.appear(1000)
      chart.appear(1000, 100)

      return () => {
        root.dispose()
      }
    }
  }, [services])

  return (
    <>
      {!isEmpty(percentage) && (
        <div className='px-2 fw-bolder text-muted'>{intl.formatMessage({id: 'SERVICES_USED'})}</div>
      )}
      <div className='row pt-2 pb-4'>
        <div className='col-xl-3'>
          <div className='fs-7' ref={chartRef} id='chart-dashboard-service-used' />
        </div>
        <div className='col-xl-9'>
          <div className='d-flex flex-fill flex-column content-justify-center'>
            {percentage &&
              percentage.map((item, index) => (
                <div className='d-flex fw-bold align-items-center' key={index}>
                  <div
                    className='bullet w-8px h-3px rounded-2 me-3'
                    style={{backgroundColor: item.color}}
                  />
                  <div className='text-gray-800 flex-grow-1 me-4'>
                    {intl.formatMessage({id: item.label})}
                  </div>
                  <div className='fw-bolder text-gray-500 text-xxl-end'>{item.value}%</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export {ServicesUsed}
