import clsx from 'clsx'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import {InputTextFormik, SelectFormik} from '../../../../../_gori/partials/widgets'
import {OPTION_INSURANCE_TYPE} from '../../core/_const'
import {isEmpty} from 'lodash'

type Props = {
  formik: any
  disabled: boolean
}

const InsuranceSignature: React.FC<Props> = ({formik, disabled = false}) => {
  const intl = useIntl()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers

  const optionsSignature = useMemo(() => {
    const optionsService = carriers?.[formik.values?.service]?.options?.signature || []
    const options = [{label: intl.formatMessage({id: 'NONE'}), value: null}, ...optionsService]
    return options
  }, [carriers, formik.values?.service, intl])

  return (
    <>
      <div className={clsx({'cursor-no-drop': disabled})}>
        {carriers[formik.values?.service]?.options?.insurance?.enabled && (
          <>
            <div className={clsx('d-flex mb-4')}>
              <SelectFormik
                label={intl.formatMessage({id: 'INSURANCE'})}
                className={clsx('col-6')}
                labelClassName='col-6 col-form-label fw-bolder'
                options={OPTION_INSURANCE_TYPE}
                onChange={() => {
                  formik.setFieldValue('insurance_option.insured_amount', 0)
                }}
                name='insurance_option.provider'
                emptyDefault={false}
                formik={formik}
                hasUseIntl={true}
                disabled={disabled}
              />
            </div>
            {formik.values.insurance_option?.provider && (
              <div className={clsx('d-flex mb-4')}>
                <InputTextFormik
                  label={intl.formatMessage({id: 'INSURANCE_AMOUNT'})}
                  className={clsx('col-6')}
                  labelClassName='col-6 col-form-label fw-bolder'
                  type='number'
                  name='insurance_option.insured_amount'
                  formik={formik}
                  min={0}
                  max={5000}
                  disabled={disabled}
                >
                  {carriers[formik.values?.service]?.options?.insurance?.default_coverage > 0 && (
                    <span className='text-danger fs-7'>
                      {intl.formatMessage(
                        {id: 'INSURANCE_COVERAGE_TEXT'},
                        {
                          amount:
                            carriers[formik.values?.service]?.options?.insurance?.default_coverage,
                        }
                      )}
                    </span>
                  )}
                </InputTextFormik>
              </div>
            )}
          </>
        )}
        {!isEmpty(carriers[formik.values?.service]?.options?.signature) && (
          <div className='d-flex mb-6'>
            <SelectFormik
              label={intl.formatMessage({id: 'SIGNATURE_CONFIRMATION'})}
              className={clsx('col-6')}
              labelClassName='col-6 col-form-label fw-bolder'
              options={optionsSignature}
              name='signature_confirmation'
              emptyDefault={false}
              formik={formik}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    </>
  )
}

export {InsuranceSignature}
