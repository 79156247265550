import {isEmpty} from 'lodash'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import BatchService from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

type Props = {
  show: boolean
  handleClose: () => void
  batch: any
  orders: any
  reloadTable: any
}
const RemoveFromBatchModal: React.FC<Props> = ({show, handleClose, batch, orders, reloadTable}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const handleRemoveOrders = async () => {
    if (isEmpty(orders)) return
    let orderIds: Array<number> = []
    orders.forEach((row: any) => {
      orderIds.push(row.original.id)
    })

    if (orderIds.length > 0) {
      try {
        setIsLoadingForm(true)
        await BatchService.removeOrders(
          {
            batch_id: batch.id,
            order_ids: orderIds,
          },
          {cancelToken: newCancelToken()}
        )
        toast.success(intl.formatMessage({id: 'REMOVED_FROM_BATCH_SUCCESSFULLY'}))
        reloadTable()
        handleClose()
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='remove_from_batch'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto mw-800px'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <div>{intl.formatMessage({id: 'REMOVE_FROM_BATCH'})}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center p-0'>
            <img
              className='d-block mx-auto mt-9 mb-5'
              src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')}
              alt='img'
            />
            <p className='fs-4 mb-16'>
              <span>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE'})}</span>
              <span>
                <b>
                  {' ' +
                    orders.length +
                    ' ' +
                    (orders.length === 1
                      ? intl.formatMessage({id: 'ORDER'})
                      : intl.formatMessage({id: 'ORDERS'})) +
                    ' '}
                </b>
              </span>
              <span>{intl.formatMessage({id: 'FROM_THE_CURRENT_BATCH'})}</span>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2 min-w-67px'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary min-w-67px'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={handleRemoveOrders}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {RemoveFromBatchModal}
