import React from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {Button} from '../../../../_gori/partials/widgets'

type Props = {
  show: boolean
  handleClose: (type) => void
  batch?: any
}

const BatchDuplicateModal: React.FC<Props> = ({show, handleClose, batch}) => {
  const intl = useIntl()

  return (
    <>
      <Modal
        id='gori_modal_batch_duplicated'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose('close')
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'MOVE_SHIPMENT_TO_BATCH'})} "
              {batch?.existing_batch ? batch.existing_batch?.name : batch?.name}"
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='row my-10'>
                <div className='fs-3 mb-1'>
                  <span className='fw-bolder'>
                    {batch.has_batch.length} {intl.formatMessage({id: 'ORDERS'})}
                  </span>{' '}
                  {intl.formatMessage({id: 'ARE_ALREADY_IN_A_DIFFERENT_BATCH'})}.
                </div>
                <div className='fs-3'>
                  {intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_MOVE_THEM_TO'})}{' '}
                  <span className='fw-bolder'>
                    {batch?.existing_batch ? batch.existing_batch?.name : batch?.name}
                  </span>{' '}
                  ?
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <div className='row'>
                <div className='d-flex justify-content-end'>
                  <Button
                    className='btn btn-secondary me-3'
                    label={
                      intl.formatMessage({id: 'NO'}) + ', ' + intl.formatMessage({id: 'EXCLUDE'})
                    }
                    event={() => handleClose('exclude')}
                  />
                  <Button
                    className='btn btn-primary'
                    label={
                      intl.formatMessage({id: 'YES'}) + ', ' + intl.formatMessage({id: 'MOVE'})
                    }
                    event={() => handleClose('move')}
                  />
                </div>
              </div>
            </Container>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {BatchDuplicateModal}
