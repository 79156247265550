import {useFormik} from 'formik'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {Button} from '../../../../../_gori/partials/widgets'
import {ActionAllModal} from '../../../common'
import {SET_UP_MODAL_ALL} from '../../../common/core/_const'
import {EditShipAddress} from '../../components/EditShipAddress'
import {OrdersConfig} from '../../core/_const'

type Props = {
  show: boolean
  handleClose: () => void
  data: any
  handleCreateReturnLabelSuccess: () => void
  isShipmentPage?: boolean
}

const CreateMultiReturnLabelModal: React.FC<Props> = ({
  show,
  handleClose,
  data,
  handleCreateReturnLabelSuccess,
  isShipmentPage = false,
}) => {
  const intl = useIntl()
  const {stringYup, orderYup, regexYup, infoYup} = UseYupValidate()
  const [showModal, setShowModal] = useState<{createReturnLabel: boolean}>({
    createReturnLabel: false,
  })
  const [dataModal, setDataModal] = useState<{createReturnLabel: any}>({createReturnLabel: {}})
  const [fromToModal, setFormToModal] = useState<any>({
    show: false,
    name: undefined,
    data: {},
    validate: {},
  })

  // BEGIN: validate
  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country,
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: stringYup(255, 'PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const initValidateSchema = useMemo(() => {
    return {
      to: Yup.object().shape(
        Object.entries(validateAddress('to')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['to_first_name', 'to_company']]
      ),
    }
  }, [validateAddress])

  const validationSchema = Yup.object().shape(initValidateSchema)
  // END: validate

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.VERIFIED.value:
            initValue.to[`to_${value.value}`] = false
            break
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.to[`to_${value.value}`] = true
            break
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            initValue.to[`to_${value.value}`] = false
            break
          default:
            initValue.to[`to_${value.value}`] = ''
            break
        }
        return initValue
      },
      {to: {}}
    )
    return init
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const dataReturn = data.map((row) => {
        if (isShipmentPage) {
          return {
            order_code: row.original.order_code,
            data_payload: {
              gori_shipment_id: row.original.id,
              order_id: row.original.order_id,
              payload: {
                ...values.to,
              },
            },
          }
        }

        return {
          order_code: row.original.code,
          data_payload: {
            order_id: row.original.id,
            payload: {
              ...values.to,
            },
          },
        }
      })
      setDataModal((prev) => ({...prev, createReturnLabel: dataReturn}))
      setShowModal((prev) => ({...prev, createReturnLabel: true}))
    },
  })

  return (
    <>
      {showModal.createReturnLabel && (
        <ActionAllModal
          show={showModal.createReturnLabel}
          data={dataModal.createReturnLabel}
          setUpModal={SET_UP_MODAL_ALL.RETURN_LABELS}
          handleClose={() => {
            handleCreateReturnLabelSuccess()
            handleClose()
          }}
        />
      )}
      {!showModal.createReturnLabel && (
        <Modal
          id='gori_modal_create_return_label'
          tabIndex={-1}
          aria-hidden='true'
          centered
          dialogClassName='h-auto mw-500px'
          show={show}
          backdrop='static'
          onHide={handleClose}
        >
          <div className='modal-content'>
            <Modal.Header closeButton>
              <Modal.Title bsPrefix='fw-bolder fs-1'>
                {intl.formatMessage({id: 'CREATE_RETURN_LABEL'})}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='vh-75 scroll-y'>
              <div className='px-4'>
                {(() => {
                  return (
                    <div className='row'>
                      <div className='d-flex mb-2 row'>
                        <h2 className='fw-bolder text-dark'>
                          {intl.formatMessage({id: 'ORDER_RETURN_DETAIL'})}
                        </h2>
                        <div className='align-middle'>
                          <span className='fw-bold text-gray-600'>
                            {intl.formatMessage(
                              {id: 'INPUT_ORDERS_ARE_SELECTED'},
                              {selected: data.length}
                            )}
                          </span>
                        </div>
                      </div>
                      <div className='bg-light rounded p-8 h-100'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <h3 className='fw-bolder fs-2'>
                            {intl.formatMessage({id: 'SHIP_TO_ADDRESS'})}
                          </h3>
                          <div
                            className='d-flex align-items-center text-primary fw-bolder cursor-pointer'
                            onClick={() => {
                              setFormToModal({
                                show: true,
                                name: 'to',
                                data: formik.values.to,
                                validate: validateAddress('to'),
                              })
                            }}
                          >
                            <span className='fs-2 me-1'>+</span>
                            {intl.formatMessage({
                              id: Object.entries(formik.values.to).some(
                                ([key, value]) => !isEmpty(value)
                              )
                                ? 'EDIT_ADDRESS'
                                : 'NEW_ADDRESS',
                            })}
                          </div>
                        </div>
                        <EditShipAddress
                          formik={formik}
                          fromToModal={fromToModal}
                          handleCloseFromToModal={() =>
                            setFormToModal({
                              show: false,
                              name: undefined,
                              data: {},
                              validate: {},
                            })
                          }
                          name='to'
                          hasReturn
                          optionReturn='return_to'
                        />
                      </div>
                    </div>
                  )
                })()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-2'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  loadingText={intl.formatMessage({id: 'CANCEL'})}
                  event={handleClose}
                />
                <Button
                  className={'btn btn-primary'}
                  label={intl.formatMessage({id: 'CREATE'})}
                  loadingText={intl.formatMessage({id: 'CREATE'})}
                  event={formik.submitForm}
                />
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </>
  )
}

export {CreateMultiReturnLabelModal}
