import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const intl = useIntl()

  return (
    <div className='footer py-4 d-flex flex-xxl-column' id='gori_footer'>
      {/* begin::Container */}
      <div className='d-flex flex-column flex-md-row flex-stack'>
        {/* begin::Copyright */}
        <div className='mx-20 text-dark order-2 order-md-1'>
          <span className='text-gray-400 fw-bold me-1'>
            &copy; {intl.formatMessage({id: 'COPYRIGHT'}, {year: new Date().getFullYear()})}{' '}
            {intl.formatMessage({id: 'GORI_COMPANY'})}
            {'. '}
            {intl.formatMessage({id: 'ALL_RIGHTS_RESERVED'})}
          </span>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
