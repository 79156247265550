import React, {useEffect, useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {ConnectShopify} from './shopify/ConnectShopify'

type Props = {
  isConnection?: boolean | false
}

const Shopify: React.FC<Props> = ({isConnection = false}) => {
  const intl = useIntl()
  const [connection, setConnection] = useState<boolean>(isConnection)

  useEffect(() => {
    setConnection(isConnection)
  }, [isConnection])

  const hasConnection = () => {
    return (
      <div className='connection'>
        <h3>
          {intl.formatMessage({id: 'CONNECTION_SUCCESSFUL'})}
          <KTSVG path='/media/gori/valid.svg' />
        </h3>
      </div>
    )
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <>
        <div>
          <p className='fs-4 text-gray-700 fw-bold text-start my-6'>
            {intl.formatMessage({
              id: 'PLEASE_ENTER_YOUR_SHOPIFY_STORE_DOMAIN_BELOW_TO_CONNECT',
            })}
          </p>
          <img
            alt='Shopify'
            src={toAbsoluteUrl('/media/gori/onboard/label/v2/shopify.png')}
            className='w-100'
          />
        </div>
        <div>{connection ? hasConnection() : <ConnectShopify />}</div>
      </>
    </CSSTransition>
  )
}

export {Shopify}
