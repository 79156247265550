import {isEmptyArray} from 'formik'
import {capitalize, find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {TableWrapper} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import {SettingsConfig} from '../../../core/_const'
import SettingsService from '../../../core/_requests'
import {LabelsModal} from '../../../modals/shipping/labels-slips/LabelsModal'
import {PackingSlipsModal} from '../../../modals/shipping/labels-slips/PackingSlipsModal'

const LabelsSlipsPage: React.FC = () => {
  const intl = useIntl()
  const {currentCompany} = useAuth()
  const [settings, setSettings] = useState<any>([])
  const [companyLocation, setCompanyLocation] = useState<any>([])
  const [loadingFirst, setLoadingFirst] = useState<boolean>(true)
  const {newCancelToken} = useCancelToken()
  const [showModal, setShowModal] = useState<any>({
    labels: false,
    slips: false,
  })

  const getCompany = useCallback(async () => {
    try {
      const res = await SettingsService.getCompany({
        cancelToken: newCancelToken(),
      })
      if (res) {
        setCompanyLocation({
          address: res.street1,
          address_2: res.street2,
          country: res.country,
          city: res.city,
          state: res.state,
          zip: res.zip,
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
    }
  }, [newCancelToken])

  const getSettings = useCallback(async () => {
    try {
      setLoadingFirst(true)
      const settings = await SettingsService.settings({cancelToken: newCancelToken()})
      if (isEmpty(settings?.packing_slip?.store_location)) {
        await getCompany()
      }
      setSettings(settings)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingFirst(false)
    }
  }, [getCompany, newCancelToken])

  const buildSkeleton = useMemo(() => {
    return (
      <>
        <div className='mt-8'>
          <div className='d-flex justify-content-center'>
            <div className='w-100 w-sm-75 bg-light rounded p-8'>
              <div className='d-flex justify-content-between'>
                <div className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <span className='fs-1 col-1 placeholder rounded-2 bg-secondary' />
              </div>
              <div className='fs-2 col-12 placeholder placeholder-lg rounded-2 bg-secondary mt-6 mb-10' />
              <div className='col-12 d-flex justify-content-between'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-12 d-flex justify-content-between mt-6'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='d-flex justify-content-center'>
            <div className='w-100 w-sm-75 bg-light rounded p-8'>
              <div className='d-flex justify-content-between'>
                <div className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <span className='fs-1 col-1 placeholder rounded-2 bg-secondary' />
              </div>
              <div className='fs-2 col-12 placeholder placeholder-lg rounded-2 bg-secondary mt-6 mb-10' />
              <div className='col-12 d-flex justify-content-between'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-12 d-flex justify-content-between mt-6'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }, [])

  useEffect(() => {
    getSettings()
  }, [getSettings])

  return (
    <>
      {showModal.labels && (
        <LabelsModal
          show={showModal.labels}
          handleClose={() => {
            getSettings()
            setShowModal((prev) => ({...prev, labels: false}))
          }}
          data={settings?.print}
        />
      )}
      {showModal.slips && (
        <PackingSlipsModal
          show={showModal.slips}
          handleClose={() => {
            getSettings()
            setShowModal((prev) => ({...prev, slips: false}))
          }}
          data={{
            ...settings?.packing_slip,
            company_location: companyLocation,
          }}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableWrapper className='h-100'>
          <div className='mb-4'>
            <div className='card-header card-header-stretch d-flex h-55px'>
              <div className='card-title'>
                <h3>{intl.formatMessage({id: 'LABELS_AND_PACKING_SLIPS'})}</h3>
              </div>
            </div>
            <div className='card-body'>
              {loadingFirst ? (
                <>{buildSkeleton}</>
              ) : (
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <div className='w-100 w-sm-75 border border-secondary rounded-2 py-6 px-8 mt-8'>
                    <div>
                      <div className='d-flex justify-content-between fw-bolder'>
                        <span className='fs-3 fw-bolder text-gray-700'>
                          {intl.formatMessage({id: 'LABELS_AND_PACKING_SLIPS'})}
                        </span>
                        <span
                          className='fs-5 text-primary text-decoration-underline cursor-pointer'
                          onClick={() => setShowModal((prev) => ({...prev, labels: true}))}
                        >
                          {intl.formatMessage({id: 'EDIT'})}
                        </span>
                      </div>
                    </div>

                    <p className='form-label text-gray-600'>
                      {intl.formatMessage({
                        id: 'LABEL_TYPE_CONTENT',
                      })}
                    </p>

                    <div className='mt-6'>
                      {Object.entries(SettingsConfig.LABELS_SLIPS.LABELS).map(
                        ([key, item], index) => {
                          switch (item.value) {
                            case SettingsConfig.LABELS_SLIPS.LABELS.INCLUDE_SLIPS.value:
                              return (
                                <div className='d-flex text-gray-700 fs-5 mt-8' key={index}>
                                  <div className='col-6 col-sm-4 fw-bold'>
                                    {intl.formatMessage({id: item.label})}
                                  </div>
                                  <div className='text-wrap fw-bolder'>
                                    {intl.formatMessage({
                                      id: settings?.print?.[item.value] ? 'YES' : 'NO',
                                    })}
                                  </div>
                                </div>
                              )
                            case SettingsConfig.LABELS_SLIPS.LABELS.TYPE.value:
                            case SettingsConfig.LABELS_SLIPS.LABELS.SIZE.value:
                              return (
                                <div className='d-flex text-gray-700 fs-5 mt-8' key={index}>
                                  <div className='col-6 col-sm-4 fw-bold'>
                                    {intl.formatMessage({id: item.label})}
                                  </div>
                                  <div className='text-wrap fw-bolder'>
                                    {SettingsConfig?.[`OPTIONS_LABEL_${key}`].find(
                                      (option) => option.value === settings?.print?.[item.value]
                                    )?.label || ''}
                                  </div>
                                </div>
                              )
                            default:
                              return null
                          }
                        }
                      )}
                    </div>
                  </div>

                  <div className='w-100 w-sm-75 border border-secondary rounded-2 py-6 px-8 mt-8'>
                    <div>
                      <div className='d-flex flex-wrap justify-content-between'>
                        <span className='fs-3 fw-bolder text-gray-700'>
                          {intl.formatMessage({id: 'PACKING_SLIP_CONTENT'})}
                        </span>
                        <span
                          className='fs-5 text-primary text-decoration-underline cursor-pointer fw-bolder ms-4'
                          onClick={() => setShowModal((prev) => ({...prev, slips: true}))}
                        >
                          {intl.formatMessage({id: 'EDIT'})}
                        </span>
                      </div>

                      <p className='form-label text-gray-600'>
                        {intl.formatMessage({
                          id: 'TAILOR_YOUR_PACKING_SLIPS_TO_MATCH_YOUR_NEED_AND_BRANDING',
                        })}
                      </p>

                      <div className='mt-6'>
                        {Object.values(SettingsConfig.LABELS_SLIPS.SLIPS).map((row, index) => {
                          const SLIPS_CONFIG = SettingsConfig.LABELS_SLIPS.SLIPS
                          const value = settings.packing_slip?.[row.value]

                          const renderRow = (label, content, append?) => (
                            <div className='d-flex text-gray-700 fs-5 mt-8' key={index}>
                              <div className='col-6 col-sm-4 fw-bold'>
                                {intl.formatMessage({id: label})}
                                {append}
                              </div>
                              <div className='text-wrap fw-bolder'>
                                {label !== SLIPS_CONFIG.STORE_LOGO.label
                                  ? capitalize(content)
                                  : content}
                              </div>
                            </div>
                          )

                          switch (row.value) {
                            case SLIPS_CONFIG.ORDER_NUMBER.value:
                              return renderRow(
                                row.label,
                                intl.formatMessage({
                                  id: value
                                    ? find(SettingsConfig.OPTIONS_ORDER, {value: value})?.label
                                    : 'NONE',
                                }),
                                ' #'
                              )
                            case SLIPS_CONFIG.BARCODE.value:
                              return renderRow(
                                row.label,
                                intl.formatMessage({
                                  id: value
                                    ? find(SettingsConfig.OPTIONS_BARCODE, {value: value})?.label
                                    : 'NONE',
                                })
                              )
                            case SLIPS_CONFIG.ORDER_DATE.value:
                            case SLIPS_CONFIG.SHIP_TO.value:
                            case SLIPS_CONFIG.SHIPPING_SERVICE.value:
                            case SLIPS_CONFIG.ITEM_DETAIL.value:
                              return renderRow(
                                row.label,
                                intl.formatMessage({id: value ? 'ON' : 'OFF'})
                              )
                            case SLIPS_CONFIG.TRACKING.value:
                              return renderRow(
                                row.label,
                                intl.formatMessage({id: value ? 'ON' : 'OFF'}),
                                ' #'
                              )
                            case SLIPS_CONFIG.STORE_LOGO.value:
                              return renderRow(
                                row.label,
                                !isEmpty(value) ? (
                                  <div className={'col-8'}>
                                    <div className='w-50 overflow-hidden'>
                                      <img
                                        src={value?.url_show}
                                        className='rounded-2 w-100'
                                        alt='img-file'
                                      />
                                      <div className='text-truncate mt-2'>{value?.name}</div>
                                    </div>
                                  </div>
                                ) : (
                                  intl.formatMessage({id: 'NONE'})
                                )
                              )
                            case SLIPS_CONFIG.STORE_NAME.value:
                              const storeName =
                                value === false ? 'NONE' : !!value ? value : currentCompany?.name
                              return renderRow(
                                row.label,
                                storeName === 'NONE' ? intl.formatMessage({id: 'NONE'}) : storeName
                              )
                            case SLIPS_CONFIG.THIRD_PARTY_NAME.value:
                              return renderRow(
                                row.label,
                                intl.formatMessage({id: value ? 'YES' : 'NO'})
                              )
                            case SLIPS_CONFIG.STORE_LOCATION.value:
                              let storeLocation
                              const location = isEmptyArray(value)
                                ? companyLocation
                                : value === false
                                ? 'NONE'
                                : value

                              if (location === 'NONE') {
                                return renderRow(row.label, intl.formatMessage({id: 'NONE'}))
                              } else {
                                const {
                                  address = '',
                                  address_2 = '',
                                  city = '',
                                  state = '',
                                  zip = '',
                                } = location
                                storeLocation = `${address ? address : ''}${
                                  address_2 ? ` ${address_2},` : address ? ',' : ''
                                }${city ? ` ${city}, ` : ''}${state ? ` ${state},` : ''}${
                                  zip ? ` ${zip}` : ''
                                }`

                                return renderRow(row.label, storeLocation)
                              }
                            default:
                              return renderRow(
                                row.label,
                                isEmpty(value) ? intl.formatMessage({id: 'NONE'}) : value
                              )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </TableWrapper>
      </CSSTransition>
    </>
  )
}

export {LabelsSlipsPage}
