import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../../../app/api/RequestBase'

const URL = '/filters'

const getCustomColumns = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL, config)
}

const saveCustomColumns = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(URL, payload, config)
}

const updateCustomColumns = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(URL, payload, config)
}

const TableService = {
  getCustomColumns,
  saveCustomColumns,
  updateCustomColumns,
}

export default TableService
