import {isEmpty} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {Button, ConfirmActionSwal, TableHeader} from '../../../../../../_gori/partials/widgets'
import {FromOrToModal} from '../../../../orders'
import SettingsService from '../../../core/_requests'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'

type Props = {
  selectedListId?: any
  reloadTable: any
  dataInit: {
    data: any
    validate: any
    nameTab: {name: 'to' | 'from'; hasReturn: boolean}
  }
}

const ShippingAddressFilter: React.FC<Props> = ({selectedListId, reloadTable, dataInit}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loadingRemove, setLoadingRemove] = useState(false)
  const {routes} = UseRoutesPermission()
  const [showModal, setShowModal] = useState<{shippingAddress: boolean; confirmDelete: boolean}>({
    shippingAddress: false,
    confirmDelete: false,
  })

  const handleDelete = useCallback(async () => {
    if (isEmpty(selectedListId)) return
    setLoadingRemove(true)
    const payload = {
      ids: selectedListId.map((item) => item.original.id),
    }

    try {
      const res = await SettingsService.deleteAddress(payload, {cancelToken: newCancelToken()})
      if (res.status) {
        reloadTable()
        toast.success(intl.formatMessage({id: 'DELETED_SUCCESSFULLY'}))
      }
    } catch (error) {
      if (isCancel(error)) return
      toast.error(intl.formatMessage({id: 'DELETED_FAILED'}))
    } finally {
      setLoadingRemove(false)
    }
  }, [intl, isCancel, newCancelToken, reloadTable, selectedListId])

  return (
    <>
      {showModal.confirmDelete && (
        <ConfirmActionSwal
          show={showModal.confirmDelete}
          title={intl.formatMessage({id: 'DELETE_ADDRESS'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDelete}
          handleClose={() => setShowModal((prev) => ({...prev, confirmDelete: false}))}
        />
      )}
      {showModal.shippingAddress && (
        <FromOrToModal
          data={{
            show: showModal.shippingAddress,
            name: dataInit.nameTab.name,
            data: dataInit.data,
            validate: dataInit.validate,
          }}
          handleSave={() => {
            setShowModal((prev) => ({...prev, shippingAddress: false}))
            reloadTable()
          }}
          handleClose={() => {
            setShowModal((prev) => ({...prev, shippingAddress: false}))
          }}
          hasReturn={dataInit.nameTab.hasReturn}
          autoSave
        />
      )}
      <TableHeader className='card-title d-flex flex-wrap gap-2 justify-content-between align-items-start p-0'>
        <div className='card-toolbar flex-row-fluid justify-content-end align-items-start'>
          <div className={`d-flex align-items-center ${!(selectedListId.length > 0) && 'd-none'}`}>
            <div className='fw-bolder me-5'>
              <span className='me-2'>{selectedListId.length}</span>
              {intl.formatMessage({id: 'SELECTED'})}
            </div>
            <Button
              className='btn bg-light-danger bg-hover-danger text-danger text-hover-white btn-sm fs-5 me-3'
              label={intl.formatMessage({id: 'REMOVE'})}
              loadingText={intl.formatMessage({id: 'REMOVE'})}
              event={() => setShowModal((prev) => ({...prev, confirmDelete: true}))}
              loading={loadingRemove}
            />
          </div>
          {routes.SETTINGS_ADDRESSES.hasPermission && (
            <div
              className='btn text-white text-hover-primary bg-primary bg-hover-light-primary btn-sm fs-5'
              onClick={() => {
                setShowModal((prev) => ({...prev, shippingAddress: true}))
              }}
            >
              {`+ ${intl.formatMessage({id: 'NEW_ADDRESS'})}`}
            </div>
          )}
        </div>
      </TableHeader>
    </>
  )
}

export {ShippingAddressFilter}
