import {RateSkeleton} from './RateSkeleton'

const OrderDetailSkeleton: React.FC = () => {
  return (
    <div className='d-flex h-75'>
      <div className='col-12 col-xl-7 scroll-y'>
        <div className='bg-light rounded mb-6 mx-4 p-5'>
          <span className='fs-1 w-200px placeholder placeholder-lg rounded-2 bg-secondary' />
          <hr className='bg-gray-600' />
          <div className='d-flex flex-column'>
            {Array.from({length: 2}).map((item, index) => (
              <div className='my-4' key={index}>
                <div className='d-flex justify-content-between mb-2'>
                  <span className='fs-1 w-150px placeholder placeholder-lg rounded-2 bg-secondary' />
                  <span className='fs-3 w-100px placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <span className='btn w-100 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            ))}
          </div>
          <div className='d-flex justify-content-between align-content-center mt-6'>
            <div>
              <span className='fs-1 w-150px placeholder placeholder-lg rounded-2 bg-secondary' />
            </div>
            <span className='btn w-50 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <div className='d-flex my-4'>
            {Array.from({length: 4}).map((item, index) => (
              <div className='col-md-3' key={index}>
                <span className='fs-3 col-8 placeholder placeholder-lg rounded-2 bg-secondary mb-2' />
                <span className='btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            ))}
          </div>
          <div className='d-flex my-4'>
            {Array.from({length: 2}).map((item, index) => (
              <div className='col-md-3' key={index}>
                <span className='fs-3 col-8 placeholder placeholder-lg rounded-2 bg-secondary mb-2' />
                <span className='btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            ))}
          </div>
        </div>
        <div className='bg-light rounded mb-6 mx-4 p-5'>
          <span className='fs-1 w-200px placeholder placeholder-lg rounded-2 bg-secondary' />
          <hr className='bg-gray-600' />
          <div className='d-flex flex-column my-4'>
            {Array.from({length: 2}).map((item, index) => (
              <div className='d-flex my-4' key={index}>
                {Array.from({length: 2}).map((item, index) => (
                  <div
                    className='d-flex justify-content-between align-content-center w-50 px-4'
                    key={index}
                  >
                    <div>
                      <span className='fs-1 w-75px placeholder placeholder-lg rounded-2 bg-secondary' />
                    </div>
                    <span className='btn w-50 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <hr className='bg-gray-600' />
          <div className='d-flex my-4'>
            {Array.from({length: 4}).map((item, index) => (
              <div className='col-md-3' key={index}>
                <span className='fs-3 col-8 placeholder placeholder-lg rounded-2 bg-secondary mb-2' />
                <span className='btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='col-12 col-xl-5 bg-white rounded shadow-sm border p-5'>
        <RateSkeleton />
      </div>
    </div>
  )
}

export {OrderDetailSkeleton}
