import React, {useContext, useState} from 'react'

export interface AsideProviderModel {
  activeFooter: any | null
  setActiveFooter: any
  hasShowSubmenu: boolean
  setHasShowSubmenu: any
}

const initAsideContextPropsState = {
  activeFooter: null,
  setActiveFooter: () => {},
  hasShowSubmenu: false,
  setHasShowSubmenu: () => {},
}

const AsideContext = React.createContext<AsideProviderModel>(initAsideContextPropsState)

const AsideProvider: React.FC = ({children}) => {
  const [activeFooter, setActiveFooter] = useState<any | null>(
    initAsideContextPropsState.activeFooter
  )
  const [hasShowSubmenu, setHasShowSubmenu] = useState<boolean>(
    initAsideContextPropsState.hasShowSubmenu
  )

  return (
    <AsideContext.Provider
      value={{activeFooter, setActiveFooter, hasShowSubmenu, setHasShowSubmenu}}
    >
      {children}
    </AsideContext.Provider>
  )
}

function useAsideProvider() {
  return useContext(AsideContext)
}

export {AsideProvider, useAsideProvider}
