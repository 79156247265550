import {FC} from 'react'
import {DisableSidebar} from '../../../_gori/layout/core'
import {OnboardPage} from '../../modules/onboard'
import {OnboardProvider} from '../../../_gori/helpers/components/OnboardProvider'
const OnboardWrapper: FC = () => {
  return (
    <DisableSidebar>
      <OnboardProvider>
        <OnboardPage />
      </OnboardProvider>
    </DisableSidebar>
  )
}

export default OnboardWrapper
