import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import BatchService from '../../core/_requests'
import {toast} from 'react-toastify'
import {omit} from 'lodash'

type Props = {
  show: boolean
  hasUpload: boolean
  handleClose: () => void
  data: {
    templateCurrent: any
    templateValues: any
    optionsName: any
  }
  handleUploadFile: any
  handleGetTemplate: any
}

const SaveTemplateMappingModal: React.FC<Props> = ({
  show,
  hasUpload = false,
  handleClose,
  data,
  handleUploadFile,
  handleGetTemplate,
}) => {
  const {templateCurrent, templateValues, optionsName} = data
  const idTemplateCurrent = templateCurrent?.current?.id || null
  const intl = useIntl()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()

  const [errors, setErrors] = useState<{
    validationErrors: any
    upload: any
    missing: any
    duplicate: any
  }>({
    validationErrors: null,
    upload: null,
    missing: null,
    duplicate: null,
  })
  const [loading, setLoading] = useState<{update: boolean; create: boolean}>({
    update: false,
    create: false,
  })

  const validateSchema = Yup.object({
    template_name: stringYup(50, 'TEMPLATE_NAME')
      .trim()
      .test({
        name: 'unique',
        test: function (value) {
          return !optionsName?.map((item) => item?.toLowerCase()).includes(value?.toLowerCase())
        },
        message: intl.formatMessage(
          {id: 'INPUT_ALREADY_EXISTS'},
          {input: intl.formatMessage({id: 'TEMPLATE_NAME'})}
        ),
      })
      .matches(/^(?!.*(?:-{2,}))[a-zA-Z0-9\s-]+$/, {
        message: intl.formatMessage(
          {id: 'INPUT_MUST_CONTAIN_LETTERS_DIGITS_SPACES_DASH'},
          {input: intl.formatMessage({id: 'TEMPLATE_NAME'})}
        ),
        excludeEmptyString: true,
      }),
  })

  const formik = useFormik({
    initialValues: {template_name: ''},
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: async (values: any) => {
      handleCreateTemplate({name: values.template_name})
    },
  })

  const handleSkipAndUpload = () => {
    if (hasUpload) {
      handleUploadFile()
    }
    handleClose()
  }

  const handleUpdateTemplate = async () => {
    const config = {
      cancelToken: newCancelToken(),
    }
    const payload = {
      international: templateValues.international,
      mappings: Object.values(omit(templateValues, ['international'])),
    }
    setLoading((prev) => ({...prev, update: true}))

    try {
      const res = await BatchService.editMappingTemplate(idTemplateCurrent, payload, config)
      if (res) {
        toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
        handleGetTemplate()
        handleSkipAndUpload()
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, update: false}))
    }
  }

  const handleCreateTemplate = async ({name}) => {
    const config = {
      cancelToken: newCancelToken(),
    }
    const payload = {
      international: templateValues.international,
      mappings: Object.values(omit(templateValues, ['international'])),
      name: name,
    }
    setLoading((prev) => ({...prev, create: true}))

    try {
      const {batch_mapping} = await BatchService.createMappingTemplate(payload, config)
      if (batch_mapping) {
        templateCurrent.current = batch_mapping
        handleGetTemplate()
        toast.success(intl.formatMessage({id: 'CREATED_SUCCESSFULLY'}))
        handleSkipAndUpload()
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, create: false}))
    }
  }

  return (
    <>
      {errors.validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setErrors((prev) => ({...prev, validationErrors: undefined}))
          }}
          response={errors.validationErrors}
        />
      )}
      <Modal
        id='gori_modal_save_template_mapping'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-650px h-auto'
        show={show}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <h2>{intl.formatMessage({id: 'SAVE_MAPPING_TEMPLATE'})}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <InputTextFormik
                labelClassName='form-label'
                label={intl.formatMessage({id: 'TEMPLATE_NAME'})}
                formik={formik}
                name='template_name'
              />
              <div className='mt-4'>
                {intl.formatMessage({
                  id: 'PROVIDE_A_NEW_NAME_FOR_THE_TEMPLATE_IF_YOU_WISH_TO_SAVE_IT_AS_A_NEW_TEMPLATE',
                })}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              {hasUpload && (
                <Button
                  className='btn btn-secondary me-2'
                  label={intl.formatMessage({id: 'SKIP'})}
                  loadingText={intl.formatMessage({id: 'SKIP'})}
                  event={handleSkipAndUpload}
                />
              )}
              {idTemplateCurrent && (
                <Button
                  className='btn btn-secondary me-2'
                  label={intl.formatMessage({id: 'UPDATE_CURRENT_TEMPLATE'})}
                  loadingText={intl.formatMessage({id: 'UPDATE_CURRENT_TEMPLATE'})}
                  event={handleUpdateTemplate}
                  loading={loading.update}
                  disabled={loading.update}
                />
              )}
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE_NEW'})}
                loadingText={intl.formatMessage({id: 'SAVE_NEW'})}
                event={formik.handleSubmit}
                loading={loading.create}
                disabled={loading.create}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {SaveTemplateMappingModal}
