import React, {useState, useEffect, useCallback} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useIntl} from 'react-intl'
import {KTSVG, StorageHelpers} from '../../../../../_gori/helpers'
import {ManualOrder} from './order/ManualOrder'
import {Batches} from './batches/Batches'
import {Store} from './store/Store'
import OnboardService from '../../core/_requests'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import FooterActions from './common/FooterActions'

type Props = {
  setActiveStep: any
}

const LabelCreation: React.FC<Props> = ({setActiveStep}) => {
  const intl = useIntl()
  const [active, setActive] = useState<string | null>(null)
  const connectShopify = StorageHelpers.getItemLocalStorage('CONNECT_SHOPIFY')
  const {newCancelToken, isCancel} = useCancelToken()
  const [payloadOption, setPayloadOption] = useState<string | null>(null)

  const LabelCreationOption = [
    {
      label: 'manual_order',
      image: 'media/gori/onboard/label/manual.svg',
    },
    {
      label: 'batch_upload',
      image: 'media/gori/onboard/label/batches.svg',
    },
    {
      label: 'connect_a_store',
      image: 'media/gori/onboard/label/store.svg',
    },
  ]

  const updateStep = useCallback(async () => {
    let payload = {
      step: 'add_fund',
      data: ['label_creation'],
      funding_option: null,
    }
    try {
      await OnboardService.store(payload, {cancelToken: newCancelToken()})
    } catch (error: any) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    if (connectShopify) {
      setActive(LabelCreationOption[2]?.label)
    }
    updateStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectShopify])
  const getActiveComponent = () => {
    switch (active) {
      case 'manual_order':
        return <ManualOrder />
      case 'batch_upload':
        return <Batches />
      case 'connect_a_store':
        return <Store setPayloadOption={setPayloadOption} />
    }
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='d-flex flex-column align-items-center justify-content-center py-12 text-center'>
        <div className='w-75 d-flex flex-column align-items-center justify-content-center '>
          <div className='row w-50'>
            <p className='fs-3 fw-bolder'>
              {intl.formatMessage({id: 'START_SHIPPING_WITH_YOUR_PREFERRED_OPTION'})}
            </p>
            {LabelCreationOption.map((item, index) => {
              return (
                <div
                  className='col-md-4'
                  key={index}
                  onClick={() => {
                    setActive(item.label)
                    setPayloadOption(item?.label)
                  }}
                >
                  <div
                    className={`choose-box label shadow ${active === item.label ? 'active' : ''}`}
                  >
                    <div>
                      <KTSVG path={item.image} className='' />
                    </div>
                    <div className='choose-text-label'>
                      <span>{intl.formatMessage({id: item.label.toUpperCase()})}</span>
                    </div>
                  </div>
                </div>
              )
            })}
            <div>{getActiveComponent()}</div>
          </div>
          <FooterActions
            active={active}
            setActiveStep={setActiveStep}
            payloadOption={payloadOption}
          />
        </div>
      </div>
    </CSSTransition>
  )
}

export {LabelCreation}
