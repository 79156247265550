import {useFormik} from 'formik'
import {isEmpty, startCase, toLower} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {convertCurrency, convertUserTimeZone, KTSVG, toAbsoluteUrl} from '../../../../_gori/helpers'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  InputTextAreaNoFormik,
  InputTextNoFormik,
  SelectNoFormik,
  Table,
  TableWrapper,
} from '../../../../_gori/partials/widgets'
import {useAuth} from '../../auth'
import {SkeletonClaimsForm} from '../../claims'
import {OPTION_CLAIMS_APPEAL_REASON, OPTION_ITEM_TYPE, VALIDATE_FILE} from '../core/_const'
import ClaimsService from '../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  claimId: number | undefined
}

const DetailClaimsModal: React.FC<Props> = ({claimId, show, handleClose}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch, currentUser} = useAuth()
  const [dataForm, setDataForm] = useState<any>()
  const [dataHistory, setDataHistory] = useState<{
    histories: Array<Object>
    pagination: any
  }>({
    histories: [],
    pagination: {},
  })
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)

  const columnsHistories = useMemo(
    () => [
      {
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-50px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'STATUS'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-dark text-center',
        Cell: ({row}: {row: any}) => {
          return (
            <span
              className={`claims-status-badge-${row.original?.status?.replaceAll(
                '_',
                '-'
              )} px-4 py-2 rounded-pill`}
            >
              {startCase(toLower(row.original?.status_after))}
            </span>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'NOTE'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap mw-150px',
        Cell: ({row}: {row: any}) => {
          return <span className='text-gray-800'>{row.original.note}</span>
        },
      },
    ],
    [intl, currentUser]
  )

  const getClaimsDetail = useCallback(async () => {
    try {
      const config = {cancelToken: newCancelToken()}

      setIsLoadingForm(true)
      let totalClaim = 0
      const [{claim}, resHistories] = await Promise.all([
        ClaimsService.getClaimDetail(claimId?.toString(), config),
        ClaimsService.getClaimHistory(claimId?.toString(), config),
      ])

      const newItems = claim.items.map((item: any, index) => {
        item.show = true
        item.key = index
        let _claim_item_type: any = {label: '', value: ''}
        OPTION_ITEM_TYPE.forEach((itemOpt) => {
          if (itemOpt.value === item.claim_item_type) {
            _claim_item_type.label = intl.formatMessage({id: itemOpt.label})
            _claim_item_type.value = item.claim_item_type
            return _claim_item_type
          }
        })
        return {...item, claim_item_type: _claim_item_type}
      })
      const newAppeals = claim.appeals.map((item: any, index) => {
        item.show = true
        item.key = index
        return item
      })
      claim.items?.forEach((item) => {
        totalClaim += item.claim_item_price * item?.claim_item_qty
      })
      claim.claim_total = totalClaim
      setDataForm({...claim, items: newItems, appeals: newAppeals})
      setDataHistory(resHistories)
    } catch (error) {
      if (isCancel(error)) return
      console.error(error)
    } finally {
      setIsLoadingForm(false)
    }
  }, [claimId, intl, isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch || !claimId || !show) return

    getClaimsDetail()
  }, [claimId, getClaimsDetail, loadingSwitch, show])

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: dataForm,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleCloseModal()
    },
  })

  const isOtherImageFile = (media_path) => {
    const validExtensions = VALIDATE_FILE.EXTENSION_OTHER_IMAGE
    const fileExtension = media_path.split('.')
    return validExtensions.includes(fileExtension[fileExtension.length - 1])
  }

  return (
    <>
      <Modal
        id='gori_modal_detail_claim'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'CLAIM_DETAILS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            {isLoadingForm || isEmpty(dataForm) ? (
              <SkeletonClaimsForm name='detail' />
            ) : (
              <Container>
                <div className='row my-7'>
                  <h3 className='pb-2'>{intl.formatMessage({id: 'CLAIM_INFORMATION'})}</h3>
                  <div className='table-responsive'>
                    <table className='table table-rounded table-striped border gs-7'>
                      <thead>
                        <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'>
                          <th>{intl.formatMessage({id: 'TRACKING_NUMBER'})}</th>
                          <th>{intl.formatMessage({id: 'CLAIM_REASON'})}</th>
                          <th>{intl.formatMessage({id: 'CLAIM_AMOUNT'})}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{dataForm.tracking_number}</td>
                          <td>{dataForm.claim_reason}</td>
                          <td> {convertCurrency(dataForm.claim_total)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='row my-7'>
                  <h3 className='pb-2'>{intl.formatMessage({id: 'CLAIM_HISTORY'})}</h3>
                  <TableWrapper className='mb-5'>
                    <Table
                      columns={columnsHistories}
                      data={dataHistory.histories}
                      pagination={dataHistory.pagination}
                      tableClass={'table table-rounded table-striped border gs-7'}
                      usePagination={dataHistory.pagination?.total_pages > 1}
                    />
                    {dataHistory.pagination?.total < 1 && (
                      <div className='d-flex justify-content-center text-dark mb-5'>
                        {intl.formatMessage({id: 'NO_HISTORY'})}
                      </div>
                    )}
                  </TableWrapper>

                  {!isEmpty(dataForm?.appeals) && (
                    <div className='row my-7'>
                      <h5>{intl.formatMessage({id: 'APPEAL_INFORMATION'})}</h5>
                      <div className='bg-light px-10 py-5 rounded'>
                        {dataForm?.appeals.map((appeal, index) => {
                          return (
                            <div
                              key={index}
                              className='mb-10 border-bottom border-secondary border-2'
                            >
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <span className='fs-6 fw-bolder'>
                                  {intl.formatMessage({id: 'APPEAL'})} {index + 1}
                                </span>
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='btn btn-sm btn-icon btn-white btn-active-light opacity-75 cursor-pointer rounded-circle'
                                    onClick={() => {
                                      const newAppeals = dataForm.appeals.map((item) => {
                                        if (item.key === index) {
                                          item.show = !item.show
                                        }
                                        return item
                                      })
                                      setDataForm((prev) => ({...prev, appeals: newAppeals}))
                                    }}
                                  >
                                    {appeal.show ? (
                                      <KTSVG
                                        path='/media/gori/claims/down.svg'
                                        className='svg-icon-2'
                                      />
                                    ) : (
                                      <KTSVG
                                        path='/media/gori/claims/left.svg'
                                        className='svg-icon-2'
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {appeal.show && (
                                <div>
                                  <div className='my-5'>
                                    <label className='fw-bold text-gray-800'>
                                      {intl.formatMessage({id: 'REASON_FOR_APPEAL'})}
                                    </label>
                                    <select className='form-select cursor-no-drop' disabled>
                                      {OPTION_CLAIMS_APPEAL_REASON.map((item, index) => (
                                        <option selected={item.value === appeal.reason} key={index}>
                                          {intl.formatMessage({id: item.label})}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className='my-5'>
                                    <label className='fw-bold text-gray-800'>
                                      {intl.formatMessage({id: 'ADDITIONAL_INFORMATION'})}
                                    </label>
                                    <textarea
                                      className='form-control cursor-no-drop'
                                      id='information'
                                      value={appeal.information}
                                      disabled
                                    />
                                  </div>
                                  <div className='col-md-12'>
                                    <label className='fw-bold text-gray-800'>
                                      {intl.formatMessage({id: 'PROOF_OF_VALUE_DAMAGE'})}
                                    </label>
                                    <div className='d-flex flex-lg-wrap align-items-center bg-light rounded-2'>
                                      {appeal?.photos.map((img, index) => {
                                        return (
                                          <div key={index} className='m-3 cursor-pointer'>
                                            {isOtherImageFile(img?.media_path) ? (
                                              <img
                                                onClick={() => blockPopUp(img.url)}
                                                src={toAbsoluteUrl('/media/gori/claims/folder.svg')}
                                                width={150}
                                                height={150}
                                                alt='folder'
                                              />
                                            ) : (
                                              <img
                                                src={img?.url}
                                                width={150}
                                                height={150}
                                                alt='img'
                                              />
                                            )}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className='row my-7'>
                  <h3 className='pb-2'>{intl.formatMessage({id: 'ITEM_DETAILS'})}</h3>
                  <div className='bg-light px-10 py-5 rounded'>
                    {dataForm.items.map((item, index) => (
                      <div key={index} className='mb-10 border-bottom border-secondary border-2'>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                          <span className='fs-6 fw-bolder'>
                            {intl.formatMessage({id: 'ITEM'})} {index + 1}
                          </span>
                          <div className='d-flex align-items-center'>
                            <div
                              className='btn btn-sm btn-icon btn-white btn-active-light opacity-75 cursor-pointer rounded-circle'
                              onClick={() => {
                                const newItems = dataForm.items.map((item) => {
                                  if (item.key === index) {
                                    item.show = !item.show
                                  }
                                  return item
                                })
                                setDataForm((prev) => ({...prev, items: newItems}))
                              }}
                            >
                              {item.show ? (
                                <KTSVG path='/media/gori/claims/down.svg' className='svg-icon-2' />
                              ) : (
                                <KTSVG path='/media/gori/claims/left.svg' className='svg-icon-2' />
                              )}
                            </div>
                          </div>
                        </div>
                        {item.show && (
                          <>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='d-flex mb-5'>
                                  <InputTextNoFormik
                                    className='col-8'
                                    labelClassName='col-4 col-form-label text-gray-800'
                                    label={intl.formatMessage({id: 'ITEM_NAME'})}
                                    value={item.claim_item_name}
                                    disabled
                                  />
                                </div>
                                <div className='d-flex mb-5'>
                                  <InputTextNoFormik
                                    type='number'
                                    className='col-8'
                                    labelClassName='col-4 col-form-label text-gray-800'
                                    label={intl.formatMessage({id: 'UNIT_PRICE'})}
                                    value={item.claim_item_price}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='d-flex mb-5'>
                                  <SelectNoFormik
                                    className='col-8'
                                    labelClassName='col-4 col-form-label'
                                    label={intl.formatMessage({id: 'ITEM_TYPE'})}
                                    placeholder={intl.formatMessage(
                                      {id: 'SELECT_A_INPUT'},
                                      {input: intl.formatMessage({id: 'ITEM_TYPE'})}
                                    )}
                                    emptyDefault={false}
                                    options={OPTION_ITEM_TYPE}
                                    value={item.claim_item_type}
                                    disabled
                                    hasUseIntl={true}
                                  />
                                </div>
                                {!isEmpty(item?.claim_item_specific_item_type) && (
                                  <div className='d-flex mb-5'>
                                    <InputTextNoFormik
                                      className='col-8'
                                      labelClassName='col-4 col-form-label'
                                      label={intl.formatMessage({id: 'SPECIFIC_ITEM_TYPE'})}
                                      value={item.claim_item_specific_item_type}
                                      disabled
                                    />
                                  </div>
                                )}
                                <div className='d-flex'>
                                  <InputTextNoFormik
                                    type='number'
                                    className='col-8'
                                    labelClassName='col-4 col-form-label text-gray-800'
                                    label={intl.formatMessage({id: 'QUANTITY'})}
                                    value={item.claim_item_qty}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row mb-5'>
                              <InputTextAreaNoFormik
                                labelClassName='col-form-label'
                                label={intl.formatMessage({id: 'ITEM_DESCRIPTION'})}
                                value={item.claim_item_desc}
                                disabled
                              />
                            </div>
                            <div className='row mb-5'>
                              <label className={'form-label'}>
                                {intl.formatMessage({
                                  id:
                                    dataForm.claim_reason === 'damage'
                                      ? 'PHOTO_OF_DAMAGED_ITEM'
                                      : 'PHOTO_OF_ITEM',
                                })}
                              </label>
                              <div className='col-md-12'>
                                <div className='d-flex flex-lg-wrap align-items-center bg-light rounded-2'>
                                  {item.photos.map((img, index) => {
                                    return (
                                      <div key={index} className='m-3 cursor-pointer'>
                                        {isOtherImageFile(img?.media_path) ? (
                                          <img
                                            onClick={() => blockPopUp(img.url)}
                                            src={toAbsoluteUrl('/media/gori/claims/folder.svg')}
                                            width={150}
                                            height={150}
                                            alt='folder'
                                          />
                                        ) : (
                                          <img src={img?.url} width={150} height={150} alt='img' />
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    {/* Proof of Value */}
                    <div className='mb-10'>
                      <span className='fs-6 fw-bolder mb-5'>
                        {intl.formatMessage({id: 'PROOF_OF_VALUE_INVOICE'})}
                      </span>
                      <div className=''>
                        <div className='col-md-12'>
                          <div className='d-flex flex-lg-wrap align-items-center bg-light rounded-2'>
                            {dataForm.claim_invoice.map((item, index) => {
                              return (
                                <div key={index} className='m-3'>
                                  {isOtherImageFile(item?.media_path) ? (
                                    <img
                                      onClick={() => blockPopUp(item.url)}
                                      src={toAbsoluteUrl('/media/gori/claims/folder.svg')}
                                      width={150}
                                      height={150}
                                      alt='folder'
                                    />
                                  ) : (
                                    <img src={item?.url} width={150} height={150} alt='img' />
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Replacement Package Information */}
                    <div className='mb-10'>
                      <span className='fs-6 fw-bolder mb-5'>
                        {intl.formatMessage({id: 'REPLACEMENT_PACKAGE_INFORMATION'})}
                      </span>
                      <div>
                        <div className='d-flex align-items-center mb-4'>
                          <span className='fs-6 fw-semibold text-gray-800 d-block'>
                            {intl.formatMessage({id: 'HAS_A_REPLACEMENT_PACKAGE_BEEN_SHIPPED'})}
                          </span>
                          <label className='form-check form-switch form-check-custom form-check-solid px-5'>
                            <input
                              className='form-check-input cursor-no-drop'
                              type='checkbox'
                              defaultChecked={dataForm.claim_is_replace_package === 1}
                              defaultValue={dataForm?.claim_is_replace_package}
                              disabled
                            />
                          </label>
                        </div>
                        {dataForm.claim_is_replace_package ? (
                          <div className='d-flex'>
                            <InputTextNoFormik
                              className='col-9'
                              labelClassName='col-3 col-form-label'
                              label={intl.formatMessage({id: 'REPLACEMENT_TRACKING_NUMBER'})}
                              value={dataForm.tracking_number_replace}
                              disabled
                            />
                          </div>
                        ) : (
                          <div className='d-flex align-items-center mb-5'>
                            <span className='fs-6 fw-semibold text-gray-800 d-block'>
                              {intl.formatMessage({
                                id: 'DO_YOU_PLAN_ON_SHIPPING_A_RE_PLACEMENT_PACKAGE',
                              })}
                            </span>
                            <label className='form-check form-switch form-check-custom form-check-solid px-5'>
                              <input
                                className='form-check-input cursor-no-drop'
                                type='checkbox'
                                defaultChecked={dataForm.claim_is_plan_replace_package === 1}
                                defaultValue={dataForm.claim_is_plan_replace_package}
                                disabled
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Recipient Contacts */}
                    <div className='mb-10'>
                      <span className='fs-6 fw-bolder mb-5'>
                        {intl.formatMessage({id: 'RECIPIENT_CONTACTS'})}
                      </span>
                      <div className='d-flex'>
                        <div className='col-md-6 mb-1 me-5'>
                          <div className='d-flex'>
                            <InputTextNoFormik
                              className='col-8'
                              labelClassName='col-4 col-form-label'
                              label={intl.formatMessage({id: "RECIPIENT'S_PHONE"})}
                              value={dataForm.claim_recipient_phone}
                              disabled
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='d-flex'>
                            <InputTextNoFormik
                              className='col-8'
                              labelClassName='col-4 col-form-label text-gray-800'
                              label={intl.formatMessage({id: "RECIPIENT'S_EMAIL"})}
                              value={dataForm.claim_recipient_email}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary'
                label={intl.formatMessage({id: 'CLOSE'})}
                loadingText={intl.formatMessage({id: 'CLOSE'})}
                event={handleCloseModal}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {DetailClaimsModal}
