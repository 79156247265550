const OPTIONS_ACTIVITY = [
  {label: 'ALL', value: 'all'},
  {label: 'LABELS', value: 'label'},
  {label: 'DEPOSITS', value: 'deposit'},
]

const VALUE_SHIPMENT_MAP = {
  CHECK: {
    FROM: 'from',
    TO: 'to',
  },
  TYPE: {
    GLOBAL: 'false',
    DOMESTIC: 'true',
  },
}

export {OPTIONS_ACTIVITY, VALUE_SHIPMENT_MAP}
