import React, {useState} from 'react'
import * as Yup from 'yup'
import {CSSTransition} from 'react-transition-group'
import {Button, ButtonBack, InputTextFormik} from '../../../../../../../_gori/partials/widgets'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {useFormik} from 'formik'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import SettingsService from '../../../../core/_requests'
import {toast} from 'react-toastify'
import {OrderType} from '../../../../../orders/core/_const'
import {StoresListing} from '../StoresListing'

const ConnectWalmart: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [isReload, setIsReload] = useState<boolean>(false)

  const {walmartYup} = UseYupValidate()
  const validationSchema = Yup.object().shape({
    api_key: walmartYup.apiKey,
    secret_key: walmartYup.secretKey,
  })

  const initialValues = {
    store_type: OrderType.WALMART,
    api_key: '',
    secret_key: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const config = {cancelToken: newCancelToken()}
      setStatus('')
      await SettingsService.connect(values, config)
        .then(async () => {
          toast.success(intl.formatMessage({id: 'CONNECTIONS_SUCCESSFUL'}))
          handleResetForm()
          setIsReload(true)
        })
        .catch((error) => {
          if (isCancel(error)) return
          setStatus(intl.formatMessage({id: 'INVALID_API_KEY'}))
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const handleResetForm = () => {
    formik.resetForm()
  }

  const goBack = () => {
    navigate('/settings/connections')
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className={'card p-10 rounded-0 rounded-top'}>
          <div className='card-title'>
            <div className={'d-flex gap-4 align-items-center'}>
              <ButtonBack label={intl.formatMessage({id: 'BACK'})} event={goBack} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} {intl.formatMessage({id: 'WALMART'})}
              </span>
            </div>
          </div>
          <div className='card-body d-flex justify-content-center'>
            <div className='col-md-8'>
              <InputTextFormik
                labelClassName='col-md-4 col-form-label'
                required
                label={'Walmart ' + intl.formatMessage({id: 'API_KEY'})}
                formik={formik}
                name={'api_key'}
              />
              <InputTextFormik
                labelClassName='col-md-4 col-form-label mt-5'
                required
                label={'Walmart ' + intl.formatMessage({id: 'API_SECRET'})}
                formik={formik}
                name={'secret_key'}
              />

              {formik.status && (
                <div className='col-md-8 mt-5 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <div className='text-end'>
                <Button
                  className='btn btn-primary mt-7'
                  label={intl.formatMessage({id: 'CONNECT'})}
                  loadingText={intl.formatMessage({id: 'CONNECT'})}
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !formik.isValid}
                  event={formik.submitForm}
                />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <StoresListing isReload={isReload} setIsReload={setIsReload} storeType={OrderType.WALMART} />
    </>
  )
}

export {ConnectWalmart}
