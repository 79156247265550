import {createContext, useContext, useReducer} from 'react'

export const StateContext = createContext<any>({})

export const StateProvider = ({initialState, reducer, children}) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStatesGlobalNoStorage = () => useContext(StateContext)
