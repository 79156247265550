import {CSSTransition} from 'react-transition-group'

const ActiveLogSkeleton: React.FC = () => {
  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='card card-xl-stretch mb-xl-10 shadow-sm'>
        {/* style={{filter: 'blur(4px)'}} */}
        <div className='card-body d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <h1 className='col-6 placeholder-wave'>
              <span className='col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
            </h1>
            <div className='col-6 d-flex flex-row-reverse placeholder-wave'>
              <span className='btn col-9 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
            </div>
          </div>
          <div className='pt-5'>
            <div className='timeline-label'>
              {Array.from({length: 8}).map((item, index) => (
                <div className='timeline-item my-12' key={index}>
                  <div className='timeline-label fw-bolder text-gray-800 fs-8 placeholder-wave '>
                    <span className='fs-3 col-10 placeholder placeholder-lg rounded-2 bg-secondary ' />
                  </div>
                  <div className='timeline-badge'>
                    <i
                      className='fa fa-genderless dashboard-theme-color-green fs-1'
                      aria-hidden='true'
                    ></i>
                  </div>
                  <div className='timeline-content d-flex '>
                    <div className='timeline-label fw-bolder text-gray-800 fs-8 placeholder-wave mx-2'>
                      <span className='fs-3 col-10 placeholder placeholder-lg rounded-2 bg-secondary ' />
                    </div>
                    <span className='fs-3 col-6 placeholder placeholder-lg rounded-2 bg-secondary ' />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export {ActiveLogSkeleton}
