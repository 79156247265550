import React, {useContext, useState} from 'react'
import {BatchesProviderModel, FileModel} from '../../../app/modules/batches/core/_models'

const initBatchContextPropsState = {
  batch: {
    id: undefined,
    path: '',
    name: '',
    status: '',
    created_at: '',
    updated_at: '',
  },
  setBatch: () => {},
}

const BatchContext = React.createContext<BatchesProviderModel>(initBatchContextPropsState)

const BatchesProvider: React.FC = ({children}) => {
  const [batch, setBatch] = useState<FileModel>(initBatchContextPropsState.batch)

  return <BatchContext.Provider value={{batch, setBatch}}>{children}</BatchContext.Provider>
}

function useBatchesProvider() {
  return useContext(BatchContext)
}

export {BatchesProvider, useBatchesProvider}
