import React, {useContext, useState} from 'react'

export interface OrdersProviderModel {
  optionsAddress: {from: any; to: any}
  setOptionsAddress: any
}

const initOrdersContextPropsState = {
  optionsAddress: {from: [], to: []},
  setOptionsAddress: () => {},
}

const OrdersContext = React.createContext<OrdersProviderModel>(initOrdersContextPropsState)

const OrdersProvider: React.FC = ({children}) => {
  const [optionsAddress, setOptionsAddress] = useState<any>(
    initOrdersContextPropsState.optionsAddress
  )

  return (
    <OrdersContext.Provider value={{optionsAddress, setOptionsAddress}}>
      {children}
    </OrdersContext.Provider>
  )
}

function useOrdersProvider() {
  return useContext(OrdersContext)
}

export {OrdersProvider, useOrdersProvider}
