import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_gori/helpers'
import {Button} from '../../../../../_gori/partials/widgets'

type Props = {
  show: boolean
  handleClose: () => void
  handleCreateLabel: () => void
  name?: string
}

const NoticeCreateLabelModal: React.FC<Props> = ({
  show,
  handleClose,
  handleCreateLabel,
  name = 'CREATE_LABEL',
}) => {
  const intl = useIntl()

  return (
    <>
      <Modal
        id='gori_modal_notice_create_label'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>{intl.formatMessage({id: name})}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mx-10 my-5 d-flex flex-column justify-content-center align-items-center fs-4'>
              <div className='mb-5'>
                <img src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')} alt='img' />
              </div>
              <p className='text-center'>
                {intl.formatMessage({
                  id: 'THE_SELECTED_PACKAGE_TYPE_DOES_NOT_MATCH_THE_SHIPPING_SERVICE_THE_PACKAGE_TYPE_WILL_BE_IGNORED',
                })}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: name})}
                loadingText={intl.formatMessage({id: name})}
                event={handleCreateLabel}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {NoticeCreateLabelModal}
