import {intersection, isEmpty} from 'lodash'
import {useMemo} from 'react'
import {useAuth} from '../../app/modules/auth'
import {KEY_PERMISSIONS, ROUTING_PERMISSIONS} from '../constants'

const UseRoutesPermission = () => {
  const {currentUser} = useAuth()
  const permission = currentUser?.permissions

  const routes: any = useMemo(() => {
    const _routes = Object.entries(ROUTING_PERMISSIONS).reduce(
      (initValue, [key, value]: [any, any]) => {
        let roles: any = []
        if (value?.roles) {
          let isSystemAdminOnly = value?.roles.includes(KEY_PERMISSIONS.SYSTEM_ADMIN)
          roles = [
            ...value?.roles,
            KEY_PERMISSIONS.SYSTEM_ADMIN,
            ...(isSystemAdminOnly ? [] : [KEY_PERMISSIONS.ADMIN]),
          ]
        }
        initValue[key] = !isEmpty(intersection(roles, permission))
          ? {...value, hasPermission: true}
          : {...value}
        return initValue
      },
      {}
    )

    return _routes
  }, [permission])

  const firstPage = useMemo(() => {
    const keyFirst: any = Object.keys(routes).find((page) => routes[page].hasPermission === true)
    return routes[keyFirst]
  }, [routes])

  return {routes: routes, firstPage: firstPage}
}

export default UseRoutesPermission
