import React, {useEffect, useMemo, useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useIntl} from 'react-intl'
import {PROFILE_FIELD, STEPS} from '../../core/_const'
import {find, forEach, includes, isEmpty, omit} from 'lodash'
import {
  Button,
  ButtonBack,
  InputEmailFormik,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import OnboardService from '../../core/_requests'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import clsx from 'clsx'
import {AutocompleteAddress} from '../../../common/components/AutocompleteAddress'
import {ButtonLabelCreation} from '../../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {KTSVG} from '../../../../../_gori/helpers'
import UserService from '../../../users/core/_requests'
import {ConfigGeneral} from '../../../settings/core/_const'
import {textAlign} from 'html2canvas/dist/types/css/property-descriptors/text-align'

type Props = {
  setActiveStep: any
  onboard: any | null
  setOnboard: any
  setErrorValidate: any
}

const Profile: React.FC<Props> = ({setActiveStep, onboard, setOnboard, setErrorValidate}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {stringYup, regexYup, infoYup} = UseYupValidate()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [isLoadingSaveAndExit, setIsLoadingSaveAndExit] = useState<boolean>(false)
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false)
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useAuth()

  const nextStep = () => {
    setActiveStep(STEPS.shipping)
  }
  const backStep = () => {
    setActiveStep(STEPS.hazmat)
  }

  const initialValues = useMemo(() => {
    let init: any = {}
    forEach(
      [
        ...PROFILE_FIELD.LEFT,
        ...PROFILE_FIELD.CENTER,
        ...PROFILE_FIELD.RIGHT.report,
        ...PROFILE_FIELD.RIGHT.accounting,
      ],
      (field: any) => {
        if (!isEmpty(onboard)) {
          switch (field.name) {
            case 'report_name':
              init[field.name] = onboard?.profile?.report?.name ?? ''
              break
            case 'report_email':
              init[field.name] = ''
              init[field.nameParent] = onboard?.profile?.report?.emails ?? []
              break
            case 'accounting_name':
              init[field.name] = onboard?.profile?.accounting?.name ?? ''
              break
            case 'accounting_email':
              init[field.name] = ''
              init[field.nameParent] = onboard?.profile?.accounting?.emails ?? []
              break
            default:
              init[field.name] = onboard?.profile?.[`${field.name}`]
              break
          }
        }
      }
    )
    return init
  }, [onboard])

  const validationSchema = useMemo(() => {
    let userStep = STEPS[`${currentUser?.onboard_step}`] ?? 0
    let currentStep = STEPS[`profile`]
    let required = true
    if (currentStep >= userStep && saveAndExit) {
      required = false
    }
    return {
      company_name: stringYup(100, 'COMPANY_NAME', required),
      phone: infoYup.phone('PHONE', required),
      promo_code: stringYup(50, 'PROMO_CODE', false),
      monthly_shipment_volume: stringYup(255, 'MONTHLY_SHIPMENT_VOLUME', required),
      sale_rep: stringYup(50, 'SALE_REP', false).matches(/^[\d\w\s]+$/, {
        message: intl.formatMessage(
          {id: 'SPECIAL_CHARACTER_ARE_GENERATE_NOT_ALLOW'},
          {input: intl.formatMessage({id: 'SALE_REP'})}
        ),
        excludeEmptyString: true,
      }),
      country: stringYup(255, 'COUNTRY', required),
      street1: stringYup(255, 'ADDRESS', required),
      street2: stringYup(255, 'ADDRESS_2', false),
      city: stringYup(255, 'CITY', required),
      zip: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', required),
        otherwise: stringYup(255, 'POSTAL_CODE', required),
      }),
      state: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', required),
        otherwise: stringYup(255, 'STATE', required),
      }),
      report_name: regexYup.inputText('NAME', required, 100),
      report_emails: Yup.lazy(() => {
        return required
          ? Yup.array()
              .min(
                1,
                intl.formatMessage(
                  {id: 'INPUT_IS_REQUIRED'},
                  {input: intl.formatMessage({id: 'EMAIL'})}
                )
              )
              .max(
                ConfigGeneral.MAX_EMAIL_NUMBER,
                intl.formatMessage(
                  {id: 'ONLY_UP_TO_INPUT_EMAIL_ADDRESSES_ARE_ALLOWED'},
                  {input: ConfigGeneral.MAX_EMAIL_NUMBER}
                )
              )
          : Yup.array()
      }),
      report_email: Yup.lazy(() => {
        return required
          ? infoYup.email('EMAIL', false).test({
              name: 'unique',
              test: function (value) {
                const emails = this.parent.report_emails || []
                return !emails?.map((item) => item?.toLowerCase()).includes(value?.toLowerCase())
              },
              message: intl.formatMessage(
                {id: 'INPUT_ALREADY_EXISTS'},
                {input: intl.formatMessage({id: 'EMAIL'})}
              ),
            })
          : infoYup.email('EMAIL', false)
      }),
      accounting_name: regexYup.inputText('NAME', required, 100),
      accounting_emails: Yup.lazy(() => {
        return required
          ? Yup.array()
              .min(
                1,
                intl.formatMessage(
                  {id: 'INPUT_IS_REQUIRED'},
                  {input: intl.formatMessage({id: 'EMAIL'})}
                )
              )
              .max(
                ConfigGeneral.MAX_EMAIL_NUMBER,
                intl.formatMessage(
                  {id: 'ONLY_UP_TO_INPUT_EMAIL_ADDRESSES_ARE_ALLOWED'},
                  {input: ConfigGeneral.MAX_EMAIL_NUMBER}
                )
              )
          : Yup.array()
      }),
      accounting_email: Yup.lazy(() => {
        return required
          ? infoYup.email('EMAIL', false).test({
              name: 'unique',
              test: function (value) {
                const emails = this.parent.accounting_emails || []
                return !emails?.map((item) => item?.toLowerCase()).includes(value?.toLowerCase())
              },
              message: intl.formatMessage(
                {id: 'INPUT_ALREADY_EXISTS'},
                {input: intl.formatMessage({id: 'EMAIL'})}
              ),
            })
          : infoYup.email('EMAIL', false)
      }),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, saveAndExit])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: async (values, {setSubmitting}) => {
      try {
        if (!saveAndExit) {
          setSubmitting(true)
          setIsLoadingForm(true)
        } else {
          setIsLoadingSaveAndExit(true)
        }
        let payload = {
          step: 'profile',
          data: {
            ...omit(values, [
              'report_name',
              'report_email',
              'report_emails',
              'accounting_name',
              'accounting_email',
              'accounting_emails',
            ]),
            report: {
              name: values.report_name,
              emails: values.report_emails,
            },
            accounting: {
              name: values.accounting_name,
              emails: values.accounting_emails,
            },
          },
          save_and_exit: saveAndExit,
        }
        const config = {cancelToken: newCancelToken()}
        const res = await OnboardService.store(payload, config)
        if (res) {
          setCurrentUser(await UserService.getUser(config))
          let newOnboard = {
            profile: payload.data,
            hazmat: onboard.hazmat,
            shipping: onboard.shipping,
            ob_general: onboard.ob_general,
            ship_from_address: onboard.ship_from_address,
          }
          setOnboard(newOnboard)
          toast.success(intl.formatMessage({id: 'UPDATE_PROFILE_SUCCESSFULLY'}))
          await nextStep()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        if (!saveAndExit) {
          setSubmitting(false)
          setIsLoadingForm(false)
        } else {
          setIsLoadingSaveAndExit(true)
          navigate('/logout')
        }
        formik.resetForm()
      }
    },
  })

  const selectedPlace = (selected) => {
    let city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(formik.getFieldProps(`city`).name, city?.long_name ?? null)
    let state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(formik.getFieldProps(`state`).name, state?.short_name ?? null)
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`country`).name, country?.short_name ?? null)
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('zip', zipCode)
  }

  const checkHasSaved = () => {
    let userStep = STEPS[`${currentUser?.onboard_step}`] ?? 0
    let currentStep = STEPS[`profile`]
    return userStep > currentStep
  }

  useEffect(() => {
    setErrorValidate(!isEmpty(formik.errors))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <>
          <div className='d-flex justify-content-center py-12'>
            <div className='w-75'>
              <div className='row bg-white m-0 rounded-2 p-10 shadow'>
                <div className='col-md-4'>
                  <div className='text-start'>
                    <p className='fs-3 fw-bolder text-primary'>
                      {intl.formatMessage({id: 'ABOUT_YOUR_BUSINESS'})}
                    </p>
                    <div className='row'>
                      {PROFILE_FIELD.LEFT.map((field, index) => {
                        if (field.type === 'text') {
                          return (
                            <div className='my-2 col-12' key={index}>
                              <InputTextFormik
                                className='col-12'
                                formik={formik}
                                name={field.name}
                                disabled={formik.isSubmitting || field.disabled}
                                label={intl.formatMessage({id: field.label})}
                                labelClassName={clsx('fw-bolder', {required: field.required})}
                              />
                            </div>
                          )
                        }
                        if (field.type === 'select') {
                          return (
                            <div className='my-2 col-12' key={index}>
                              <SelectFormik
                                checkFormik
                                emptyDefault={false}
                                options={field?.options || []}
                                formik={formik}
                                name={field.name}
                                placeholder={intl.formatMessage({id: field.placeholder})}
                                required={field.required}
                                isSearchable={false}
                                hasUseIntl={true}
                                label={intl.formatMessage({id: field.label})}
                                labelClassName={clsx('fw-bolder', {required: field.required})}
                              />
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='text-start'>
                    <p className='fs-3 fw-bolder text-primary'>
                      {intl.formatMessage({id: 'BUSINESS_ADDRESS'})}
                    </p>
                    <div className='row'>
                      {PROFILE_FIELD.CENTER.map((field, index) => {
                        if (field.type === 'text') {
                          return (
                            <div className={`my-2 ${field?.col}`} key={index}>
                              <InputTextFormik
                                className='col-12'
                                formik={formik}
                                name={field.name}
                                disabled={formik.isSubmitting}
                                label={intl.formatMessage({id: field.label})}
                                labelClassName={clsx('fw-bolder', {required: field.required})}
                              />
                            </div>
                          )
                        }
                        if (field.type === 'select') {
                          return (
                            <div className={clsx('my-2')} key={index}>
                              <SelectFormik
                                checkFormik
                                placeholder={''}
                                options={field?.options || []}
                                formik={formik}
                                name={field.name}
                                required={field.required}
                                label={intl.formatMessage({id: field.label})}
                                labelClassName={clsx('fw-bolder', {required: field.required})}
                              />
                            </div>
                          )
                        }
                        if (field.type === 'address') {
                          return (
                            <div className={`my-2 ${field?.col} address-complete`} key={index}>
                              <label
                                className={`form-label fw-bolder ${
                                  field.required ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: field.label})}
                              </label>
                              <AutocompleteAddress
                                formik={formik}
                                name={field.name}
                                label=''
                                onSelected={(selected) => {
                                  selectedPlace(selected)
                                }}
                              />
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='text-start'>
                    <p className='fs-3 fw-bolder text-primary'>
                      {intl.formatMessage({id: 'PRIMARY_CONTACT_EMAILS'})}
                    </p>
                    <div className='row'>
                      <div>
                        <p className='fs-5 fw-bolder'>{intl.formatMessage({id: 'DATA_REPORT'})}</p>
                        {PROFILE_FIELD.RIGHT?.report.map((field, index) => {
                          if (field.type === 'text') {
                            return (
                              <div className={`my-2 ${field?.col}`} key={index}>
                                <InputTextFormik
                                  className='col-12'
                                  formik={formik}
                                  name={field.name}
                                  disabled={formik.isSubmitting}
                                  label={intl.formatMessage({id: field.label})}
                                  labelClassName={clsx('fw-bolder', {required: field.required})}
                                />
                              </div>
                            )
                          }
                          if (field.type === 'email') {
                            return (
                              <div className={`my-2 ${field?.col}`} key={index}>
                                <InputEmailFormik
                                  label={intl.formatMessage({id: field.label})}
                                  labelClassName={'fw-bolder'}
                                  required={field.required}
                                  formik={formik}
                                  name={field.name}
                                  nameParent={field.nameParent}
                                  maxEmailNumber={ConfigGeneral.MAX_EMAIL_NUMBER}
                                  configAppend={{
                                    name: intl.formatMessage({id: 'ADD'}),
                                    position: 'right',
                                    classInput: 'pe-25',
                                    hasEvent: true,
                                  }}
                                />
                              </div>
                            )
                          }
                          return null
                        })}
                      </div>
                      <div>
                        <p className='fs-5 fw-bolder mt-2'>
                          {intl.formatMessage({id: 'ACCOUNTING'})}
                        </p>
                        {PROFILE_FIELD.RIGHT?.accounting.map((field, index) => {
                          if (field.type === 'text') {
                            return (
                              <div className={`my-2 ${field?.col}`} key={index}>
                                <InputTextFormik
                                  className='col-12'
                                  formik={formik}
                                  name={field.name}
                                  disabled={formik.isSubmitting}
                                  label={intl.formatMessage({id: field.label})}
                                  labelClassName={clsx('fw-bolder', {required: field.required})}
                                />
                              </div>
                            )
                          }
                          if (field.type === 'email') {
                            return (
                              <div className={`my-2 ${field?.col}`} key={index}>
                                <InputEmailFormik
                                  label={intl.formatMessage({id: field.label})}
                                  labelClassName={'fw-bolder'}
                                  required={field.required}
                                  formik={formik}
                                  name={field.name}
                                  nameParent={field.nameParent}
                                  maxEmailNumber={ConfigGeneral.MAX_EMAIL_NUMBER}
                                  configAppend={{
                                    name: intl.formatMessage({id: 'ADD'}),
                                    position: 'right',
                                    classInput: 'pe-25',
                                    hasEvent: true,
                                  }}
                                />
                              </div>
                            )
                          }
                          return null
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-2 d-flex justify-content-between'>
                <ButtonBack
                  event={() => backStep()}
                  className='back onboard-form'
                  label={intl.formatMessage({id: 'BACK'})}
                  disabled={(checkHasSaved() && !isEmpty(formik.errors)) || isLoadingForm}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'CONTINUE'})}
                  loadingText={intl.formatMessage({id: 'CONTINUE'})}
                  loading={isLoadingForm}
                  event={async () => {
                    await setSaveAndExit(false)
                    formik.submitForm()
                  }}
                  disabled={formik.isSubmitting || !isEmpty(formik.errors)}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <ButtonLabelCreation
              className='btn btn-light'
              label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              event={async () => {
                await setSaveAndExit(true)
                formik.submitForm()
              }}
              loading={isLoadingSaveAndExit}
              disabled={(checkHasSaved() && !isEmpty(formik.errors)) || isLoadingForm}
            >
              <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
            </ButtonLabelCreation>
          </div>
        </>
      </CSSTransition>
    </>
  )
}

export {Profile}
