import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import SettingsService from '../../../../../settings/core/_requests'
import {Button, InputTextFormik} from '../../../../../../../_gori/partials/widgets'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {StorageHelpers} from '../../../../../../../_gori/helpers'

const ConnectShopify: React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {regexYup} = UseYupValidate()

  const initialValues = {
    storeLink: '',
  }

  const validationSchema = Yup.object().shape({
    storeLink: regexYup.storeLink,
  })

  const onSubmit = async (values) => {
    setIsLoading(true)
    StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
    await SettingsService.getShopifyIntegrateLink(values.storeLink).then((res) => {
      setIsLoading(false)
      window.location.href = res
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='d-flex justify-content-center my-6'>
          <div className='w-75'>
            <div className='text-start'>
              <InputTextFormik
                labelClassName='fw-bolder'
                required
                formik={formik}
                name={'storeLink'}
                label={intl.formatMessage({id: 'SHOPIFY_STORE_DOMAIN'})}
              />
            </div>
            <div
              className={clsx('text-end mt-4', {
                'cursor-no-drop': isLoading,
              })}
            >
              <Button
                className={clsx('btn btn-primary', {
                  'pe-none': isLoading,
                  'cursor-no-drop': isLoading,
                })}
                loading={isLoading}
                loadingText={intl.formatMessage({id: 'CONNECT'})}
                label={intl.formatMessage({id: 'CONNECT'})}
                event={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {ConnectShopify}
