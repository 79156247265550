import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import SettingsService from '../../../../core/_requests'
import {Button, ButtonBack, InputTextFormik} from '../../../../../../../_gori/partials/widgets'
import {StoresListing} from '../StoresListing'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {useAuth} from '../../../../../auth'
import {StorageHelpers} from '../../../../../../../_gori/helpers'
import {OrderType} from '../../../../../orders/core/_const'

const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

const ConnectShopify: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {regexYup} = UseYupValidate()
  const {currentCompany} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const validationSchema = Yup.object().shape({
    storeLink: regexYup.storeLink,
  })

  const initialValues = {
    storeLink: '',
  }

  const onSubmit = async (values) => {
    setIsLoading(true)
    StorageHelpers.setItemCookies('slug', currentCompany?.slug, {
      path: '/',
      domain: BASE_DOMAIN?.split(':')[0],
    })
    await SettingsService.getShopifyIntegrateLink(values.storeLink).then((res) => {
      setIsLoading(false)
      window.location.replace(res)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const onGoBack = () => {
    navigate('/settings/connections')
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card p-10 rounded-bottom-0'>
          <div className='card-title'>
            <div className='d-flex gap-4 align-items-center'>
              <ButtonBack label={intl.formatMessage({id: 'BACK'})} event={onGoBack} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} Shopify
              </span>
            </div>
          </div>
          <div className='card-body d-flex justify-content-center'>
            <div className='d-flex align-items-start col-9'>
              <div className='col-9 d-flex align-items-center'>
                <InputTextFormik
                  className='col-md-9'
                  labelClassName='col-md-3'
                  required
                  label={'Shopify ' + intl.formatMessage({id: 'STORE_DOMAIN'})}
                  formik={formik}
                  name={'storeLink'}
                />
              </div>

              <div
                className={clsx('btn-group ms-5', {
                  'cursor-no-drop': isLoading,
                })}
              >
                <Button
                  className={clsx('btn btn-primary', {
                    'pe-none': isLoading,
                    'cursor-no-drop': isLoading,
                  })}
                  loading={isLoading}
                  loadingText={intl.formatMessage({id: 'CONNECT'})}
                  label={intl.formatMessage({id: 'CONNECT'})}
                  event={formik.submitForm}
                />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <StoresListing storeType={OrderType.SHOPIFY} />
    </>
  )
}

export {ConnectShopify}
