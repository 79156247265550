import React from 'react'

type Props = {
  countColumn?: number
  countRow?: number
  showPagination?: boolean
}

const TableSkeleton: React.FC<Props> = ({
  countColumn = 6,
  countRow = 10,
  showPagination = true,
}) => {
  const columns = Array.from({length: countColumn})
  const rows = Array.from({length: countRow})
  return (
    <div>
      {rows.map((row, indexRow) => (
        <div key={indexRow}>
          <div className='d-flex'>
            {columns.map((item, index) => (
              <div key={index} className={`col-${Math.floor(12 / countColumn)} placeholder-wave`}>
                <span className='col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            ))}
          </div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export {TableSkeleton}
