import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, omit} from 'lodash'
import moment from 'moment'
import React, {useRef, useState} from 'react'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import SignatureCanvas from 'react-signature-canvas'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {InputRadioFormik} from '../../../../../_gori/partials/widgets/form/InputRadioFormik'
import {OPTIONS_SIGNATURE, STEPS} from '../../core/_const'
import OnboardService from '../../core/_requests'

type Props = {
  show: boolean
  handleCloseModal: () => void
  setActiveStep?: any
}

const ACHModal: React.FC<Props> = ({show, handleCloseModal, setActiveStep}) => {
  const intl = useIntl()
  const sigCanvas = useRef<any>(null)
  const {infoYup, regexYup, stringYup, numberYup, dateYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [errorSignature, setErrorSignature] = useState<boolean>(false)

  const initialValues = {
    company: '',
    address: '',
    primary_contact_name: '',
    primary_phone: '',
    primary_email: '',
    secondary_contact_name: '',
    secondary_phone: '',
    secondary_email: '',
    payment_type: 'bank_account',
    bank_account: {
      bank_name: '',
      bank_account_number: '',
      bank_routing_number: '',
      name_on_bank_account: '',
      billing_address: '',
      bank_account_type_1: 'checking',
      bank_account_type_2: 'personal',
    },
    credit_card: {
      credit_card_number: '',
      expiration_date: '',
      cvv: '',
      name_on_credit_card: '',
      billing_address: '',
    },
    amount_of_initial_balance: '',
    minimum_reload_balance: '',
    auto_reload_amount: '',
    date: moment(),
    name: '',
    signature: '',
    option_signature: 'write',
    signature_draw: '',
  }

  const achValidate = Yup.object().shape({
    company: stringYup(255, 'COMPANY_NAME'),
    address: stringYup(255, 'ADDRESS'),
    primary_contact_name: regexYup.inputText('PRIMARY_CONTACT_NAME'),
    primary_phone: infoYup.phone('PRIMARY_PHONE'),
    primary_email: infoYup.email('PRIMARY_EMAIL'),
    secondary_contact_name: regexYup.inputText('SECONDARY_CONTACT_NAME', false),
    secondary_phone: infoYup.phone('SECONDARY_PHONE', false),
    secondary_email: infoYup.email('SECONDARY_EMAIL', false),
    payment_type: stringYup(50, 'PAYMENT_TYPE', false),
    bank_account: Yup.object().when('payment_type', {
      is: (val) => {
        return val === 'bank_account'
      },
      then: Yup.object().shape({
        bank_name: regexYup.inputText('BANK_NAME'),
        bank_account_number: stringYup(50, 'BANK_ACCOUNT_NUMBER'),
        bank_routing_number: stringYup(50, 'BANK_ROUTING_NUMBER'),
        name_on_bank_account: regexYup.inputText('NAME_ON_BANK_ACCOUNT'),
        billing_address: stringYup(255, 'BILLING_ADDRESS'),
      }),
    }),
    credit_card: Yup.object().when('payment_type', {
      is: (val) => val === 'credit_card',
      then: Yup.object().shape({
        credit_card_number: stringYup(50, 'CREDIT_CARD_NUMBER'),
        expiration_date: regexYup.expirationDate('EXPIRATION_DATE'),
        cvv: regexYup.cvv('CVV'),
        name_on_credit_card: regexYup.inputText('NAME_ON_CREDIT_CARD'),
        billing_address: stringYup(255, 'BILLING_ADDRESS'),
      }),
    }),
    amount_of_initial_balance: numberYup.amount('AMOUNT_OF_INITIAL_BALANCE'),
    minimum_reload_balance: numberYup.amount('MINIMUM_RELOAD_BALANCE'),
    auto_reload_amount: numberYup.amount('AUTO_RELOAD_AMOUNT'),
    name: regexYup.inputText('NAME'),
    date: dateYup('DATE'),
    signature: Yup.string().when('option_signature', {
      is: (val) => val === 'write',
      then: regexYup.inputText('E_SIGNATURE'),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: achValidate,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoadingForm(true)
      setErrorSignature(false)
      values.signature_draw = ''
      if (values?.option_signature === 'drawing') {
        values.signature_draw = sigCanvas.current.toDataURL()
        setErrorSignature(sigCanvas.current.isEmpty())
      }
      if (errorSignature) {
        setSubmitting(false)
        setIsLoadingForm(false)
        return
      }
      const removeKey = values.payment_type === 'credit_card' ? 'bank_account' : 'credit_card'
      const _payload = {
        step: 'add_fund',
        funding_option: 'ach',
        data: cloneDeep({...values, date: values?.date.format()}),
      }
      const payload = omit(_payload, [removeKey])

      try {
        const response = await OnboardService.store(payload, {cancelToken: newCancelToken()})
        if (response) {
          toast.success(intl.formatMessage({id: 'UPDATE_ADD_FUND_SUCCESSFULLY'}))
          if (setActiveStep) {
            setActiveStep(STEPS.label_creation)
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
        setIsLoadingForm(false)
      }
    },
  })

  const checkSignature = () => {
    if (formik.values[`option_signature`] === 'drawing' && sigCanvas.current.isEmpty()) {
      setErrorSignature(true)
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_credit_card_ach'
        tabIndex={-1}
        aria-hidden='true'
        centered
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
        dialogClassName='mw-1000px h-auto'
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder'}>
            <h3 className='fw-bolder fs-3'>{intl.formatMessage({id: 'GORI_WALLET_FORM'})}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <div className='customer-information-element'>
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='fw-bolder fs-2'>
                1. {intl.formatMessage({id: 'CUSTOMER_INFORMATION'})}
              </h3>
            </div>
            <div className='row my-4'>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  required
                  label={intl.formatMessage({id: 'COMPANY_NAME'})}
                  formik={formik}
                  name={'company'}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  required
                  label={intl.formatMessage({id: 'ADDRESS'})}
                  formik={formik}
                  name={'address'}
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  required
                  label={intl.formatMessage({id: 'PRIMARY_CONTACT_NAME'})}
                  formik={formik}
                  name={'primary_contact_name'}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  required
                  label={intl.formatMessage({id: 'PRIMARY_PHONE'})}
                  formik={formik}
                  name={'primary_phone'}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  required
                  label={intl.formatMessage({id: 'PRIMARY_EMAIL'})}
                  formik={formik}
                  name={'primary_email'}
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  label={intl.formatMessage({id: 'SECONDARY_CONTACT_NAME'})}
                  formik={formik}
                  name={'secondary_contact_name'}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  label={intl.formatMessage({id: 'SECONDARY_PHONE'})}
                  formik={formik}
                  name={'secondary_phone'}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  label={intl.formatMessage({id: 'SECONDARY_EMAIL'})}
                  formik={formik}
                  name={'secondary_email'}
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
          </div>
          <div className='customer-information-element'>
            <h3 className='fw-bolder fs-2'>2. {intl.formatMessage({id: 'PAYMENT_INFORMATION'})}</h3>
            <div className='d-flex align-items-center my-4 bg-light rounded-2 px-4 py-2'>
              <KTSVG path='/media/gori/ach/mark.svg' className='svg-icon svg-icon-1 ms-2' />
              <span className='fw-bolder'>
                {intl.formatMessage({
                  id: 'FILL_OUT_BANK_OR_CREDIT_CARD_INFORMATION_BASED_ON_YOUR_PAYMENT_METHOD',
                })}
              </span>
            </div>
            <div className='d-flex'>
              <div
                className={clsx(
                  'btn border border-2 bg-hover-light-primary border-hover-primary text-hover-primary',
                  {
                    'border-primary text-primary bg-light-primary':
                      formik.values.payment_type === 'bank_account',
                    'border-secondary text-gray-500': formik.values.payment_type === 'credit_card',
                  }
                )}
                onClick={() => formik.setFieldValue('payment_type', 'bank_account')}
              >
                {intl.formatMessage({id: 'BANK_INFORMATION'})}
              </div>
              <div
                className={clsx(
                  'btn border border-2 ms-2 bg-hover-light-primary border-hover-primary text-hover-primary',
                  {
                    'border-primary text-primary bg-light-primary':
                      formik.values.payment_type === 'credit_card',
                    'border-secondary text-gray-500': formik.values.payment_type === 'bank_account',
                  }
                )}
                onClick={() => formik.setFieldValue('payment_type', 'credit_card')}
              >
                {intl.formatMessage({id: 'CREDIT_CARD_INFORMATION'})}
              </div>
            </div>
            {formik.values.payment_type === 'bank_account' && (
              <div>
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <InputTextFormik
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'BANK_NAME'})}
                      formik={formik}
                      name={'bank_account.bank_name'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <InputTextFormik
                      type='number'
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'BANK_ACCOUNT_NUMBER'})}
                      formik={formik}
                      name={'bank_account.bank_account_number'}
                      disabled={formik.isSubmitting}
                      isInteger
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputTextFormik
                      type='number'
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'BANK_ROUTING_NUMBER'})}
                      formik={formik}
                      name={'bank_account.bank_routing_number'}
                      disabled={formik.isSubmitting}
                      isInteger
                    />
                  </div>
                </div>
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <InputTextFormik
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'NAME_ON_BANK_ACCOUNT'})}
                      formik={formik}
                      name={'bank_account.name_on_bank_account'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputTextFormik
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'BILLING_ADDRESS'})}
                      formik={formik}
                      name={'bank_account.billing_address'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <label className='form-label fw-bolder required'>
                      {intl.formatMessage({id: 'BANK_ACCOUNT_TYPE'})}
                    </label>
                    <div className='d-flex'>
                      <InputRadioFormik
                        formik={formik}
                        labelClassName={'fs-6'}
                        type='radio'
                        name='bank_account.bank_account_type_1'
                        label={intl.formatMessage({id: 'CHECKING'})}
                        valueInput='checking'
                        onChange={(e) => {
                          formik.setFieldValue('bank_account.bank_account_type_1', e.target.value)
                        }}
                      />
                      <InputRadioFormik
                        formik={formik}
                        className='ms-8'
                        labelClassName={'fs-6'}
                        type='radio'
                        name='bank_account.bank_account_type_1'
                        label={intl.formatMessage({id: 'SAVINGS'})}
                        valueInput='savings'
                        onChange={(e) => {
                          formik.setFieldValue('bank_account.bank_account_type_1', e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <label className='form-label fw-bolder required'>
                      {intl.formatMessage({id: 'BANK_ACCOUNT_TYPE'})}
                    </label>
                    <div className='d-flex'>
                      <InputRadioFormik
                        formik={formik}
                        labelClassName={'fs-6'}
                        type='radio'
                        name='bank_account.bank_account_type_2'
                        label={intl.formatMessage({id: 'PERSONAL'})}
                        valueInput='personal'
                        onChange={(e) => {
                          formik.setFieldValue('bank_account.bank_account_type_2', e.target.value)
                        }}
                      />
                      <InputRadioFormik
                        formik={formik}
                        className='ms-8'
                        labelClassName={'fs-6'}
                        type='radio'
                        name='bank_account.bank_account_type_2'
                        label={intl.formatMessage({id: 'BUSINESS'})}
                        valueInput='business'
                        onChange={(e) => {
                          formik.setFieldValue('bank_account.bank_account_type_2', e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {formik.values.payment_type === 'credit_card' && (
              <div>
                <div className='row my-4 my-4'>
                  <div className='col-md-4'>
                    <InputTextFormik
                      isInteger
                      maxlength={16}
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'CREDIT_CARD_NUMBER'})}
                      formik={formik}
                      name={'credit_card.credit_card_number'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <InputTextFormik
                      isInteger
                      maxlength={5}
                      formatDate
                      labelClassName='fw-bolder'
                      required
                      placeholder='MM/YY'
                      label={intl.formatMessage({id: 'EXPIRATION_DATE'})}
                      formik={formik}
                      name={'credit_card.expiration_date'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputTextFormik
                      isInteger
                      maxlength={4}
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'CVV'})}
                      formik={formik}
                      name={'credit_card.cvv'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <InputTextFormik
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'NAME_ON_CREDIT_CARD'})}
                      formik={formik}
                      name={'credit_card.name_on_credit_card'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputTextFormik
                      labelClassName='fw-bolder'
                      required
                      label={intl.formatMessage({id: 'BILLING_ADDRESS'})}
                      formik={formik}
                      name={'credit_card.billing_address'}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='customer-information-element'>
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='fw-bolder fs-2'>3. {intl.formatMessage({id: 'METER_CHARGE_TYPE'})}</h3>
            </div>
            <div className='row my-4'>
              <div className='col-md-4'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='form-label fw-bolder required'>
                    {intl.formatMessage({id: 'AMOUNT_OF_INITIAL_BALANCE'})}
                  </label>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='tooltip-disabled'>
                        {intl.formatMessage({
                          id: 'INITIAL_BALANCE_LOAD_TO_METER',
                        })}
                      </Tooltip>
                    }
                  >
                    <div>
                      <KTSVG path='/media/gori/ach/help.svg' className='svg-icon svg-icon-1' />
                    </div>
                  </OverlayTrigger>
                </div>
                <InputTextFormik
                  className='flex-fill'
                  formik={formik}
                  name='amount_of_initial_balance'
                  type='number'
                  required
                  disabled={formik.isSubmitting}
                  configAppend={{
                    name: '$',
                    position: 'left',
                    classInput: 'ps-8',
                  }}
                  isCurrency={true}
                />
              </div>
              <div className='col-md-4'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='form-label fw-bolder required'>
                    {intl.formatMessage({id: 'MINIMUM_RELOAD_BALANCE'})}
                  </label>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='tooltip-disabled'>
                        {intl.formatMessage({
                          id: 'IF_THE_BALANCE_FALLS_BELOW_THE_SPECIFIED_MINIMUM',
                        })}
                      </Tooltip>
                    }
                  >
                    <div>
                      <KTSVG path='/media/gori/ach/help.svg' className='svg-icon svg-icon-1' />
                    </div>
                  </OverlayTrigger>
                </div>
                <InputTextFormik
                  className='flex-fill'
                  formik={formik}
                  name='minimum_reload_balance'
                  type='number'
                  required
                  disabled={formik.isSubmitting}
                  configAppend={{
                    name: '$',
                    position: 'left',
                    classInput: 'ps-8',
                  }}
                  isCurrency={true}
                />
              </div>
              <div className='col-md-4'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='form-label fw-bolder required'>
                    {intl.formatMessage({id: 'AUTO_RELOAD_AMOUNT'})}
                  </label>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='tooltip-disabled'>
                        {intl.formatMessage({
                          id: 'RELOAD_AMOUNT_WHEN_METER_BALANCE_GO_BELOW_MINIMUM',
                        })}
                      </Tooltip>
                    }
                  >
                    <div>
                      <KTSVG path='/media/gori/ach/help.svg' className='svg-icon svg-icon-1' />
                    </div>
                  </OverlayTrigger>
                </div>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  formik={formik}
                  name='auto_reload_amount'
                  type='number'
                  required
                  disabled={formik.isSubmitting}
                  configAppend={{
                    name: '$',
                    position: 'left',
                    classInput: 'ps-8',
                  }}
                  isCurrency={true}
                />
              </div>
            </div>
          </div>
          <div className='customer-information-element'>
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='fw-bolder fs-2'>4. {intl.formatMessage({id: 'CONFIRMATION'})}</h3>
            </div>
            <div className='fw-bold'>
              <div>{intl.formatMessage({id: 'ONLINE_PAYMENT_ACKNOWLEDGEMENT'})}</div>
              <div className='my-4'>{intl.formatMessage({id: 'CONFIRM_TEXT'})}</div>
              <div>
                {intl.formatMessage({
                  id: 'I_UNDERSTAND_AND_AUTHORIZE_ALL_THE_ABOVE',
                })}
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-md-4'>
                <InputDate
                  labelClassName='fw-bolder'
                  label={intl.formatMessage({id: 'DATE'})}
                  formik={formik}
                  name={'date'}
                  required
                  className='flex-fill'
                />
              </div>
              <div className='col-md-4'>
                <InputTextFormik
                  className='flex-fill'
                  labelClassName='fw-bolder'
                  required
                  label={intl.formatMessage({id: 'NAME'})}
                  formik={formik}
                  name={'name'}
                />
              </div>
            </div>
            <div className='col-md-12 customer-information d-flex flex-column flex-md-row'>
              <div
                className={`col-md-5 signature ${
                  formik?.values?.option_signature === 'drawing' ? 'drawing' : ''
                }`}
              >
                <label className='form-label fw-bolder required'>
                  {intl.formatMessage({id: 'E_SIGNATURE'})}
                </label>
                <div className='option-signature'>
                  <SelectFormik
                    emptyDefault={false}
                    options={OPTIONS_SIGNATURE}
                    name='option_signature'
                    formik={formik}
                    placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                    disabled={formik.isSubmitting}
                    hasUseIntl={true}
                  />
                </div>
                {formik?.values?.option_signature === 'drawing' ? (
                  <>
                    <button
                      className='btn btn-secondary mt-0'
                      onClick={() => sigCanvas.current.clear()}
                    >
                      {intl.formatMessage({id: 'CLEAR'})}
                    </button>
                    <div className={`sigPadContainer ${errorSignature ? 'error' : ''}`}>
                      <SignatureCanvas
                        penColor='black'
                        canvasProps={{className: 'sigCanvas'}}
                        ref={sigCanvas}
                        onBegin={() => {
                          setErrorSignature(false)
                        }}
                      />
                      <span role='alert' style={{color: '#f1416c'}}>
                        {errorSignature ? intl.formatMessage({id: 'E_SIGNATURE_IS_REQUIRED'}) : ''}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='signature-text'>
                      <InputTextFormik
                        className='flex-fill'
                        labelClassName='fw-bolder'
                        required
                        formik={formik}
                        name={'signature'}
                        maxlength={20}
                        disabled={formik.isSubmitting}
                      />
                    </div>
                  </>
                )}
              </div>
              {formik?.values?.option_signature === 'write' && (
                <div className='col-md-5 signature-drawing-custom'>
                  <div className='signature-drawing'>
                    <div className='signature-textarea'>
                      <div className='textarea'>
                        <span>{formik?.values?.signature}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'SUBMIT'})}
              loadingText={intl.formatMessage({id: 'SUBMIT'})}
              loading={formik.isSubmitting || isLoadingForm}
              event={() => {
                checkSignature()
                formik.submitForm()
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ACHModal}
