import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

type Props = {
  show: boolean
  action?: string
  messageCancel?: string
  title: any
  message: any
  confirmMessage?: string
  cancelMessage?: string
  showCancelButton?: boolean
  handleClose: (status?: boolean) => void
  handleCallBack: any
  outsideClick?: boolean
}

const ConfirmActionSwal: React.FC<Props> = ({
  show,
  action,
  title,
  message,
  confirmMessage,
  messageCancel,
  cancelMessage,
  showCancelButton = true,
  handleClose,
  handleCallBack,
  outsideClick = true,
}) => {
  const intl = useIntl()

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      icon: 'border-0 mt-16 mb-5',
      title: 'position-absolute w-100 fw-bolder fs-1 h4 text-start border-bottom mb-0',
      htmlContainer: 'mx-10 mt-0 mb-16 fs-4 fw-bolder',
      confirmButton: 'btn fw-bold btn-primary',
      closeButton: 'btn-close',
      cancelButton: 'btn fw-bold btn-secondary',
    },
    closeButtonHtml: '',
    showCloseButton: true,
    buttonsStyling: false,
    scrollbarPadding: false,
    heightAuto: false,
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    focusDeny: false,
    width: '800px',
  })

  useEffect(() => {
    show &&
      swalWithBootstrapButtons
        .fire({
          allowOutsideClick: outsideClick,
          html: message,
          title: title,
          icon: 'warning',
          iconHtml: `<img src=${toAbsoluteUrl(
            '/media/gori/dashboard/error_balance.svg'
          )} alt='img' />`,
          showCancelButton: showCancelButton,
          confirmButtonText:
            intl.formatMessage({id: 'YES'}) +
            (action ? ' , ' + action?.toLocaleLowerCase() + '!' : ''),
          cancelButtonText: messageCancel ? messageCancel : intl.formatMessage({id: 'NO_CANCEL'}),
        })
        .then((result) => {
          if (result.isConfirmed) {
            handleCallBack()
            if (confirmMessage) {
              swalWithBootstrapButtons.fire({
                text: confirmMessage,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn fw-bold btn-primary',
                },
                confirmButtonText: intl.formatMessage({id: 'OK_GOT_IT'}),
              })
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            if (cancelMessage) {
              swalWithBootstrapButtons.fire({
                text: cancelMessage,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn fw-bold btn-primary',
                },
                confirmButtonText: intl.formatMessage({id: 'OK_GOT_IT'}),
              })
            }
          }
          handleClose()
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return <></>
}

export {ConfirmActionSwal}
