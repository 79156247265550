import {useFormik} from 'formik'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import {useLanguage} from '../../../../../../_gori/i18n/Metronici18n'
import {LanguageConfig} from '../../../../../../_gori/config/LanguageConfig'

type Props = {
  show: boolean
  handleClose: () => void
}

const LanguageModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {language, handleUpdateLanguage} = useLanguage()
  const [validationErrors, setValidationErrors] = useState<any>()
  const languages = useMemo(() => {
    return LanguageConfig().LANGUAGES
  }, [language])

  const formik = useFormik({
    initialValues: {
      language: language,
    },
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setSubmitting(true)
        await handleUpdateLanguage(currentUser?.id, values.language)
      } catch (error: any) {
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleCloseModal = useCallback(() => {
    if (formik.isSubmitting) {
      return
    }
    handleClose()
    formik.resetForm()
  }, [formik.isSubmitting])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_change_language'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'LANGUAGE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='col-12'>
                <span className='form-label text-gray-600'>
                  {intl.formatMessage({id: 'LANGUAGE'})}
                </span>
              </div>
              <div>
                <SelectFormik
                  hasUseIntl
                  emptyDefault={false}
                  options={languages || []}
                  name='language'
                  formik={formik}
                  placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                  disabled={formik.isSubmitting}
                  styleInput={{backgroundColor: '#fff !important', height: '42px'}}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                disabled={formik.isSubmitting}
                event={formik.submitForm}
                loading={formik.isSubmitting}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {LanguageModal}
