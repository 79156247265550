import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import {OrdersConfig} from '../core/_const'
import OrderHelper from '../core/_helpers'
import OrderService from '../core/_requests'
import {ActionAllDoneModal} from '../../common'
import {SET_UP_MODAL_NOTICE} from '../../common/core/_const'

type Props = {
  isSelectAll?: boolean
  show: boolean
  data: any
  handleClose: () => void
}

const ProcessCheapestServiceModal: React.FC<Props> = ({
  isSelectAll = false,
  show,
  data,
  handleClose,
}) => {
  const intl = useIntl()
  const orderHelper = OrderHelper()
  const {newCancelToken, isCancel} = useCancelToken()
  const [getRateSuccess, setGetRateSuccess] = useState<number>(0)
  const [getRateFailed, setGetRateFailed] = useState<number>(0)
  const [percentage, setPercentage] = useState<number>(0)
  const [noticeModal, setNoticeModal] = useState<{show: boolean; arrNotice: Array<object>}>({
    show: false,
    arrNotice: [{data: {}, success: {}, error: {}}],
  })

  const arrGetRate = useMemo(() => {
    const arrNew: any = []
    for (let i = 0; i < data.length; i += OrdersConfig.GET_CHEAPEST_SERVICE) {
      arrNew.push(data.slice(i, i + OrdersConfig.GET_CHEAPEST_SERVICE))
    }
    return arrNew
  }, [data])

  const getCheapestService = useCallback(async () => {
    const results: any = []

    let close = false
    for (let i = 0; i < arrGetRate.length; i++) {
      if (close) break
      try {
        if (isSelectAll) {
          await Promise.all(
            // eslint-disable-next-line no-loop-func
            arrGetRate[i].map((row) => {
              const _result: any = {}
              const data = row.original
              return OrderService.cheapestRate(
                {order_id: data?.id},
                {cancelToken: newCancelToken()}
              )
                .then((res) => {
                  _result.success = res
                  setGetRateSuccess((prev) => prev + 1)
                })
                .catch((err: any) => {
                  if (isCancel(err)) {
                    close = true
                  }
                  _result.error = err.response
                  setGetRateFailed((prev) => prev + 1)
                })
                .finally(() => {
                  if (!isEmpty(_result)) {
                    _result.data = row.original
                    results.push(_result)
                  }
                })
            })
          )
        } else {
          await Promise.all(
            // eslint-disable-next-line no-loop-func
            arrGetRate[i].map((row) => {
              const _result: any = {}
              const data = row.original
              const dataParcels = orderHelper.buildRateParcels(
                `${data.carrier}_${data.service}`,
                data.parcels
              )

              return OrderService.cheapestRate(
                {
                  order_id: data?.id,
                  cheapest: data?.is_cheapest_rate,
                  shipment: {
                    from_address: Object.entries(OrdersConfig.SHIPPING).reduce(
                      (fromData, [key, value]) => {
                        fromData[value.value] = data?.[`from_${value.value}`]
                        return fromData
                      },
                      {}
                    ),
                    to_address: Object.entries(OrdersConfig.SHIPPING).reduce(
                      (toData, [key, value]) => {
                        toData[value.value] = data?.[`to_${value.value}`]
                        return toData
                      },
                      {}
                    ),
                    parcel: dataParcels,
                  },
                },
                {cancelToken: newCancelToken()}
              )
                .then((res) => {
                  _result.success = res
                  setGetRateSuccess((prev) => prev + 1)
                })
                .catch((err: any) => {
                  if (isCancel(err)) {
                    close = true
                  }
                  _result.error = err.response
                  setGetRateFailed((prev) => prev + 1)
                })
                .finally(() => {
                  if (!isEmpty(_result)) {
                    _result.data = row.original
                    results.push(_result)
                  }
                })
            })
          )
        }
      } catch (error: any) {
        if (isCancel(error)) return
      }
    }

    await setNoticeModal({show: true, arrNotice: results})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrGetRate, isCancel, newCancelToken])

  useEffect(() => {
    setPercentage(Math.round(((getRateSuccess + getRateFailed) / data.length) * 100))
  }, [getRateSuccess, getRateFailed, data.length])

  useEffect(() => {
    getCheapestService()
  }, [getCheapestService])

  return (
    <>
      {noticeModal.show && (
        <ActionAllDoneModal
          setUpModal={SET_UP_MODAL_NOTICE.CHEAPEST}
          show={noticeModal.show}
          handleClose={() => {
            setNoticeModal({show: false, arrNotice: []})
            handleClose()
          }}
          arrNotice={noticeModal.arrNotice}
        />
      )}
      {!noticeModal.show && (
        <Modal
          id='gori_create_all_label_modal'
          tabIndex={-1}
          aria-hidden='true'
          centered
          dialogClassName='mw-500px h-auto'
          show={show}
          onHide={handleClose}
          backdrop='static'
        >
          <div className='modal-content'>
            <Modal.Header>
              <Modal.Title bsPrefix={'fw-bolder fs-1'}>
                {intl.formatMessage({id: 'SET_TO_CHEAPEST_SERVICE'})}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {intl.formatMessage(
                  {id: 'SET_THE_CHEAPEST_SERVICE_FOR_SUCCESS_OUT_OF_TOTAL_LABELS'},
                  {success: getRateSuccess, total: data.length}
                )}
              </div>
              <div className='text-danger'>
                {getRateFailed > 0 &&
                  intl.formatMessage(
                    {id: 'FAILED_TO_SET_FOR_FAILED_LABELS'},
                    {failed: getRateFailed}
                  )}
              </div>
              <div className='mt-5'>
                <div className='progress h-30px'>
                  <div
                    role='progressbar'
                    className='progress-bar bg-image-gori'
                    style={{width: `${percentage}%`}}
                    aria-valuenow={percentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {percentage >= 100 ? 100 : Math.ceil(percentage)}%
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={`btn btn-${
                  percentage >= 100 ? 'success text-hover-white' : 'danger'
                } d-flex`}
                label={
                  percentage >= 100
                    ? intl.formatMessage({id: 'DONE'})
                    : intl.formatMessage({id: 'STOP'})
                }
                event={handleClose}
              />
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </>
  )
}

export {ProcessCheapestServiceModal}
