import {reducerCases} from './constants'

export const initialState = {
  isShowCustomColumns: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case reducerCases.IS_SHOW_CUSTOM_COLUMNS:
      return {
        ...state,
        isShowCustomColumns: action.isShowCustomColumns,
      }
    default:
      return state
  }
}

export default reducer
