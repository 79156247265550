import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'
import AuthRequestBase from '../../../api/AuthRequestBase'

const getFilterStatus = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims/filter-status', config)
}

const getClaimsStatistics = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims/statistics', config)
}

const getClaims = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims', config)
}

const getClaimDetail = (id?: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`/claims/detail/${id}`, config)
}

const getClaimHistory = (id?: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`/claims/histories/${id}?page_size=50`, config)
}

const createClaims = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims', payload, config)
}

const editClaims = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put('/claims', payload, config)
}

const uploadFile = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/upload-media', payload, config)
}

const exportCsv = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims/download-csv', config)
}

const syncClaimsStatus = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims/sync-fotform-force', payload, config)
}

const deleteClaims = (id, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`/claims/${id}`, config)
}

const postClaimsAppeal = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims/appeal', payload, config)
}

const checkSignatureClaim = (config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.get('/agreements/check-signature-claim', config)
}

const signatureClaim = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.post('/agreements/signature-claim', payload, config)
}

const uploadUsps = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims/upload', payload, config)
}

const ClaimsService = {
  getFilterStatus,
  getClaimsStatistics,
  getClaims,
  deleteClaims,
  getClaimDetail,
  getClaimHistory,
  createClaims,
  editClaims,
  uploadFile,
  exportCsv,
  syncClaimsStatus,
  postClaimsAppeal,
  checkSignatureClaim,
  signatureClaim,
  uploadUsps,
}

export default ClaimsService
