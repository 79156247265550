import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import React, {useMemo, useState} from 'react'
import moment from 'moment'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {Button, InputDate, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import OrderService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const HoldOrderModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const {dateYup} = UseYupValidate()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const initValidateSchema = useMemo(() => {
    return {
      hold_until_date: dateYup(
        'HOLD_ORDER_DATE',
        moment()?.startOf('day')?.add(1, 'days')?.format() ||
          moment()?.startOf('day')?.add(1, 'days')
      ),
    }
  }, [dateYup])

  const [validateSchema] = useState<any>(initValidateSchema)

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const validationSchema = Yup.object().shape(validateSchema)

  const formik = useFormik({
    initialValues: {
      hold_until_date: moment().add(1, 'days'),
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      let payload = {
        hold_until_date: values.hold_until_date.format(),
        order_ids: orders.map((item: any) => {
          return item.original.id
        }),
      }

      setIsLoadingForm(true)
      try {
        const res = await OrderService.holdOrder(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'HOLD_ORDER_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_hold_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleCloseModal()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <h2>{intl.formatMessage({id: 'HOLD_ORDERS'})}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex align-items-center mb-4'>
              <InputDate
                labelClassName='fs-5 col-4'
                label={intl.formatMessage({id: 'HOLD_ORDER_UNTIL'})}
                formik={formik}
                required
                min={moment().add(1, 'days')}
                name='hold_until_date'
              />
            </div>
            <p className='text-muted'>
              {intl.formatMessage(
                {id: 'HOLD_ORDER_TEXT'},
                {brand: intl.formatMessage({id: 'BRAND_NAME'})}
              )}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {HoldOrderModal}
