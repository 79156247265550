import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'

const Card = ({children, title = '', titleClass = '', learnMoreUrl}) => {
  const intl = useIntl()
  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='bg-light-primary mt-10 rounded-2 p-8'>
        <h2
          className={clsx('fw-boldest text-primary-bold mb-8', titleClass)}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <div>{children}</div>
        <div className='text-start fw-bold mt-4 fs-5'>
          <a href={learnMoreUrl} target={'_blank'} rel='noreferrer'>
            {intl.formatMessage({id: 'LEARN_MORE'})}
          </a>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Card
