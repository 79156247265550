import React from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'

import Card from '../common/Card'

const ManualOrder: React.FC = () => {
  const intl = useIntl()

  return (
    <Card
      title={intl.formatMessage({id: 'EXPLORING_PRICING_OPTIONS_FOR_ESTABLISHING_A_NEW_LABEL'})}
      learnMoreUrl={'/help-center/create-shipment/manual-order'}
    >
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/gori/onboard/label/v2/manual_order.png')}
        className='w-100'
      />
    </Card>
  )
}

export {ManualOrder}
