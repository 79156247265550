import React from 'react'
import {Modal} from 'react-bootstrap'
import {convertCurrency, KTSVG, toAbsoluteUrl} from '../../../../../_gori/helpers'
import moment from 'moment/moment'
import {Button} from '../../../../../_gori/partials/widgets'
import {useIntl} from 'react-intl'

type Props = {
  loadingDone: boolean
  handleCloseDoneModal: () => void
  showDoneModal: boolean
  formik: any
  wireDate: any
}

const FundDoneModal: React.FC<Props> = ({
  showDoneModal,
  handleCloseDoneModal,
  formik,
  loadingDone,
  wireDate,
}) => {
  const intl = useIntl()

  return (
    <Modal
      id='gori_modal_add_fund_done'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={showDoneModal}
      backdrop='static'
      onHide={() => handleCloseDoneModal()}
    >
      <Modal.Header closeButton>
        <Modal.Title bsPrefix={'fw-bolder fs-1'}>
          {intl.formatMessage({id: 'WIRE_TRANSFER'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='vh-75 scroll-y'>
        <div className='text-center'>
          <img
            alt={intl.formatMessage({id: 'WIRE_TRANSFER'})}
            src={toAbsoluteUrl('media/gori/onboard/add-fund/submitted.png')}
            className='h-200px m-1'
          />
        </div>
        <div
          className='text-submitted'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'YOUR_WIRE_TRANSFER_TRANSACTION'}),
          }}
        ></div>
        <div className='add-fund-transaction'>
          <p>
            {intl.formatMessage({id: 'SUBMISSION_DATE'}) + ': '}
            <strong>{moment().format(process.env.REACT_APP_DATE_FORMAT)}</strong>
          </p>
          <p>
            {intl.formatMessage({id: 'WIRE_DATE'}) + ': '}
            <strong>{wireDate}</strong>
          </p>
          <p>
            {intl.formatMessage({id: 'WIRE_AMOUNT'}) + ': '}
            {convertCurrency(formik.values.amount)}
          </p>
        </div>
        <div className='d-flex text-gray-700 fw-bold fs-8'>
          <KTSVG path='/media/gori/account/attention.svg' className='svg-icon-3 me-2' />
          {intl.formatMessage({
            id: 'WIRE_TRANSACTIONS_MAY_TAKE_UP_TO_3_BUSINESS_DAYS',
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <Button
            className='btn btn-sm btn-primary'
            label={intl.formatMessage({id: 'CLOSE'})}
            event={() => handleCloseDoneModal()}
            loading={loadingDone}
            disabled={loadingDone}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default FundDoneModal
