import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {useAuth} from '../../../auth'
import DashboardService from '../../core/_requests'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5locales_zh_Hant from '@amcharts/amcharts5/locales/zh_Hant'
import {useLanguage} from '../../../../../_gori/i18n/Metronici18n'

type Props = {
  loading?: boolean
  onLoading: any
}

const PostageHistory: React.FC<Props> = ({loading, onLoading}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const {loadingSwitch} = useAuth()
  const {language} = useLanguage()
  const [chartComponent, setChartComponent] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [selection, setSelection] = useState('one_year')
  const [postageHistory, setPostageHistory] = useState<any>()

  const getPostageHistory = useCallback(async () => {
    const config = {cancelToken: newCancelToken()}
    try {
      const response = await DashboardService.getPostageHistory(config)
      if (response?.postage_history) {
        setPostageHistory(
          Object.entries(response.postage_history).map(([key, value]: [any, any]) => {
            return {
              x: value.label,
              y: parseFloat(value.value),
            }
          })
        )
      }
    } catch (error: any) {
      if (isCancel(error)) return
      console.error(error)
    } finally {
      onLoading('postageHistory')
    }
  }, [isCancel, newCancelToken, onLoading])

  const updateData = (timeline) => {
    setSelection(timeline)
    switch (timeline) {
      case 'three_months':
        chartComponent.xAxis.zoom(9 / 12, 1)
        return
      case 'six_months':
        chartComponent.xAxis.zoom(6 / 12, 1)
        return
      case 'one_year':
        chartComponent.xAxis.zoom(0, 1)
        return
    }
  }

  useEffect(() => {
    if (loadingSwitch) {
      return
    }
    getPostageHistory()
  }, [getPostageHistory, loadingSwitch])

  useEffect(() => {
    if (loading || !chartRef.current || isEmpty(postageHistory)) {
      return
    }

    let root = am5.Root.new('chart-dashboard-postage-history')
    root.setThemes([am5themes_Animated.new(root)])
    switch (language) {
      case 'zh':
        root.locale = am5locales_zh_Hant
        break
    }
    root?._logo?.dispose()

    let chart = root.container.children.push(am5xy.XYChart.new(root, {}))
    chart.root.dom.style.height = '150px'
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      })
    )

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: false,
        baseInterval: {
          timeUnit: 'month',
          count: 1,
        },
        // startLocation: 0.5,
        // endLocation: 0.5,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    )

    yAxis.set(
      'numberFormatter',
      am5.NumberFormatter.new(root, {
        numberFormat: '#a',
      })
    )

    let series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: intl.formatMessage({id: 'DELIVERIES'}),
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'x',
        valueYField: 'y',
        tooltip: am5.Tooltip.new(root, {
          labelText: '[bold]{name}: {valueY}',
        }),
      })
    )
    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.2,
    })

    series.fills.template.set(
      'fillPattern',
      am5.LinePattern.new(root, {
        color: am5.color('#9bd5cf'),
        fill: am5.color('#9bd5cf'),
      })
    )
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 3,
          stroke: root.interfaceColors.get('background'),
          strokeWidth: 1,
          fill: am5.color('#16baa8'),
        }),
      })
    })
    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['x'],
    })
    series.data.setAll(postageHistory)
    series.appear(1000)
    chart.zoomOutButton.set('forceHidden', true)
    chart.appear(1000, 100)

    setChartComponent({
      xAxis: xAxis,
      yAxis: yAxis,
    })
    return () => {
      root.dispose()
    }
  }, [intl, loadingSwitch, postageHistory, loading])

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='card shadow-sm h-210'>
        <div className='card-body d-flex flex-column'>
          {loading ? (
            <div className='d-flex justify-content-between'>
              <h1 className='col-4 placeholder-wave'>
                <span className='col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </h1>
              <div className='col-8 d-flex flex-row-reverse'>
                <div className='d-flex placeholder-wave'>
                  <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex justify-content-between '>
              <div className='text-dark fw-bolder fs-3 opacity-75'>
                {intl.formatMessage({id: 'SHIPMENT_TRENDS'})}
              </div>
              <div className='card-toolbar mb-0'>
                <div
                  className={clsx('btn btn-sm btn-active-light fw-bolder px-2', {
                    active: selection === 'one_year',
                  })}
                  onClick={() => updateData('one_year')}
                >
                  {intl.formatMessage({id: 'YEAR'})}
                </div>
                <div
                  className={clsx('btn btn-sm btn-active-light fw-bolder px-2', {
                    active: selection === 'six_months',
                  })}
                  onClick={() => updateData('six_months')}
                >
                  {intl.formatMessage({id: '6M'})}
                </div>
                <div
                  className={clsx('btn btn-sm btn-active-light fw-bolder px-2', {
                    active: selection === 'three_months',
                  })}
                  onClick={() => updateData('three_months')}
                >
                  {intl.formatMessage({id: '3M'})}
                </div>
              </div>
            </div>
          )}
          <div className='w-100' ref={chartRef} id='chart-dashboard-postage-history' />
        </div>
      </div>
    </CSSTransition>
  )
}

export {PostageHistory}
