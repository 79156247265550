import _, {isEmpty, startCase} from 'lodash'
import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  show: boolean
  handleClose: () => void
  data: any
}

const UploadDone: React.FC<Props> = ({show, handleClose, data}) => {
  const intl = useIntl()
  return (
    <Modal
      id='gori_adjustment_upload_modal'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'UPLOAD_USPS_CLAIM'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <h4>
            <span className='text-danger'>
              {intl.formatMessage({id: 'ERRORS'})}: {Object.values(data?.errors).length ?? 0}
            </span>
          </h4>
          <h4>
            <span className='text-primary'>
              {intl.formatMessage({id: 'TOTAL'})}: {data.total}
            </span>
          </h4>
          <hr />
          <div className='timeline-label'>
            {data?.errors &&
              Object.values(data.errors).map((value: any, index) => {
                let line = Object.keys(data.errors)[index]
                return (
                  <div className='timeline-item' key={index}>
                    <div className='timeline-label fw-bolder text-gray-800 fs-8'>
                      {' '}
                      {intl.formatMessage({id: 'ROW'})} {line}
                    </div>
                    <div className='timeline-badge'>
                      <i
                        className='fa fa-genderless dashboard-theme-color-green fs-1 dashboard__activity__color-gray'
                        aria-hidden='true'
                      ></i>
                    </div>
                    <div className='timeline-content d-flex'>
                      <span className='fw-bolder text-gray-800 ps-3'>
                        <span className='fs-5 me-3 dashboard__activity__color-gray'>
                          {Object.values(value).map((item: any, key) => {
                            let errorArray = item[0].split('|')
                            let errorString = !isEmpty(errorArray[2])
                              ? intl.formatMessage(
                                  {id: errorArray[1]},
                                  {
                                    input: startCase(errorArray[0]),
                                    max: errorArray[2],
                                  }
                                )
                              : intl.formatMessage(
                                  {id: errorArray[1]},
                                  {
                                    input: startCase(errorArray[0]),
                                  }
                                )
                            return <p className='text-danger'>{errorString}</p>
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                )
              })}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {UploadDone}
