import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function ShopifyIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='shopify-integration'
        heading='Easy and Affordable Shipping Service'
        headingSubtext='We offer discounted shipping rates of up to 89% for Shopify&nbsp;sellers.'
        integrationLogo='/media/gori/integrations/shopify/shopify-logo.svg'
        integrationLogoAlt='shopify-logo'
        integrateImg='/media/gori/integrations/shopify/integrate.webp'
        card1Copy='ShipBae offers Shopify sellers the most competitive shipping rates available. Integrate seamlessly with your Shopify stores to consolidate orders and create shipping labels with ease. This streamlined solution simplifies the order fulfillment process, boosting efficiency for your business.'
        card2Copy='ShipBae provides a list of rates from top-tier carriers for your packages, allowing you to compare options at a glance. Choose the rate that best fits your needs!'
        card3Copy='Our dashboard analytics help you uncover patterns, trends, and relationships that might not be apparent from a single data source, leading to better analysis and decision-making.'
        card4Copy='ShipBae offers a variety of features to help you manage your orders effortlessly. Filter and batch orders, export shipment reports, file claims for lost or damaged packages, and more!'
      />
    </>
  )
}
