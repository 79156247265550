import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import {SettingsConfig} from '../../../core/_const'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import SettingsService from '../../../core/_requests'
import {toast} from 'react-toastify'
import {InputCheckboxFormik} from '../../../../../../_gori/partials/widgets/form/InputCheckboxFormik'

type Props = {
  show: boolean
  handleClose: any
  data: any
}

const LabelsModal: React.FC<Props> = ({show, handleClose, data}) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()

  const formik = useFormik({
    initialValues: {
      label_type: data?.label_type ?? null,
      packing_slip_size: data?.packing_slip_size ?? null,
      include_slips: data?.include_slips ?? false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        const config = {
          cancelToken: newCancelToken(),
        }
        const payload = {
          label_type: values?.label_type,
          packing_slip_size: values?.packing_slip_size,
          include_slips: values?.include_slips,
        }
        const {message} = await SettingsService.updatePrint(payload, config)
        if (message) {
          toast.success(intl.formatMessage({id: message}))
          handleCloseModal()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const checkboxRadio = (label, name) => {
    return (
      <>
        <div className='col-form-label fs-5 text-truncate me-4'>
          {intl.formatMessage({id: label})}
        </div>
        <div className='d-flex align-items-center mt-2'>
          <div>
            <InputCheckboxFormik
              formik={formik}
              type='radio'
              label={intl.formatMessage({id: 'YES'})}
              className={'pe-4'}
              checked={formik.values[name]}
              onClick={() => formik.setFieldValue(name, true)}
            />
          </div>
          <div>
            <InputCheckboxFormik
              formik={formik}
              type='radio'
              label={intl.formatMessage({id: 'NO'})}
              checked={!formik.values[name]}
              onClick={() => formik.setFieldValue(name, false)}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <Modal
      id='gori_modal_settings_labels'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-650px h-auto'
      show={show}
      backdrop='static'
      onHide={handleCloseModal}
    >
      <div className='modal-content'>
        {validationErrors && (
          <ValidationErrorModal
            handleClose={() => {
              setValidationErrors(undefined)
            }}
            response={validationErrors}
          />
        )}
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'LABELS_AND_PACKING_SLIPS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className='fw-bold fs-5'>
              {intl.formatMessage({id: 'LABELS_AND_PACKING_SLIPS'})}
            </div>
            <div className='py-4'>
              {intl.formatMessage({
                id: 'LABEL_TYPE_CONTENT',
              })}
            </div>
            <div className='row mb-8'>
              <SelectFormik
                label={intl.formatMessage({id: 'TYPE'})}
                className='col-8'
                labelClassName='col-4 col-form-label fs-5'
                emptyDefault={false}
                options={SettingsConfig.OPTIONS_LABEL_TYPE}
                name='label_type'
                formik={formik}
              />
            </div>
            <div className='row mb-8'>
              <SelectFormik
                label={intl.formatMessage({id: 'PACKING_SLIP_SIZE'})}
                className='col-8'
                labelClassName='col-4 col-form-label fs-5'
                emptyDefault={false}
                options={SettingsConfig.OPTIONS_LABEL_SIZE}
                name='packing_slip_size'
                formik={formik}
                checkFormik={false}
              />
            </div>
            <div className='d-flex'>
              {checkboxRadio('INCLUDE_PACKING_SLIPS_WHEN_PRINTING_LABELS', 'include_slips')}
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              loadingText={intl.formatMessage({id: 'CANCEL'})}
              event={handleCloseModal}
            />
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'SAVE'})}
              loadingText={intl.formatMessage({id: 'SAVE'})}
              event={formik.submitForm}
              loading={isLoading}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {LabelsModal}
