import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, find, forEach, includes, isEmpty, map, omit} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputEmailFormik,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {AutocompleteAddress} from '../../../../common'
import {
  ConfigGeneral,
  OPTIONS_METHODS,
  OPTIONS_SERVICES,
  PROFILE_EDIT_FIELD,
} from '../../../core/_const'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {toast} from 'react-toastify'
import SettingsService from '../../../core/_requests'
import UserService from '../../../../users/core/_requests'
import {getCompany} from '../../../../onboard/core/_helper'
import {useAuth} from '../../../../auth'
import {StorageHelpers} from '../../../../../../_gori/helpers'
import {InputCheckboxFormik} from '../../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import {OPTION_COUNTRIES} from '../../../../../../_gori/constants'

type Props = {
  show: boolean
  handleClose: () => void
  company: any
}

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const CURRENT_COMPANY_STORAGE: any =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'

const CompanyEditModal: React.FC<Props> = ({show, handleClose, company}) => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const {setCurrentUser, setCurrentCompany} = useAuth()
  const {stringYup, infoYup, regexYup, arrayRequiredYup} = UseYupValidate()
  const {isCancel, newCancelToken} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [isShipFromForUpsFedex, setIsShipFromForUpsFedex] = useState<boolean>(false)

  const OPTIONS_SERVICES_CUSTOM = useMemo(() => {
    return [...OPTIONS_SERVICES, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])
  const OPTIONS_METHODS_CUSTOM = useMemo(() => {
    return [...OPTIONS_METHODS, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])
  const [options, setOptions] = useState<{services: Array<any>; methods: Array<any>}>({
    services: OPTIONS_SERVICES_CUSTOM,
    methods: OPTIONS_METHODS_CUSTOM,
  })

  const validateSchema = useMemo(() => {
    const required = routes.ADMINS.hasPermission
    return {
      company_name: stringYup(100, 'COMPANY_NAME', required),
      phone: infoYup.phone('PHONE', required),
      monthly_shipment_volume: stringYup(255, 'MONTHLY_SHIPMENT_VOLUME', required),
      sales_rep: stringYup(50, 'SALE_REP', false).matches(/^[\d\w\s]+$/, {
        message: intl.formatMessage(
          {id: 'SPECIAL_CHARACTER_ARE_GENERATE_NOT_ALLOW'},
          {input: intl.formatMessage({id: 'SALE_REP'})}
        ),
        excludeEmptyString: true,
      }),
      country: stringYup(255, 'COUNTRY', required),
      street1: stringYup(255, 'ADDRESS', required),
      street2: stringYup(255, 'ADDRESS_2', false),
      city: stringYup(255, 'CITY', required),
      zip: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', required),
        otherwise: stringYup(255, 'POSTAL_CODE', required),
      }),
      state: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', required),
        otherwise: stringYup(255, 'STATE', required),
      }),
      ship_from_country: stringYup(255, 'COUNTRY', required),
      ship_from_street1: stringYup(255, 'ADDRESS', required),
      ship_from_street2: stringYup(255, 'ADDRESS_2', false),
      ship_from_city: stringYup(255, 'CITY', required),
      ship_from_zip: Yup.string().when(`ship_from_country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', required),
        otherwise: stringYup(255, 'POSTAL_CODE', required),
      }),
      ship_from_state: Yup.string().when(`ship_from_country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', required),
        otherwise: stringYup(255, 'STATE', required),
      }),
      ups_fedex_country: stringYup(255, 'COUNTRY', isShipFromForUpsFedex),
      ups_fedex_street1: stringYup(255, 'ADDRESS', isShipFromForUpsFedex),
      ups_fedex_street2: stringYup(255, 'ADDRESS_2', false),
      ups_fedex_city: stringYup(255, 'CITY', isShipFromForUpsFedex),
      ups_fedex_zip: Yup.string().when(`ups_fedex_country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', required),
        otherwise: stringYup(255, 'POSTAL_CODE', isShipFromForUpsFedex),
      }),
      ups_fedex_state: Yup.string().when(`ups_fedex_country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', isShipFromForUpsFedex),
        otherwise: stringYup(255, 'STATE', isShipFromForUpsFedex),
      }),
      report_name: regexYup.inputText('NAME', isShipFromForUpsFedex, 100),
      report_emails: Yup.lazy(() => {
        return required
          ? Yup.array()
              .min(
                1,
                intl.formatMessage(
                  {id: 'INPUT_IS_REQUIRED'},
                  {input: intl.formatMessage({id: 'EMAIL'})}
                )
              )
              .max(
                ConfigGeneral.MAX_EMAIL_NUMBER,
                intl.formatMessage(
                  {id: 'ONLY_UP_TO_INPUT_EMAIL_ADDRESSES_ARE_ALLOWED'},
                  {input: ConfigGeneral.MAX_EMAIL_NUMBER}
                )
              )
          : Yup.array()
      }),
      report_email: Yup.lazy(() => {
        return required
          ? infoYup.email('EMAIL', false).test({
              name: 'unique',
              test: function (value) {
                const emails = this.parent.report_emails || []
                return !emails?.map((item) => item?.toLowerCase()).includes(value?.toLowerCase())
              },
              message: intl.formatMessage(
                {id: 'INPUT_ALREADY_EXISTS'},
                {input: intl.formatMessage({id: 'EMAIL'})}
              ),
            })
          : infoYup.email('EMAIL', false)
      }),
      accounting_name: regexYup.inputText('NAME', required, 100),
      accounting_emails: Yup.lazy(() => {
        return required
          ? Yup.array()
              .min(
                1,
                intl.formatMessage(
                  {id: 'INPUT_IS_REQUIRED'},
                  {input: intl.formatMessage({id: 'EMAIL'})}
                )
              )
              .max(
                ConfigGeneral.MAX_EMAIL_NUMBER,
                intl.formatMessage(
                  {id: 'ONLY_UP_TO_INPUT_EMAIL_ADDRESSES_ARE_ALLOWED'},
                  {input: ConfigGeneral.MAX_EMAIL_NUMBER}
                )
              )
          : Yup.array()
      }),
      accounting_email: Yup.lazy(() => {
        return required
          ? infoYup.email('EMAIL', false).test({
              name: 'unique',
              test: function (value) {
                const emails = this.parent.accounting_emails || []
                return !emails?.map((item) => item?.toLowerCase()).includes(value?.toLowerCase())
              },
              message: intl.formatMessage(
                {id: 'INPUT_ALREADY_EXISTS'},
                {input: intl.formatMessage({id: 'EMAIL'})}
              ),
            })
          : infoYup.email('EMAIL', false)
      }),
      interested_services: arrayRequiredYup('THE_SERVICES_SELECTED', required ? 1 : 0, 'ARE'),
      using_methods: arrayRequiredYup('THE_METHODS_SELECTED', required ? 1 : 0, 'ARE'),
    }
  }, [routes, stringYup, infoYup, regexYup, arrayRequiredYup, intl, isShipFromForUpsFedex])

  const initialValues = useMemo(() => {
    let init: any = {}
    forEach(
      [
        ...PROFILE_EDIT_FIELD.ABOUT_YOUR,
        ...PROFILE_EDIT_FIELD.BUSINESS_ADDRESS,
        ...PROFILE_EDIT_FIELD.PRIMARY_CONTACT,
      ],
      (field: any) => {
        if (!isEmpty(company)) {
          switch (field.name) {
            case 'company_name':
              init[field?.name] = company?.name ?? ''
              break
            case 'report_email':
              init[field?.name] = ''
              init[field?.nameParent] = company?.report?.emails ?? []
              break
            case 'report_name':
              init[field?.name] = company?.report?.name ?? ''
              break
            case 'accounting_email':
              init[field?.name] = ''
              init[field?.nameParent] = company?.accounting?.emails ?? []
              break
            case 'accounting_name':
              init[field?.name] = company?.accounting?.name ?? ''
              break
            default:
              init[field.name] = company?.[`${field.name}`]
              break
          }
        }
      }
    )
    init['interested_services'] =
      OPTIONS_SERVICES_CUSTOM.filter((option) =>
        company?.interested_services?.includes(option.value)
      ) ?? []
    init['using_methods'] =
      OPTIONS_METHODS_CUSTOM.filter((option) => company?.using_methods?.includes(option.value)) ??
      []

    init['ship_from_country'] = company.ship_from_address_default?.country ?? ''
    init['ship_from_street1'] = company.ship_from_address_default?.address ?? ''
    init['ship_from_street2'] = company.ship_from_address_default?.address2 ?? ''
    init['ship_from_city'] = company.ship_from_address_default?.city ?? ''
    init['ship_from_zip'] = company.ship_from_address_default?.zip_code ?? ''
    init['ship_from_state'] = company.ship_from_address_default?.state ?? ''

    init['ups_fedex_country'] = company.ship_from_address_ups_fedex?.country ?? ''
    init['ups_fedex_street1'] = company.ship_from_address_ups_fedex?.address ?? ''
    init['ups_fedex_street2'] = company.ship_from_address_ups_fedex?.address2 ?? ''
    init['ups_fedex_city'] = company.ship_from_address_ups_fedex?.city ?? ''
    init['ups_fedex_zip'] = company.ship_from_address_ups_fedex?.zip_code ?? ''
    init['ups_fedex_state'] = company.ship_from_address_ups_fedex?.state ?? ''

    const arr = company.interested_services
    const checkEditsUps = arr && arr.includes('ups')
    const checkEditsFedex = arr && arr.includes('fedex')

    if (checkEditsFedex || checkEditsUps) {
      setIsShipFromForUpsFedex(true)
    } else {
      setIsShipFromForUpsFedex(false)
    }

    return init
  }, [OPTIONS_METHODS_CUSTOM, OPTIONS_SERVICES_CUSTOM, company])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validateSchema),
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setSubmitting(true)
        const payload = {
          ...omit(values, [
            'report_name',
            'report_email',
            'report_emails',
            'accounting_name',
            'accounting_email',
            'accounting_emails',
          ]),
          report: {
            name: values.report_name,
            emails: values.report_emails,
          },
          accounting: {
            name: values.accounting_name,
            emails: values.accounting_emails,
          },
          interested_services: map(cloneDeep(values.interested_services), 'value'),
          using_methods: map(cloneDeep(values.using_methods), 'value'),
          type: 'company',
          ups_fedex_city: isShipFromForUpsFedex ? values.ups_fedex_city : '',
          ups_fedex_country: isShipFromForUpsFedex ? values.ups_fedex_country : '',
          ups_fedex_state: isShipFromForUpsFedex ? values.ups_fedex_state : '',
          ups_fedex_street1: isShipFromForUpsFedex ? values.ups_fedex_street1 : '',
          ups_fedex_street2: isShipFromForUpsFedex ? values.ups_fedex_street2 : '',
          ups_fedex_zip: isShipFromForUpsFedex ? values.ups_fedex_zip : '',
        }
        const config = {cancelToken: newCancelToken()}
        await SettingsService.accountEdit(payload, config)
        const userAuth = await UserService.getUser(config)
        const newCompany = await getCompany(userAuth.companies)
        if (newCompany) {
          StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE, newCompany)
          StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY, newCompany.encrypt_key)
          setCurrentCompany(newCompany)
        }
        setCurrentUser(userAuth)
        toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    const interested_services = formik.values.interested_services
    let checkEditsUps = false
    let checkEditsFedex = false
    interested_services.map((item) => {
      if (item.value === 'ups') {
        checkEditsUps = true
      }

      if (item.value === 'fedex') {
        checkEditsFedex = true
      }
    })

    if (checkEditsFedex || checkEditsUps) {
      setIsShipFromForUpsFedex(true)
    } else if (!checkEditsFedex && !checkEditsUps) {
      setIsShipFromForUpsFedex(false)
    }
  }, [formik.values.interested_services, isShipFromForUpsFedex])

  useEffect(() => {
    const updateOptions = (sourceOptions, formikValues, targetField) => {
      let modifiedOptions = cloneDeep(sourceOptions)

      if (map(cloneDeep(formikValues), 'value').includes('not_sure')) {
        modifiedOptions = sourceOptions.map((option) => ({
          ...option,
          isDisabled: option?.value !== 'not_sure',
        }))
      } else if (!isEmpty(formikValues)) {
        modifiedOptions = sourceOptions.map((option) => ({
          ...option,
          isDisabled: option?.value === 'not_sure',
        }))
      }

      setOptions((prev) => ({...prev, [targetField]: modifiedOptions}))
    }
    updateOptions(OPTIONS_SERVICES_CUSTOM, formik.values?.interested_services, 'services')
    updateOptions(OPTIONS_METHODS_CUSTOM, formik.values?.using_methods, 'methods')
  }, [
    OPTIONS_METHODS_CUSTOM,
    OPTIONS_SERVICES_CUSTOM,
    formik.values?.interested_services,
    formik.values?.using_methods,
  ])

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const selectedPlace = (selected) => {
    let city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(formik.getFieldProps(`city`).name, city?.long_name ?? '')
    let state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(formik.getFieldProps(`state`).name, state?.short_name ?? '')
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`country`).name, country?.short_name ?? '')
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('zip', zipCode)
  }

  const selectedPlaceShipFromDefault = (selected) => {
    let ups_fedex_city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ship_from_city`).name,
      ups_fedex_city?.long_name ?? ''
    )
    let ship_from_state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ship_from_state`).name,
      ship_from_state?.short_name ?? ''
    )
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`ship_from_country`).name, country?.short_name ?? '')
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('ship_from_zip', zipCode)
  }
  const selectedPlaceUpsFedex = (selected) => {
    let ups_fedex_city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_city`).name,
      ups_fedex_city?.long_name ?? ''
    )
    let ups_fedex_state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_state`).name,
      ups_fedex_state?.short_name ?? ''
    )
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`ups_fedex_country`).name, country?.short_name ?? '')
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('ups_fedex_zip', zipCode)
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_accounting_setting'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'COMPANY_PROFILE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <div className='row'>
              <div className='col-md-4' id='onboard'>
                <div className='text-start'>
                  <p className='fs-3 fw-bolder text-primary'>
                    {intl.formatMessage({id: 'ABOUT_YOUR_BUSINESS'})}
                  </p>
                  <div className='row'>
                    {PROFILE_EDIT_FIELD.ABOUT_YOUR.map((field, index) => {
                      if (field.type === 'text') {
                        return (
                          <div className='my-2 col-12' key={index}>
                            <InputTextFormik
                              className='col-12'
                              formik={formik}
                              name={field.name}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: field.label})}
                              labelClassName={clsx('fw-bolder', {required: field.required})}
                            />
                          </div>
                        )
                      }
                      if (field.type === 'select') {
                        return (
                          <div className='my-2 col-12' key={index}>
                            <SelectFormik
                              checkFormik
                              emptyDefault={false}
                              options={field?.options || []}
                              formik={formik}
                              name={field.name}
                              placeholder={intl.formatMessage({id: field.placeholder})}
                              required={field.required}
                              isSearchable={false}
                              hasUseIntl={true}
                              label={intl.formatMessage({id: field.label})}
                              labelClassName={clsx('fw-bolder', {required: field.required})}
                            />
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
                <div className='text-start mt-6'>
                  <p className='fs-3 fw-bolder text-primary'>
                    {intl.formatMessage({id: 'BUSINESS_ADDRESS'})}
                  </p>
                  <div className='row'>
                    {PROFILE_EDIT_FIELD.BUSINESS_ADDRESS.map((field, index) => {
                      if (field.type === 'text') {
                        return (
                          <div className={`my-2 ${field?.col}`} key={index}>
                            <InputTextFormik
                              className='col-12'
                              formik={formik}
                              name={field.name}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: field.label})}
                              labelClassName={clsx('fw-bolder', {required: field.required})}
                            />
                          </div>
                        )
                      }
                      if (field.type === 'select') {
                        return (
                          <div className={clsx('my-2')} key={index}>
                            <SelectFormik
                              checkFormik
                              placeholder={''}
                              options={field?.options || []}
                              formik={formik}
                              name={field.name}
                              required={field.required}
                              label={intl.formatMessage({id: field.label})}
                              labelClassName={clsx('fw-bolder', {required: field.required})}
                            />
                          </div>
                        )
                      }
                      if (field.type === 'address') {
                        return (
                          <div className={`my-2 ${field?.col} address-complete`} key={index}>
                            <label
                              className={`form-label fw-bolder ${field.required ? 'required' : ''}`}
                            >
                              {intl.formatMessage({id: field.label})}
                            </label>
                            <AutocompleteAddress
                              formik={formik}
                              name={field.name}
                              label=''
                              onSelected={(selected) => {
                                selectedPlace(selected)
                              }}
                            />
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='text-start'>
                  <p className='fs-3 fw-bolder text-primary'>
                    {intl.formatMessage({id: 'PRIMARY_CONTACT_EMAILS'})}
                  </p>
                  <div className='row'>
                    {PROFILE_EDIT_FIELD.PRIMARY_CONTACT.map((field, index) => {
                      let renderHeader
                      switch (index) {
                        case 0:
                          renderHeader = (
                            <p className='fs-5 fw-bolder'>
                              {intl.formatMessage({id: 'DATA_REPORT'})}
                            </p>
                          )
                          break
                        case 2:
                          renderHeader = (
                            <p className='fs-5 fw-bolder'>
                              {intl.formatMessage({id: 'ACCOUNTING'})}
                            </p>
                          )
                          break
                        default:
                          renderHeader = null
                          break
                      }
                      if (field.type === 'text') {
                        return (
                          <div className={`my-2 ${field?.col}`} key={index}>
                            {renderHeader}
                            <InputTextFormik
                              className='col-12'
                              formik={formik}
                              name={field.name}
                              disabled={formik.isSubmitting}
                              label={intl.formatMessage({id: field.label})}
                              labelClassName={clsx('fw-bolder', {required: field.required})}
                            />
                          </div>
                        )
                      }
                      if (field.type === 'email') {
                        return (
                          <div className={`my-2 ${field?.col}`} key={index}>
                            {renderHeader}
                            <InputEmailFormik
                              label={intl.formatMessage({id: field.label})}
                              labelClassName={'fw-bolder'}
                              required={field.required}
                              formik={formik}
                              name={field.name}
                              nameParent={field.nameParent}
                              maxEmailNumber={ConfigGeneral.MAX_EMAIL_NUMBER}
                              configAppend={{
                                name: intl.formatMessage({id: 'ADD'}),
                                position: 'right',
                                classInput: 'pe-25',
                                hasEvent: true,
                              }}
                            />
                          </div>
                        )
                      }
                      return renderHeader
                    })}
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='text-start'>
                  <p className='fs-3 fw-bolder text-primary'>
                    {intl.formatMessage({id: 'SHIPPING_PREFERENCE'})}
                  </p>
                  <div className='row'>
                    <div className={clsx('my-2')}>
                      <SelectFormik
                        placeholder={intl.formatMessage({id: 'SELECT'})}
                        label={intl.formatMessage({
                          id: 'WHICH_METHOD_ARE_YOU_USING_TO_CREATE_YOUR_LABELS',
                        })}
                        labelClassName='fw-bolder'
                        options={options.methods}
                        formik={formik}
                        name={'using_methods'}
                        emptyDefault={false}
                        isMulti={true}
                        required
                      />
                    </div>
                    <div className={clsx('my-2')}>
                      <SelectFormik
                        placeholder={intl.formatMessage({id: 'SELECT'})}
                        label={intl.formatMessage({
                          id: 'WHAT_SHIPPING_SERVICES_ARE_YOU_INTERESTED_IN',
                        })}
                        labelClassName='fw-bolder'
                        options={options.services}
                        formik={formik}
                        name={'interested_services'}
                        emptyDefault={false}
                        isMulti={true}
                        required
                      />
                    </div>
                    <div className={clsx('my-2')}>
                      <div className='row bg-light rounded-2 p-4'>
                        <div className={clsx('my-2')}>
                          <div className='form-label fw-bolder flex-column'>
                            <span className='fw-bold fs-2'>
                              {intl.formatMessage({id: 'DEFAULT'})}{' '}
                            </span>
                            <span>{intl.formatMessage({id: 'SHIPPING_FROM_ADDRESS_DEFAULT'})}</span>
                          </div>
                        </div>
                        <div className={`my-2 col-12`}>
                          <label className='form-label fw-bolder required'>
                            {intl.formatMessage({id: 'COUNTRY'})}
                          </label>
                          <SelectFormik
                            checkFormik
                            placeholder={''}
                            options={OPTION_COUNTRIES}
                            formik={formik}
                            name={'ship_from_country'}
                          />
                        </div>
                        <div className={`my-2 col-12 address-complete`}>
                          <label className={'form-label fw-bolder required'}>
                            {intl.formatMessage({id: 'ADDRESS'})}
                          </label>
                          <AutocompleteAddress
                            formik={formik}
                            name={'ship_from_street1'}
                            label=''
                            className={'col-12'}
                            onSelected={(selected) => {
                              selectedPlaceShipFromDefault(selected)
                            }}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ship_from_street2'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'ADDRESS_2'})}
                            labelClassName={'fw-bolder'}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ship_from_city'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'CITY'})}
                            labelClassName={'fw-bolder required'}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ship_from_zip'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'POSTAL_CODE'})}
                            labelClassName={'fw-bolder required'}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ship_from_state'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'STATE'})}
                            labelClassName={'fw-bolder required'}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsx('my-2')}
                      style={{display: !isShipFromForUpsFedex ? 'none' : ''}}
                    >
                      <div className='row bg-light rounded-2 p-4'>
                        <div className={clsx('my-2')}>
                          <div className='d-flex justify-content-between'>
                            <label className='form-label fw-bolder'>
                              <span className='fw-bold fs-2'>
                                {' '}
                                {intl.formatMessage({id: 'SHIPPING_FROM_ADDRESS_UPS_FEDEX'})}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className={`my-2 col-12`}>
                          <label className='form-label fw-bolder required'>
                            {intl.formatMessage({id: 'COUNTRY'})}
                          </label>
                          <SelectFormik
                            checkFormik
                            placeholder={''}
                            options={OPTION_COUNTRIES}
                            formik={formik}
                            name={'ups_fedex_country'}
                          />
                        </div>
                        <div className={`my-2 col-12 address-complete`}>
                          <label className={'form-label fw-bolder required'}>
                            {intl.formatMessage({id: 'ADDRESS'})}
                          </label>
                          <AutocompleteAddress
                            formik={formik}
                            name={'ups_fedex_street1'}
                            label=''
                            className={'col-12'}
                            onSelected={(selected) => {
                              selectedPlaceUpsFedex(selected)
                            }}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ups_fedex_street2'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'ADDRESS_2'})}
                            labelClassName={'fw-bolder'}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ups_fedex_city'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'CITY'})}
                            labelClassName={'fw-bolder required'}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ups_fedex_zip'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'POSTAL_CODE'})}
                            labelClassName={'fw-bolder required'}
                          />
                        </div>
                        <div className={`my-2 col-6`}>
                          <InputTextFormik
                            formik={formik}
                            name={'ups_fedex_state'}
                            disabled={formik.isSubmitting}
                            label={intl.formatMessage({id: 'STATE'})}
                            labelClassName={'fw-bolder required'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                disabled={formik.isSubmitting}
                event={formik.submitForm}
                loading={formik.isSubmitting}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CompanyEditModal}
