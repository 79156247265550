import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {StorageHelpers} from '../../../_gori/helpers'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import useCancelToken from '../../../_gori/hooks/UseCancelToken'
import AuthService from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'

const baseDomain = process.env.REACT_APP_DOMAIN
const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const PERMISSIONS_STORAGE_KEY =
  process.env.REACT_APP_PERMISSIONS_STORAGE_KEY || 'PERMISSIONS_STORAGE_KEY'
const CURRENT_COMPANY_STORAGE =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'

export function RetoolLogin() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const {newCancelToken, isCancel} = useCancelToken()
  const {saveAuth, setCurrentUser, setCurrentCompany} = useAuth()
  const [loading, setLoading] = useState(false)

  const getCurrentCompanyBySlug = (companies: any, slug: string) => {
    return companies.find((item) => {
      return item.slug === slug
    })
  }

  const handleSaveCache = useCallback(async () => {
    try {
      setLoading(true)
      const config = {cancelToken: newCancelToken()}
      const payload = {token: token}
      const host = document.location.host?.split(':')?.[0]
      const domain = baseDomain?.split(':')?.[0]
      const subDomain = host.split(`.${domain}`).filter((item) => item !== domain)?.[0]

      const retoolLogin = await AuthService.retoolLogin(payload, config)
      if (retoolLogin) {
        saveAuth(retoolLogin?.token)
        const currentCompany = getCurrentCompanyBySlug(retoolLogin?.users?.companies, subDomain)
        await AuthService.saveCache(currentCompany?.encrypt_key, config)
        StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY, currentCompany?.encrypt_key)
        if (currentCompany) {
          StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE, currentCompany)
        }
        StorageHelpers.setItemLocalStorage(PERMISSIONS_STORAGE_KEY, retoolLogin?.users?.permissions)
        setCurrentUser(retoolLogin?.users)
        setCurrentCompany(currentCompany)
        navigate('/dashboard')
      }
    } catch (e) {
      if (isCancel(e)) return
      navigate('/logout')
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (token) {
      handleSaveCache()
    } else {
      navigate('/logout')
    }
  }, [handleSaveCache, navigate, token])

  return (
    <>
      <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
        <>{loading && <LoadingPage />}</>
      </CSSTransition>
    </>
  )
}
