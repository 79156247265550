import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

const MaintenancePage: FC = () => {
  const intl = useIntl()
  return (
    <div className='d-flex flex-fill justify-content-center'>
      <div className='w-70 d-flex flex-column align-items-center justify-content-center text-center'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/gori/errors/maintenance.png')}
          className='w-50 mb-5'
        />
        <h1 className='fw-bolder fs-4x mb-10'>
          {intl.formatMessage({id: 'WE_WILL_BE_RIGHT_BACK'})}
        </h1>
        <div className='fw-bold fs-3 text-gray-800'>
          {intl.formatMessage({
            id: 'WE_ARE_CURRENTLY_UPDATING_OUR_SYSTEM_TO_PROVIDE_YOU_WITH_A_SEAMLESS_EXPERIENCE',
          })}
          <br />
          {intl.formatMessage({id: 'WE_CANNOT_WAIT_FOR_YOU_TO_SEE_IT_PLEASE_CHECK_BACK_SOON'})}
        </div>
      </div>
    </div>
  )
}

export {MaintenancePage}
