import React from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'

import Card from '../common/Card'

const Batches: React.FC = () => {
  const intl = useIntl()

  return (
    <Card
      title={intl.formatMessage({id: 'UPLOAD_ORDERS_USING_CSV_FILE'})}
      learnMoreUrl={'/help-center/create-shipment/batch-order'}
    >
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/gori/onboard/label/v2/batches.png')}
        className='w-100'
      />
    </Card>
  )
}

export {Batches}
