import clsx from 'clsx'
import {cloneDeep, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {RateSkeleton} from '../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {Rates} from '../../../orders'
import {OrdersConfig} from '../../core/_const'
import OrderService from '../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  disabled?: boolean
  data: any
  handleEditRateLabelSuccess: () => void
  isUpdate?: any
}

const RatesModal: React.FC<Props> = ({
  show,
  handleClose,
  disabled,
  data,
  handleEditRateLabelSuccess,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState<{getRate: boolean; save: boolean}>({
    getRate: false,
    save: false,
  })
  const [dataRates, setDataRates] = useState<any>()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [chooseRate, setChooseRate] = useState<any>()

  const handleGetRates = useCallback(async () => {
    setLoading((prev) => ({...prev, getRate: true}))

    try {
      const {rates} = await OrderService.rates(
        {
          shipment: {
            international_option: data?.international_option,
            service_options: {
              confirmation: data?.signature_confirmation,
              insured_amount: Number(data?.insurance_option?.insured_amount),
            },
            from_address: Object.entries(OrdersConfig.SHIPPING).reduce((fromData, [key, value]) => {
              fromData[value.value] = data?.from?.[`from_${value.value}`]
              return fromData
            }, {}),
            to_address: Object.entries(OrdersConfig.SHIPPING).reduce((toData, [key, value]) => {
              toData[value.value] = data?.to?.[`to_${value.value}`]
              return toData
            }, {}),
            parcel: data?.parcels,
          },
        },
        {cancelToken: newCancelToken()}
      )

      let _chooseRate: any = {}
      if (data.service) {
        _chooseRate = cloneDeep(rates).find((item) => {
          return item?.matching_package && item?.carrier_service === data.service
        })

        if (!_chooseRate) {
          _chooseRate = cloneDeep(rates).find((item) => {
            return item?.carrier_service === data.service
          })
        }
      }

      setDataRates({
        chooseRate: _chooseRate,
        options: rates,
      })
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, getRate: false}))
    }
  }, [
    data?.from,
    data?.insurance_option?.insured_amount,
    data?.parcels,
    data.service,
    data?.signature_confirmation,
    data?.to,
    isCancel,
    newCancelToken,
  ])

  useEffect(() => {
    if (isEmpty(data)) return
    handleGetRates()
  }, [handleGetRates, data])

  const handleEditRate = useCallback(async () => {
    setLoading((prev) => ({...prev, save: true}))
    try {
      const res = await OrderService.patch(
        {
          type: 'service',
          order_ids: [data.id],
          fields: {
            service: chooseRate?.carrier_service,
            package_type: chooseRate?.package_type?.value ?? null,
            estimated_rate: chooseRate?.rate ?? null,
            is_cheapest_rate: chooseRate?.cheapest ?? false,
            preset_id:
              chooseRate?.carrier_service === data.service &&
              chooseRate?.package_type?.value === data.parcels?.package_type
                ? data.preset_id
                : null,
          },
        },
        {cancelToken: newCancelToken()}
      )
      if (res) {
        handleEditRateLabelSuccess()
        handleClose()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, save: false}))
    }
  }, [
    chooseRate,
    data.id,
    data.parcels?.package_type,
    data.preset_id,
    data.service,
    handleClose,
    handleEditRateLabelSuccess,
    isCancel,
    newCancelToken,
  ])

  const handleClickRate = (rate) => {
    setDataRates((prev) => ({
      ...prev,
      chooseRate: rate,
    }))
    setChooseRate(rate)
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_edit_rate_label'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div
          className={clsx('modal-content', {
            'cursor-no-drop': disabled,
          })}
        >
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'RATES'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container
              className={clsx({
                'pe-none': disabled,
              })}
            >
              {loading.getRate ? (
                <RateSkeleton />
              ) : (
                <Rates
                  data={dataRates}
                  handleClickRate={handleClickRate}
                  disabled={loading.getRate || loading.save || disabled}
                />
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                event={handleEditRate}
                disabled={loading.getRate || loading.save || isEmpty(chooseRate)}
                loading={loading.save}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {RatesModal}
