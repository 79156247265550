import React from 'react'
import {useIntl} from 'react-intl'
import {InputSearch} from '../../../../_gori/partials/widgets'

const BatchesFilter = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column flex-fill'>
      <div className='card-title d-flex justify-content-between'>
        <div className='d-flex align-items-center position-relative'>
          <InputSearch placeholder={intl.formatMessage({id: 'SEARCH_BATCH'})} />
        </div>
      </div>
    </div>
  )
}

export {BatchesFilter}
