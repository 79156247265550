type Props = {
  children?: any
  className?: string
  classNameTooltip?: string
  textTooltip?: string
  handleEvent?: any
}
const TooltipModal: React.FC<Props> = ({
  children,
  textTooltip,
  className,
  classNameTooltip,
  handleEvent,
}) => {
  return children && textTooltip ? (
    <div className={`tooltip-modal ${className}`}>
      {children}
      <div className={`tooltip-modal__text ${classNameTooltip}`} onClick={handleEvent}>
        {textTooltip}
      </div>
    </div>
  ) : null
}

export {TooltipModal}
