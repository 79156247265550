import {AxiosRequestConfig} from 'axios'
import AuthRequestBase from '../../../api/AuthRequestBase'

const updateLanguage = (userId, payload, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.put(`/users/profile/general/${userId}`, payload, config)
}
const CommonService = {
  updateLanguage,
}

export default CommonService
