import {OPTION_COUNTRIES} from '../../../../_gori/constants'

export const STEPS = {
  welcome: 0,
  profile: 1,
  shipping: 2,
  ob_general: 3,
  add_fund: 4,
  label_creation: 5,
  hazmat: 6,
}

export const OPTIONS_SIGNATURE = [
  {
    label: 'SIGNATURE_WRITE',
    value: 'write',
  },
  {
    label: 'SIGNATURE_DRAWING',
    value: 'drawing',
  },
]

export const OPTIONS_SERVICES = [
  {
    label: 'USPS',
    value: 'usps',
  },
  {
    label: 'UPS',
    value: 'ups',
  },
  {
    label: 'FedEx',
    value: 'fedex',
  },
  {
    label: 'DHL',
    value: 'dhl',
  },
]

export const OPTIONS_METHODS = [
  {
    label: 'ShipBae',
    value: 'shipbae',
  },
  {
    label: 'Gori API',
    value: 'gori_api',
  },
]

export const PROFILE_FIELD = {
  LEFT: [
    {
      name: 'company_name',
      label: 'COMPANY_NAME',
      type: 'text',
      required: false,
      disabled: true,
    },
    {
      name: 'phone',
      label: 'PHONE',
      type: 'text',
      required: true,
    },
    {
      name: 'monthly_shipment_volume',
      label: 'MONTHLY_SHIPMENT_VOLUME',
      type: 'select',
      required: true,
      options: [
        {label: 'I_SHIP_OCCASIONALLY_UNDER_10_PACKAGES_PER_MONTH', value: 'under_10'},
        {label: '11_100_PACKAGES_PER_MONTH', value: '11_100'},
        {label: '101_500_PACKAGES_PER_MONTH', value: '101_500'},
        {label: '501_1000_PACKAGES_PER_MONTH', value: '501_1000'},
        {label: '1001_5000_PACKAGES_PER_MONTH', value: '1001_5000'},
        {label: 'MORE_THAN_5000_PACKAGES_PER_MONTH', value: 'more_5000'},
      ],
      placeholder: 'HOW_CAN_HELP',
    },
    {
      name: 'sale_rep',
      label: 'GORI_SALE_REP',
      type: 'text',
      required: false,
      placeholder: 'GORI_SALE_REP',
    },
    {
      name: 'promo_code',
      label: 'PROMO_CODE_LABEL',
      type: 'text',
      required: false,
      placeholder: 'PROMO_CODE_LABEL',
    },
  ],
  CENTER: [
    {
      name: 'country',
      label: 'COUNTRY',
      type: 'select',
      required: true,
      options: OPTION_COUNTRIES,
    },
    {
      name: 'street1',
      label: 'ADDRESS',
      type: 'address',
      required: true,
      col: 'col-12',
    },
    {
      name: 'street2',
      label: 'ADDRESS_2',
      type: 'address',
      required: false,
      col: 'col-6',
    },
    {
      name: 'city',
      label: 'CITY',
      type: 'text',
      required: true,
      col: 'col-6',
    },
    {
      name: 'zip',
      label: 'POSTAL_CODE',
      type: 'text',
      required: true,
      col: 'col-6',
    },
    {
      name: 'state',
      label: 'STATE',
      type: 'text',
      required: true,
      col: 'col-6',
    },
  ],
  RIGHT: {
    report: [
      {
        name: 'report_name',
        label: 'NAME',
        type: 'text',
        required: true,
        col: 'col-12',
      },
      {
        name: 'report_email',
        nameParent: 'report_emails',
        label: 'EMAILS',
        type: 'email',
        required: true,
        col: 'col-12',
      },
    ],
    accounting: [
      {
        name: 'accounting_name',
        label: 'NAME',
        type: 'text',
        required: true,
        col: 'col-12',
      },
      {
        name: 'accounting_email',
        nameParent: 'accounting_emails',
        label: 'EMAILS',
        type: 'email',
        required: true,
        col: 'col-12',
      },
    ],
  },
}
