import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {Button, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import OrderService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}
const RemoveHoldOrderModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      order_ids: '',
    },
    onSubmit: async () => {
      const newItems = orders.map((item: any) => {
        return item.original.id
      })
      let payload = {
        order_ids: newItems,
      }
      setIsLoadingForm(true)
      try {
        const res = await OrderService.removeHold(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'REMOVED_HOLD_ORDER_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_remove_hold_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto mw-800px'
        show={show}
        backdrop='static'
        onHide={() => {
          handleCloseModal()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <div>{intl.formatMessage({id: 'REMOVE_HOLD'})}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center p-0'>
            <img
              className='d-block mx-auto mt-9 mb-5'
              src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')}
              alt='img'
            />
            <p className='fs-4'>
              <b>
                {orders.length +
                  ' ' +
                  (orders.length === 1
                    ? intl.formatMessage({id: 'ORDER'})
                    : intl.formatMessage({id: 'ORDERS'}))}
              </b>{' '}
              <span className='fs-4'>
                {intl.formatMessage({id: 'REMOVE_HOLD_ORDER_TEXT'})}
                {` `}
                {intl.formatMessage({id: 'REMOVE_HOLD_ORDER_ADDITIONAL'})}
              </span>
            </p>
            <p className='fs-4'>
              {intl.formatMessage(
                {id: 'HOLD_ORDER_TEXT'},
                {brand: intl.formatMessage({id: 'BRAND_NAME'})}
              )}
            </p>
            <p className='mb-16 fs-4'>{intl.formatMessage({id: 'HOLD_ORDER_TEXT_CONTINUE'})}</p>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'REMOVE_HOLD'})}
                loadingText={intl.formatMessage({id: 'REMOVE_HOLD'})}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {RemoveHoldOrderModal}
