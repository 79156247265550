import {useFormik} from 'formik'
import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {Button} from '../../../../_gori/partials/widgets'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  confirm: () => void
}

const CheapestServiceModal: React.FC<Props> = ({show, handleClose, orders, confirm}) => {
  const intl = useIntl()

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      confirm()
      handleCloseModal()
    },
  })

  return (
    <>
      <Modal
        id='gori_modal_cheapest_service'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'SET_TO_CHEAPEST_SERVICE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mx-10 my-5 d-flex flex-column justify-content-center align-items-center fs-4'>
              <div className='mb-5'>
                <img src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')} alt='img' />
              </div>
              <p className='text-center'>
                {intl.formatMessage(
                  {
                    id: 'THIS_ACTION_WILL_CHANGE_THE_SERVICE_FOR_THE_SELECTED_ORDERS',
                  },
                  {selected: orders.length}
                )}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'CONFIRM'})}
                loadingText={intl.formatMessage({id: 'CONFIRM'})}
                event={formik.submitForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CheapestServiceModal}
