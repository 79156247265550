const FilterOrderSkeleton: React.FC = () => {
  return (
    <div className='card card-body px-8'>
      <div className='my-5 d-flex justify-content-between'>
        <div className='col-5 placeholder-wave'>
          <span className='btn col-5 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
        </div>
        <div className='col-5 d-flex align-items-center flex-row-reverse'>
          <div className='col-4 placeholder-wave'>
            <span className=' btn col-11 placeholder placeholder-lg rounded-2 bg-secondary ' />
          </div>
          <div className='col-4 placeholder-wave'>
            <span className=' btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {FilterOrderSkeleton}
