import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {OverlayTrigger, Popover} from 'react-bootstrap'

type Props = {
  className?: any
  callBack?: any
  label?: any
  disabled?: boolean
  duration?: number
}

const ButtonConfirmProgress: FC<Props> = ({
  className,
  callBack,
  label,
  disabled = false,
  duration = 1000,
}) => {
  const intl = useIntl()

  const success = (button: any) => {
    button.classList.add('success')
    setTimeout(
      (button) => {
        button.style.removeProperty('--duration')
        button.classList.remove('success')
      },
      4000,
      button
    )
    if (callBack) {
      callBack()
    }
  }

  const stopProgress = (button: any) => {
    button.classList.remove('process')
    clearTimeout(button.timeout)
  }

  const startProgress = (button: any) => {
    if (!button.classList.contains('success') && !button.classList.contains('process')) {
      button.style.setProperty('--duration', duration + 'ms')
      button.classList.add('process')
      button.timeout = setTimeout(success, duration, button)
    }
  }

  const popover = (
    <Popover>
      <Popover.Header as='h3'>
        {intl.formatMessage({id: 'CLICK_AND_HOLD_TO_CONFIRM'})}
      </Popover.Header>
      <Popover.Body>
        {intl.formatMessage({id: 'THIS_IS_A_CLICK_AND_HOLD_BUTTON_FOR_EASIER_CONFIRMATION'})}
        <div className='justify-content-center d-flex mt-2'>
          <img className='popover-img' src={'/media/gori/batches/popover.png'} alt='img' />
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <OverlayTrigger trigger={['hover', 'focus']} placement='auto' overlay={popover}>
        <button
          className={`${className} button-hold ${disabled && 'disabled'}`}
          onMouseDown={(event) => startProgress(event.target)}
          onTouchStart={(event) => startProgress(event.target)}
          onMouseUp={(event) => stopProgress(event.target)}
          onMouseLeave={(event) => stopProgress(event.target)}
          onTouchEnd={(event) => stopProgress(event.target)}
          onTouchMove={(event) => stopProgress(event.target)}
        >
          <div>
            <svg className='confirm-progress' viewBox='0 0 32 32'>
              <circle r='8' cx='16' cy='16' />
            </svg>
            <svg className='tick' viewBox='0 0 24 24'>
              <polyline points='18,7 11,16 6,12' />
            </svg>
          </div>
          {label}
        </button>
      </OverlayTrigger>
    </>
  )
}

export {ButtonConfirmProgress}
