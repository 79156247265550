import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const URL = '/batches'

const get = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}`, config)
}

const search = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL, config)
}

const getBatchOrders = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/detail/${id}`, config)
}

const download = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/download/${id}`, config)
}

const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}`, payload, config)
}

const upload = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/upload`, payload, config)
}

const print = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/print`, payload, config)
}

const createLabel = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`/shipments/batch-order`, payload, config)
}

const update = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}`, payload, config)
}

const removeOrders = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/remove-orders`, payload, config)
}

const archiveBatch = (id: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/archive/${id}`, payload, config)
}

const removeBatch = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`${URL}/${id}`, config)
}

const getMappingTemplate = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/mapping`, config)
}

const createMappingTemplate = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/mapping`, payload, config)
}

const editMappingTemplate = (id: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/mapping/${id}`, payload, config)
}

const deleteMappingTemplate = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`${URL}/mapping/${id}`, config)
}

const setDefaultTemplate = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/mapping/default`, payload, config)
}

const BatchService = {
  get,
  search,
  getBatchOrders,
  download,
  store,
  upload,
  print,
  createLabel,
  update,
  removeOrders,
  archiveBatch,
  removeBatch,
  getMappingTemplate,
  createMappingTemplate,
  editMappingTemplate,
  deleteMappingTemplate,
  setDefaultTemplate,
}

export default BatchService
