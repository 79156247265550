import clsx from 'clsx'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {
  DEFAULT_DATE_FILTER,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  TABLE_KEY_COLUMN,
} from '../../../../_gori/constants'
import {
  convertCurrency,
  convertUserTimeZone,
  KTSVG,
  setLoadingActionTable,
  useDisabled,
} from '../../../../_gori/helpers'
import {StatisticSkeleton, TableSkeleton} from '../../../../_gori/helpers/components'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableTabs,
  TableWrapper,
} from '../../../../_gori/partials/widgets'
import {useAuth} from '../../auth'
import {AppealClaimsModal, DetailClaimsModal, EditClaimsModal, ClaimsFilter} from '../../claims'
import {CLAIMS_STATUS, ClaimsConfig} from '../core/_const'
import ClaimsService from '../core/_requests'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {toast} from 'react-toastify'

const ClaimsPage: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {routes} = UseRoutesPermission()
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch, currentUser, currentCompany} = useAuth()
  const {setPageDisabled} = useDisabled()
  const [searchParams] = useSearchParams()
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [statisticData, setStatisticData] = useState<any>([])
  const [claimId, setClaimId] = useState<number>()
  const [deleteClaimId, setDeleteClaimId] = useState<number>()
  const initFilterValue = useMemo(
    () => ({
      start: moment().subtract(DEFAULT_DATE_FILTER.CLAIMS, 'days').startOf('day').format(),
      end: moment().endOf('day').format(),
    }),
    []
  )
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)

  const [showModal, setShowModal] = useState<any>({
    confirm: false,
    form_edit: false,
    form_detail: false,
    form_appeal: false,
  })

  const setTable = useCallback(async () => {
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text'),
        sort_by: searchParams.get('sort_by') || null,
        sort_direction: searchParams.get('sort_direction') || null,
        date_from: searchParams.get('date_from') || initFilterValue.start,
        date_to: searchParams.get('date_to') || initFilterValue.end,
        status: searchParams.get('status') || null,
      },
      cancelToken: newCancelToken(),
    }
    setPageDisabled(true)

    try {
      const res = await ClaimsService.getClaims(config)
      setTableData(res.claims)
      setPagination(res.pagination)
      setStatisticData(res.statistics)
    } catch (error) {
      if (isCancel(error)) return
      console.error(error)
    } finally {
      setPageDisabled(false)
      setLoadingFirst(false)
    }
  }, [initFilterValue, isCancel, newCancelToken, searchParams, setPageDisabled])

  const handleDetailClaim = (id) => {
    setShowModal((prev) => ({...prev, form_detail: true}))
    setClaimId(id)
  }

  const handleAppealClaim = (id) => {
    setShowModal((prev) => ({...prev, form_appeal: true}))
    setClaimId(id)
  }

  const handleDeleteClaim = async () => {
    if (deleteClaimId) {
      setTableData(setLoadingActionTable(tableData, [deleteClaimId], true))
      try {
        const response = await ClaimsService.deleteClaims(deleteClaimId, {
          cancelToken: newCancelToken(),
        })
        if (response) {
          setTable()
          toast.success(intl.formatMessage({id: 'DELETED_SUCCESSFULLY'}))
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setTableData(setLoadingActionTable(tableData, [deleteClaimId], false))
      }
    }
  }
  const handleViewOrder = useCallback(
    (orderId: any) => {
      navigate('/orders/' + orderId)
    },
    [navigate]
  )

  const columns = useMemo(
    () => [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'REQUEST_DATE'}),
        accessor: 'created_at',
        headerClassName: 'min-w-50px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        id: 'order_code',
        Header: 'BAE ID',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return row.original.order_code ? (
            <div
              className={clsx(
                'text-gray-800 mb-1 d-inline',
                routes.ORDERS_UPDATE.hasPermission ? 'cursor-pointer text-hover-primary' : ''
              )}
              onClick={() =>
                routes.ORDERS_UPDATE.hasPermission ? handleViewOrder(row.original.order_id) : false
              }
            >
              {`${currentCompany?.sin_code}-${row.original?.order_code}`}
            </div>
          ) : (
            ''
          )
        },
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        accessor: 'tracking_number',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            <div
              className={clsx(
                '',
                routes.CLAIMS_UPDATE.hasPermission || routes.CLAIMS_VIEW.hasPermission
                  ? 'text-hover-primary cursor-pointer '
                  : ''
              )}
              onClick={() =>
                routes.CLAIMS_UPDATE.hasPermission || routes.CLAIMS_VIEW.hasPermission
                  ? handleDetailClaim(row.original?.id)
                  : false
              }
            >
              {row.original?.tracking_number}
            </div>
          )
        },
      },
      {
        id: 'status',
        Header: intl.formatMessage({id: 'STATUS'}),
        accessor: 'status',
        headerClassName: 'w-180px text-center',
        cellClassName: 'text-dark text-center',
        Cell: ({row}: {row: any}) => {
          return (
            <div
              className={`claims-status-badge-${row.original?.status?.replaceAll(
                '_',
                '-'
              )} px-4 py-2 rounded-pill`}
            >
              {intl.formatMessage({id: row.original?.status?.toUpperCase()})}
            </div>
          )
        },
      },
      {
        id: 'items',
        Header: intl.formatMessage({id: 'NUMBER_OF_ITEM'}),
        accessor: 'items',
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            <div
              className={clsx(
                'd-flex justify-content-center',
                routes.CLAIMS_UPDATE.hasPermission || routes.CLAIMS_VIEW.hasPermission
                  ? 'cursor-pointer text-hover-primary'
                  : ''
              )}
              onClick={() =>
                routes.CLAIMS_UPDATE.hasPermission || routes.CLAIMS_VIEW.hasPermission
                  ? handleDetailClaim(row.original?.id)
                  : false
              }
            >
              {row?.original?.items?.length}
            </div>
          )
        },
      },
      {
        id: 'claim_total',
        Header: intl.formatMessage({id: 'CLAIM_TOTAL'}),
        accessor: 'claim_total',
        headerClassName: 'min-w-150px  text-start',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          let totalClaim = 0
          row.original?.items?.forEach((item) => {
            totalClaim += item.claim_item_price * item?.claim_item_qty
          })
          return <div className='d-flex justify-content-start'>{convertCurrency(totalClaim)}</div>
        },
      },
      {
        id: 'claim_reason',
        Header: intl.formatMessage({id: 'CLAIM_REASON'}),
        accessor: 'claim_reason',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            <span
              className={`claims-status-badge-reason-${row.original?.claim_reason} px-3 py-1 rounded-2 `}
            >
              {intl.formatMessage({
                id: row.original?.claim_reason?.toUpperCase(),
              })}
            </span>
          )
        },
      },
      {
        id: 'is_batch_upload',
        Header: intl.formatMessage({id: 'USPS_BULK'}),
        accessor: 'is_batch_upload',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            <span
              className={`claims-status-badge-reason-${row?.original?.is_batch_upload} px-3 py-1 rounded-2 `}
            >
              {row?.original?.is_batch_upload ? intl.formatMessage({id: 'YES'}) : ''}
            </span>
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        headerClassName: 'fixed-column',
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => {
          const isUnsubmitted = row.original?.status === CLAIMS_STATUS.UNSUBMITTED
          const isDenied = row.original?.status === CLAIMS_STATUS.DENIED
          const isUSPS = row.original?.carrier === ClaimsConfig.CARRIER

          let list: any = [
            {
              label: intl.formatMessage({id: 'EDIT'}),
              action: () => {
                if (isUnsubmitted) {
                  setClaimId(row.original.id)
                  setShowModal((prev) => ({...prev, form_edit: true}))
                }
              },
              className: `${isUnsubmitted ? 'cursor-pointer' : 'cursor-no-drop'}`,
              hidden: !routes.CLAIMS_UPDATE.hasPermission,
            },
            {
              label: intl.formatMessage({id: 'DELETE'}),
              action: () => {
                if (isUnsubmitted) {
                  setDeleteClaimId(row.original.id)
                  setShowModal((prev) => ({...prev, confirm: true}))
                }
              },
              className: `${isUnsubmitted ? 'cursor-pointer' : 'cursor-no-drop'}`,
              hidden: !routes.CLAIMS_DELETE.hasPermission,
            },
            {
              label: intl.formatMessage({id: 'APPEAL'}),
              action: () => {
                if (isDenied && isUSPS) {
                  handleAppealClaim(row.original?.claim_id)
                }
              },
              className: `${isDenied && isUSPS ? 'cursor-pointer' : 'cursor-no-drop'}`,
              hidden: !routes.CLAIMS_APPEAL.hasPermission,
            },
          ]

          return (
            <DropdownButton
              loading={row.original.isLoading}
              className={clsx({'pe-none': row.original.isLoading})}
              list={list}
            />
          )
        },
      },
    ],
    [intl, currentUser, routes, currentCompany?.sin_code, handleViewOrder]
  )

  useEffect(() => {
    if (loadingSwitch) return
    setTable()
    return () => {}
  }, [searchParams, loadingSwitch, setTable])
  const handleExportCSV = async () => {
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text'),
        date_from: searchParams.get('date_from') || initFilterValue.start,
        date_to: searchParams.get('date_to') || initFilterValue.end,
        status: searchParams.get('status') || '',
      },
      cancelToken: newCancelToken(),
    }

    setIsLoadingExport(true)
    try {
      const response = await ClaimsService.exportCsv(config)
      const blob = new Blob([response.content], {type: response.type})
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = response.file_name
      link.click()
    } catch (error) {
      console.error(error)
      if (isCancel(error)) return
    } finally {
      setIsLoadingExport(false)
    }
  }

  return (
    <>
      {showModal.confirm && (
        <ConfirmActionSwal
          show={showModal.confirm}
          title={intl.formatMessage({id: 'DELETE_CLAIM'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDeleteClaim}
          handleClose={() => setShowModal((prev) => ({...prev, confirm: false}))}
        />
      )}
      {showModal.form_detail && (
        <DetailClaimsModal
          show={showModal.form_detail}
          handleClose={() => {
            setShowModal((prev) => ({...prev, form_detail: false}))
            setClaimId(undefined)
          }}
          claimId={claimId}
        />
      )}
      {showModal.form_edit && (
        <EditClaimsModal
          show={showModal.form_edit}
          handleClose={() => {
            setShowModal((prev) => ({...prev, form_edit: false}))
            setClaimId(undefined)
          }}
          claimId={claimId}
          reloadTable={() => {
            setTable()
          }}
        />
      )}
      {showModal.form_appeal && (
        <AppealClaimsModal
          show={showModal.form_appeal}
          handleClose={() => {
            setShowModal((prev) => ({...prev, form_appeal: false}))
          }}
          claimId={claimId}
          reloadTable={() => {
            setTable()
          }}
        />
      )}
      {loadingFirst ? (
        <>
          <StatisticSkeleton length={7} />
          <TableWrapper>
            <TableBody>
              <div className='my-5 d-flex justify-content-between'>
                <div className='col-7 placeholder-wave'>
                  <span className='btn col-4 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  <span className='btn col-4 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                </div>
                <div className='col-4 d-flex align-items-center flex-row-reverse'>
                  <div className='col-4 placeholder-wave'>
                    <span className=' btn col-11 placeholder placeholder-lg rounded-2 bg-secondary ' />
                  </div>
                  <div className='col-4 placeholder-wave'>
                    <span className=' btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
              </div>
              <TableSkeleton />
            </TableBody>
          </TableWrapper>
        </>
      ) : (
        <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
          <TableTabs
            dataTabs={statisticData}
            keyCheckActive='status'
            classNameTabs='claims-tabs'
            children={
              <TableWrapper className='rounded-top-left-0-claims'>
                <ClaimsFilter
                  reloadTable={() => {
                    setTable()
                  }}
                />
                <TableBody>
                  <Table
                    columns={columns}
                    data={tableData}
                    pagination={pagination}
                    tbodyClass='text-gray-600 fw-bold'
                    usePagination
                  />
                  <div className='pt-4 d-flex justify-content-between'>
                    <Link to='policy' className='fw-bolder'>
                      {intl.formatMessage({id: 'VIEW_CLAIMS_POLICY'})}
                    </Link>
                    <div
                      className={'btn btn-light fw-bolder align-content-center'}
                      onClick={() => handleExportCSV()}
                    >
                      <KTSVG path='/media/gori/account/download.svg' className='svg-icon-2' />
                      {intl.formatMessage({id: 'EXPORT_CLAIM_LIST'})}
                      {isLoadingExport && (
                        <div className='ms-2 spinner-border spinner-border-sm' role='status' />
                      )}
                    </div>
                  </div>
                </TableBody>
              </TableWrapper>
            }
          ></TableTabs>
        </CSSTransition>
      )}
    </>
  )
}

export {ClaimsPage}
