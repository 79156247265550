import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE} from '../../../../_gori/constants'
import {
  convertUserTimeZone,
  isFeatureEnabled,
  KTSVG,
  setLoadingActionTable,
  useDisabled,
} from '../../../../_gori/helpers'
import {TableSkeleton} from '../../../../_gori/helpers/components'
import {useBatchesProvider} from '../../../../_gori/helpers/components/BatchesProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  Table,
  TableBody,
  TableHeader,
  TableWrapper,
  TableTabs,
} from '../../../../_gori/partials/widgets'
import {useAuth} from '../../auth'
import BatchService from '../core/_requests'
import {BatchesFilter} from './BatchesFilter'
import {BatchesUpload} from './BatchesUpload'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {PageTitle} from '../../../../_gori/layout/core'
import {HelpCenterWidget} from '../../common/components/HelpCenterWidget'
import {HELP_CENTER_WIDGET_PARAMS} from '../../orders/core/_const'
import {FEATURES} from '../../../../_gori/constants/_features'

const BatchesPage: React.FC = () => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {setBatch} = useBatchesProvider()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [statistic, setStatistic] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const {loadingSwitch, currentUser} = useAuth()
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const {routes} = UseRoutesPermission()
  const handleFileDetail = useCallback(
    (row: number, id: number) => {
      if (!tableData) return
      navigate(`detail/${id}?status=open`)
      setBatch(tableData[row])
    },
    [tableData, navigate, setBatch]
  )

  const popover = (
    <Popover onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)}>
      <Popover.Body className='fw-bolder text-muted'>
        <a href='/help-center/create-shipment/batch-order' rel='noreferrer' target='_blank'>
          {intl.formatMessage({id: 'HOW_TO_CREATE_BATCHES_LABEL'})}
        </a>
      </Popover.Body>
    </Popover>
  )

  const handleDownloadFile = useCallback(
    async (batch: any) => {
      try {
        setTableData(setLoadingActionTable(tableData, [batch.id], true))
        const response = await BatchService.download(batch.id, {
          cancelToken: newCancelToken(),
        })
        if (response) {
          const blob = new Blob([response.content], {type: response.type})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = response.file_name
          link.click()
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setTableData(setLoadingActionTable(tableData, [batch.id], false))
      }
    },
    [isCancel, newCancelToken, tableData]
  )

  const columns = useMemo(
    () => [
      {
        id: 'bullet-header',
        Header: intl.formatMessage({id: 'DOWNLOAD'}),
        headerClassName: 'w-125px text-center',
        Cell: ({row}: {row: any}) => (
          <div className='d-flex justify-content-center flex-shrink-0'>
            <div
              title={intl.formatMessage({id: 'FILE_DETAIL'})}
              role={'link'}
              className='btn btn-icon btn-active-color-primary btn-sm me-1 cursor-pointer'
              onClick={() => handleDownloadFile(row.original)}
            >
              {row.original.isLoading ? (
                <div className='spinner-border spinner-border-sm text-primary' role='status' />
              ) : (
                <KTSVG path='/media/gori/batches/file_download_2.svg' className='svg-icon-1' />
              )}
            </div>
          </div>
        ),
      },
      {
        id: 'name',
        Header: intl.formatMessage({id: 'NAME'}),
        accessor: 'name',
        headerClassName: 'min-w-150px',
        cellClassName: 'fw-bolder fs-6 border-0',
        Cell: ({row}: {row: any}) => (
          <div
            role={'link'}
            onClick={() => handleFileDetail(row.index, row.original.id)}
            className='text-hover-primary text-dark cursor-pointer'
          >
            {row.original.name}
          </div>
        ),
      },
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'UPLOAD_DATE'}),
        accessor: 'created_at',
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) =>
          row.original.created_at && (
            <>
              <span className='fw-bolder me-1'>
                {convertUserTimeZone(
                  row.original.created_at,
                  currentUser,
                  process.env.REACT_APP_DATE_FORMAT
                )}
              </span>{' '}
              <span className='text-gray-600'>
                {convertUserTimeZone(
                  row.original.created_at,
                  currentUser,
                  process.env.REACT_APP_TIME_FORMAT
                )}
              </span>
            </>
          ),
      },
      {
        id: 'actions-header',
        Header: intl.formatMessage({id: 'DETAIL'}),
        headerClassName: 'w-150px text-center fixed-column',
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => (
          <div className='d-flex justify-content-center flex-shrink-0'>
            <div
              title={intl.formatMessage({id: 'FILE_DETAIL'})}
              role={'link'}
              onClick={() => handleFileDetail(row.index, row.original.id)}
              className='btn btn-bg-light btn-active-color-primary text-hover-primary btn-sm cursor-pointer text-gray-600'
            >
              <span className='me-3'>{intl.formatMessage({id: 'VIEW'})}</span>
              <KTSVG path='/media/gori/arrows/arrow_right.svg' className='svg-icon-3 m-0' />
            </div>
          </div>
        ),
      },
    ],
    [currentUser, handleDownloadFile, handleFileDetail, intl]
  )

  const setTable = useCallback(async () => {
    setPageDisabled(true)
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text') || null,
        sort_by: searchParams.get('sort_by') || null,
        sort_direction: searchParams.get('sort_direction') || null,
        status: searchParams.get('status') || null,
      },
      cancelToken: newCancelToken(),
    }
    try {
      const response = await BatchService.search(config)
      setTableData(response.batches)
      setPagination(response.pagination)
      setStatistic(response.statistics)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
      setLoadingFirst(false)
    }
  }, [isCancel, newCancelToken, searchParams, setPageDisabled])

  useEffect(() => {
    if (loadingSwitch) return

    setTable()
  }, [loadingSwitch, setTable])

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'BATCHES'})}</PageTitle>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <TableWrapper>
            {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
              <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.BATCHES} />
            )}
            <TableBody>
              <div className='my-5'>
                <div className='w-100 h-150px mb-5 placeholder placeholder-lg rounded-2 bg-secondary ' />
                <div className='mb-5 d-flex flex-fill justify-content-between'>
                  <div className='col-3 placeholder-wave'>
                    <span className='btn col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                  <div className='col-3 placeholder-wave d-flex flex-row-reverse'>
                    <span className='btn col-9 placeholder placeholder-lg rounded-2 bg-secondary ' />
                  </div>
                </div>
              </div>
              <TableSkeleton countColumn={3} />
            </TableBody>
          </TableWrapper>
        ) : (
          <>
            {routes.BATCHES_CREATE.hasPermission && (
              <BatchesUpload reloadTable={() => setTable()} />
            )}
            <TableTabs
              dataTabs={statistic}
              keyCheckActive='status'
              children={
                <TableWrapper className='rounded-top-left-0-dt'>
                  <TableHeader className='min-h-1px my-5 mb-8 d-flex flex-column'>
                    <BatchesFilter />
                  </TableHeader>
                  <TableBody>
                    <Table
                      columns={columns}
                      data={tableData}
                      pagination={pagination}
                      usePagination
                    />
                  </TableBody>
                  {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
                    <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.BATCHES} />
                  )}
                </TableWrapper>
              }
            />
          </>
        )}
      </CSSTransition>
    </>
  )
}

export {BatchesPage}
