import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useCallback, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {OPTION_DIMENSION_UNIT, OPTION_WEIGHT_UNIT, OrdersConfig} from '../core/_const'
import OrderService from '../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  disabled?: boolean
  data: {
    id: number
    preset_id: number
    parcels: [
      {
        length: string
        width: string
        weight: string
        height: string
        dimension_unit: string
        weight_unit: string
        package_id: number
      }
    ]
  }
  handlePackagesTableEditSuccess: () => void
}

const PackagesTableModal: React.FC<Props> = ({
  show,
  handleClose,
  disabled,
  data,
  handlePackagesTableEditSuccess,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {numberYup, stringYup} = UseYupValidate()
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()

  const validateSchema = Yup.object().shape(
    OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
      switch (currentName) {
        case 'length':
        case 'width':
        case 'height':
        case 'weight':
          initParcel[currentName] = numberYup.parcel(currentName)
          break
        default:
          initParcel[currentName] = stringYup(50, 'UNIT')
          break
      }
      return initParcel
    }, {})
  )

  const formik = useFormik({
    initialValues: {
      length: data.parcels[0].length,
      width: data.parcels[0].width,
      weight: data.parcels[0].weight,
      height: data.parcels[0].height,
      dimension_unit: data.parcels[0]?.dimension_unit,
      weight_unit: data.parcels[0]?.weight_unit,
    },
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      packagesTableEdit(values)
    },
  })

  const packagesTableEdit = useCallback(
    async (values) => {
      setLoadingSave(true)
      const dataParcels = data.parcels[0]
      const arePropertiesEqual = (obj1, obj2, properties) => {
        return properties.every((prop) => obj1[prop].toString() === obj2[prop].toString())
      }
      const dimensionProperties = ['length', 'width', 'height', 'dimension_unit']
      const weightProperties = ['weight', 'weight_unit']

      const checkEqualDimension = arePropertiesEqual(dataParcels, values, dimensionProperties)
      const checkEqualWeight = arePropertiesEqual(dataParcels, values, weightProperties)

      try {
        await OrderService.patch(
          {
            type: 'package',
            order_ids: [data.id],
            fields: {
              ...values,
              preset_id: checkEqualDimension && checkEqualWeight ? data.preset_id : null,
              package_id: checkEqualDimension ? data.parcels[0].package_id : null,
            },
          },
          {cancelToken: newCancelToken()}
        )

        setLoadingSave(false)
        handleClose()
        handlePackagesTableEditSuccess()
      } catch (error: any) {
        setLoadingSave(false)
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      }
    },
    [
      data.id,
      data.parcels,
      data.preset_id,
      handleClose,
      handlePackagesTableEditSuccess,
      isCancel,
      newCancelToken,
    ]
  )

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_edit_packages_info'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
          formik.resetForm()
        }}
      >
        <div
          className={clsx('modal-content', {
            'cursor-no-drop': disabled,
          })}
        >
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'PACKAGE_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              className={clsx({
                'pe-none': disabled,
              })}
            >
              <div className='row'>
                <div className='col-12 d-flex'>
                  {['length', 'width', 'height'].map((item, index) => {
                    return (
                      <div className='col-3' key={index}>
                        <InputTextFormik
                          type='number'
                          min={0}
                          className={clsx('me-2', {'pe-none': disabled})}
                          labelClassName={'text-muted'}
                          label={intl.formatMessage({id: item.toUpperCase()})}
                          formik={formik}
                          name={`${item}`}
                          checkFormik={false}
                        />
                      </div>
                    )
                  })}
                  <div className='col-3'>
                    <SelectFormik
                      className={clsx({'pe-none': disabled})}
                      label={intl.formatMessage({id: 'UNIT'})}
                      labelClassName={'text-muted'}
                      options={OPTION_DIMENSION_UNIT}
                      name={'dimension_unit'}
                      formik={formik}
                      checkFormik={false}
                      placeholder=''
                    />
                  </div>
                </div>
                <div className='col-12 d-flex mt-3'>
                  <div className='col-3'>
                    <InputTextFormik
                      type='number'
                      min={0}
                      className={clsx('me-2', {'pe-none': disabled})}
                      labelClassName={'text-muted'}
                      label={intl.formatMessage({id: 'WEIGHT'})}
                      formik={formik}
                      name={'weight'}
                      checkFormik={false}
                    />
                  </div>
                  <div className='col-3'>
                    <SelectFormik
                      className={clsx({'pe-none': disabled})}
                      label={intl.formatMessage({id: 'UNIT'})}
                      labelClassName={'text-muted'}
                      options={OPTION_WEIGHT_UNIT}
                      name={'weight_unit'}
                      formik={formik}
                      checkFormik={false}
                      placeholder=''
                    />
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                event={formik.handleSubmit}
                loading={loadingSave}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {PackagesTableModal}
