import React, {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, ConfirmActionSwal, TableHeader} from '../../../../../../_gori/partials/widgets'
import {CreatePackagesModal} from '../../../modals/shipping/packages/CreatePackagesModal'
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import SettingsService from '../../../core/_requests'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'

type Props = {
  reloadTable?: any
  selectedListId?: any
}

const PackagesFilter: React.FC<Props> = ({selectedListId, reloadTable}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [showModal, setShowModal] = useState<{addPackage: boolean; confirmDelete: boolean}>({
    addPackage: false,
    confirmDelete: false,
  })

  const handleDeleteAll = useCallback(async () => {
    if (isEmpty(selectedListId)) return
    setLoadingRemove(true)

    try {
      const {status} = await SettingsService.deletePackage(
        {
          package_ids: selectedListId.map((item) => item.original.id),
        },
        {cancelToken: newCancelToken()}
      )
      if (status) {
        reloadTable()
        toast.success(intl.formatMessage({id: 'DELETED_SUCCESSFULLY'}))
      }
    } catch (error) {
      if (isCancel(error)) return
      toast.error(intl.formatMessage({id: 'DELETED_FAILED'}))
    } finally {
      setLoadingRemove(false)
    }
  }, [intl, isCancel, newCancelToken, reloadTable, selectedListId])

  return (
    <>
      {showModal.confirmDelete && (
        <ConfirmActionSwal
          show={showModal.confirmDelete}
          title={intl.formatMessage({id: 'DELETE_CUSTOM_PACKAGE'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDeleteAll}
          handleClose={() => setShowModal((prev) => ({...prev, confirmDelete: false}))}
        />
      )}
      <CreatePackagesModal
        show={showModal.addPackage}
        handleClose={() => {
          setShowModal((prev) => ({...prev, addPackage: false}))
        }}
        handleCreatePackagesSuccess={reloadTable}
      />
      <TableHeader className='card-title d-flex flex-wrap gap-2 justify-content-between align-items-start p-0'>
        <div className='card-toolbar flex-row-fluid justify-content-end align-items-start'>
          <div className={`d-flex align-items-center ${!(selectedListId.length > 0) && 'd-none'}`}>
            <div className='fw-bolder me-5'>
              <span className='me-2'>{selectedListId.length}</span>
              {intl.formatMessage({id: 'SELECTED'})}
            </div>
            <Button
              className='btn bg-light-danger bg-hover-danger text-danger text-hover-white btn-sm fs-5 me-3'
              label={intl.formatMessage({id: 'REMOVE'})}
              loadingText={intl.formatMessage({id: 'REMOVE'})}
              event={() => setShowModal((prev) => ({...prev, confirmDelete: true}))}
              loading={loadingRemove}
            />
          </div>
          <div
            className='btn text-white text-hover-primary bg-primary bg-hover-light-primary btn-sm fs-5'
            onClick={() => setShowModal((prev) => ({...prev, addPackage: true}))}
          >
            {`+ ${intl.formatMessage({id: 'ADD_CUSTOM_PACKAGE'})}`}
          </div>
        </div>
      </TableHeader>
    </>
  )
}

export {PackagesFilter}
