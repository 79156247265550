import clsx from 'clsx'
import {cloneDeep, isEmpty, isEqual, omit} from 'lodash'
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, ConfirmActionSwal} from '../../../../../_gori/partials/widgets'
import BatchService from '../../core/_requests'

type Props = {
  loadingParent: {getTemplate: boolean}
  mappingTemplate: {options: any; value: any}
  handleClickTemplate: any
  handleDeleteFieldSuccess: any
}

const ButtonTemplateMapping: FC<Props> = ({
  loadingParent = {getTemplate: false},
  mappingTemplate = {options: [], value: null},
  handleClickTemplate,
  handleDeleteFieldSuccess,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState<{delete: number | undefined}>({
    delete: undefined,
  })
  const [visibleMenu, setVisibleMenu] = useState(false)
  const filterMappingTemplateRef = useRef<any>(null)
  const [valueSearch, setValueSearch] = useState('')
  const [showModal, setShowModal] = useState<{
    confirmActionSwal: 'DELETE' | undefined
  }>({
    confirmActionSwal: undefined,
  })
  const [dataModal, setDataModal] = useState<{confirmActionSwal: any}>({
    confirmActionSwal: null,
  })

  const handleDropdownClick = () => {
    setVisibleMenu(!visibleMenu)
  }

  const resetDropdown = () => {
    setVisibleMenu(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterMappingTemplateRef.current &&
        !filterMappingTemplateRef.current.contains(event.target)
      ) {
        resetDropdown()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleConfirmActionSwal = useCallback(() => {
    switch (showModal.confirmActionSwal) {
      case 'DELETE':
        handleDeleteField(dataModal.confirmActionSwal)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const handleDeleteField = async (id) => {
    try {
      const config = {
        cancelToken: newCancelToken(),
      }
      setLoading((prev) => ({...prev, delete: id}))
      const {message} = await BatchService.deleteMappingTemplate(id, config)
      if (message) {
        toast.success(intl.formatMessage({id: message}))
        handleDeleteFieldSuccess({deleteThis: mappingTemplate?.value?.id === id})
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, delete: undefined}))
    }
  }

  const customsData = useMemo(() => {
    if (!valueSearch) return mappingTemplate.options
    return cloneDeep(mappingTemplate.options).filter(({label}) => {
      return label.toLowerCase().includes(valueSearch.toLowerCase().trim())
    })
  }, [mappingTemplate.options, valueSearch])

  const foundTemplate = (data) => {
    return isEqual(omit(mappingTemplate?.value, ['default']), omit(data, ['default']))
  }

  const renderMenuItem = (data, index) => {
    const {label, id} = data
    return (
      <div
        key={index}
        className={clsx('dropdown-submenu', {
          'cursor-no-drop': loading.delete,
        })}
      >
        <div
          className={clsx(
            'dropdown-submenu__item d-flex justify-content-between align-items-center my-1 p-1 rounded-2',
            {
              'bg-primary text-white': foundTemplate(data),
              'bg-hover-light-primary text-hover-primary': !foundTemplate(data),
              'pe-none': loading.delete,
            }
          )}
        >
          <div
            className={clsx('text-truncate cursor-pointer flex-fill p-2 px-3', {
              'bg-hover-light-primary': !foundTemplate(data),
            })}
            onClick={() => {
              handleClickTemplate(data)
              resetDropdown()
            }}
          >
            {label}
          </div>
          {loading.delete === id ? (
            <span className='spinner-border spinner-border-sm align-middle me-2' />
          ) : (
            <Button
              event={() => {
                setDataModal((prev) => ({...prev, confirmActionSwal: id}))
                setShowModal((prev) => ({...prev, confirmActionSwal: 'DELETE'}))
              }}
              className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1'
            >
              <KTSVG
                path='/media/gori/orders/delete.svg'
                className={clsx('opacity-50', {
                  'text-white': foundTemplate(data),
                })}
                svgClassName='mh-10px'
              />
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <ConfirmActionSwal
        show={!!showModal.confirmActionSwal}
        title={intl.formatMessage({id: 'BATCHES'})}
        message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
        messageCancel={intl.formatMessage({id: 'NO'})}
        handleCallBack={handleConfirmActionSwal}
        handleClose={() => {
          setDataModal((prev) => ({...prev, confirmActionSwal: null}))
          setShowModal((prev) => ({...prev, confirmActionSwal: undefined}))
        }}
      />
      <div className='d-flex flex-fill'>
        <label className='form-label col-form-label fw-bolder fs-3 me-6 text-truncate'>
          {intl.formatMessage({id: 'MAPPING_TEMPLATE'})}
        </label>
        <div
          className={clsx('dropdown flex-fill position-relative', {
            'border border-2 border-primary rounded-2': mappingTemplate?.value?.label,
            'cursor-no-drop': loadingParent.getTemplate,
          })}
          ref={filterMappingTemplateRef}
        >
          <div
            className={clsx('dropdown__label flex-fill', {'pe-none': loadingParent.getTemplate})}
            onClick={handleDropdownClick}
            ref={filterMappingTemplateRef}
          >
            {mappingTemplate?.value?.label ? (
              <span className='fw-bolder fs-5 text-gray-600 text-truncate w-400px'>
                {mappingTemplate?.value?.label}
              </span>
            ) : (
              <span className='fw-bolder fs-5 text-gray-600'>
                {intl.formatMessage({id: 'PLEASE_CHOOSE'})}
              </span>
            )}

            <i className='bi bi-caret-down-fill ms-2'></i>
          </div>
          <div
            className={clsx('dropdown-menu start-0 end-0 mt-2', {
              show: visibleMenu,
              'pe-none': loadingParent.getTemplate,
            })}
          >
            <div className={clsx('dropdown-submenu mh-300px scroll-y')}>
              <div className='px-3 py-2 bg-white'>
                <input
                  type='text'
                  autoComplete='off'
                  className='form-control'
                  onChange={(e) => {
                    setValueSearch(e.target.value)
                  }}
                  value={valueSearch}
                />
              </div>
              <div className='px-4'>
                {isEmpty(customsData) ? (
                  <div className='text-center fw-bold fs-5 text-gray-400 py-2'>No options</div>
                ) : (
                  customsData.map((item, index) => renderMenuItem(item, index))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ButtonTemplateMapping}
