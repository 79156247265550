import clsx from 'clsx'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {toast} from 'react-toastify'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, TABLE_KEY_COLUMN} from '../../../../../../_gori/constants'
import {setLoadingActionTable, useDisabled} from '../../../../../../_gori/helpers'
import {TableSkeleton} from '../../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableWrapper,
} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import SettingsService from '../../../core/_requests'
import {EditPackagesModal} from '../../../modals/shipping/packages/EditPackagesModal'
import {PackagesFilter} from './PackagesFilter'

const PackagesPage: React.FC = () => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch} = useAuth()
  const {setPageDisabled} = useDisabled()
  const [searchParams] = useSearchParams()
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [showFormEdit, setShowFormEdit] = useState<boolean>(false)
  const [packageModel, setPackageModel] = useState<number>()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [deletePackageId, setDeletePackageId] = useState<number>()
  const [selectedListId, setSelectedListId] = useState<any>([])

  const nameTab = useMemo(() => {
    return searchParams.get('type') ? 'custom' : 'custom'
  }, [searchParams])

  const setTable = useCallback(async () => {
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
      },
    }
    setPageDisabled(true)
    try {
      const res = await SettingsService.getPackages(config)
      let newPackages = Array.from(res.packages, (packages: any) => {
        return {
          ...packages,
          canCheck: true,
        }
      })
      setTableData(newPackages)
      setPagination(res.pagination)
    } catch (error) {
      if (isCancel(error)) return
      console.error(error)
    } finally {
      setPageDisabled(false)
      setLoadingFirst(false)
    }
  }, [isCancel, searchParams, setPageDisabled])

  const reloadTable = useCallback(() => {
    setTable()
  }, [setTable])

  const handleDeletePackage = async () => {
    if (deletePackageId) {
      setTableData(setLoadingActionTable(tableData, [deletePackageId], true))
      try {
        const response = await SettingsService.deletePackage(
          {package_ids: [deletePackageId]},
          {cancelToken: newCancelToken()}
        )
        if (response) {
          toast.success(intl.formatMessage({id: 'DELETE_SUCCESSFULLY'}))
          reloadTable()
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setTableData(setLoadingActionTable(tableData, [deletePackageId], false))
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      },
      {
        Header: intl.formatMessage({id: 'NAME'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='text-hover-primary cursor-pointer text-truncate'>
              {row.original?.name}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'LENGTH'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='text-hover-primary cursor-pointer text-truncate'>
              {Number.parseFloat(row.original?.length)}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'WIDTH'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='text-hover-primary cursor-pointer text-truncate'>
              {Number.parseFloat(row.original?.width)}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'HEIGHT'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='text-hover-primary cursor-pointer text-truncate'>
              {Number.parseFloat(row.original?.height)}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'UNIT'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          return (
            <div className='text-hover-primary cursor-pointer text-truncate'>
              {row.original.unit}
            </div>
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => {
          let list: any = [
            {
              label: intl.formatMessage({id: 'EDIT'}),
              action: () => {
                setPackageModel(row.original)
                setShowFormEdit(true)
              },
              className: `cursor-pointer`,
            },
            {
              label: intl.formatMessage({id: 'DELETE'}),
              action: () => {
                setDeletePackageId(row.original.id)
                setShowConfirmModal(true)
              },
              className: `cursor-pointer`,
            },
          ]

          return (
            <DropdownButton
              loading={row.original.isLoading}
              className={clsx({'pe-none': row.original.isLoading})}
              list={list}
            />
          )
        },
      },
    ],
    [intl]
  )

  useEffect(() => {
    if (loadingSwitch) return

    setTable()

    return () => {}
  }, [searchParams, loadingSwitch, setTable])

  return (
    <>
      {showConfirmModal && (
        <ConfirmActionSwal
          show={showConfirmModal}
          title={intl.formatMessage({id: 'DELETE_CUSTOM_PACKAGE'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDeletePackage}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
      {showFormEdit && (
        <EditPackagesModal
          show={showFormEdit}
          handleClose={() => {
            setShowFormEdit(false)
            setPackageModel(undefined)
          }}
          packageModel={packageModel}
          reloadTable={reloadTable}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <>
            <TableWrapper>
              <TableBody>
                <div className='mt-6 d-flex  align-item-center justify-content-between'>
                  <div className='col-3'>
                    <span className='fs-1 col-12 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  </div>
                  <div className='col-2 d-flex flex-row-reverse'>
                    <span className='btn col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
                <hr className='mb-8' />
                <TableSkeleton countRow={8} />
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <TableWrapper>
            <div className='mb-4'>
              <div className='card-header card-header-stretch d-flex h-55px'>
                <div className='card-title'>
                  <h3>{intl.formatMessage({id: 'PACKAGES'})}</h3>
                </div>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      to={'/settings/packages?type=custom'}
                      className={clsx('nav-link text-active-primary me-6 ', {
                        active: nameTab === 'custom',
                      })}
                    >
                      {intl.formatMessage({id: 'CUSTOM'})}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <TableBody>
              <PackagesFilter selectedListId={selectedListId} reloadTable={reloadTable} />
              <Table
                columns={columns}
                data={tableData}
                pagination={pagination}
                tbodyClass='text-gray-600 fw-bold'
                usePagination
                useCheckBox
                callBackSetData={(selected) => {
                  setSelectedListId(selected)
                }}
              />
            </TableBody>
          </TableWrapper>
        )}
      </CSSTransition>
    </>
  )
}

export {PackagesPage}
