import clsx from 'clsx'
import {cloneDeep, isEmpty} from 'lodash'
import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {CompanyModel, getAuth, useAuth} from '../../../app/modules/auth'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {StorageHelpers} from '../StorageHelpers'
import {KTSVG} from './KTSVG'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
type Props = {
  className?: string
  classNameMenu?: string
  isModeLaptop?: boolean
}

const SwitchCompany: React.FC<Props> = ({
  className = '',
  classNameMenu = '',
  isModeLaptop = false,
}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [valueSearch, setValueSearch] = useState('')
  const {isMobileTablet, isWindows} = useWindowDimensions()

  const [chooseCompany, setChooseCompany] = useState(
    StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
      ? StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
      : currentUser?.companies?.[0]?.encrypt_key
  )

  useEffect(() => {
    setChooseCompany(StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY), currentUser])

  const listCompany = currentUser?.companies.map((item: CompanyModel) => ({
    label: item.name,
    value: item.encrypt_key,
  }))

  const handleSwitchCompany = (companyEncrypt: any) => {
    setChooseCompany(companyEncrypt)

    const auth = getAuth()
    const choosen =
      currentUser?.companies.filter((item: any) => item.encrypt_key === companyEncrypt)[0] ||
      undefined
    const url = document.location.host
    const protocal = document.location.protocol
    document.location.replace(
      protocal +
        '//' +
        choosen?.slug +
        '.' +
        url.split('.').slice(1).join('.') +
        '/refresh-token/?slug=' +
        choosen?.slug +
        '&token=' +
        auth?.refresh_token
    )
  }

  const customsData = useMemo(() => {
    if (!valueSearch) return listCompany
    return cloneDeep(listCompany).filter(({label}) => {
      return label.toLowerCase().includes(valueSearch.toLowerCase().trim())
    })
  }, [listCompany, valueSearch])

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          'd-flex justify-content-between align-items-center border text-primary rounded-2 px-5 py-3',
          {
            'cursor-pointer border-hover-primary bg-hover-light-primary':
              currentUser?.companies.length > 1,
            'cursor-no-drop border-hover-info bg-hover-light-secondary':
              currentUser?.companies.length <= 1,
          }
        )}
        data-kt-menu-trigger={currentUser?.companies.length > 1 && 'click'}
        data-kt-menu-overflow='false'
        data-kt-menu-placement={(isMobileTablet && 'left') || (isWindows && 'right')}
      >
        <span className='fw-bolder'>
          {cloneDeep(listCompany).filter((company) => company.value === chooseCompany)?.[0]?.label}
        </span>
        <KTSVG path='/media/gori/arrows/arrow_right.svg' className='svg-icon-2' />
      </div>
      <div
        className={clsx(
          'menu menu-sub menu-sub-dropdown menu-column menu-rounded ms-9 fs-6 w-200px h-180px scroll-y',
          classNameMenu,
          {'mt-8': isModeLaptop}
        )}
        data-kt-menu='true'
      >
        <div className='px-3 py-2 sticky-top bg-white'>
          <input
            type='text'
            className='form-control'
            onChange={(e) => setValueSearch(e.target.value)}
            value={valueSearch}
          />
        </div>
        {isEmpty(customsData) ? (
          <div className='d-flex flex-fill justify-content-center align-items-center fs-3 text-gray-600'>
            {intl.formatMessage({id: 'NOT_FOUND'})}
          </div>
        ) : (
          customsData.map((company) => (
            <div className='px-3'>
              <div
                className={clsx(
                  'd-flex align-items-center justify-content-between p-3 rounded-2 flex-fill text-truncate',
                  {
                    'bg-primary text-white cursor-no-drop': company.value === chooseCompany,
                    'bg-hover-light-primary text-hover-primary': company.value !== chooseCompany,
                  }
                )}
                onClick={() => {
                  if (chooseCompany !== company.value) {
                    handleSwitchCompany(company.value)
                  }
                }}
              >
                {company.label}
                {company.value === chooseCompany && (
                  <KTSVG
                    path='/media/gori/valid_current_color.svg'
                    className='svg-icon-3 text-white'
                  />
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export {SwitchCompany}
