import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_DATE_FILTER, DEFAULT_PAGE} from '../../../../_gori/constants'
import {DatePicker} from '../../../../_gori/helpers/components/DatePicker'
import {InputSearch, TableHeader} from '../../../../_gori/partials/widgets'
import {CreateClaimsModal} from '../modals/CreateClaimsModal'
import {UploadUspsClaimsAgreementModal} from '../modals/UploadUspsClaimsAgreementModal'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'

type Props = {
  reloadTable?: any
}

const ClaimsFilter: React.FC<Props> = ({reloadTable}) => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showModalNewClaim, setShowModalNewClaim] = useState<boolean>(false)
  const [showModalUploadUspsClaim, setShowModalUploadUspsClaim] = useState<boolean>(false)
  const {routes} = UseRoutesPermission()

  const handleSetDefaultPageParam = () => {
    if (!(searchParams.get('search_text') === '' && searchParams.get('page') === DEFAULT_PAGE)) {
      searchParams.delete('search_text')
      searchParams.set('page', DEFAULT_PAGE)
      setSearchParams(searchParams)
    }
    reloadTable()
  }

  return (
    <>
      {routes.CLAIMS_CREATE.hasPermission && (
        <CreateClaimsModal
          show={showModalNewClaim}
          handleClose={() => {
            setShowModalNewClaim(false)
          }}
          handleCreateClaimsSuccess={() => handleSetDefaultPageParam()}
        />
      )}
      <UploadUspsClaimsAgreementModal
        show={showModalUploadUspsClaim}
        handleClose={() => {
          setShowModalUploadUspsClaim(false)
        }}
        reloadTable={() => reloadTable()}
      />
      <TableHeader className='min-h-1px my-5 mb-8'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative'>
            <InputSearch placeholder={intl.formatMessage({id: 'TRACKING_NUMBER'})} />
          </div>
        </div>
        <div className='card-toolbar flex-row-fluid justify-content-end gap-3'>
          <div>
            <DatePicker
              className={'bg-input-dark'}
              name={intl.formatMessage({id: 'REQUEST_DATE'})}
            />
          </div>
          <div
            className={'btn btn-light-primary'}
            onClick={() => setShowModalUploadUspsClaim(true)}
          >
            {intl.formatMessage({id: 'UPLOAD_USPS_CLAIMS'})}
          </div>
          {routes.CLAIMS_CREATE.hasPermission && (
            <div className='btn btn-primary' onClick={() => setShowModalNewClaim(true)}>
              {intl.formatMessage({id: 'START_A_NEW_CLAIM'})}
            </div>
          )}
        </div>
      </TableHeader>
    </>
  )
}

export {ClaimsFilter}
