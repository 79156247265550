import FileSaver from 'file-saver'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {DEFAULT_PAGE} from '../../../../_gori/constants'
import {KTSVG, fileHelper, toAbsoluteUrl} from '../../../../_gori/helpers'
import {Button} from '../../../../_gori/partials/widgets'
import {BatchUploadMappingModal} from '../../batches'
import {BatchesConfig} from '../core/_const'

type Props = {
  reloadTable?: any
}

const BatchesUpload: React.FC<Props> = ({reloadTable}) => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [show, setShow] = useState<{upload: boolean; errors: boolean}>({
    upload: false,
    errors: false,
  })

  const handleUploadSuccess = () => {
    if (!(searchParams.get('search_text') === '' && searchParams.get('page') === DEFAULT_PAGE)) {
      searchParams.delete('search_text')
      searchParams.set('page', DEFAULT_PAGE)
      setSearchParams(searchParams)
    }
    reloadTable()
    formik.resetForm()
  }

  const handleCloseModal = () => {
    setShow((prev) => ({...prev, upload: false}))
    formik.resetForm()
  }

  const setFile = (e: any) => {
    const file = e.target.files[0]
    if (!file) return

    if (BatchesConfig.SUPPORTED_FORMATS.indexOf(file.type) > -1) {
      formik.setFieldValue('fileUpload', e.currentTarget.files[0])
      formik.submitForm()
      e.target.value = null
    } else {
      toast.error(
        intl.formatMessage({
          id: 'INVALID_FILE_TYPE',
        })
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      fileUpload: null,
      columnsCSV: null,
    },
    onSubmit: async (values: any) => {
      const {row, header} = await fileHelper.checkRowHaveData(values.fileUpload)
      if (row <= BatchesConfig.ALERT_ERROR_UPLOAD) {
        const columnsCSV = fileHelper.convertHeaderFile(header)
        await formik.setFieldValue('columnsCSV', columnsCSV)
        await setShow((prev) => ({...prev, upload: true}))
      } else {
        toast.error(
          intl.formatMessage({
            id: 'CANNOT_UPLOAD_MORE_THAN_5000_ORDERS',
          })
        )
      }
    },
  })

  return (
    <>
      {show.upload && (
        <BatchUploadMappingModal
          showModal={show.upload}
          handleClose={handleCloseModal}
          data={formik.values}
          handleUploadSuccess={handleUploadSuccess}
        />
      )}
      <div className='card mb-5'>
        <div className='mx-7 my-5'>
          <div className='d-flex flex-fill justify-content-between'>
            <div className='d-flex align-items-center position-relative mb-2'></div>
            <div className='d-flex flex-row flex-wrap gap-3'>
              <Button
                event={() =>
                  FileSaver.saveAs(
                    toAbsoluteUrl('/media/gori/batches/ShipBae_Batch_Template_Domestic.csv'),
                    'ShipBae_Batch_Template_Domestic.csv'
                  )
                }
                className='btn btn-light btn-sm fw-bolder rounded-2'
                label={intl.formatMessage({id: 'BATCH_TEMPLATE'})}
                children={<KTSVG path='/media/gori/batches/file_download.svg' />}
              />
              <Button
                event={() =>
                  FileSaver.saveAs(
                    toAbsoluteUrl('/media/gori/batches/ShipBae_Batch_Template_International.csv'),
                    'ShipBae_Batch_Template_International.csv'
                  )
                }
                className='btn btn-light btn-sm fw-bolder rounded-2'
                label={intl.formatMessage({id: 'BATCH_TEMPLATE_INTL'})}
                children={<KTSVG path='/media/gori/batches/file_download.svg' />}
              />
            </div>
          </div>
          <div className='dropzone bg-light-primary border-primary border-2 mt-5'>
            <div className='d-flex justify-content-center my-4'>
              <div>
                <div className='fs-4 fw-bold text-dark mb-1'>
                  {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
                  <span className='ms-2 text-primary'>
                    {intl.formatMessage({id: 'BROWSE_FILES'})}
                  </span>
                </div>
                <Button
                  className='btn btn-secondary mt-4'
                  label={intl.formatMessage({id: 'UPLOAD_FILE'})}
                  event={formik.submitForm}
                />
              </div>
              <input
                type='file'
                id='file-upload'
                name='file-img'
                onChange={(e: any) => setFile(e)}
                className='dropzone__upload mt-n10 cursor-pointer'
                accept={BatchesConfig.SUPPORTED_FORMATS.join(',')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {BatchesUpload}
