export const SET_UP_MODAL_ALL = {
  CREATE_LABELS: {
    key: 'CREATE_LABELS',
    title: 'LABELS_CREATION',
    process: 'CREATED_LABEL_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_CREATE_LABELS',
    countCallApi: 10,
    notice: {
      key: 'CREATE_LABELS',
      title: 'LABELS',
      success: 'CREATED_SUCCESSFULLY',
      failed: 'CREATED_FAILED',
      showPrintLabels: true,
    },
  },
  RETURN_LABELS: {
    key: 'RETURN_LABELS',
    title: 'RETURN_LABELS',
    process: 'RETURN_LABEL_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_RETURN_LABELS',
    countCallApi: 10,
    notice: {
      key: 'RETURN_LABELS',
      title: 'RETURN_LABELS',
      success: 'RETURN_LABEL_SUCCESSFULLY',
      failed: 'RETURN_LABEL_FAILED',
      showPrintLabels: true,
    },
  },
  PACKING_SLIPS: {
    key: 'PACKING_SLIPS',
    title: 'PACKING_SLIPS',
    process: 'PACKING_SLIP_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_PRINT_PACKING_SLIP',
    countCallApi: 10,
    notice: {
      key: 'PACKING_SLIPS',
      title: 'PACKING_SLIPS',
      success: 'PRINT_PACKING_SLIP_SUCCESSFULLY',
      failed: 'PRINT_PACKING_SLIP_FAILED',
      showPrintLabels: true,
    },
  },
  REFUND_REQUEST: {
    key: 'REQUEST_REFUND',
    title: 'REQUEST_REFUND',
    process: 'REQUEST_REFUND_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_REQUEST_REFUNDS',
    countCallApi: 10,
    notice: {
      key: 'REQUEST_REFUND',
      title: 'REQUEST_REFUND',
      success: 'REQUEST_REFUND_SUCCESSFULLY',
      failed: 'REQUEST_REFUND_FAILED',
      showPrintLabels: false,
    },
  },
}

export const SET_UP_MODAL_NOTICE = {
  ESTIMATED: {
    key: 'ESTIMATE_SERVICE',
    title: 'ESTIMATE_SERVICE',
    success: 'SET_THE_ESTIMATED_SERVICE_SUCCESSFULLY',
    failed: 'SET_THE_ESTIMATED_SERVICE_FAILED',
    showPrintLabels: false,
  },
  CHEAPEST: {
    key: 'CHEAPEST_SERVICE',
    title: 'CHEAPEST_SERVICE',
    success: 'SET_THE_CHEAPEST_SERVICE_SUCCESSFULLY',
    failed: 'SET_THE_CHEAPEST_SERVICE_FAILED',
    showPrintLabels: false,
  },
}
