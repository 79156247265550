export const FEATURES = {
  REGISTER: 'register',
  PDX: 'pdx',
  DEPOSITS: 'deposits',
  LANDING_PAGE: 'landing_page',
  SUPPORT_PAGE: 'support_page',
  ACCOUNT_INFO: 'account_info',
  API_KEYS: 'api_keys',
  MARKETPLACE: 'marketplace',
  ABOUT_PAGE: 'about_page',
  PRIVACY_POLICY: 'privacy_policy',
  SERVICE_TERMS: 'service_terms',
  HAZMAT: 'hazmat',
  CLAIMS: 'claims',
  WARRANTY: 'warranty',
  USER_MANAGEMENT: 'user_management',
  LABELS_SLIPS: 'labels_slips',
  WEBHOOKS: 'webhooks',
  METER: 'meter',
  SHIPMENT_REPORT: 'shipment_report',
  HELP_CENTER_WIDGET: 'help_center_widget',
  ESTIMATED_RATE: 'estimated_rate',
}
