import React from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../helpers'

export const LANGUAGE_KEY = {
  EN: 'en',
  ZH: 'zh',
}

export const LanguageConfig = () => {
  const intl = useIntl()

  return {
    LANGUAGES: [
      {
        value: LANGUAGE_KEY.EN,
        label: (
          <>
            <img
              className='w-15px h-15px rounded-1 me-2'
              src={toAbsoluteUrl('/media/flags/united-states.svg')}
              alt='metronic'
            />
            {intl.formatMessage({id: 'ENGLISH'})}
          </>
        ),
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
      },
      {
        value: LANGUAGE_KEY.ZH,
        label: (
          <>
            <img
              className='w-15px h-15px rounded-1 me-2'
              src={toAbsoluteUrl('/media/flags/china.svg')}
              alt='metronic'
            />
            {intl.formatMessage({id: 'CHINESE'})}
          </>
        ),
        flag: toAbsoluteUrl('/media/flags/china.svg'),
      },
    ],
  }
}
