import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {Link} from 'react-router-dom'

export function StartFreeToday() {
  const intl = useIntl()
  return (
    <section className='enjoy light-blue-bg'>
      <div className='container'>
        <p className='text-center fs-2 fw-bolder mw-1116px pb-4 mx-auto pretendard'>
          {intl.formatMessage({id: 'LANDING_CLOSING_COPY'})}
        </p>
        <img
          className='d-block mx-auto shipbae-logo mw-100'
          src={toAbsoluteUrl('/media/gori/landing/shipbae-logo.webp')}
          alt='shipbae-logo'
        />
        <Link
          to='/auth/registration'
          className='btn btn-outline gradient text-white rounded-pill text-center d-block w-fit-content mx-auto mt-9 mb-4'
        >
          <img
            className='me-3'
            src={toAbsoluteUrl('/media/gori/landing/store.svg')}
            alt='store-icon'
          />
          {intl.formatMessage({id: 'LANDING_BUTTON_1'})}
        </Link>
      </div>
    </section>
  )
}
