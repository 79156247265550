import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, find, has, isEmpty, isEqual, omit, pick, some} from 'lodash'
import moment from 'moment'
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {REGEX_ALPHANUMERIC, SERVICES} from '../../../../../_gori/constants'
import {
  convertUserTimeZone,
  isUSMilitaries,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../../_gori/helpers'
import {blockPopUp} from '../../../../../_gori/helpers/BrowserHelpers'
import {useMeter} from '../../../../../_gori/helpers/components/MeterProvider'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import {OrderDetailSkeleton} from '../../../../../_gori/helpers/components/skeleton/OrderDetailSkeleton'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseCancelTokenHasKey from '../../../../../_gori/hooks/UseCancelTokenHasKey'
import UseRoutesPermission from '../../../../../_gori/hooks/UseRoutesPermission'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  SelectNoFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useAuth} from '../../../auth'
import {OPTION_ITEM_TYPE} from '../../../claims/core/_const'
import {
  ActionsDetailButton,
  CustomsInternational,
  EditAddress,
  InsuranceSignature,
  ItemPackages,
  ManageTagModal,
  NoticeCreateLabelModal,
  OrderChildModal,
  OrderItems,
  PackageInfo,
  PresetApplyButton,
  Rates,
  ShipmentTable,
} from '../../../orders'
import ShipmentService from '../../../shipments/core/_requests'
import {OrdersConfig, OrderStatus, SORT_KEYS_ORDER_VALIDATE} from '../../core/_const'
import OrderHelper from '../../core/_helpers'
import OrderService from '../../core/_requests'

const OrderChild: FC = () => {
  const intl = useIntl()
  const orderHelper = OrderHelper()
  const {setMeterChange} = useMeter()
  const {currentUser, currentCompany} = useAuth()
  const renderFirst = useRef(true)
  const navigate = useNavigate()
  const location = useLocation()
  const state: any = location.state
  const dataCopy = state?.dataCopy || null
  const fromCreateOrder = state?.fromCreateOrder || false
  const {newCancelToken, isCancel} = useCancelToken()
  const {newCancelTokenHasKey, isCancelHasKey} = UseCancelTokenHasKey()
  const {stringYup, orderYup, regexYup, numberYup, infoYup, dateYup} = UseYupValidate()
  const params = useParams()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const [loading, setLoading] = useState<{
    first: boolean
    save: boolean
    rate: boolean
    createOrder: boolean
    createLabel: boolean
    updateRate: boolean
    assignTag: boolean
  }>({
    first: true,
    save: false,
    rate: false,
    createOrder: false,
    createLabel: false,
    updateRate: false,
    assignTag: false,
  })

  const [data, setData] = useState<any>(null)
  const [dataTags, setDataTags] = useState<any>([])
  const [showModal, setShowModal] = useState<{
    reship: boolean
    noticeCreateLabel: boolean
    manageTag: boolean
  }>({
    reship: false,
    noticeCreateLabel: false,
    manageTag: false,
  })
  const [action, setAction] = useState<'create_order' | 'save' | 'create_label' | undefined>()
  const [fromToModal, setFormToModal] = useState<any>({
    show: false,
    name: undefined,
    data: {},
    validate: {},
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const row = useRef({parcels: 0, order_items: 0, custom_items: 0})
  const {routes} = UseRoutesPermission()
  const [dataRates, setDataRates] = useState<any>()
  const [canSave, setCanSave] = useState<boolean>(!params?.id)
  const [canGetRates, setCanGetRates] = useState<boolean>(false)
  const [checkValidateLeft, setCheckValidateLeft] = useState<boolean>(false)
  const currentValue = useRef<any>(null)
  const currentRates = useRef<any>(null)
  const [isClickMultiTags, setIsClickMultiTags] = useState<boolean>(true)
  const [scrollToErrors, setScrollToErrors] = useState<boolean>(false)

  const [showHide, setShowHide] = useState<{
    shipmentDetail: boolean
    custom: boolean
    orderDetail: boolean
  }>({shipmentDetail: false, custom: false, orderDetail: true})

  const hasPermissionView =
    routes.ORDERS_UPDATE.hasPermission || routes.ORDERS_CREATE.hasPermission
      ? false
      : routes.ORDERS_VIEW.hasPermission

  const isShippedPage = useMemo(() => {
    return data?.status === OrderStatus.SHIPPED
  }, [data?.status])

  const isDisablePage = useMemo(() => {
    return isShippedPage || loading.save || loading.createOrder || loading.createLabel
  }, [isShippedPage, loading.createLabel, loading.createOrder, loading.save])

  // BEGIN: validate
  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country,
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const validateItems = useMemo(() => {
    return Yup.object().shape({
      title: stringYup(255, 'NAME'),
      sku: stringYup(70, 'SKU', false),
      quantity: numberYup.quantity('QUANTITY', false),
      unit_price: numberYup.unitPrice('PRICE', false),
    })
  }, [numberYup, stringYup])

  const validateCustoms = useCallback(
    (name) => {
      const customsValidation = {
        content: orderYup.customs.content,
        content_description: orderYup.customs.content_description,
        signer: orderYup.customs.signer,
        description: orderYup.customs.description,
        hs_tariff_number: orderYup.customs.hsTariffNumber,
        country: orderYup.customs.country,
        code: orderYup.customs.code,
        quantity: orderYup.customs.quantity,
        value: orderYup.customs.value,
        weight: orderYup.customs.weight,
      }

      return customsValidation[name] || Yup.string().trim()
    },
    [orderYup.customs]
  )

  const initValidateSchema = useMemo(() => {
    return {
      from: Yup.object().shape(
        Object.entries(validateAddress('from')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['from_first_name', 'from_company']]
      ),
      to: Yup.object().shape(
        Object.entries(validateAddress('to')).reduce((validate, [key, value]) => {
          validate[key] = value
          return validate
        }, {}),
        [['to_first_name', 'to_company']]
      ),
      parcels: Yup.object().shape({
        [row.current.parcels]: Yup.object().shape(
          OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
            switch (currentName) {
              case 'length':
              case 'width':
              case 'height':
              case 'weight':
                initParcel[currentName] = numberYup.parcel(currentName)
                break
              default:
                initParcel[currentName] = stringYup(50, 'UNIT')
                break
            }
            return initParcel
          }, {})
        ),
      }),
      ship_by_date: orderYup.shipByDate,

      international: Yup.boolean(),
      international_option: Yup.object().when('international', {
        is: (val) => val === true,
        then: Yup.object().shape({
          content: validateCustoms('content'),
          signer: validateCustoms('signer'),
          description: validateCustoms('content_description'),
        }),
      }),
      custom_items: Yup.object().when('international', {
        is: (val) => val === true,
        then: Yup.object().shape({
          [row.current.custom_items]: Yup.object().shape(
            Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
              initCustoms[value.value] = validateCustoms(value.value)
              return initCustoms
            }, {})
          ),
        }),
      }),
      check_weight: Yup.boolean().when('international', {
        is: (val) => val === true,
        then: orderYup.checkIntlWeight,
      }),
      service: stringYup(50, 'SERVICE', false),
      insurance_option: Yup.object().shape({
        insured_amount: orderYup.insuredAmount,
      }),
      reference1: orderYup.reference('REF_1'),
      reference2: orderYup.reference('REF_2'),
      claim_category: stringYup(255, 'MERCHANDISE_CATEGORY', false),
    }
  }, [numberYup, orderYup, stringYup, validateAddress, validateCustoms])

  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)
  const validationSchema = Yup.object().shape(validateSchema, [['international', 'custom_items']])
  // END: validate

  const initValueEdit = useCallback(
    (name, init) => {
      let _data = cloneDeep(data || dataCopy)
      if (_data?.international_option?.custom_items) {
        _data.custom_items = dataCopy
          ? _data?.international_option?.custom_items.map((item) =>
              omit(item, ['id', 'international_option_id', 'order_item_id'])
            )
          : _data?.international_option?.custom_items
      }

      if ((data || dataCopy) && _data?.[name]?.length >= 1) {
        let _valueName: any = {}
        let _validateName: any = {}

        for (let i = 0; i < _data?.[name]?.length; i++) {
          _valueName[row.current[name]] = _data?.[name][i]
          switch (name) {
            case 'order_items':
              _validateName[row.current[name]] = validateItems
              break
            case 'custom_items':
              _validateName[row.current[name]] = Yup.object()
                .default({})
                .shape(
                  Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
                    initCustoms[value.value] = validateCustoms(value.value)
                    return initCustoms
                  }, {})
                )
              break
            default:
              _validateName[row.current[name]] = validateSchema[name].fields[0]
              break
          }

          if (i < _data?.[name]?.length - 1) {
            row.current[name] += 1
          }
        }

        init[name] = _valueName

        switch (name) {
          case 'custom_items':
            setValidateSchema((prev) => {
              return {
                ...prev,
                [name]: Yup.object().when('international', {
                  is: (val) => val === true,
                  then: Yup.object().shape({..._validateName}),
                }),
              }
            })
            break
          default:
            setValidateSchema((prev) => {
              return {
                ...prev,
                [name]: Yup.object().shape({..._validateName}),
              }
            })
            break
        }
      } else {
        switch (name) {
          case 'parcels':
            init[name] = {
              0: {
                package_id: null,
                package_type: '',
                length: null,
                width: null,
                height: null,
                weight: null,
                dimension_unit: 'in',
                weight_unit: 'oz',
              },
            }
            break
          case 'custom_items':
            init[name] = {
              0: {
                description: '',
                hs_tariff_number: '',
                country: '',
                code: '',
                quantity: '',
                value: '',
                weight: '',
              },
            }
            break

          default:
            break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dataCopy]
  )

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.from[`from_${value.value}`] =
              (data || dataCopy)?.[`from_${value.value}`] || false
            initValue.to[`to_${value.value}`] = (data || dataCopy)?.[`to_${value.value}`] ?? true
            break
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            break
          default:
            initValue.from[`from_${value.value}`] =
              (data || dataCopy)?.[`from_${value.value}`] || ''
            initValue.to[`to_${value.value}`] = (data || dataCopy)?.[`to_${value.value}`] || ''
            break
        }
        return initValue
      },
      {from: {}, to: {}}
    )
    initValueEdit('parcels', init)
    init.ship_by_date =
      isEmpty((data || dataCopy)?.ship_by_date) ||
      (data || dataCopy)?.ship_by_date === 'Invalid date'
        ? undefined
        : (data || dataCopy)?.ship_by_date
    init.international = !!(data || dataCopy)?.international_option
    init.international_option = {
      content: (data || dataCopy)?.international_option?.content || null,
      signer: (data || dataCopy)?.international_option?.signer || null,
      description: (data || dataCopy)?.international_option?.description || null,
    }
    initValueEdit('custom_items', init)
    initValueEdit('order_items', init)
    init.estimated_rate = data?.estimated_rate || null
    init.is_cheapest_rate = data?.is_cheapest_rate || false
    init.delivery_option_id = data?.delivery_option_id || null
    const latestShipment = (data || dataCopy)?.shipments[0] || null
    init.service =
      (data || dataCopy)?.status === OrderStatus.SHIPPED
        ? latestShipment?.carrier && latestShipment?.service
          ? `${latestShipment?.carrier}_${latestShipment?.service}`
          : null
        : (data || dataCopy)?.carrier && (data || dataCopy)?.service
        ? `${(data || dataCopy)?.carrier}_${(data || dataCopy)?.service}`
        : null
    init.signature_confirmation = (data || dataCopy)?.signature_confirmation || null
    init.insurance_option = {
      insured_amount: (data || dataCopy)?.insurance_option?.insured_amount?.toString() || null,
      provider: (data || dataCopy)?.insurance_option?.provider || null,
    }
    init.preset_id = (data || dataCopy)?.preset_id || null
    init.check_weight = false
    init.reference1 = (data || dataCopy)?.reference1 || null
    init.reference2 = (data || dataCopy)?.reference2 || null
    init.tags = (data || dataCopy)?.tags.map((tag) => tag.id) || []
    init.claim_category = (data || dataCopy)?.claim_category || null
    return init
  }, [data, dataCopy, initValueEdit])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values = {
        ...values,
        ship_by_date:
          isEmpty(values.ship_by_date?.format()) || values.ship_by_date?.format() === 'Invalid date'
            ? undefined
            : values.ship_by_date?.format(),
      }

      if (action === 'create_order') {
        postCreateOrder(values)
      }

      if (action === 'save') {
        handleSaveOrders(values)
      }

      if (action === 'create_label') {
        handleCreateLabel(values)
      }
    },
  })

  // BEGIN: Reset Validate
  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateSchema])
  // END: Reset Validate

  // BEGIN: initialize to initial value
  const getInitValue = useCallback(async () => {
    if (!params?.id) return
    setLoading((prev) => ({...prev, first: true}))
    try {
      const res = await OrderService.get(params?.id, {cancelToken: newCancelToken()})
      if (res) {
        const date = convertUserTimeZone(res.order?.ship_by_date, currentUser, '')
        setData({...res.order, ship_by_date: date})
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken, params?.id])

  useEffect(() => {
    if (!params?.id) return
    getInitValue()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInitValue, params.id])
  // END: initialize to initial value

  const handleClickTag = useCallback(
    async (item, e) => {
      const isChecked =
        cloneDeep(formik.values.tags).filter((idTag) => idTag === item.id).length > 0

      const payload = {
        tag_id: item.id,
        order_ids: [params?.id],
        mode: isChecked ? 'remove' : 'add',
      }
      setLoading((prev) => ({...prev, assignTag: true}))
      try {
        const {tags} = await OrderService.assignTag(payload, {cancelToken: newCancelToken()})
        if (tags) {
          formik.setFieldValue(
            'tags',
            isChecked
              ? formik.values.tags.filter((id) => id !== item.id)
              : [item.id, ...formik.values.tags].sort((a, b) => b - a)
          )
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, assignTag: false}))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isCancel, newCancelToken, formik.values.tags, params?.id]
  )

  // BEGIN: Tag
  const optionsTag = useMemo(() => {
    const _optionsTag = dataTags.map((item: any) => ({
      label: (
        <div className='d-flex align-items-center' onClick={(e) => handleClickTag(item, e)}>
          <div className='form-check'>
            <input
              aria-label='check'
              className='form-check-input cursor-pointer'
              type={'checkbox'}
              checked={formik.values.tags.includes(item.id)}
            />
          </div>
          <KTSVG
            path='/media/gori/orders/tag.svg'
            className='svg-icon-2 ms-2'
            style={{color: item.color}}
          />
          <span className='text-truncate'>{item.name}</span>
        </div>
      ),
      value: item.id.toString(),
    }))

    return [
      ..._optionsTag,
      {
        label: (
          <div className='mt-0'>
            <hr className='mt-n2 text-gray-600 me-n3 ms-n3 overflow-auto' />
            <span className='fw-bold fs-5'>{intl.formatMessage({id: 'MANAGE_TAGS'})}</span>
          </div>
        ),
        value: 'manage_tag',
      },
    ]
  }, [dataTags, formik.values.tags, handleClickTag, intl])

  const getOptionsTag = useCallback(async () => {
    try {
      const config = {cancelToken: newCancelToken()}
      const {tags} = await OrderService.getTags(config)
      setDataTags(tags.map((item) => ({id: item.id, name: item.name, color: item.color})))
    } catch (error) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    getOptionsTag()

    return () => {}
  }, [getOptionsTag])
  // END: Tag

  // BEGIN: Action Rate
  const handleGetRates = useCallback(async () => {
    setLoading((prev) => ({...prev, rate: true}))
    let canceled = false

    const dataParcels = orderHelper.buildRateParcels(
      formik?.values?.service,
      formik?.values?.parcels,
      false
    )

    currentRates.current = pick(formik.values, [
      'from',
      'to',
      'parcels',
      'international_option',
      'signature_confirmation',
      'insurance_option.insured_amount',
    ])

    try {
      const {rates} = await OrderService.rates(
        {
          shipment: {
            international_option: {
              ...formik.values?.international_option,
              custom_items: formik.values?.custom_items,
            },
            service_options: {
              confirmation: formik.values?.signature_confirmation,
              insured_amount: Number(formik.values?.insurance_option?.insured_amount),
            },
            from_address: Object.entries(OrdersConfig.SHIPPING).reduce((fromData, [key, value]) => {
              fromData[value.value] = formik.values?.from?.[`from_${value.value}`]
              return fromData
            }, {}),
            to_address: Object.entries(OrdersConfig.SHIPPING).reduce((toData, [key, value]) => {
              toData[value.value] = formik.values?.to?.[`to_${value.value}`]
              return toData
            }, {}),
            parcel: dataParcels,
          },
        },
        {cancelToken: newCancelTokenHasKey('getRates')}
      )

      if (!canceled) {
        let _chooseRate: any = {}
        if (formik.values?.service) {
          _chooseRate = cloneDeep(rates).find((item: any) => {
            return item?.matching_package && item?.carrier_service === formik.values?.service
          })

          if (!_chooseRate) {
            _chooseRate = cloneDeep(rates).find((item) => {
              return item?.carrier_service === formik.values?.service
            })
          }
        }

        if (_chooseRate?.carrier_service !== formik.values?.service) {
          formik.setFieldValue('service', '')
          formik.setFieldValue('estimated_rate', null)
          formik.setFieldValue('is_cheapest_rate', false)
        }

        setDataRates({
          chooseRate: _chooseRate,
          options: rates,
        })
      }
    } catch (error: any) {
      if (isCancelHasKey(error)) {
        canceled = true
        return
      }
      setDataRates({
        chooseRate: null,
        options: [],
      })
      setValidationErrors(error?.response)
    } finally {
      if (!canceled) {
        setLoading((prev) => ({...prev, rate: false}))
      }
    }
  }, [formik, isCancelHasKey, newCancelTokenHasKey, orderHelper])

  useEffect(() => {
    if (data?.status === OrderStatus.SHIPPED) return
    const values = pick(formik.values, [
      'from',
      'to',
      'parcels',
      'international_option',
      'signature_confirmation',
      'insurance_option.insured_amount',
    ])
    const validateParcels = ['length', 'width', 'height', 'weight'].every(
      (item) => Number(formik.values.parcels[0]?.[item]) > 0
    )
    const errors = Object.keys(formik.errors).map((item) => item)
    const fillDataRequired = !errors.some((item) =>
      [
        'from',
        'to',
        'parcels',
        'international_option',
        'signature_confirmation',
        'insurance_option',
      ].includes(item)
    )
    const _canGetRates = validateParcels && fillDataRequired
    setCanGetRates(_canGetRates)

    let timer
    const delayedHandleGetRates = () => {
      timer = setTimeout(() => {
        if (
          (!isEqual(currentRates.current, values) && _canGetRates && !loading.first) ||
          (!isEqual(currentRates.current, values) && _canGetRates && !params?.id)
        ) {
          handleGetRates()
        }
      }, 1000)
    }
    delayedHandleGetRates()

    return () => clearTimeout(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRates.current, formik.values, handleGetRates])

  useEffect(() => {
    const validateParcels = ['length', 'width', 'height', 'weight'].every(
      (item) => Number(formik.values.parcels[0]?.[item]) > 0
    )
    if (
      data?.status !== OrderStatus.SHIPPED &&
      params?.id &&
      validateParcels &&
      renderFirst.current
    ) {
      handleGetRates()
      const allValue = cloneDeep({
        ...formik.values,
        parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])},
      })
      currentValue.current = pick(allValue, [
        'from',
        'to',
        'parcels',
        'reference1',
        'reference2',
        'claim_category',
        'ship_by_date',
        'order_items',
        'custom_items',
        'international_option',
        'signature_confirmation',
        'insurance_option',
      ])
      renderFirst.current = false
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.parcels[0]])

  const handleEditRate = useCallback(
    async (chooseRate) => {
      setLoading((prev) => ({...prev, updateRate: true}))
      try {
        const res = await OrderService.patch(
          {
            type: 'service',
            order_ids: [params?.id],
            fields: {
              service: chooseRate?.carrier_service,
              package_type: chooseRate?.package_type?.value ?? null,
              estimated_rate: chooseRate?.rate ?? null,
              is_cheapest_rate: chooseRate?.cheapest ?? false,
              preset_id:
                chooseRate?.carrier_service === formik.values.service &&
                chooseRate?.package_type?.value === formik.values.parcels?.[0]?.package_type
                  ? formik?.values?.preset_id
                  : null,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (res) {
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, updateRate: false}))
      }
    },
    [
      formik.values?.parcels,
      formik.values?.preset_id,
      formik.values?.service,
      isCancel,
      newCancelToken,
      params?.id,
    ]
  )

  const handleClickRate = (rate) => {
    formik.setValues({
      ...formik.values,
      signature_confirmation: null,
      insurance_option: {
        insured_amount: null,
        provider: null,
      },
      service: rate?.carrier_service || '',
      estimated_rate: rate?.rate || null,
      is_cheapest_rate: rate?.cheapest || false,
    })
    setDataRates((prev) => ({
      ...prev,
      chooseRate: rate,
    }))
    if (params?.id) {
      handleEditRate(rate)
    }
  }
  // END: Action Rate

  // BEGIN: Detail Order
  const handleShipSuccess = useCallback(
    (url, navigateUrl?) => {
      setMeterChange(true)
      setShowModal((prev) => ({...prev, reship: false}))
      blockPopUp(url, '_blank')
      if (data?.status === OrderStatus.SHIPPED) {
        row.current = {parcels: 0, order_items: 0, custom_items: 0}
        getInitValue()
      } else if (navigateUrl) {
        navigate(navigateUrl)
      } else {
        navigate(-1)
      }
    },
    [data?.status, getInitValue, navigate, setMeterChange]
  )

  const postCreateOrder = useCallback(
    async (values) => {
      currentValue.current = pick(
        {...formik.values, parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])}},
        [
          'from',
          'to',
          'parcels',
          'reference1',
          'reference2',
          'claim_category',
          'ship_by_date',
          'order_items',
          'custom_items',
          'international_option',
          'signature_confirmation',
          'insurance_option',
        ]
      )

      setLoading((prev) => ({...prev, createOrder: true}))

      const dataParcels = orderHelper.buildParcels(cloneDeep(values?.parcels))
      const internationalOption = orderHelper.buildInternationalOption(
        values?.international_option,
        values?.custom_items
      )

      try {
        const {order} = await OrderService.store(
          {
            fields: {
              service: values.service ?? null,
              estimated_rate: values?.estimated_rate ?? null,
              is_cheapest_rate: values?.is_cheapest_rate ?? false,
              preset_id: values?.preset_id ?? null,
              signature_confirmation: values.signature_confirmation ?? null,
              insurance_option: carriers[values?.service]?.options?.insurance?.enabled
                ? values.insurance_option
                : null,
              reference1: values.reference1,
              reference2: values.reference2,
              ship_by_date: values.ship_by_date,
              order_items: values?.order_items,
              international_option: values.international ? internationalOption : null,
              claim_category: values?.claim_category || null,
              ...values.from,
              ...values.to,
              ...dataParcels,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (order) {
          navigate(`/orders/${order.id}`, {
            state: {
              fromCreateOrder: true,
            },
          })
          renderFirst.current = true
          toast.success(intl.formatMessage({id: 'CREATED_SUCCESSFULLY'}))
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, createOrder: false}))
        setAction(undefined)
      }
    },
    [carriers, formik.values, intl, isCancel, navigate, newCancelToken, orderHelper]
  )

  const handleSaveOrders = useCallback(
    async (values) => {
      try {
        currentValue.current = pick(
          {...formik.values, parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])}},
          [
            'from',
            'to',
            'parcels',
            'reference1',
            'reference2',
            'claim_category',
            'ship_by_date',
            'order_items',
            'custom_items',
            'international_option',
            'signature_confirmation',
            'insurance_option',
          ]
        )

        setLoading((prev) => ({...prev, save: true}))

        const dataParcels = orderHelper.buildParcels(cloneDeep(values?.parcels))
        const internationalOption = orderHelper.buildInternationalOption(
          values?.international_option,
          values?.custom_items
        )
        const res = await OrderService.update(
          params?.id,
          {
            fields: {
              service: values.service ?? null,
              estimated_rate: values?.estimated_rate ?? null,
              is_cheapest_rate: values?.is_cheapest_rate ?? false,
              preset_id: values?.preset_id ?? null,
              signature_confirmation: values.signature_confirmation ?? null,
              insurance_option: carriers[values?.service]?.options?.insurance?.enabled
                ? values.insurance_option
                : null,
              reference1: values.reference1,
              reference2: values.reference2,
              ship_by_date: values.ship_by_date,
              order_items: values?.order_items,
              international_option: values.international ? internationalOption : null,
              claim_category: values?.claim_category || null,
              ...values.from,
              ...values.to,
              ...dataParcels,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (res) {
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          handleGetRates()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, save: false}))
        setAction(undefined)
      }
    },
    [
      carriers,
      formik.values,
      handleGetRates,
      intl,
      isCancel,
      newCancelToken,
      orderHelper,
      params?.id,
    ]
  )

  const handleCreateLabel = useCallback(
    async (values) => {
      const _valueForm = cloneDeep(values)
      setLoading((prev) => ({...prev, createLabel: true}))

      const dataParcels = orderHelper.buildParcels(cloneDeep(_valueForm?.parcels))
      const internationalOption = orderHelper.buildInternationalOption(
        _valueForm?.international_option,
        _valueForm?.custom_items
      )

      const isReship = data?.status === OrderStatus.SHIPPED
      const payload = {
        id: data?.id,
        store_type: data?.store_type,
        store_id: data?.store_id,
        fields: {
          service: _valueForm.service,
          preset_id: values?.preset_id ?? null,
          delivery_option_id: _valueForm.delivery_option_id ?? null,
          signature_confirmation: _valueForm.signature_confirmation ?? null,
          insurance_option: carriers[_valueForm?.service]?.options?.insurance?.enabled
            ? _valueForm.insurance_option
            : null,
          reference1: _valueForm?.reference1,
          reference2: _valueForm?.reference2,
          ship_by_date:
            isEmpty(_valueForm?.ship_by_date) || _valueForm?.ship_by_date === 'Invalid date'
              ? null
              : _valueForm?.ship_by_date,
          order_items: _valueForm?.order_items,
          international_option: _valueForm.international ? internationalOption : null,
          ..._valueForm.from,
          ..._valueForm.to,
          ...dataParcels,
        },
      }

      try {
        const response = isReship
          ? await ShipmentService.reship(payload, {cancelToken: newCancelToken()})
          : await ShipmentService.store(payload, {cancelToken: newCancelToken()})
        if (response) {
          const navigateUrl = params?.id ? '/orders?&status=' + OrderStatus.SHIPPED : null
          handleShipSuccess(response.label_url, navigateUrl)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, createLabel: false}))
        setAction(undefined)
      }
    },
    [
      carriers,
      data?.id,
      data?.store_id,
      data?.store_type,
      data?.status,
      handleShipSuccess,
      isCancel,
      params?.id,
      newCancelToken,
      orderHelper,
    ]
  )
  // END: Detail Order

  // BEGIN: Items and Package action
  const handleAddNewItem = useCallback(
    (name) => {
      let newItem
      switch (name) {
        case 'order_items':
          newItem = {
            [row.current[name] + 1]: {
              sku: '',
              title: '',
              quantity: null,
              unit_price: null,
            },
          }
          break

        case 'parcels':
          newItem = {
            [row.current[name] + 1]: {
              length: '',
              width: '',
              height: '',
              weight: '',
              dimension_unit: null,
              weight_unit: null,
            },
          }
          break

        case 'custom_items':
          newItem = {
            [row.current[name] + 1]: {
              description: '',
              hs_tariff_number: '',
              country: '',
              code: '',
              quantity: '',
              value: '',
              weight: '',
            },
          }
          break

        default:
          break
      }

      formik.setFieldValue(name, {...formik.values[name], ...newItem})

      let keyLast = row.current[name].toString()
      const validate = !isEmpty(formik.values?.[name])
        ? Object.entries(formik.values?.[name]).reduce((init, [key, value]) => {
            switch (name) {
              case 'order_items':
                init[key] = validateItems
                break
              case 'custom_items':
                init[key] = Yup.object()
                  .default({})
                  .shape(
                    Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce(
                      (initCustoms, [key, value]) => {
                        switch (value.value) {
                          case 'country':
                            initCustoms[value.value] =
                              formik.values?.service === SERVICES.DHL_EXPRESS
                                ? stringYup(255, 'COUNTRY_OF_ORIGIN')
                                : validateCustoms(value.value)
                            break
                          default:
                            initCustoms[value.value] = validateCustoms(value.value)
                            break
                        }
                        return initCustoms
                      },
                      {}
                    )
                  )
                break
              default:
                init[key] = validateSchema[name].fields[0]
                break
            }
            keyLast = key
            return init
          }, {})
        : {}

      switch (name) {
        case 'order_items':
          validate[Number(keyLast) + 1] = validateItems
          break
        case 'custom_items':
          validate[Number(keyLast) + 1] = Yup.object()
            .default({})
            .shape(
              Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
                switch (value.value) {
                  case 'country':
                    initCustoms[value.value] =
                      formik.values?.service === SERVICES.DHL_EXPRESS
                        ? stringYup(255, 'COUNTRY_OF_ORIGIN')
                        : validateCustoms(value.value)
                    break
                  default:
                    initCustoms[value.value] = validateCustoms(value.value)
                    break
                }
                return initCustoms
              }, {})
            )
          break
        default:
          validate[Number(keyLast) + 1] = validateSchema[name].fields[0]
          break
      }

      switch (name) {
        case 'custom_items':
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().when('international', {
                is: (val) => val === true,
                then: Yup.object().default({}).shape(validate),
              }),
            }
          })
          break
        default:
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().default({}).shape(validate),
            }
          })
          break
      }

      row.current = {...row.current, [name]: (row.current[name] += 1)}
    },
    [formik, stringYup, validateCustoms, validateItems, validateSchema]
  )

  const handleDeleteItem = useCallback(
    (name, keyDelete) => {
      const objDelete = omit(formik.values[name], [keyDelete])
      formik.setFieldValue(name, objDelete)
      const validate = Object.entries(objDelete).reduce((init, [key, value]) => {
        switch (name) {
          case 'order_items':
            init[key] = validateItems
            break
          case 'custom_items':
            init[key] = Yup.object()
              .default({})
              .shape(
                Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
                  initCustoms[value.value] = validateCustoms(value.value)
                  return initCustoms
                }, {})
              )
            break
          default:
            init[key] = validateSchema[name].fields[0]
            break
        }
        return init
      }, {})

      switch (name) {
        case 'custom_items':
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().when('international', {
                is: (val) => val === true,
                then: Yup.object().shape({...validate}),
              }),
            }
          })
          break
        default:
          setValidateSchema((prev) => {
            return {
              ...prev,
              [name]: Yup.object().shape(validate),
            }
          })
          break
      }
    },
    [formik, validateCustoms, validateItems, validateSchema]
  )
  // END: Items and Package action

  // BEGIN: Validate depends on service
  useEffect(() => {
    if (formik.values.international) {
      const customs = {}
      const customsRequiredCountry = {}
      Object.keys(formik.values.custom_items).forEach((key) => {
        customs[key] = Yup.object()
          .default({})
          .shape(
            Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
              initCustoms[value.value] = validateCustoms(value.value)
              return initCustoms
            }, {})
          )
        customsRequiredCountry[key] = Yup.object()
          .default({})
          .shape(
            Object.entries(OrdersConfig.CUSTOMS.ITEMS).reduce((initCustoms, [key, value]) => {
              switch (value.value) {
                case 'country':
                  initCustoms[value.value] = stringYup(255, 'COUNTRY_OF_ORIGIN')
                  break
                default:
                  initCustoms[value.value] = validateCustoms(value.value)
                  break
              }
              return initCustoms
            }, {})
          )
      })

      const custom_items = Yup.object().when('international', {
        is: (val) => val === true && formik.values.service === SERVICES.DHL_EXPRESS,
        then: Yup.object().default({}).shape(customsRequiredCountry),
        otherwise: Yup.object().default({}).shape(customs),
      })

      setValidateSchema((prev) => {
        return {
          ...prev,
          custom_items,
        }
      })
    }

    if (formik.values.service === SERVICES.UPS_MI) {
      setValidateSchema((prev) => {
        return {
          ...prev,
          reference1: orderYup
            .reference('REF_1', true)
            .matches(
              REGEX_ALPHANUMERIC,
              intl.formatMessage(
                {id: 'INPUT_ALPHANUMERIC'},
                {input: intl.formatMessage({id: 'REF_1'})}
              )
            ),
        }
      })
    } else {
      setValidateSchema((prev) => {
        return {
          ...prev,
          reference1: orderYup.reference('REF_1'),
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.service])
  // END: Validate depends on service

  // BEGIN: remove validate international when change country
  useEffect(() => {
    if (!formik.values.international) {
      setValidateSchema((prev) => ({...prev, custom_items: initValidateSchema.custom_items}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.international])
  // END: remove validate international when change country

  // BEGIN: Validate parcel when change package type
  useEffect(() => {
    setValidateSchema((prev) => ({
      ...prev,
      parcels: Yup.object().shape({
        [row.current.parcels]: Yup.object().shape(
          OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
            switch (currentName) {
              case 'length':
              case 'width':
              case 'height':
                initParcel[currentName] = numberYup.parcel(currentName)
                break
              case 'weight':
                initParcel[currentName] = numberYup.parcel(
                  currentName,
                  true,
                  formik.values.parcels[0]?.package_type ? 'hasPackage' : ''
                )
                break
              default:
                initParcel[currentName] = stringYup(50, 'UNIT')
                break
            }
            return initParcel
          }, {})
        ),
      }),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.parcels[0]?.package_type])
  // END: Validate parcel when change package type

  // BEGIN: Check international
  useEffect(() => {
    const toCountry = formik.values?.to?.to_country?.trim()
    const toState = formik.values?.to?.to_state?.trim()
    const toCity = formik.values?.to?.to_city?.trim()
    const fromCountry = formik.values?.from?.from_country?.trim()
    const fromState = formik.values?.from?.from_state?.trim()
    const fromCity = formik.values?.from?.from_city?.trim()

    if ((toCountry && toCountry !== 'US') || (fromCountry && fromCountry !== 'US')) {
      formik.setFieldValue('international', true)
    } else {
      if (
        OrdersConfig.US_TERRITORIES.includes(toState) ||
        OrdersConfig.US_TERRITORIES.includes(fromState)
      ) {
        formik.setFieldValue('international', true)
      } else if (isUSMilitaries(toCity, fromCity)) {
        formik.setFieldValue('international', true)
      } else {
        formik.setFieldValue('international', false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [
    formik.values.from.from_country,
    formik.values.from.from_state,
    formik.values.from.from_city,
    formik.values.to.to_country,
    formik.values.to.to_state,
    formik.values.to.to_city,
  ])
  // END: Check international

  // BEGIN: Validate has can save
  useEffect(() => {
    const dataParcels = pick(
      {...formik.values, parcels: {0: orderHelper.formatParcels(formik.values.parcels[0])}},
      [
        'from',
        'to',
        'parcels',
        'reference1',
        'reference2',
        'claim_category',
        'ship_by_date',
        'order_items',
        'custom_items',
        'international_option',
        'signature_confirmation',
        'insurance_option',
      ]
    )
    if (params?.id && (isEqual(dataParcels, currentValue.current) || !currentValue.current)) {
      return setCanSave(false)
    }
    setCanSave(checkValidateLeft)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidateLeft, formik.values, currentValue.current, params?.id])

  useEffect(() => {
    const result = some(
      [
        'from',
        'to',
        'parcels',
        'custom_items',
        'international_option',
        'order_items',
        'check_weight',
      ],
      (key) => has(formik.errors, key)
    )
    if (checkValidateLeft !== !result) {
      setCheckValidateLeft(!result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])
  // END: Validate has can save

  // BEGIN: Notice about package type & service in Create Label
  const serviceHasPackage = useMemo(() => {
    if (!formik.values.parcels[0]?.package_type || !formik.values?.service) return true
    const {package_types} = carriers?.[formik.values.service]
    if (package_types) {
      return !!find(package_types, {value: formik.values?.parcels?.[0]?.package_type})
    }
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, formik.values.parcels?.[0]?.package_type, formik.values.service])

  const handleCreateLabelModal = () => {
    setShowModal((prev) => ({...prev, noticeCreateLabel: false}))
    setAction('create_label')
    setScrollToErrors(true)
    formik.handleSubmit()
  }
  // END: Notice about package type & service in Create Label

  // BEGIN: Scroll to errors
  const getErrorPaths = (errors: Record<string, any>, prefix = ''): string[] => {
    return Object.keys(errors).reduce<string[]>((paths, key) => {
      const currentPath = prefix ? `${prefix}.${key}` : key
      const value = errors[key]

      if (key === 'to' || key === 'from') {
        paths.push(currentPath)
        return paths
      }

      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (item !== null && typeof item === 'object') {
              paths.push(...getErrorPaths(item, `${currentPath}.${index}`))
            }
          })
        } else {
          paths.push(...getErrorPaths(value, currentPath))
        }
      } else {
        paths.push(currentPath)
      }

      return paths
    }, [])
  }

  const getKeyOrder = (path: string) => {
    const key = path.split('.')[0]
    return SORT_KEYS_ORDER_VALIDATE.indexOf(key)
  }

  useEffect(() => {
    const errorPaths = getErrorPaths(formik.errors)

    if (errorPaths.length > 0 && scrollToErrors) {
      const firstErrorPath = errorPaths.sort((a, b) => getKeyOrder(a) - getKeyOrder(b))[0]
      const input: any = document.querySelector(`[name="${firstErrorPath}"]`) as HTMLElement

      if (input) {
        const elementToScroll = input.type === 'hidden' ? input.closest('div') : input

        if (elementToScroll) {
          setTimeout(() => {
            elementToScroll.scrollIntoView({behavior: 'smooth', block: 'center'})
            if (elementToScroll.type !== 'hidden') {
              elementToScroll.focus()
            }
          }, 0)
          setScrollToErrors(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToErrors, formik.errors])
  // END: Scroll to errors

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      {showModal.noticeCreateLabel && (
        <NoticeCreateLabelModal
          show={showModal.noticeCreateLabel}
          handleClose={() => setShowModal((prev) => ({...prev, noticeCreateLabel: false}))}
          handleCreateLabel={handleCreateLabelModal}
          name={data?.status === OrderStatus.SHIPPED ? 'RESHIP' : 'CREATE_LABEL'}
        />
      )}

      {showModal.reship && (
        <OrderChildModal
          show={showModal.reship}
          handleClose={() => {
            getInitValue()
            setShowModal((prev) => ({...prev, reship: false}))
          }}
          handleSuccess={handleShipSuccess}
          name='createLabel'
          parentId={data.id}
        />
      )}
      {showModal.manageTag && (
        <ManageTagModal
          show={showModal.manageTag}
          dataInit={dataTags}
          handleClose={() => {
            setShowModal((prev) => ({...prev, manageTag: false}))
            setIsClickMultiTags(true)
          }}
          reloadOptionsTag={(newData) => {
            setDataTags(newData)
            formik.setFieldValue(
              'tags',
              formik.values.tags.filter((tagId) => find(newData, {id: tagId}))
            )
          }}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <>
          {params?.id && loading.first ? (
            <div className='w-1275px m-center'>
              <OrderDetailSkeleton />
            </div>
          ) : (
            (() => {
              let children = null
              if (data) {
                children = data?.children
                  .map((obj) => `${currentCompany?.sin_code}-${obj.code}`)
                  .join(', ')
              }

              return (
                <div className='w-100 mw-1275px m-center'>
                  <div className='sticky-top bg-page row d-flex align-items-center flex-wrap col-12 py-4 gap-2 gap-xl-0'>
                    <div className='col-12 col-xl-7 d-flex flex-wrap gap-2 gap-2 align-items-center justify-content-between'>
                      <div
                        onClick={() => {
                          return fromCreateOrder || dataCopy
                            ? navigate(`/orders?status=` + OrderStatus?.OPEN)
                            : navigate(data?.status === OrderStatus?.OPEN ? -1 : -2)
                        }}
                        className={`ps-1 pe-3 py-1 rounded-2 fw-bolder bg-hover-light-primary text-primary`}
                      >
                        <KTSVG
                          path='/media/gori/arrows/arrow_left.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span>{intl.formatMessage({id: 'BACK'})}</span>
                      </div>
                      <span className='fs-1 fw-boldest text-dark'>
                        {intl.formatMessage({
                          id:
                            data?.status === OrderStatus.SHIPPED ? 'ORDER_DETAIL' : 'CREATE_LABEL',
                        })}
                      </span>
                      {data?.status === OrderStatus.SHIPPED &&
                      routes.SHIPMENTS_CREATE.hasPermission ? (
                        <div className='w-75px' />
                      ) : (
                        <div
                          className={clsx({
                            'cursor-no-drop': loading.createOrder || loading.save || isDisablePage,
                          })}
                        >
                          <Button
                            className={clsx('btn btn-sm me-3', {
                              'bg-primary text-white bg-hover-light-primary text-hover-primary':
                                canSave && checkValidateLeft,
                              'bg-primary text-white cursor-no-drop opacity-50':
                                !canSave || !checkValidateLeft,
                            })}
                            label={intl.formatMessage({id: 'SAVE'})}
                            loadingText={intl.formatMessage({id: 'SAVING'})}
                            event={() => {
                              setScrollToErrors(true)
                              formik.handleSubmit()
                              if (canSave) {
                                params?.id ? setAction('save') : setAction('create_order')
                              }
                            }}
                            loading={loading.createOrder || loading.save}
                            disabled={isDisablePage}
                          />
                        </div>
                      )}
                    </div>
                    {params?.id && (
                      <div className='col-12 col-xl-5'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div>
                            <div>
                              <SelectNoFormik
                                styleInput={{
                                  // visibility: 'hidden',
                                  height: '1px',
                                }}
                                styleMenu={{
                                  width: '250px',
                                }}
                                emptyDefault={false}
                                value={{
                                  label:
                                    isEmpty(formik.values.tags) || isEmpty(dataTags) ? (
                                      loading.assignTag ? (
                                        <span
                                          className='indicator-progress'
                                          style={{display: 'block'}}
                                        >
                                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                        </span>
                                      ) : (
                                        intl.formatMessage({id: 'TAG'})
                                      )
                                    ) : (
                                      <div className='d-flex align-items-center'>
                                        {loading.assignTag && (
                                          <span
                                            className='indicator-progress'
                                            style={{display: 'block'}}
                                          >
                                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                          </span>
                                        )}
                                        {formik.values.tags.map((idTag, idx) => (
                                          <KTSVG
                                            key={idx}
                                            path='/media/gori/orders/tag.svg'
                                            className='svg-icon-2 ms-2'
                                            style={{color: find(dataTags, {id: idTag})?.color}}
                                          />
                                        ))}
                                      </div>
                                    ),
                                  value: '',
                                }}
                                options={optionsTag}
                                onChange={(e) => {
                                  if (e.value === 'manage_tag') {
                                    setIsClickMultiTags(false)
                                    setShowModal((prev) => ({...prev, manageTag: true}))
                                  }
                                }}
                                isSearchable={false}
                                disabled={isEmpty(optionsTag) || loading.assignTag || isDisablePage}
                                isClickMulti={isClickMultiTags}
                              />
                            </div>
                          </div>

                          <ActionsDetailButton
                            canSave={canSave}
                            data={data}
                            setData={setData}
                            disabled={loading.save || loading.createOrder || loading.createLabel}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row col-12'>
                    <div className='scroll-y vh-75 z-index-1 col-12 col-xl-7'>
                      <div
                        className={clsx('bg-white rounded-2 border border-primary', {
                          'bg-light cursor-no-drop': isDisablePage,
                        })}
                      >
                        {isShippedPage ? (
                          <div
                            className='d-flex justify-content-between align-items-center p-6 cursor-pointer'
                            onClick={() =>
                              setShowHide((prev) => ({
                                ...prev,
                                shipmentDetail: !prev.shipmentDetail,
                              }))
                            }
                          >
                            <span className='fs-2 fw-bolder required'>
                              {intl.formatMessage({id: 'SHIPMENT_DETAIL'})}
                            </span>

                            <span className='cursor-pointer text-hover-primary p-2 bg-hover-light-primary rounded-circle'>
                              <KTSVG
                                path='/media/gori/arrows/arrow_down.svg'
                                className='svg-icon-2'
                              />
                            </span>
                          </div>
                        ) : (
                          <div
                            className='d-flex justify-content-between align-items-center p-6'
                            onClick={() =>
                              setShowHide((prev) => ({
                                ...prev,
                                shipmentDetail: !prev.shipmentDetail,
                              }))
                            }
                          >
                            <span className='fs-2 fw-bolder required'>
                              {intl.formatMessage({id: 'SHIPMENT_DETAIL'})}
                            </span>
                            <PresetApplyButton
                              formik={formik}
                              disabled={isDisablePage}
                              fromCreateOrder={fromCreateOrder}
                              dataCopy={dataCopy}
                              setDataRates={setDataRates}
                              renderFirst={renderFirst}
                            />
                          </div>
                        )}
                        {((showHide.shipmentDetail && isShippedPage) || !isShippedPage) && (
                          <div className='pb-6 px-6'>
                            <hr className='bg-gray-600 mt-0 mb-6' />
                            <div>
                              <div>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <h3 className='fw-bolder fs-2'>
                                    {intl.formatMessage({id: 'SHIP_FROM'})}
                                  </h3>
                                  {!isShippedPage && (
                                    <div
                                      className='d-flex align-items-center text-muted text-hover-primary fw-bolder cursor-pointer'
                                      onClick={() => {
                                        setFormToModal({
                                          show: true,
                                          name: 'from',
                                          data: formik.getFieldProps('from').value,
                                          validate: validateAddress('from'),
                                        })
                                      }}
                                    >
                                      <span className='fs-2 me-1'>+</span>
                                      {intl.formatMessage({
                                        id: Object.entries(formik.getFieldProps('from').value).some(
                                          ([key, value]) => !isEmpty(value)
                                        )
                                          ? 'EDIT_ADDRESS'
                                          : 'NEW_ADDRESS',
                                      })}
                                    </div>
                                  )}
                                </div>
                                <EditAddress
                                  formik={formik}
                                  fromToModal={fromToModal}
                                  handleCloseFromToModal={() =>
                                    setFormToModal({
                                      show: false,
                                      name: undefined,
                                      data: {},
                                      validate: {},
                                    })
                                  }
                                  name='from'
                                  hasGetAddress={!fromCreateOrder}
                                  disabled={isDisablePage || hasPermissionView}
                                />
                              </div>
                              <div className='mt-4'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <h3 className='fw-bolder fs-2'>
                                    {intl.formatMessage({id: 'SHIP_TO'})}
                                  </h3>
                                  {!isShippedPage && (
                                    <div
                                      className='d-flex align-items-center text-muted text-hover-primary fw-bolder cursor-pointer'
                                      onClick={() => {
                                        setFormToModal({
                                          show: true,
                                          name: 'to',
                                          data: formik.values.to,
                                          validate: validateAddress('to'),
                                        })
                                      }}
                                    >
                                      <span className='fs-2 me-1'>+</span>
                                      {intl.formatMessage({
                                        id: Object.entries(formik.values.to).some(
                                          ([key, value]) => !isEmpty(value)
                                        )
                                          ? 'EDIT_ADDRESS'
                                          : 'NEW_ADDRESS',
                                      })}
                                    </div>
                                  )}
                                </div>
                                <EditAddress
                                  formik={formik}
                                  fromToModal={fromToModal}
                                  handleCloseFromToModal={() =>
                                    setFormToModal({
                                      show: false,
                                      name: undefined,
                                      data: {},
                                      validate: {},
                                    })
                                  }
                                  name='to'
                                  hasGetAddress={!fromCreateOrder}
                                  disabled={isDisablePage || hasPermissionView}
                                />
                              </div>
                            </div>
                            <div>
                              <PackageInfo
                                formik={formik}
                                disabled={isDisablePage || hasPermissionView}
                                hasGetPackage={!fromCreateOrder}
                              />
                            </div>
                            <div>
                              <div className='d-flex flex-wrap gap-2 justify-content-between mt-10 mb-2'>
                                <div className='fs-3 fw-bolder'>
                                  {intl.formatMessage({id: 'SHIPMENT_DATE'})}
                                </div>
                                <div
                                  className={clsx('d-flex col-12 col-sm-6', {
                                    'cursor-no-drop': isDisablePage || hasPermissionView,
                                  })}
                                >
                                  <InputDate
                                    className={clsx('col-12', {'pe-none': isDisablePage})}
                                    labelClassName='col-3 col-form-label text-nowrap text-muted'
                                    name='ship_by_date'
                                    formik={formik}
                                    min={moment()}
                                    disabled={isDisablePage || hasPermissionView}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {formik.values.international && (
                        <div className='mt-10'>
                          <CustomsInternational
                            formik={formik}
                            handleAddNewItem={handleAddNewItem}
                            handleDeleteItem={handleDeleteItem}
                            disabled={isDisablePage || hasPermissionView}
                            isShippedPage={isShippedPage}
                            showHide={showHide}
                            setShowHide={setShowHide}
                          />
                        </div>
                      )}
                      <div
                        className={clsx('bg-white rounded-2 mt-10', {
                          'bg-light cursor-no-drop': isDisablePage,
                        })}
                      >
                        {isDisablePage ? (
                          <div
                            className='d-flex justify-content-between align-items-center cursor-pointer p-6'
                            onClick={() =>
                              setShowHide((prev) => ({...prev, orderDetail: !prev.orderDetail}))
                            }
                          >
                            <span className='fs-2 fw-bolder'>
                              {intl.formatMessage({id: 'ORDER_DETAIL'})}
                            </span>
                            <span className='cursor-pointer text-hover-primary p-2 bg-hover-light-primary rounded-circle'>
                              <KTSVG
                                path='/media/gori/arrows/arrow_down.svg'
                                className='svg-icon-2'
                              />
                            </span>
                          </div>
                        ) : (
                          <div className='d-flex justify-content-between align-items-center p-6'>
                            <span className='fs-2 fw-bolder'>
                              {intl.formatMessage({id: 'ORDER_DETAIL'})}
                            </span>
                          </div>
                        )}
                        {((showHide.orderDetail && isDisablePage) || !isDisablePage) && (
                          <div className='pb-6 px-6'>
                            <hr className='bg-gray-600 mt-0 mb-6' />
                            <div className='d-flex'>
                              <div className='col-6 pe-6'>
                                <label className='form-label text-muted col-4'>
                                  {intl.formatMessage({id: 'ORDER_DATE'})}
                                </label>
                                <span className='col-8 text-dark fw-bolder'>
                                  {!params?.id
                                    ? moment().format(process.env.REACT_APP_DATE_FORMAT)
                                    : convertUserTimeZone(
                                        data?.created_at,
                                        currentUser,
                                        process.env.REACT_APP_DATE_FORMAT
                                      )}
                                </span>
                              </div>
                              <div className='col-6 ps-6'>
                                <label className='form-label text-muted col-4'>BAE ID</label>
                                <span className='col-8 text-dark fw-bolder'>
                                  {data && (
                                    <>
                                      <span className='fw-bolder text-dark'>
                                        {currentCompany?.sin_code}-{data?.code}
                                      </span>{' '}
                                      {data.children.length <= 0 && !isEmpty(data.split_from) && (
                                        <>
                                          <span className='m-1 badge badge-pill badge-light'>
                                            {intl.formatMessage({id: 'SPLIT'})}
                                          </span>
                                          <span className='fw-bolder text-dark'>
                                            {data?.split_from}
                                          </span>
                                        </>
                                      )}
                                      {children && (
                                        <>
                                          <span className='m-1 badge badge-pill badge-light'>
                                            {intl.formatMessage({id: 'COMBINED'})}
                                          </span>
                                          <span className='fw-bolder text-dark'>{children}</span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div className={clsx('d-flex col-6 pe-6')}>
                                <InputTextFormik
                                  className={clsx('col-8')}
                                  labelClassName='col-4 col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: 'REF_1'})}
                                  name='reference1'
                                  formik={formik}
                                  disabled={hasPermissionView || isDisablePage}
                                />
                              </div>
                              <div className={clsx('d-flex col-6 ps-6')}>
                                <InputTextFormik
                                  className={clsx('col-8')}
                                  labelClassName='col-4 col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: 'REF_2'})}
                                  name='reference2'
                                  formik={formik}
                                  checkFormik={false}
                                  disabled={hasPermissionView || isDisablePage}
                                />
                              </div>
                            </div>
                            <div className='d-flex mt-4'>
                              <SelectFormik
                                className='col-8'
                                labelClassName='col-4 col-form-label text-nowrap text-muted'
                                label={intl.formatMessage({id: 'MERCHANDISE_CATEGORY'})}
                                placeholder={intl.formatMessage(
                                  {id: 'SELECT_A_INPUT'},
                                  {input: intl.formatMessage({id: 'MERCHANDISE_CATEGORY'})}
                                )}
                                options={OPTION_ITEM_TYPE}
                                formik={formik}
                                name={'claim_category'}
                                hasUseIntl={true}
                                disabled={hasPermissionView || isDisablePage}
                              />
                            </div>
                            <hr className='bg-gray-600' />
                            <ItemPackages
                              formik={formik}
                              data={data}
                              handleAddNewItem={handleAddNewItem}
                              handleDeleteItem={handleDeleteItem}
                              disabled={
                                isDisablePage || hasPermissionView || !isEmpty(data?.store_order_id)
                              }
                            />
                          </div>
                        )}
                      </div>
                      {!isEmpty(data?.children) && params?.id && (
                        <OrderItems childItems={data?.children} disabled={isDisablePage} />
                      )}
                      {!isEmpty(data?.shipments) && params?.id && (
                        <ShipmentTable tableData={data?.shipments} />
                      )}
                    </div>
                    <div className='col-12 col-xl-5 mt-4 mt-xl-0'>
                      <div
                        className={clsx('bg-white shadow rounded-2 p-6', {
                          'bg-light': isDisablePage,
                        })}
                      >
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex align-items-center'>
                            <span className='fs-2 fw-bolder'>
                              {intl.formatMessage({id: 'RATES'})}
                            </span>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id='tooltip-disabled'>
                                  {intl.formatMessage({
                                    id: 'ESTIMATED_RATE_IS_EXCLUSIVE',
                                  })}
                                </Tooltip>
                              }
                            >
                              <span>
                                <KTSVG
                                  path='/media/gori/orders/exclamation.svg'
                                  className='ms-2 mb-1 svg-icon-4 cursor-pointer'
                                />
                              </span>
                            </OverlayTrigger>
                          </div>
                          {data?.status !== OrderStatus.SHIPPED && (
                            <Button
                              className={clsx('btn btn-sm p-0', {
                                'cursor-no-drop opacity-75': loading.rate || !canGetRates,
                              })}
                              event={() => canGetRates && handleGetRates()}
                            >
                              <KTSVG
                                path='/media/gori/orders/sync.svg'
                                className='svg-icon-2'
                                svgClassName={clsx({'spin text-primary': loading.rate})}
                              />
                              {intl.formatMessage({id: 'REFRESH'})}
                            </Button>
                          )}
                        </div>
                        <hr className='bg-gray-600' />
                        <div>
                          {loading.rate && (
                            <div className='h-400px d-flex justify-content-center align-items-center'>
                              <img
                                src={toAbsoluteUrl('/media/gori/orders/reload.gif')}
                                className='w-150px'
                                alt='reload-rate'
                              />
                            </div>
                          )}
                          {formik.values?.service &&
                            isEmpty(dataRates) &&
                            !loading.rate &&
                            data?.status !== OrderStatus.SHIPPED &&
                            Object.entries(carriers)?.map(
                              ([keyService, valueService]: [string, any], index) => {
                                if (keyService === formik.values?.service) {
                                  return (
                                    <div
                                      className={clsx(
                                        'btn border border-primary bg-light-primary d-flex justify-content-between my-5',
                                        {active: true}
                                      )}
                                      key={index}
                                    >
                                      <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-40px me-5'>
                                          <span className='symbol-label bg-transparent'>
                                            <img
                                              src={valueService.visuals?.logo}
                                              className='h-100 align-self-center'
                                              alt='logo-shipment'
                                            />
                                          </span>
                                        </div>
                                        <span className='text-gray-800 fs-6 fw-bolder'>
                                          {valueService.visuals?.display_name}
                                        </span>
                                      </div>
                                    </div>
                                  )
                                }
                                return null
                              }
                            )}
                          {!loading.rate && (
                            <Rates
                              data={dataRates}
                              handleClickRate={handleClickRate}
                              disabled={loading.updateRate || isDisablePage}
                              dataShipment={data}
                            />
                          )}
                          <InsuranceSignature formik={formik} disabled={isDisablePage} />
                        </div>
                        <div className={clsx('d-flex justify-content-end')}>
                          {(!params?.id || data?.status === OrderStatus.OPEN) &&
                            routes.SHIPMENTS_CREATE.hasPermission && (
                              <div
                                className={clsx({
                                  'cursor-no-drop':
                                    loading.createLabel ||
                                    loading.rate ||
                                    loading.save ||
                                    !formik.values.service,
                                })}
                              >
                                <Button
                                  className={clsx('btn btn-sm', {
                                    'bg-primary text-white bg-hover-light-primary text-hover-primary':
                                      checkValidateLeft && formik.values.service,
                                    'bg-primary text-white cursor-no-drop opacity-50':
                                      !checkValidateLeft || !formik.values.service,
                                  })}
                                  label={intl.formatMessage({id: 'CREATE_LABEL'})}
                                  loadingText={intl.formatMessage({id: 'CREATE_LABEL'})}
                                  event={() => {
                                    setScrollToErrors(true)
                                    formik.handleSubmit()
                                    if (checkValidateLeft && formik.values.service) {
                                      if (!serviceHasPackage) {
                                        setShowModal((prev) => ({...prev, noticeCreateLabel: true}))
                                      } else {
                                        setAction('create_label')
                                      }
                                    }
                                  }}
                                  icon={
                                    <KTSVG
                                      path='/media/gori/orders/upload_create.svg'
                                      className={clsx('svg-icon-2 text-white')}
                                    />
                                  }
                                  loading={loading.createLabel}
                                  disabled={
                                    loading.createLabel ||
                                    loading.rate ||
                                    loading.save ||
                                    !formik.values.service
                                  }
                                />
                              </div>
                            )}
                          {data?.status === OrderStatus.SHIPPED &&
                            routes.SHIPMENTS_CREATE.hasPermission && (
                              <Button
                                className='btn btn-primary btn-sm'
                                label={intl.formatMessage({id: 'RESHIP'})}
                                loadingText={intl.formatMessage({id: 'RESHIP'})}
                                event={() => {
                                  setShowModal((prev) => ({...prev, reship: true}))
                                }}
                                icon={
                                  <KTSVG
                                    path='/media/gori/orders/upload_create.svg'
                                    className='svg-icon-2'
                                  />
                                }
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })()
          )}
        </>
      </CSSTransition>
    </>
  )
}

export {OrderChild}
