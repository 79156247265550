import {FC, useRef} from 'react'
import {FormCheckType} from 'react-bootstrap/FormCheck'
import _ from 'lodash'
import {FormikProps} from 'formik'
import clsx from 'clsx'

type Props = {
  type?: FormCheckType
  className?: any
  required?: boolean
  label?: any
  labelClassName?: any
  checked?: any
  disabled?: boolean
  formik: FormikProps<any>
  name?: any
  value?: any
  onClick?: any
}

const InputCheckboxFormik: FC<Props> = ({
  type = 'checkbox',
  className,
  label,
  labelClassName,
  checked,
  disabled = false,
  formik,
  name,
  onClick,
}) => {
  const {current: id} = useRef(_.uniqueId('input-check'))
  let fieldProps = formik.getFieldProps(name)
  return (
    <div className={className}>
      <div
        className={clsx({
          'form-check': label,
          'form-switch': type === 'switch',
          'cursor-no-drop': disabled,
        })}
      >
        <input
          id={id}
          checked={checked ?? fieldProps.value}
          aria-label='check'
          className='form-check-input cursor-pointer'
          type={type === 'switch' ? 'checkbox' : type}
          disabled={disabled}
          {...fieldProps}
          onClick={onClick}
        />
        {label && (
          <label htmlFor={id} className={`form-check-label form-label ${labelClassName}`}>
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export {InputCheckboxFormik}
