import axios from 'axios'
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {SwapperComponent} from '../../../../_gori/assets/ts/components'
import {StorageHelpers} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {useLanguage} from '../../../../_gori/i18n/Metronici18n'
import {LayoutSplashScreen} from '../../../../_gori/layout/core'
import UserService from '../../users/core/_requests'
import * as authHelper from './AuthHelpers'
import {AuthModel, CompanyModel, UserModel} from './_models'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const CURRENT_COMPANY_STORAGE: any =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  currentCompany: CompanyModel | undefined
  setCurrentCompany: Dispatch<SetStateAction<CompanyModel | undefined>>
  logout: () => void
  loadingSwitch: boolean
  setLoadingSwitch: Dispatch<boolean>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentCompany: undefined,
  setCurrentCompany: () => {},
  logout: () => {},
  loadingSwitch: false,
  setLoadingSwitch: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [loadingSwitch, setLoadingSwitch] = useState(false)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentCompany, setCurrentCompany] = useState<CompanyModel | undefined>()
  const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    SwapperComponent.removeInstances() // fix title header
    saveAuth(undefined)
    setLoadingSwitch(false)
    setCurrentUser(undefined)
    setCurrentCompany(undefined)
  }

  useEffect(() => {
    const companyLocalStorage = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE)
    if (!currentCompany && !companyLocalStorage) return
    if (!currentCompany && companyLocalStorage) {
      setCurrentCompany(companyLocalStorage)
    }
    // if (!currentCompany) {
    //   setCurrentCompany(currentUser?.companies[0] || {})
    // }
    const companyLocal = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
    axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal
      ? companyLocal
      : currentCompany?.encrypt_key

    return () => {
      delete axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`]
    }
  }, [SIGNATURE_ACCESS_KEY, currentCompany])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        currentCompany,
        setCurrentCompany,
        logout,
        loadingSwitch,
        setLoadingSwitch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const {setLanguage} = useLanguage()
  const {newCancelToken, isCancel} = useCancelToken()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const config = {cancelToken: newCancelToken()}

    const requestUser = async () => {
      try {
        if (
          document.location.pathname === '/refresh-token/' ||
          document.location.pathname === '/login-page'
        ) {
          return
        }
        if (!didRequest.current) {
          const data = await UserService.getUser(config)
          if (data) {
            setCurrentUser(data)
            setLanguage(data?.user_profiles?.general?.language)
          }
        }
      } catch (error) {
        if (isCancel(error)) return
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
