const baseDomain = process.env.REACT_APP_DOMAIN

export const getCompany = (companies: any) => {
  const host = document.location.host?.split(':')?.[0]
  const domain = baseDomain?.split(':')?.[0]
  const subDomain = host.split(`.${domain}`).filter((item) => item !== domain)?.[0]

  return companies.find((item) => {
    if (subDomain === item.slug) {
      return item
    }
    return null
  })
}
