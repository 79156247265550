import clsx from 'clsx'
import React, {useCallback, useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_PAGE} from '../../../constants'
import {useIntl} from 'react-intl'

type Props = {
  keyCheckActive: string
  dataTabs: Array<{label: string; value: string; count: number; active?: boolean}>
  disableTabAll?: boolean
  title?: any
}

const TableTabsSetting: React.FC<Props> = ({
  title,
  keyCheckActive,
  dataTabs,
  disableTabAll = false,
}) => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()

  const tabsInit = useMemo(() => {
    const tabs = disableTabAll
      ? dataTabs
      : [
          {
            label: 'All',
            value: '',
            count: dataTabs.reduce((initTab, currentTab) => (initTab += currentTab.count), 0),
          },
          ...dataTabs,
        ]
    return tabs
  }, [dataTabs, disableTabAll])

  const newTabs = useMemo(() => {
    const valueCheckActive = searchParams.get(keyCheckActive)
    let tabsNew: Array<{}> = []

    if (!valueCheckActive) {
      tabsNew = tabsInit.map((tab: any, key: number) => {
        tab.active = key === 0
        return tab
      })
    } else {
      tabsNew = tabsInit.map((tab: any) => {
        tab.active = tab.value === valueCheckActive
        return tab
      })
    }

    return tabsNew
  }, [tabsInit, searchParams, keyCheckActive])

  const deleteParamEmpty = useCallback(
    (name) => {
      if (!searchParams.get(name)) {
        searchParams.delete(name)
      }
    },
    [searchParams]
  )

  const handleChangeTab = useCallback(
    (valueCheckActive) => {
      searchParams.set('page', DEFAULT_PAGE)
      searchParams.set(keyCheckActive, valueCheckActive)
      deleteParamEmpty('status')
      deleteParamEmpty('search_text')
      setSearchParams(searchParams)
    },
    [deleteParamEmpty, keyCheckActive, searchParams, setSearchParams]
  )

  return (
    <div className='card-header card-header-stretch d-flex h-55px'>
      <div className='card-title'>
        <h3>{title}</h3>
      </div>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {newTabs.map((tab: any, key) => (
          <li className='nav-item' key={key}>
            <div
              className={clsx('nav-link text-active-primary me-6 cursor-pointer', {
                active: tab.active,
              })}
              onClick={() => handleChangeTab(tab.value)}
            >
              <div>{intl.formatMessage({id: tab.label.toUpperCase()})}</div>
              {tab.count > 0 && (
                <div className={clsx('table-tabs__tab__count', {active: tab.active})}>
                  {tab.count}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export {TableTabsSetting}
