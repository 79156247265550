import {cloneDeep, has, map} from 'lodash'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'

const formatParcels = (parcels) => {
  return Object.entries(parcels).reduce((init, [key, value]: [any, any]) => {
    if (['length', 'width', 'height', 'weight'].includes(key)) {
      init[key] = Number(value)
    } else {
      init[key] = value
    }
    return init
  }, {})
}

const buildParcels = (parcels: any) => {
  return Object.entries(cloneDeep(parcels)).map(([key, value]: [any, any]) => {
    if (typeof value.package_type === 'object') {
      value.package_type = ''
    }
    return value
  })[0] as object
}

const buildRateParcels = (carriers: any, service: any, parcels: any, checkPackageType = true) => {
  let packageTypes = carriers[service]?.package_types

  return Object.entries(cloneDeep(parcels)).map(([key, value]: [any, any]) => {
    map(['length', 'width', 'height', 'weight'], (item) => {
      if (has(value, item)) {
        return Number(value[item])
      }
      return item
    })

    if (typeof value.package_type === 'object') {
      value.package_type = ''
    } else if (typeof value.package_type === 'string' && checkPackageType) {
      if (packageTypes) {
        const valid = packageTypes.some((item) => item.value === value.package_type)
        if (!valid) {
          value.package_type = ''
        }
      } else {
        value.package_type = ''
      }
    }
    return value
  })[0] as object
}

const buildInternationalOption = (internationalOption: any, customItems: any) => {
  let newInternationalOption = cloneDeep(internationalOption)
  newInternationalOption.custom_items = Object.entries(cloneDeep(customItems)).map(
    ([key, value]) => value
  )

  return newInternationalOption
}

const OrderHelper = () => {
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers

  return {
    formatParcels: (parcels: any) => formatParcels(parcels),
    buildParcels: (parcels: any) => buildParcels(parcels),
    buildRateParcels: (service: any, parcels: any, checkPackageType?: boolean) =>
      buildRateParcels(carriers, service, parcels, checkPackageType),
    buildInternationalOption: (internationalOption: any, customItems: any) =>
      buildInternationalOption(internationalOption, customItems),
  }
}

export default OrderHelper
