import {useFormik} from 'formik'
import React, {useState} from 'react'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import {fileHelper, KTSVG, toAbsoluteUrl, useDisabled} from '../../../../_gori/helpers'
import FileSaver from 'file-saver'
import ClaimsService from '../core/_requests'
import {AdminConfig} from '../../admin/core/_const'
import {UploadDone} from './UploadDone'

type Props = {
  reloadTable?: any
  handleClose: () => void
}

const UploadUspsClaims: React.FC<Props> = ({reloadTable, handleClose}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState<boolean>(false)
  const {pageDisabled, setPageDisabled} = useDisabled()
  const [showDone, setShowDone] = useState<boolean>(false)
  const [uploadDone, setUploadDone] = useState<any>()

  const formik = useFormik({
    initialValues: {
      upload: '',
    },
    onSubmit: async (values: any) => {
      const {row} = await fileHelper.checkRowHaveData(values.upload)

      if (row > 1) {
        if (row <= AdminConfig.ALERT_ERROR_UPLOAD) {
          await uploadFile(values.upload)
        } else {
          toast.error(
            intl.formatMessage({
              id: 'CANNOT_UPLOAD_MORE_THAN_10000_ITEMS',
            })
          )
        }
      } else {
        toast.error(
          intl.formatMessage({
            id: 'CANNOT_UPLOAD_BLANK_FILE',
          })
        )
      }
    },
  })
  const setFile = (e: any) => {
    const file = e.target.files[0]
    if (!file) return

    if (AdminConfig.SUPPORTED_FORMATS.indexOf(file.type) > -1) {
      formik.setFieldValue('upload', e.currentTarget.files[0])
      formik.submitForm()
      e.target.value = null
    } else {
      toast.error(
        intl.formatMessage({
          id: 'INVALID_FILE_TYPE',
        })
      )
    }
  }
  const handleUploadSuccess = () => {
    handleClose()
    reloadTable()
  }
  const uploadFile = async (file) => {
    setLoading(true)
    setPageDisabled(true)
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      cancelToken: newCancelToken(),
    }
    try {
      const response = await ClaimsService.uploadUsps(formData, config)
      if (!response.response) {
        setUploadDone(response)
        setShowDone(true)
      } else {
        handleUploadSuccess()
        toast.success(intl.formatMessage({id: 'UPLOAD_SUCCESSFULLY'}))
      }
    } catch (error: any) {
      toast.error(
        intl.formatMessage({
          id: 'UPLOAD_ERROR',
        })
      )
      if (isCancel(error)) return
    } finally {
      setLoading(false)
      setPageDisabled(false)
    }
  }

  // @ts-ignore
  return (
    <Container>
      <div
        className='card mb-5 border-light shadow'
        style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem'}}
      >
        <div className='mx-7 my-5'>
          <div className='d-flex flex-fill justify-content-between'>
            <div className='d-flex align-items-center position-relative mb-2'></div>
            <div>
              <Button
                event={() =>
                  FileSaver.saveAs(
                    toAbsoluteUrl('/media/gori/claims/USPS_Claim_Upload_Template.csv'),
                    'USPS_Claim_Upload_Template.csv'
                  )
                }
                className='btn btn-light btn-sm fw-bolder rounded-2'
                label={intl.formatMessage({id: 'DOWNLOAD_TEMPLATE_FILE'})}
                children={<KTSVG path='/media/gori/claims/file_download.svg' />}
              />
            </div>
          </div>
          <div className='dropzone bg-light-primary border-primary border-2 mt-5'>
            <div className='d-flex justify-content-center my-4'>
              <div>
                <div className='fs-4 fw-bold text-dark mb-1'>
                  {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
                  <span className='ms-2 text-primary'>
                    {intl.formatMessage({id: 'BROWSE_FILES'})}
                  </span>
                </div>
                <Button
                  className='btn btn-secondary mt-4'
                  loading={loading}
                  disabled={loading}
                  label={intl.formatMessage({id: 'UPLOAD_FILE'})}
                  event={formik.submitForm}
                />
              </div>
              <input
                type='file'
                id='file-upload'
                name='file-img'
                onChange={(e: any) => setFile(e)}
                className='dropzone__upload mt-n10 cursor-pointer'
                accept={AdminConfig.SUPPORTED_FORMATS.join(',')}
                disabled={pageDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 shadow bulk-upload-usps'>
        <div className={'mb-3'}>
          <KTSVG path='/media/gori/claims/claim_upload_icon.svg' className='svg-icon-1' />
          <b>{intl.formatMessage({id: 'BULK_CLAIM_UPLOAD_TEMPLATE_GUIDE'})}</b>
        </div>
        <table className={'table rounded-2'}>
          <tr className={'bulk-upload-usps-first-child'}>
            <th scope='col' style={{width: '4%'}}></th>
            <th style={{width: '20%'}}>A</th>
            <th style={{width: '25%'}}>B</th>
            <th style={{width: '25%'}}>C</th>
            <th style={{borderRight: '1px solid #475669'}}>D</th>
          </tr>
          <tr style={{textAlign: 'center', justifyContent: 'center'}}>
            <td className={'bulk-upload-usps-td-first-child'}>1</td>
            <td scope='col' className={'bulk-upload-usps-td'}>
              {intl.formatMessage({id: 'TRACKING_NUMBER'})}
            </td>
            <td className={'bulk-upload-usps-td'}>{intl.formatMessage({id: 'CLAIM_REASON'})}</td>
            <td className={'bulk-upload-usps-td'}>
              {intl.formatMessage({id: 'BULK_TOTAL_CLAIM_AMOUNT'})}
            </td>
            <td className={'bulk-upload-usps-td'}>
              {intl.formatMessage({id: 'MERCHANDISE_CATEGORY'})}
            </td>
          </tr>
          <tr style={{textAlign: 'center'}}>
            <td className={'bulk-upload-usps-td-first-child'}>2</td>
            <td className={'bulk-upload-usps-td'}>
              <i>EH267678323412</i>
            </td>
            <td className={'bulk-upload-usps-td'}>
              <i>{intl.formatMessage({id: 'LOST'})}</i>
            </td>
            <td className={'bulk-upload-usps-td'}>
              <i>45</i>
            </td>
            <td className={'bulk-upload-usps-td'}>
              <i>{intl.formatMessage({id: 'ARTS_CRAFTS'})}</i>
            </td>
          </tr>
          <tr style={{verticalAlign: 'text-top'}}>
            <td className={'bulk-upload-usps-td-first-child'}></td>
            <td className={'bulk-upload-usps-td'}>
              <p style={{color: 'red', fontSize: '13px'}}>
                {intl.formatMessage({id: 'BULK_TABLE_TRACKING_NUMBER'})}
              </p>
            </td>
            <td className={'bulk-upload-usps-td'}>
              <p style={{color: 'red', fontSize: '13px'}}>
                {intl.formatMessage({id: 'BULK_INPUT_ONE_OF_THE_VALUE'})}
              </p>
              <ul style={{marginLeft: '20px'}}>
                <li>{intl.formatMessage({id: 'LOST'})}</li>
                <li>{intl.formatMessage({id: 'DAMAGE'})}</li>
              </ul>
            </td>
            <td className={'bulk-upload-usps-td'}>
              <p style={{color: 'red', fontSize: '13px'}}>
                {intl.formatMessage({id: 'BULK_INPUT_TOTAL_CLAIM'})}
              </p>
            </td>
            <td className={'bulk-upload-usps-td'}>
              <p style={{color: 'red', fontSize: '13px'}}>
                {intl.formatMessage({id: 'BULK_INPUT_ONE_OF_THE_VALUE'})}
              </p>
              <ul style={{marginLeft: '20px', marginTop: '3px'}}>
                <li>{intl.formatMessage({id: 'CASH_CERTIFICATE'})}</li>
                <li>{intl.formatMessage({id: 'JEWELRY'})}</li>
                <li>{intl.formatMessage({id: 'CLOTHING_HOME_PRODUCTS'})}</li>
                <li>{intl.formatMessage({id: 'ARTS_CRAFTS'})}</li>
                <li>{intl.formatMessage({id: 'MEDIA_MUSIC_VIDEO'})}</li>
                <li>{intl.formatMessage({id: 'ELECTRONICS'})}</li>
                <li>{intl.formatMessage({id: 'COMPUTERS'})}</li>
                <li>{intl.formatMessage({id: 'COLLECTIBLES'})}</li>
                <li>{intl.formatMessage({id: 'SPORTS_EQUIPMENT'})}</li>
                <li>{intl.formatMessage({id: 'LIQUOR_WINE'})}</li>
                <li>{intl.formatMessage({id: 'ANIMALS'})}</li>
                <li>{intl.formatMessage({id: 'DOCUMENT_RECONSTRUCTION'})}</li>
                <li>{intl.formatMessage({id: 'HAZARDOUS_MATERIAL'})}</li>
                <li>{intl.formatMessage({id: 'EVENT_TICKETS'})}</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      {showDone && (
        <UploadDone show={showDone} handleClose={() => setShowDone(false)} data={uploadDone} />
      )}
    </Container>
  )
}

export {UploadUspsClaims}
