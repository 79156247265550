import {cloneDeep, difference, forEach, isEmpty, keys} from 'lodash'

export const showArrCustomColumns = (columnsInit, columnsShow) => {
  const columnA = cloneDeep(columnsInit).reduce(
    (columnA, item: any) => ({...columnA, [item.id]: item}),
    {}
  )
  let columnB = cloneDeep(columnsShow).reduce(
    (columnB, item: any) => ({...columnB, [item.id]: item}),
    {}
  )

  const newColumns = difference(keys(columnA), keys(columnB))
  if (!isEmpty(newColumns)) {
    forEach(newColumns, (key) => {
      let index = keys(columnA).indexOf(key)
      let value = {
        id: key,
        header: columnA[key].Header,
        show: true,
      }
      let entries = Object.entries(columnB)
      entries.splice(index, 0, [key, value])
      columnB = Object.fromEntries(entries)
    })
  }

  return Object.keys(columnB).map((key) => ({
    ...columnA[key],
    ...columnB[key],
  }))
}
