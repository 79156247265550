import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {isEmpty, startCase} from 'lodash'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {DEFAULT_COLOR, INVALID_COLOR, VALID_COLOR} from '../../../../_gori/constants'
import {KTSVG, toAbsoluteUrl} from '../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import {Button} from '../../../../_gori/partials/widgets'
import {CreatePackagesModal} from '../../settings'
import {OPTION_PACKAGE_TYPE} from '../core/_const'

type Props = {
  data: any
  textLabel?: string
  labelClassName?: any
  required?: boolean
  formik: FormikProps<any>
  name: string
  placeholder?: string
  className?: any
  showAddPackage?: boolean
  showInputDimension?: boolean
}

const PackagesMenu: React.FC<Props> = ({
  data,
  textLabel,
  labelClassName,
  required,
  formik,
  name,
  placeholder = '',
  className,
  showAddPackage = true,
  showInputDimension = true,
}) => {
  const storeRef = useRef<any>(null)
  const intl = useIntl()
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visibleSubMenu, setVisibleSubMenu] = useState<string | undefined>(undefined)
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)
  let isInvalid = formikTouched && formikErrors
  let isValid = formikTouched && !formikErrors && fieldProps.value
  const [showModalNewPackage, setShowModalNewPackage] = useState<boolean>(false)
  const {getPackages} = useStatesGlobal()

  const customStyle = {
    borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    borderWidth: '1px',
    borderStyle: 'solid',
    width: '100%',
    '&:hover': {
      borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    },
    boxShadow: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    backgroundImage: isValid
      ? `url(${toAbsoluteUrl('/media/gori/valid.svg')})`
      : isInvalid
      ? `url(${toAbsoluteUrl('/media/gori/invalid.svg')})`
      : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 3.5rem center',
    backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
  }

  const handleCreatePackagesSuccess = async (values) => {
    await getPackages()
    setTimeout(() => {
      formik.setFieldValue('parcels.0', {
        ...formik.values.parcels[0],
        ...values,
        package_type: '',
      })
    }, 100)
  }

  const handleDropdownClick = () => {
    setVisibleMenu(!visibleMenu)
  }

  useEffect(() => {
    data.forEach((item) => {
      const openSubMenu = item?.options?.filter(
        (option) => option?.value?.package_type === fieldProps?.value
      )

      if (openSubMenu.length > 0) {
        setVisibleSubMenu(item.current.value)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderMenuItem = (item, index) => {
    const {parent, current, options} = item

    const highLightPackageCarrier = (subItem) => {
      const {
        value: {package_type, package_id},
      } = subItem
      const optionCustoms = data.find(
        (item) => item.current.value === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value
      )
      const checkEqual = optionCustoms
        ? optionCustoms.options.find((item) => {
            return (
              item.value.length === Number(formik.values.parcels[0].length) &&
              item.value.width === Number(formik.values.parcels[0].width) &&
              item.value.height === Number(formik.values.parcels[0].height) &&
              item.value.dimension_unit === formik.values.parcels[0].dimension_unit
            )
          })
        : false
      if (
        (package_type === formik.values?.parcels?.[0]?.package_type &&
          parent.value === OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE.value) ||
        (package_id === formik.values?.parcels?.[0]?.package_id &&
          parent.value === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value)
      ) {
        return true
      } else if (checkEqual?.label === subItem?.label) {
        return true
      } else {
        return false
      }
    }

    return (
      <div
        className={clsx('dropdown-submenu', {show: visibleSubMenu === current.value})}
        key={index}
      >
        <div
          className={clsx('dropdown-submenu__item item-custom', {
            show: visibleSubMenu === current.value,
          })}
          onClick={() => {
            switch (parent.value) {
              case OPTION_PACKAGE_TYPE.INPUT_DIMENSION.value:
                setVisibleMenu(false)
                const initObj = {
                  ...formik.values?.parcels?.[0],
                  length: '',
                  width: '',
                  height: '',
                  dimension_unit: 'in',
                  package_id: null,
                  package_type: '',
                }
                formik.setFieldValue('parcels.0', initObj)
                break
              default:
                setVisibleSubMenu((prev) => (prev === current.value ? undefined : current.value))
                break
            }
          }}
        >
          {intl.formatMessage({id: current.label})}
        </div>
        <div
          className={`dropdown-submenu__inner ${visibleSubMenu === current.value ? 'show' : ''}`}
          style={{overflowY: 'auto', maxHeight: '200px'}}
        >
          {!isEmpty(options) &&
            options?.map((subItem, index) => {
              return (
                <div
                  key={index}
                  className={`dropdown-submenu__inner__item item-custom ${
                    highLightPackageCarrier(subItem)
                      ? 'text-white bg-primary'
                      : 'text-hover-primary bg-hover-active-primary'
                  }`}
                  onClick={() => {
                    setVisibleMenu(false)
                    formik.setFieldValue('parcels.0', {
                      ...formik.values.parcels[0],
                      ...subItem.value,
                    })
                  }}
                >
                  {subItem.label}
                </div>
              )
            })}
          {current.value === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value && showAddPackage && (
            <div
              key={index}
              className={`dropdown-submenu__inner__item item-custom`}
              onClick={() => {
                setVisibleMenu(false)
                formik.setFieldValue(
                  'parcels.0.package_type',
                  OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value
                )
                setShowModalNewPackage(true)
              }}
            >
              <Button
                className='btn btn-link py-0'
                label={`+ ${intl.formatMessage({id: 'ADD_CUSTOM_PACKAGE'})}`}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (storeRef.current && !storeRef.current.contains(event.target)) {
        setVisibleMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const renderLabelPackageType = useMemo(() => {
    const valuePackage = formik.values?.parcels?.[0]?.package_type

    if (isEmpty(data)) {
      return placeholder
    }

    const optionCarrier = data.find(
      (item) => item.current.value === OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE.value
    )
    if (valuePackage) {
      if (valuePackage === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value) {
        return intl.formatMessage({
          id: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label,
        })
      } else if (optionCarrier) {
        const selectedOption = optionCarrier.options.find(
          (item) => item.value.package_type === valuePackage
        )
        setVisibleSubMenu(optionCarrier?.current?.value)

        const isEqualValue = optionCarrier.options.find((item) => {
          return (
            item.value.length === Number(formik.values?.parcels?.[0]?.length) &&
            item.value.width === Number(formik.values?.parcels?.[0]?.width) &&
            item.value.height === Number(formik.values?.parcels?.[0]?.height) &&
            item.value.dimension_unit === formik.values?.parcels?.[0]?.dimension_unit
          )
        })
        if (!isEqualValue) {
          if (formik.values?.parcels?.[0]?.package_type) {
            formik.setFieldValue('parcels.0.package_type', '')
          } else {
            return selectedOption?.label
          }
          return showInputDimension
            ? intl.formatMessage({
                id: OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label,
              })
            : placeholder
        }
        return selectedOption?.label
      }
    }

    const optionCustoms = data.find(
      (item) => item.current.value === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value
    )
    if (!valuePackage && optionCustoms) {
      const selectedOption = optionCustoms.options.find((item) => {
        return item.value.package_id === Number(formik.values?.parcels?.[0]?.package_id)
      })

      setVisibleSubMenu(
        selectedOption?.label
          ? optionCustoms?.current.value
          : OPTION_PACKAGE_TYPE.INPUT_DIMENSION.value
      )

      const isEqualValue = optionCustoms.options.find((item) => {
        return (
          item.value.length === Number(formik.values?.parcels?.[0]?.length) &&
          item.value.width === Number(formik.values?.parcels?.[0]?.width) &&
          item.value.height === Number(formik.values?.parcels?.[0]?.height) &&
          item.value.dimension_unit === formik.values?.parcels?.[0]?.dimension_unit
        )
      })
      if (isEmpty(isEqualValue)) {
        formik.values.parcels?.[0]?.package_id && formik.setFieldValue('parcels.0.package_id', null)
        return showInputDimension
          ? intl.formatMessage({
              id: OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label,
            })
          : placeholder
      }

      return (
        selectedOption?.label ||
        (showInputDimension
          ? intl.formatMessage({
              id: OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label,
            })
          : placeholder)
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, formik.values.parcels, placeholder])

  return (
    <>
      <CreatePackagesModal
        show={showModalNewPackage}
        handleClose={() => {
          formik.setFieldValue('parcels.0.package_type', '')
          setShowModalNewPackage(false)
        }}
        handleCreatePackagesSuccess={handleCreatePackagesSuccess}
      />
      {textLabel && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {textLabel}
        </label>
      )}
      <div className={`dropdown ${className}`} ref={storeRef} style={{width: '100%'}}>
        <div
          className='dropdown__label py-3 px-3'
          onClick={handleDropdownClick}
          style={customStyle}
        >
          <div className='dropdown__label__left fs-7 fw-bold text-gray-700'>
            <div>{renderLabelPackageType}</div>
          </div>
          <div
            style={{
              borderLeft: '1px solid hsl(0, 0%, 80%)',
              paddingLeft: '6px',
              boxSizing: 'border-box',
              transition: 'color 150ms',
              fill: 'hsl(0, 0%, 80%)',
            }}
            className='dropdown__label__arrow'
          >
            <KTSVG path='/media/gori/select_arrow.svg' svgClassName={''} small={false} />
          </div>
        </div>
        <div
          className={clsx('dropdown-menu dropdown__menu  mw-100', {show: visibleMenu})}
          style={{width: '100%'}}
        >
          {data.map((item, index) => renderMenuItem(item, index))}
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {PackagesMenu}
