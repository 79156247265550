import {getIn, useFormik} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  InputTextArea,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {useAuth} from '../../auth'
import {UploadMultipleFile} from '../components/UploadMultipleFile'
import {OPTION_CLAIMS_APPEAL_REASON} from '../core/_const'
import ClaimsService from '../core/_requests'
import {cloneDeep, isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import {TableSkeleton} from '../../../../_gori/helpers/components'
import {convertCurrency, convertUserTimeZone} from '../../../../_gori/helpers'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'

type Props = {
  show: boolean
  handleClose: () => void
  claimId: number | undefined
  reloadTable: any
}

const AppealClaimsModal: React.FC<Props> = ({claimId, show, handleClose, reloadTable}) => {
  const intl = useIntl()
  const {loadingSwitch, currentUser} = useAuth()
  const {stringYup, authYup, arrayRequiredYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [dataForm, setDataForm] = useState<any>()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [loadingFirst, setLoadingFirst] = useState<boolean>(true)

  const [validateSchema] = useState<any>({
    reason: stringYup(50, 'REASON_FOR_APPEAL'),
    information: stringYup(2000, 'ADDITIONAL_INFORMATION'),
    photos: arrayRequiredYup('PROOF_OF_VALUE_DAMAGE'),
    accept_terms: authYup.acceptTerms,
  })

  const getClaimsDetail = useCallback(async () => {
    try {
      const config = {cancelToken: newCancelToken()}

      setIsLoadingForm(true)
      let totalClaim = 0
      const {claim} = await ClaimsService.getClaimDetail(claimId?.toString(), config)
      if (claim) {
        claim.items?.forEach((item) => {
          totalClaim += item.claim_item_price * item?.claim_item_qty
        })
        claim.claim_total = totalClaim
        setDataForm(claim)
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setIsLoadingForm(false)
      setLoadingFirst(false)
    }
  }, [claimId, isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch || !claimId || !show) return

    getClaimsDetail()
  }, [claimId, getClaimsDetail, loadingSwitch, show])

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
    setLoadingFirst(true)
  }

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      reason: '',
      information: '',
      photos: [],
      accept_terms: false,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const uploadPhoto = cloneDeep(values.photos).map((item: any) => item.path)

      try {
        setIsLoadingForm(true)
        const {status} = await ClaimsService.postClaimsAppeal(
          {...values, photos: uploadPhoto, claim_id: claimId},
          {cancelToken: newCancelToken()}
        )

        if (status) {
          toast.success(intl.formatMessage({id: 'APPEALED_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoadingFirst(false)
        setIsLoadingForm(false)
      }
    },
  })

  const onSubmitPhotoOfItem = useCallback(
    (values) => {
      formik.setFieldValue(formik.getFieldProps('photos').name, values)
    },
    [formik]
  )

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'APPEAL_CLAIM'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            {loadingFirst || isEmpty(dataForm) ? (
              <Container>
                <div className='mb-10'>
                  <TableSkeleton countColumn={3} countRow={2} showPagination={false} />
                </div>
                <div className='mb-10 bg-light rounded p-10 pt-5'>
                  <div className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary mb-5' />
                  <div className='col-12 d-flex flex-column mt-5'>
                    <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
                    <div className='btn col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                  <div className='col-12 d-flex flex-column mt-5'>
                    <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
                    <div className='col-12 h-75px placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                  <div className='col-12 d-flex flex-column mt-5'>
                    <div className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary  mb-4' />
                    <div className='col-12 h-75px placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='col-1'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      defaultChecked={false}
                      disabled
                    />
                  </div>
                  <div className='col-11 d-flex flex-column'>
                    <div className='col-12 placeholder placeholder-lg rounded-2 bg-secondary mb-4' />
                    <div className='col-12 placeholder placeholder-lg rounded-2 bg-secondary mb-4' />
                    <div className='col-12 placeholder placeholder-lg rounded-2 bg-secondary mb-4' />
                  </div>
                </div>
              </Container>
            ) : (
              <Container>
                <div className='table-responsive'>
                  <table className='table table-rounded table-striped border gs-7'>
                    <thead>
                      <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th>{intl.formatMessage({id: 'TRACKING_NUMBER'})}</th>
                        <th>{intl.formatMessage({id: 'AMOUNT_CLAIMED'})}</th>
                        <th>{intl.formatMessage({id: 'DATE_SUBMITTED'})}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{dataForm?.tracking_number}</td>
                        <td>{convertCurrency(dataForm?.claim_total)}</td>
                        <td>
                          {convertUserTimeZone(
                            dataForm?.created_at,
                            currentUser,
                            process.env.REACT_APP_DATE_FORMAT
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='bg-light rounded px-10 py-5 mb-5'>
                  <h3>{intl.formatMessage({id: 'ADDITIONAL_INFORMATION_AND_PROOF'})}</h3>
                  <p className='text-gray-700 fs-7'>
                    {intl.formatMessage({id: 'PLEASE_PROVIDE_THE_ADDITIONAL_INFORMATION_BELOW'})}
                  </p>
                  <div className='mb-3'>
                    <SelectFormik
                      labelClassName='col-form-label'
                      label={intl.formatMessage({id: 'REASON_FOR_APPEAL'})}
                      placeholder={intl.formatMessage(
                        {id: 'SELECT_A_INPUT'},
                        {input: intl.formatMessage({id: 'REASON_FOR_APPEAL'})}
                      )}
                      emptyDefault={false}
                      required
                      options={OPTION_CLAIMS_APPEAL_REASON}
                      name='reason'
                      formik={formik}
                      hasUseIntl={true}
                    />
                  </div>
                  <div className='mb-3'>
                    <InputTextArea
                      labelClassName='col-form-label'
                      label={intl.formatMessage({id: 'ADDITIONAL_INFORMATION'})}
                      formik={formik}
                      name='information'
                      required
                    />
                  </div>
                  <div className='row mb-5'>
                    <UploadMultipleFile
                      label={intl.formatMessage({
                        id: 'PROOF_OF_VALUE_DAMAGE',
                      })}
                      required
                      onSubmit={(value) => onSubmitPhotoOfItem(value)}
                      formik={formik}
                      name='photos'
                    />
                  </div>
                </div>
                <div className='d-flex align-items-start form-check form-check-custom form-check form-check-sm'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='accept_terms'
                    onChange={formik.handleChange}
                  />
                  <div className='form-check-label text-gray-600 fs-9'>
                    {intl.formatMessage({id: 'APPEAL_ACCEPT_TERMS'})}
                  </div>
                </div>
                {getIn(formik.touched, 'accept_terms') && getIn(formik.errors, 'accept_terms') && (
                  <div className='fv-plugins-message-container mt-2'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>{getIn(formik.errors, 'accept_terms')}</span>
                    </div>
                  </div>
                )}
              </Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                disabled={isLoadingForm}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {AppealClaimsModal}
