import clsx from 'clsx'
import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import {Button, ButtonBack} from '../../../../../../../_gori/partials/widgets'
import {OrderType} from '../../../../../orders/core/_const'
import SettingsService from '../../../../core/_requests'
import {StoresListing} from '../StoresListing'

const ConnectTikTok: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {isCancel, newCancelToken} = useCancelToken()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [configs, setConfigs] = useState<any>(null)

  const getConfig = useCallback(async () => {
    setIsLoading(true)
    const config = {
      params: {
        config_name: OrderType.TIKTOK,
      },
      cancelToken: newCancelToken(),
    }

    try {
      const {configurations} = await SettingsService.getConfig(config)
      setConfigs(configurations)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setIsLoading(false)
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    getConfig()
  }, [getConfig])

  const onGoBack = () => {
    navigate('/settings/connections')
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card p-10 rounded-bottom-0'>
          <div className='card-title d-flex justify-content-between'>
            <div className='d-flex gap-4 align-items-center'>
              <ButtonBack label={intl.formatMessage({id: 'BACK'})} event={onGoBack} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} TikTok
              </span>
            </div>
            <Button
              className={clsx('btn btn-primary')}
              loading={isLoading}
              loadingText={intl.formatMessage({id: 'CONNECT'})}
              label={intl.formatMessage({id: 'CONNECT'})}
              event={() => {
                const url = `https://services.tiktokshops.us/open/authorize?service_id=${configs?.TIKTOK_APP_ID}`
                window.location.replace(url)
              }}
              disabled={isLoading}
            />
          </div>
        </div>
      </CSSTransition>
      <StoresListing storeType='tiktok' />
    </>
  )
}

export {ConnectTikTok}
