import {FC, useRef} from 'react'
import {FormCheckType} from 'react-bootstrap/FormCheck'
import _ from 'lodash'
import {FormikProps} from 'formik'

type Props = {
  type?: FormCheckType
  onChange?: any
  className?: any
  required?: boolean
  label?: any
  labelClassName?: any
  formik: FormikProps<any>
  name?: any
  value?: any
  valueInput?: any
}

const InputRadioFormik: FC<Props> = ({
  type = 'checkbox',
  className,
  label,
  labelClassName,
  formik,
  name,
  onChange,
  valueInput,
}) => {
  const {current: id} = useRef(_.uniqueId('input-check'))
  let fieldProps = formik.getFieldProps(name)
  return (
    <div className={className}>
      <div className={`${label && 'form-check'} ${type === 'switch' ? 'form-switch' : ''}`}>
        <input
          id={id}
          aria-label='check'
          className='form-check-input cursor-pointer'
          type='radio'
          checked={fieldProps.value === valueInput}
          {...fieldProps}
          value={valueInput}
          onChange={(e) => onChange(e)}
        />
        {label && (
          <label htmlFor={id} className={`form-check-label form-label ${labelClassName}`}>
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export {InputRadioFormik}
