import clsx from 'clsx'
import {isEmptyArray, useFormik} from 'formik'
import {find, includes, isEmpty} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {OPTION_COUNTRIES} from '../../../../../../_gori/constants'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextArea,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import {AutocompleteAddress, ImageCrop} from '../../../../common'
import {SettingsConfig} from '../../../core/_const'
import SettingsService from '../../../core/_requests'

type Props = {
  show: boolean
  handleClose: any
  data: any
}

const sizeCrop = {
  container: {width: 400, height: 400},
  crop: {width: 300, height: 300},
}

const PackingSlipsModal: React.FC<Props> = ({show, handleClose, data}) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, regexYup} = UseYupValidate()

  const initValidateSchema = useMemo(() => {
    return {
      show_header: Yup.boolean(),
      header: Yup.string().when(`show_header`, {
        is: true,
        then: stringYup(50, 'HEADER', false),
      }),
      show_order: Yup.boolean(),
      order: Yup.string().when(`show_order`, {
        is: true,
        then: stringYup(255, 'ORDER', false),
      }),
      order_date: Yup.boolean(),
      // show_note: Yup.boolean(),
      // note:Yup.string().when(`show_note`, {
      //   is: true,
      //   then:  stringYup(3000, 'NOTE', false),
      // }),
      ship_to: Yup.boolean(),
      shipping_service: Yup.boolean(),
      tracking: Yup.boolean(),
      item_detail: Yup.boolean(),
      show_barcode: Yup.boolean(),
      barcode: Yup.string().when(`show_barcode`, {
        is: true,
        then: stringYup(255, 'BARCODE', false),
      }),
      show_company_policy: Yup.boolean(),
      company_policy: Yup.string().when(`show_company_policy`, {
        is: true,
        then: stringYup(3000, 'COMPANY_POLICY', false),
      }),
      show_footer: Yup.boolean(),
      footer: Yup.string().when(`show_footer`, {
        is: true,
        then: stringYup(50, 'FOOTER', false),
      }),
      show_store_logo: Yup.boolean(),
      show_store_name: Yup.boolean(),
      store_name: Yup.string().when(`show_store_name`, {
        is: true,
        then: stringYup(50, 'STORE_NAME', false),
      }),
      third_party_name: Yup.boolean(),
      show_store_location: Yup.boolean(),
      store_location: Yup.object().when(`show_store_location`, {
        is: true,
        then: Yup.object()
          .shape({
            address: stringYup(255, 'ADDRESS', false),
            address_2: stringYup(255, 'ADDRESS_2', false),
            city: stringYup(255, 'CITY', false),
            state: Yup.string().when(`country`, {
              is: (val) => val === 'US',
              then: regexYup.state('STATE', false),
              otherwise: stringYup(255, 'STATE', false),
            }),
            country: stringYup(255, 'COUNTRY', false),
            zip: Yup.string().when(`country`, {
              is: (val) => val === 'US',
              then: regexYup.zipCode('POSTAL_CODE', false),
              otherwise: stringYup(255, 'POSTAL_CODE', false),
            }),
          })
          .nullable(),
      }),
    }
  }, [stringYup, regexYup])

  const formik = useFormik({
    initialValues: {
      show_header: !!data?.header ?? true,
      header: data?.header || '',
      show_order: data?.order !== false,
      order: data?.order || 'order_code',
      order_date: data?.order_date ?? true,
      // show_note: !!data?.note || false,
      // note: data?.note || '',
      ship_to: data?.ship_to ?? true,
      shipping_service: data?.shipping_service ?? true,
      tracking: data?.tracking ?? true,
      item_detail: data?.item_detail ?? true,
      show_barcode: data?.barcode !== false,
      barcode: data?.barcode || 'reference1',
      show_company_policy: !!data?.company_policy || false,
      company_policy: data?.company_policy || '',
      show_footer: !!data?.footer || false,
      footer: data?.footer || '',
      show_store_logo:
        (!isEmpty(data?.store_logo) && Object.values(data?.store_logo)?.some((logo) => logo)) ??
        false,
      store_logo: data?.store_logo || '',
      show_store_name: !!data?.store_name || data?.third_party_name || false,
      store_name: data?.store_name || '',
      third_party_name: data?.third_party_name ?? false,
      show_store_location: isEmptyArray(data?.store_location)
        ? true
        : data?.store_location === false
        ? false
        : Object.values(data?.store_location)?.some((location) => location),
      store_location: isEmptyArray(data?.store_location)
        ? data?.company_location
        : data?.store_location === false
        ? {
            address: '',
            address_2: '',
            city: '',
            state: '',
            country: '',
            zip: '',
          }
        : data?.store_location,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        if (isEmpty(values['store_logo'])) {
          values['store_logo'] = data.store_logo
        }
        const config = {
          cancelToken: newCancelToken(),
        }
        const payload = {
          header: values?.show_header && !isEmpty(values?.header) ? values?.header : false,
          order: values?.show_order && !isEmpty(values?.order) ? values?.order : false,
          order_date: values?.order_date,
          // note: values?.show_note && !isEmpty(values?.note) ? values?.note : false,
          ship_to: values?.ship_to,
          shipping_service: values?.shipping_service,
          tracking: values?.tracking,
          barcode: values?.show_barcode && !isEmpty(values?.barcode) ? values?.barcode : false,
          item_detail: values?.item_detail,
          company_policy:
            values?.show_company_policy && !isEmpty(values?.company_policy)
              ? values?.company_policy
              : false,
          footer: values?.show_footer && !isEmpty(values?.footer) ? values?.footer : false,
          store_logo:
            values?.show_store_logo && !isEmpty(values?.store_logo) ? values?.store_logo : false,
          store_name:
            values?.show_store_name && !isEmpty(values?.store_name) ? values?.store_name : false,
          third_party_name: values?.show_store_name ? values?.third_party_name : false,
          store_location:
            values?.show_store_location &&
            !Object.values(values?.store_location).every((field) => !field)
              ? values?.store_location
              : false,
        }
        payload['disable'] = Object.values(payload).every((field) => !field)
        const {message} = await SettingsService.updatePackingSlip(payload, config)
        if (message) {
          toast.success(intl.formatMessage({id: message}))
          if (values?.show_store_logo && !isEmpty(values?.store_logo)) {
            values['store_logo']['public'] = true
          }
          handleCloseModal()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const onSubmitFile = useCallback(
    (key, values) => {
      formik.setFieldValue('store_logo', values)
    },
    [formik]
  )

  const selectedPlace = (placeDetails) => {
    Object.entries(SettingsConfig.STORE_LOCATION).forEach(([key, value]: [any, any]) => {
      switch (value.value) {
        case SettingsConfig.STORE_LOCATION.CITY.value:
          let city = find(placeDetails.address_components, (item) => {
            return includes(item?.types, 'locality')
          })
          formik.setFieldValue(
            formik.getFieldProps(`store_location.${value.value}`).name,
            city?.long_name ?? ''
          )
          break
        case SettingsConfig.STORE_LOCATION.STATE.value:
          let state = find(placeDetails.address_components, (item) => {
            return includes(item?.types, 'administrative_area_level_1')
          })
          formik.setFieldValue(
            formik.getFieldProps(`store_location.${value.value}`).name,
            state?.short_name ?? ''
          )
          break
        case SettingsConfig.STORE_LOCATION.COUNTRY.value:
          let country = find(placeDetails.address_components, (item) => {
            return includes(item?.types, 'country')
          })
          let checkExistsCountry
          if (country?.short_name) {
            checkExistsCountry = OPTION_COUNTRIES.find((item) => item.value === country?.short_name)
          }
          formik.setFieldValue(
            formik.getFieldProps(`store_location.${value.value}`).name,
            checkExistsCountry ? country?.short_name : ''
          )
          break
        case SettingsConfig.STORE_LOCATION.ZIP.value:
          let zipCode = find(placeDetails.address_components, (item) => {
            return includes(item?.types, 'postal_code')
          })
          let suffix = find(placeDetails.address_components, (item) => {
            return includes(item?.types, 'postal_code_suffix')
          })
          if (zipCode?.long_name) {
            zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
          } else {
            zipCode = ''
          }
          formik.setFieldValue(formik.getFieldProps(`store_location.${value.value}`).name, zipCode)
          break
      }
    })
  }

  const checkboxRadio = (label, name) => {
    return (
      <>
        <div className='col-form-label fs-5 text-truncate me-4'>
          {intl.formatMessage({id: label})}
        </div>
        <div className='d-flex align-items-center mt-2'>
          <div>
            <InputCheckboxFormik
              formik={formik}
              type='radio'
              label={intl.formatMessage({id: 'YES'})}
              className={'pe-4'}
              checked={formik.values[name]}
              onClick={() => formik.setFieldValue(name, true)}
            />
          </div>
          <div>
            <InputCheckboxFormik
              formik={formik}
              type='radio'
              label={intl.formatMessage({id: 'NO'})}
              checked={!formik.values[name]}
              onClick={() => formik.setFieldValue(name, false)}
            />
          </div>
        </div>
      </>
    )
  }

  const previewPackingSlips = useMemo(() => {
    return (
      <div className='d-flex flex-column fw-bold'>
        <div className='my-4 text-center fs-5'>
          {intl.formatMessage({id: 'REFERENCE_IMAGE_OF_PACKING_SLIPS'})}
        </div>
        <div className='border border-2 border-secondary rounded-2 p-4 flex-fill'>
          <div className='bg-light px-4 py-1 rounded-2'>
            {formik.values.show_header ? (
              <div className='d-flex my-4'>
                <div className='text-primary col-md-3'>{intl.formatMessage({id: 'HEADER'})}</div>
                <div className='col-md-4 bg-secondary rounded-1' />
              </div>
            ) : (
              <div className='h-20px my-4' />
            )}
            {formik.values.show_order ? (
              <div className='d-flex my-4'>
                <div className='text-primary col-md-3'>{`${intl.formatMessage({
                  id: 'ORDER',
                })} #`}</div>
                <div className='col-md-4 bg-secondary rounded-1' />
              </div>
            ) : (
              <div className='h-20px my-4' />
            )}
            {formik.values.order_date ? (
              <div className='d-flex my-4'>
                <div className='text-primary col-md-3'>
                  {intl.formatMessage({id: 'ORDER_DATE'})}
                </div>
                <div className='col-md-4 bg-secondary rounded-1' />
              </div>
            ) : (
              <div className='h-20px my-4' />
            )}
            {/* <div className='d-flex my-4'>
              <div className='text-primary col-md-3'>{intl.formatMessage({id: 'NOTE'})}</div>
              <div className='flex-fill bg-secondary rounded-1' />
            </div> */}
          </div>
          <div className='m-4 d-flex'>
            {formik.values.ship_to ? (
              <div className='col-md-5'>
                <div className='text-primary'>{intl.formatMessage({id: 'SHIP_TO'})}</div>
                <div className='col-md-4 bg-light rounded-1 px-10 px-xxl-20 py-3 me-3 mt-2' />
                <div className='col-md-4 bg-light rounded-1 px-10 px-xxl-20 py-3 me-4 mt-2' />
              </div>
            ) : (
              <div className='col-md-5 h-70px' />
            )}
            <div className='col-md-7 flex-fill'>
              {formik.values.shipping_service ? (
                <div className='d-flex'>
                  <div className='text-primary col-6'>
                    {intl.formatMessage({id: 'SHIPPING_SERVICE'})}
                  </div>
                  <div className='flex-fill bg-light rounded-1' />
                </div>
              ) : (
                <div className='h-20px' />
              )}
              {formik.values.tracking ? (
                <div className='d-flex mt-4'>
                  <div className='text-primary col-6'>{`${intl.formatMessage({
                    id: 'TRACKING',
                  })} #`}</div>
                  <div className='flex-fill bg-light rounded-1' />
                </div>
              ) : (
                <div className='h-20px mt-4' />
              )}
            </div>
          </div>
          {formik.values.item_detail ? (
            <div className='m-4'>
              <div className='text-primary mb-2'>
                {intl.formatMessage({id: 'ITEM'})} {intl.formatMessage({id: 'DETAIL'})}
              </div>
              <div className='d-flex justify-content-around bg-light-primary p-2 rounded-pill text-uppercase'>
                <span>{intl.formatMessage({id: 'ITEM'})}</span>
                <span>{intl.formatMessage({id: 'SKU'})}</span>
                <span>{intl.formatMessage({id: 'QTY'})}</span>
                <span>{intl.formatMessage({id: 'PRICE'})}</span>
                <span>{intl.formatMessage({id: 'TOTAL'})}</span>
              </div>
              <div className='d-flex my-2'>
                <div className='w-100 h-25px bg-light rounded-1' />
              </div>
              <div className='d-flex justify-content-end'>
                <div className='d-flex flex-column col-md-4'>
                  {/* <div className='d-flex justify-content-between my-1'>
                  <div>{intl.formatMessage({id: 'SUBTOTAL'})}</div>
                  <div className='col-4 bg-light rounded-1' />
                </div>
                <div className='d-flex justify-content-between my-1'>
                  <div>{intl.formatMessage({id: 'TAX'})}</div>
                  <div className='col-4 bg-light rounded-1' />
                </div> */}
                  <div className='d-flex justify-content-between my-1'>
                    <div>{intl.formatMessage({id: 'TOTAL'})}</div>
                    <div className='col-4 bg-light rounded-1' />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='h-125px' />
          )}
          {formik.values.show_barcode ? (
            <div className='m-4 border-bottom pb-4 border-2'>
              <div className='d-flex justify-content-center align-items-center position-relative'>
                {Array.from({length: 25}).map((item, index) => (
                  <div key={index} className='w-8px mx-1 h-50px bg-light'></div>
                ))}
                <div className='position-absolute text-primary fs-5'>
                  {intl.formatMessage({id: 'BARCODE'})}
                </div>
              </div>
            </div>
          ) : (
            <div className='h-60px border-bottom pb-4 border-2' />
          )}
          <div className='m-4'>
            {formik.values.show_company_policy ? (
              <div>
                <div className='text-primary'>{intl.formatMessage({id: 'COMPANY_POLICY'})}</div>
                <div className='mb-4'>
                  <div className='flex-fill bg-light rounded-1 py-2 mt-2' />
                  <div className='flex-fill bg-light rounded-1 py-2 mt-2' />
                  <div className='flex-fill bg-light rounded-1 py-2 my-2' />
                </div>
              </div>
            ) : (
              <div className='h-80px' />
            )}
            {formik.values.show_footer ? (
              <div className='bg-light text-primary rounded-1 py-2 text-center'>
                {intl.formatMessage({id: 'FOOTER'})}
              </div>
            ) : (
              <div className='h-30px' />
            )}
          </div>
          <div className='m-4 d-flex justify-content-center text-primary'>
            {formik.values.show_store_logo ? (
              <div className='bg-light px-10 py-2 d-flex justify-content-center align-items-center'>
                {intl.formatMessage({id: 'STORE_LOGO'})}
              </div>
            ) : (
              <div className='h-50px w-125px py-2' />
            )}
            <div className='ms-4'>
              {formik.values.show_store_name ? (
                <div className='bg-light mb-2 text-center px-2 py-1'>
                  {intl.formatMessage({id: 'STORE_NAME'})}
                </div>
              ) : (
                <div className='h-30px w-100px py-2' />
              )}
              {formik.values.show_store_location ? (
                <div className='bg-light text-center px-2 py-1'>
                  {intl.formatMessage({id: 'STORE_LOCATION'})}
                </div>
              ) : (
                <div className='h-30px w-100px py-2' />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }, [formik.values, intl])

  return (
    <Modal
      id='gori_modal_settings_packing_slips'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-1250px h-auto'
      show={show}
      backdrop='static'
      onHide={handleCloseModal}
    >
      <div className='modal-content pd'>
        {validationErrors && (
          <ValidationErrorModal
            handleClose={() => {
              setValidationErrors(undefined)
            }}
            response={validationErrors}
          />
        )}
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'PACKING_SLIP_CONTENT'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-5'>{previewPackingSlips}</div>
            <div className='col-md-7'>
              <div className='vh-75 scroll-y'>
                <div className='me-3'>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_header'
                      label={intl.formatMessage({id: 'HEADER'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_header ? (
                      <InputTextFormik className='col-4' formik={formik} name='header' />
                    ) : (
                      <div className='h-40px' />
                    )}
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_order'
                      label={`${intl.formatMessage({id: 'ORDER'})} #`}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    <div
                      className={clsx('col-4', {
                        'opacity-50 cursor-no-drop': !formik.values.show_order,
                      })}
                    >
                      <SelectFormik
                        className={clsx({'pe-none ': !formik.values.show_order})}
                        options={SettingsConfig.OPTIONS_ORDER}
                        formik={formik}
                        name='order'
                        emptyDefault={false}
                        hasUseIntl
                      />
                    </div>
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='order_date'
                      label={intl.formatMessage({id: 'ORDER_DATE'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                  </div>
                  {/* <div className='d-flex my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_note'
                      label={intl.formatMessage({id: 'NOTE'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_note && (
                      <InputTextArea className='flex-fill' formik={formik} name='note' />
                    )}
                  </div> */}
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='ship_to'
                      label={intl.formatMessage({id: 'SHIP_TO'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='shipping_service'
                      label={intl.formatMessage({id: 'SHIPPING_SERVICE'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='tracking'
                      label={`${intl.formatMessage({id: 'TRACKING'})} #`}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='item_detail'
                      label={intl.formatMessage({id: 'ITEM_DETAIL'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_barcode'
                      label={intl.formatMessage({id: 'BARCODE'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    <div
                      className={clsx('col-4', {
                        'opacity-50 cursor-no-drop': !formik.values.show_barcode,
                      })}
                    >
                      <SelectFormik
                        className={clsx({'pe-none ': !formik.values.show_barcode})}
                        options={SettingsConfig.OPTIONS_BARCODE}
                        formik={formik}
                        name='barcode'
                        emptyDefault={false}
                        hasUseIntl
                      />
                    </div>
                  </div>
                  <div className='d-flex my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_company_policy'
                      label={intl.formatMessage({id: 'COMPANY_POLICY'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_company_policy && (
                      <InputTextArea className='flex-fill' formik={formik} name='company_policy' />
                    )}
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_footer'
                      label={intl.formatMessage({id: 'FOOTER'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_footer && (
                      <InputTextFormik className='col-4' formik={formik} name='footer' />
                    )}
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_store_logo'
                      label={intl.formatMessage({id: 'STORE_LOGO'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_store_logo && (
                      <ImageCrop
                        onSubmit={(value) => onSubmitFile('store_logo', value)}
                        formik={formik}
                        name={'store_logo'}
                        size={sizeCrop}
                        currentImg={data?.store_logo}
                      />
                    )}
                  </div>
                  <div className='d-flex align-items-center my-4'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_store_name'
                      label={intl.formatMessage({id: 'STORE_NAME'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_store_name && (
                      <InputTextFormik formik={formik} className={'col-4'} name='store_name' />
                    )}
                  </div>
                  {formik.values.show_store_name && (
                    <div className='d-flex justify-content-center my-4'>
                      {checkboxRadio(
                        'UTILIZE_THE_EXISTING_3RD_PARTY_STORE_NAME',
                        'third_party_name'
                      )}
                    </div>
                  )}
                  <div className='d-flex flex-column my-4 bg-light px-4 pt-4 rounded-2'>
                    <InputCheckboxFormik
                      type='switch'
                      formik={formik}
                      name='show_store_location'
                      label={intl.formatMessage({id: 'STORE_LOCATION'})}
                      labelClassName={'ms-4'}
                      className={'col-4'}
                    />
                    {formik.values.show_store_location && (
                      <div className='row'>
                        {Object.values(SettingsConfig.STORE_LOCATION).map((row, key) => {
                          switch (row.value) {
                            case SettingsConfig.STORE_LOCATION.ADDRESS.value:
                              return (
                                <div className='col-md-6 my-2 ps-store-location' key={key}>
                                  <AutocompleteAddress
                                    formik={formik}
                                    label={intl.formatMessage({id: row.label})}
                                    name={`store_location.${row.value}`}
                                    onSelected={(selected) => {
                                      selectedPlace(selected)
                                    }}
                                    required={false}
                                  />
                                </div>
                              )
                            case SettingsConfig.STORE_LOCATION.COUNTRY.value:
                              return (
                                <div className='col-md-6 my-2' key={key}>
                                  <div className='mb-8 d-flex '>
                                    <SelectFormik
                                      className='col-9'
                                      labelClassName='col-3 col-form-label'
                                      label={intl.formatMessage({id: row.label})}
                                      options={OPTION_COUNTRIES}
                                      formik={formik}
                                      name={`store_location.${row.value}`}
                                    />
                                  </div>
                                </div>
                              )
                            default:
                              return (
                                <div className='col-md-6 my-2' key={key}>
                                  <div className='d-flex'>
                                    <InputTextFormik
                                      className='col-9'
                                      labelClassName='form-label col-3 col-form-label'
                                      label={intl.formatMessage({id: row.label})}
                                      formik={formik}
                                      name={`store_location.${row.value}`}
                                    />
                                  </div>
                                </div>
                              )
                          }
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              loadingText={intl.formatMessage({id: 'CANCEL'})}
              event={handleCloseModal}
            />
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'SAVE'})}
              loadingText={intl.formatMessage({id: 'SAVE'})}
              event={formik.submitForm}
              loading={isLoading}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {PackingSlipsModal}
