import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {Button, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import OrderService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const CancelOrderModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      order_ids: [],
    },
    onSubmit: async () => {
      const newItems = orders.map((item: any) => {
        return item.original.id
      })
      let payload = {
        order_ids: newItems,
      }

      setIsLoadingForm(true)
      try {
        const res = await OrderService.cancel(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'CANCELLED_ORDER_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_cancel_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'CANCEL_ORDERS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mx-10 my-5 d-flex flex-column justify-content-center align-items-center fs-4'>
              <div className='mb-5'>
                <img src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')} alt='img' />
              </div>
              <p>
                <b>
                  {orders.length +
                    ' ' +
                    (orders.length === 1
                      ? intl.formatMessage({id: 'ORDER'})
                      : intl.formatMessage({id: 'ORDERS'}))}
                </b>{' '}
                {intl.formatMessage(
                  {id: 'CANCEL_ORDER_TEXT'},
                  {brand: intl.formatMessage({id: 'BRAND_NAME'})}
                )}
              </p>
              <p>{intl.formatMessage({id: 'CANCEL_ORDER_TEXT_ADDITIONAL'})}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CancelOrderModal}
