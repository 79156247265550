import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../auth'
import {STEPS} from '../core/_const'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

type Props = {
  setActiveStep: any
}

const Welcome: React.FC<Props> = ({setActiveStep}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep(STEPS.hazmat)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='welcome text-center'>
        <img
          alt='welcome'
          src={toAbsoluteUrl('/media/gori/onboard/welcome/solution_motion_image.gif')}
          className='welcome-img'
        />
        <p>
          {intl.formatMessage({id: 'WELCOME'}) +
            ' ' +
            currentUser?.first_name +
            ' ' +
            currentUser?.last_name}
          <img
            alt='welcome'
            src={toAbsoluteUrl('/media/gori/onboard/welcome/party-popper.png')}
            className='partypopper'
          />
        </p>
      </div>
    </CSSTransition>
  )
}

export {Welcome}
