import React, {useState} from 'react'

function BlurredText({text, status, onClick}) {
  const [revealed, setRevealed] = useState<boolean>(false)

  const toggleReveal = () => {
    setRevealed(!revealed)
  }

  return (
    <span
      onClick={onClick || toggleReveal}
      style={{
        cursor: 'pointer',
        userSelect: 'none', // Prevent text selection
        MozUserSelect: 'none', // Firefox
        WebkitUserSelect: 'none', // Safari
        msUserSelect: 'none', // Internet Explorer/Edge
      }}
    >
      {status || revealed ? text : <span style={{filter: 'blur(5px)'}}>{text}</span>}
    </span>
  )
}

export default BlurredText
