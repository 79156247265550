import {useFormik} from 'formik'
import {cloneDeep} from 'lodash'
import moment from 'moment'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../_gori/helpers'
import {
  Button,
  InputDate,
  InputTextFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import PaymentsService from '../../../deposits/core/_requests'
import {UploadMultipleFile} from '../../../claims'
import FundDoneModal from './FundDoneModal'

type Props = {
  show: boolean
  handleCloseModal: () => void
  nextStep: any
}

const WireTransferModal: React.FC<Props> = ({show, handleCloseModal, nextStep}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {numberYup, arrayRequiredYup, dateYup} = UseYupValidate()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [showDoneModal, setShowDoneModal] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [showModal, setShowModal] = useState<any>(show)
  const [loadingDone, setLoadingDone] = useState<any>(false)
  const [wireDate, setWireDate] = useState<string>()
  const handleClose = () => {
    formik.resetForm()
    handleCloseModal()
  }

  const initialValues = useMemo(() => {
    return {
      amount: null,
      date: moment(),
      invoices: [],
    }
  }, [])

  const validateSchema = {
    amount: numberYup.amount('WIRE_AMOUNT'),
    date: dateYup('WIRE_DATE'),
    invoices: arrayRequiredYup('WIRE_TRANSFER_CONFIRMATION'),
  }

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      let payloads = cloneDeep({...values, date: values.date.format()})

      let invoices: any = []
      payloads.invoices.forEach((invoice: {path: string}) => invoices.push(invoice.path))
      payloads.invoices = invoices

      try {
        setIsLoadingForm(true)
        const {success} = await PaymentsService.wireTransfer(payloads, {
          cancelToken: newCancelToken(),
        })
        if (success) {
          toast.success(intl.formatMessage({id: 'PAYMENT_SUCCESS'}))
          setWireDate(formik.values.date.format(process.env.REACT_APP_DATE_FORMAT))
          setShowDoneModal(true)
          setShowModal(false)
        } else {
          toast.error(intl.formatMessage({id: 'PAYMENT_ERROR'}))
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  const onSubmitInvoices = useCallback(
    (values) => {
      formik.setFieldValue(formik.getFieldProps('invoices').name, values)
    },
    [formik]
  )

  const handleCloseDoneModal = async () => {
    setLoadingDone(true)
    await nextStep()
    setShowDoneModal(false)
    handleClose()
  }

  const bankInfo = [
    {
      label: 'COMPANY_NAME',
      value: 'Gori Company',
    },
    {
      label: 'COMPANY_ADDRESS',
      value: '1047 S Placentia Avenue, Fullerton CA 92831',
    },
    {
      label: 'BANK_NAME',
      value: 'BMO Bank N.A.',
    },
    {
      label: 'BANK_ADDRESS',
      value: '320 South Canal St. Chicago IL, 60606',
    },
    {
      label: 'ROUTING_NUMBER',
      value: '071025661',
    },
    {
      label: 'ACCOUNT_NUMBER',
      value: '079156972',
    },
    {
      label: 'SWIFT_CODE',
      value: 'HATRUS44',
    },
  ]

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_credit_card'
        tabIndex={-1}
        aria-hidden='true'
        centered
        show={showModal}
        backdrop='static'
        onHide={handleClose}
        dialogClassName='mw-650px h-auto'
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'WIRE_TRANSFER'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <div>
            <div className='text-gray-700 fw-bold fs-8'>{intl.formatMessage({id: 'STEP_1'})}</div>
            <h3 className='fw-bolder fs-2'>{intl.formatMessage({id: 'SEND_FUND'})}</h3>
            <div className='text-gray-700 fw-bold'>
              {intl.formatMessage({
                id: 'PLEASE_USE_THE_WIRE_INFO_BELOW',
              })}
            </div>
            <div className='rounded bg-light-primary py-5 px-10 mt-5 mb-10 mx-3'>
              {bankInfo.map((item) => (
                <div className='d-flex mb-1'>
                  <div className='text-gray-700 fw-bold fs-8 col-4'>
                    {intl.formatMessage({id: item.label})}:
                  </div>
                  <div className='fw-bolder fs-6'>{item.value}</div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className='text-gray-700 fw-bold fs-8'>{intl.formatMessage({id: 'STEP_2'})}</div>
            <h3 className='fw-bolder fs-2'>
              {intl.formatMessage({id: 'SUBMIT_WIRE_TRANSACTION_DETAIL'})}
            </h3>
            <div className='text-gray-700 fw-bold'>
              {intl.formatMessage({
                id: 'FILL_OUT_WIRE_DATE_AND_AMOUNT_AND_UPLOAD_AN_IMAGE_OF_YOUR_WIRE_TRANSFER_CONFIRMATION',
              })}
            </div>
            <div className='mt-5 mb-8 bg-light p-5 rounded'>
              <div className='row mb-4'>
                <div className='col-6'>
                  <InputDate
                    labelClassName='form-label text-gray-800 fw-bold fs-7'
                    label={intl.formatMessage({id: 'WIRE_DATE'})}
                    formik={formik}
                    name={'date'}
                    required
                  />
                </div>
                <div className='col-6'>
                  <InputTextFormik
                    type='number'
                    min={0}
                    placeholder='$'
                    labelClassName='form-label text-gray-800 fw-bold fs-7'
                    required
                    label={intl.formatMessage({id: 'WIRE_AMOUNT'}) + ' (USD)'}
                    formik={formik}
                    name='amount'
                    isCurrency
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <UploadMultipleFile
                    labelClassName='form-label text-gray-800 fw-bold fs-7'
                    label={intl.formatMessage({id: 'WIRE_TRANSFER_CONFIRMATION'})}
                    required
                    onSubmit={onSubmitInvoices}
                    formik={formik}
                    name={'invoices'}
                    direction
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex text-gray-700 fw-bold fs-7'>
            <KTSVG path='/media/gori/account/attention.svg' className='svg-icon-3 me-2' />
            {intl.formatMessage({
              id: 'WIRE_TRANSACTIONS_MAY_TAKE_UP_TO_3_BUSINESS_DAYS',
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-light me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              event={handleClose}
            />
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'SUBMIT'})}
              loadingText={intl.formatMessage({id: 'SUBMIT'})}
              event={formik.submitForm}
              loading={isLoadingForm}
              disabled={isLoadingForm}
            />
          </div>
        </Modal.Footer>
      </Modal>

      <FundDoneModal
        formik={formik}
        handleCloseDoneModal={handleCloseDoneModal}
        showDoneModal={showDoneModal}
        wireDate={wireDate}
        loadingDone={loadingDone}
      />
    </>
  )
}

export {WireTransferModal}
