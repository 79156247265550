import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import {BatchesConfig} from '../core/_const'
import BatchService from '../core/_requests'

type Props = {
  show: boolean
  orderIds: any
  handleClose: () => void
}

const CreateAllLabelsModal: React.FC<Props> = ({show, orderIds, handleClose}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [createdLabel, setCreatedLabel] = useState<number>(0)
  const [failedLabel, setFailedLabel] = useState<number>(0)
  const [percentage, setPercentage] = useState<number>(0)

  const arrCreateLabel = useMemo(() => {
    const arrNew: any = []
    for (let i = 0; i < orderIds.length; i += BatchesConfig.COUNT_CREATE_ALL_LABELS) {
      arrNew.push(orderIds.slice(i, i + BatchesConfig.COUNT_CREATE_ALL_LABELS))
    }
    return arrNew
  }, [orderIds])

  const createLabel = useCallback(async () => {
    for (let i = 0; i < arrCreateLabel.length; i++) {
      try {
        await Promise.all(
          arrCreateLabel[i].map((id) => {
            return BatchService.createLabel({id: id}, {cancelToken: newCancelToken()})
              .then((response) => {
                if (!response.label_url) {
                  setFailedLabel((prev) => prev + 1)
                } else {
                  setCreatedLabel((prev) => prev + 1)
                }
              })
              .catch(() => {
                setFailedLabel((prev) => prev + 1)
              })
          })
        )
      } catch (error) {
        if (isCancel(error)) return
      }
    }
  }, [arrCreateLabel, isCancel, newCancelToken])

  useEffect(() => {
    setPercentage(Math.round(((createdLabel + failedLabel) / orderIds.length) * 100))
  }, [createdLabel, failedLabel, orderIds.length])

  useEffect(() => {
    createLabel()
  }, [createLabel])

  return (
    <Modal
      id='gori_create_all_labels_modal'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-300px h-auto'
      show={show}
      onHide={handleClose}
      backdrop='static'
    >
      <div className='modal-content'>
        <Modal.Header>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'LABELS_CREATION'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {intl.formatMessage(
              {id: 'CREATED_LABEL_OUT_OF_TOTAL_LABELS'},
              {process: createdLabel, total: orderIds.length}
            )}
          </div>
          <div className='text-danger'>
            {failedLabel > 0 &&
              intl.formatMessage({id: 'FAILED_TO_CREATE_LABELS'}, {failed: failedLabel})}
          </div>
          <div className='mt-5'>
            <div className='progress h-30px'>
              <div
                role='progressbar'
                className='progress-bar bg-image-gori'
                style={{width: `${percentage}%`}}
                aria-valuenow={percentage}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {percentage >= 100 ? 100 : Math.ceil(percentage)}%
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`btn btn-${percentage >= 100 ? 'primary' : 'danger'} d-flex`}
            label={
              percentage >= 100
                ? intl.formatMessage({id: 'DONE'})
                : intl.formatMessage({id: 'STOP'})
            }
            event={handleClose}
          />
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {CreateAllLabelsModal}
