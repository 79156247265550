import {FC} from 'react'
import {useIntl} from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl()

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        {intl.formatMessage({id: 'PAGE_NOT_FOUND'})}
      </h1>
      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        {intl.formatMessage({id: 'THE_PAGE_YOU_LOOKED_NOT_FOUND'})}
        <br />
        {intl.formatMessage({id: 'PLAN_YOUR_BLOG_POST_BY_CHOOSING_A_TOPIC'})}
      </div>
    </>
  )
}

export {Error404}
