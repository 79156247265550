import React, {useState} from 'react'
import {ButtonBack} from '../../../../../../_gori/partials/widgets'
import {ButtonLabelCreation} from '../../../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {KTSVG, StorageHelpers, toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {STEPS} from '../../../core/_const'
import {useFormik} from 'formik'
import OnboardService from '../../../core/_requests'
import UserService from '../../../../users/core/_requests'
import {getCompany} from '../../../core/_helper'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {useAuth} from '../../../../auth'

type Props = {
  setActiveStep: any
  active: any
  payloadOption: string | null
}
const CURRENT_COMPANY_STORAGE =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const FooterActions: React.FC<Props> = ({active, setActiveStep, payloadOption}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {setCurrentUser, setCurrentCompany} = useAuth()

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      setIsLoadingForm(true)
      let payload = {
        step: 'label_creation',
        data: {
          option: payloadOption,
        },
      }
      try {
        const res = await OnboardService.store(payload, {cancelToken: newCancelToken()})
        if (res) {
          const userAuth = await UserService.getUser({cancelToken: newCancelToken()})
          const newCompany = await getCompany(userAuth.companies)
          if (newCompany) {
            StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE, newCompany)
            StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY, newCompany.encrypt_key)
            setCurrentCompany(newCompany)
          }
          setCurrentUser(userAuth)
          navigate('/dashboard')
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setIsLoadingForm(false)
        StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', false)
        StorageHelpers.setItemLocalStorage('IS_LOADING', false)
      }
    },
  })

  const backStep = () => {
    setActiveStep(STEPS.add_fund)
  }

  if (!active || payloadOption === 'connect_a_store') {
    return null
  }

  return (
    <div className='col-12 d-flex justify-content-between mt-15'>
      <ButtonBack
        event={() => backStep()}
        className='back onboard-form'
        label={intl.formatMessage({id: 'BACK'})}
      />
      <ButtonLabelCreation
        className='btn btn-primary'
        label={intl.formatMessage({id: 'LET_SHIP'})}
        loadingText={intl.formatMessage({id: 'LET_SHIP'})}
        children={
          <img
            alt='welcome'
            src={toAbsoluteUrl('/media/gori/onboard/welcome/party-popper.png')}
            className='partypopper'
          />
        }
        event={() => {
          formik.submitForm()
        }}
        loading={isLoadingForm}
      />
      <ButtonLabelCreation
        className='btn btn-light'
        label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
        loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
        event={() => {
          navigate('/logout')
        }}
      >
        <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
      </ButtonLabelCreation>
    </div>
  )
}

export default FooterActions
