import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {StorageHelpers} from '../../../../../../../_gori/helpers'
import {LoadingPage} from '../../../../../../../_gori/helpers/components/LoadingPage'
import {useAuth} from '../../../../../auth'
import {OrderType} from '../../../../../orders/core/_const'
import SettingsService from '../../../../core/_requests'

const VerifyTikTok: React.FC = () => {
  const intl = useIntl()
  const [queryParams] = useSearchParams()
  const app_key = queryParams.get('app_key')
  const code = queryParams.get('code')
  const locale = queryParams.get('locale')
  const shop_region = queryParams.get('shop_region')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {currentUser} = useAuth()

  const connectTikTok = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await SettingsService.connect({
        app_key: app_key,
        code: code,
        locale: locale,
        shop_region: shop_region,
        store_type: OrderType.TIKTOK,
      })
      if (res) {
        toast.success(intl.formatMessage({id: 'CONNECTIONS_SUCCESSFUL'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
    } finally {
      setIsLoading(false)
      if (currentUser?.onboard_step === 'done') {
        navigate('/settings/connections/tiktok')
      } else {
        StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
        navigate('/your-info')
      }
    }
  }, [app_key, code, locale, shop_region, intl, currentUser?.onboard_step, navigate])

  useEffect(() => {
    connectTikTok()
  }, [connectTikTok])

  return isLoading ? <LoadingPage className='start-0' /> : <></>
}

export {VerifyTikTok}
