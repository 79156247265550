export const ShipmentStatus = {
  PRE_TRANSIT: 0,
  IN_TRANSIT: 1,
  AVAILABLE_FOR_PICKUP: 2,
  DELIVERED: 3,
  RETURN_TO_SENDER: 4,
  FAILURE: 5,
  CANCELLED: 6,
  ERROR: 7,
  UNKNOWN: 8,
  OLD: 9,
}

export const ShipmentStatusLabel = {
  [ShipmentStatus.PRE_TRANSIT]: 'pre_transit',
  [ShipmentStatus.IN_TRANSIT]: 'in_transit',
  [ShipmentStatus.AVAILABLE_FOR_PICKUP]: 'available_for_pickup',
  [ShipmentStatus.DELIVERED]: 'delivered',
  [ShipmentStatus.RETURN_TO_SENDER]: 'return_to_sender',
  [ShipmentStatus.FAILURE]: 'failure',
  [ShipmentStatus.CANCELLED]: 'cancelled',
  [ShipmentStatus.ERROR]: 'error',
  [ShipmentStatus.UNKNOWN]: 'unknown',
  [ShipmentStatus.OLD]: 'old',
}

export const ShipmentRefund = {
  SUBMITTED: 'submitted',
  REFUNDED: 'refunded',
  REJECTED: 'rejected',
}

export const ShipmentRefundLabel = {
  [ShipmentRefund.SUBMITTED]: 'REFUND_PROCESSING',
  [ShipmentRefund.REFUNDED]: 'REFUNDED',
  [ShipmentRefund.REJECTED]: 'REFUND_REJECTED',
}

export const SET_UP_MODAL_ALL = {
  REFUND_REQUEST: {
    key: 'REQUEST_REFUND',
    title: 'REQUEST_REFUND',
    process: 'REQUEST_REFUND_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_REQUEST_REFUNDS',
    countCallApi: 10,
  },
  RETURN_LABELS: {
    key: 'RETURN_LABELS',
    title: 'RETURN_LABELS',
    process: 'RETURN_LABEL_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_RETURN_LABELS',
    countCallApi: 10,
  },
}
