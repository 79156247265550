import CryptoJS from 'crypto-js'
import RequestBase from '../../../api/RequestBase'
import {AuthModel} from './_models'
import {StorageHelpers} from '../../../../_gori/helpers'
import HttpStatusCode from '../../../api/HttpStatusCode'

const AUTH_STORAGE_KEY = process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || 'AUTH_STORAGE_KEY'
const SIGNATURE_ACCESS_KEY: any = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = StorageHelpers.getItemLocalStorage(AUTH_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    var bytes = CryptoJS.AES.decrypt(lsValue, SIGNATURE_ACCESS_KEY)
    var originalText = bytes.toString(CryptoJS.enc.Utf8)
    const auth: AuthModel = JSON.parse(originalText) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = CryptoJS.AES.encrypt(JSON.stringify(auth), SIGNATURE_ACCESS_KEY).toString()
    StorageHelpers.setItemLocalStorage(AUTH_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    StorageHelpers.storageClear()
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  axios.interceptors.request.use(
    (config: {url: string; headers: {Authorization: string; TokenId: string}}) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    async (error: any) => {
      if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
        return RequestBase.redirect('/logout')
      } else if (error?.response?.status === HttpStatusCode.TEMPORARY_REDIRECT) {
        return RequestBase.redirect('/maintenance')
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export {getAuth, removeAuth, setAuth}
