import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, InputSearch, TableHeader} from '../../../../../../_gori/partials/widgets'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'
import {InviteUserModal} from '../../../../settings'

type Props = {
  reloadTable: any
}

const UsersFilter: React.FC<Props> = ({reloadTable}) => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const [showModal, setShowModal] = useState<{createUser: boolean; inviteUser: boolean}>({
    createUser: false,
    inviteUser: false,
  })

  return (
    <>
      {showModal.inviteUser && (
        <InviteUserModal
          show={showModal.inviteUser}
          handleClose={() => {
            setShowModal((prev) => ({...prev, inviteUser: false}))
          }}
          reloadTable={reloadTable}
        />
      )}
      <TableHeader className='card-title d-flex flex-wrap gap-2 justify-content-between align-items-start p-0'>
        <div className='card-toolbar flex-row-fluid justify-content-between align-items-start'>
          <div className='d-flex align-items-center position-relative'>
            <InputSearch placeholder={intl.formatMessage({id: 'SEARCH_USERS'})} />
          </div>
          {routes.USERS_INVITE.hasPermission && (
            <Button
              className='btn text-white text-hover-primary bg-primary bg-hover-light-primary btn-sm fs-5'
              label={intl.formatMessage({id: 'INVITE_USER'})}
              event={() => setShowModal((prev) => ({...prev, inviteUser: true}))}
            />
          )}
        </div>
      </TableHeader>
    </>
  )
}

export {UsersFilter}
