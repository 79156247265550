import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import PaymentsService from '../../../deposits/core/_requests'
import {Button, InputTextFormik} from '../../../../../_gori/partials/widgets'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {useMeter} from '../../../../../_gori/helpers/components/MeterProvider'
import {convertCurrency, toAbsoluteUrl} from '../../../../../_gori/helpers'

type Props = {
  show: boolean
  handleCloseModal: () => void
  nextStep: any
}

const CreditCardModal: React.FC<Props> = ({show, handleCloseModal, nextStep}) => {
  const intl = useIntl()
  const {numberYup} = UseYupValidate()
  const {setMeterChange} = useMeter()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [showCreditDoneModal, setShowCreditDoneModal] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<any>(show)
  const [loadingDone, setLoadingDone] = useState<any>(false)

  const validateSchema = {
    amount: numberYup.amount('FUNDING_AMOUNT'),
  }

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSubmitForm(values)
    },
  })

  const handleSubmitForm = async (fields) => {
    setIsLoadingForm(true)
    try {
      const res = await PaymentsService.getElavonToken({...fields})
      if (res) {
        const {token} = res
        openLightbox(token)
      }
    } catch (error: any) {
      toast.error(error)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const handleSendApprovalElavon = async (data) => {
    setIsLoadingForm(true)
    try {
      const res = await PaymentsService.approvalElavon({...data})
      if (res) {
        setMeterChange(true)
        toast.success(intl.formatMessage({id: 'PAYMENT_SUCCESS'}))
        setShowModal(false)
        setShowCreditDoneModal(true)
      }
    } catch (error: any) {
      toast.error(error)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const showResult = (status, response) => {
    if (status === 'approval') {
      handleSendApprovalElavon(JSON.parse(response)).then(() => {})
    } else if (status === 'cancelled') {
      toast.warning(intl.formatMessage({id: 'PAYMENT_CANCEL'}))
    } else {
      toast.error(intl.formatMessage({id: 'PAYMENT_ERROR'}))
    }
  }

  const openLightbox = (token) => {
    const paymentFields = {
      ssl_txn_auth_token: token,
    }

    const callback = {
      onError: (error) => {
        showResult('error', error)
      },
      onCancelled: () => {
        showResult('cancelled', '')
      },
      onDeclined: (response) => {
        showResult('declined', JSON.stringify(response, null, '\t'))
      },
      onApproval: (response) => {
        showResult('approval', JSON.stringify(response, null, '\t'))
      },
    }
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.PayWithConverge.open(paymentFields, callback)
    }
  }

  const handleClose = () => {
    formik.resetForm()
    handleCloseModal()
  }

  const handleCloseDoneModal = async () => {
    setLoadingDone(true)
    await nextStep()
    setShowCreditDoneModal(false)
    handleClose()
    formik.resetForm()
  }

  return (
    <>
      <Modal
        id='gori_modal_credit_card'
        tabIndex={-1}
        aria-hidden='true'
        centered
        show={showModal}
        backdrop='static'
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'CREDIT_DEBIT_CARD'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column'>
            <InputTextFormik
              type='number'
              min={0}
              className='my-3'
              labelClassName='form-label'
              required
              label={intl.formatMessage({id: 'FUNDING_AMOUNT'})}
              formik={formik}
              name='amount'
            />
            <p className='fw-bold'>
              {intl.formatMessage({
                id: 'PLEASE_NOTE:_A_3.0%_CREDIT_CARD_PROCESSING_FEE_WILL_BE_ADDED_TO_THE_FUNDING_AMOUNT',
              })}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-sm btn-light me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              event={handleClose}
            />
            <Button
              className='btn btn-sm btn-primary align-self-end'
              label={intl.formatMessage({id: 'PROCEED'})}
              loadingText={intl.formatMessage({id: 'SUBMIT'})}
              disabled={isLoadingForm}
              event={formik.submitForm}
              loading={isLoadingForm}
            />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id='gori_modal_add_fund_done'
        tabIndex={-1}
        aria-hidden='true'
        centered
        show={showCreditDoneModal}
        backdrop='static'
        onHide={() => handleCloseDoneModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'WIRE_TRANSFER'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <div className='text-center'>
            <img
              alt={intl.formatMessage({id: 'WIRE_TRANSFER'})}
              src={toAbsoluteUrl('media/gori/onboard/add-fund/processed.png')}
              className='h-200px m-1'
            />
          </div>
          <div
            className='text-submitted mb-5'
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {id: 'YOUR_CREDIT_TRANSACTION'},
                {amount: convertCurrency(formik.values.amount)}
              ),
            }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-sm btn-primary'
              label={intl.formatMessage({id: 'CLOSE'})}
              event={() => handleCloseDoneModal()}
              disabled={loadingDone}
              loading={loadingDone}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {CreditCardModal}
